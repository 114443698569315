import Joi from 'joi';
import { IntlShape } from 'react-intl';

import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';
import { Seniority } from 'api/actions/seniorities/senioritiesActions.types';

import { SeniorityModalFormFields } from './SeniorityModal.types';

const VISIBLE_NAME_MAX_LENGTH = 100;
const FIND_MULTIPLE_SPACES_REGEX = /  +/g;

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) =>
  Joi.object({
    [SeniorityModalFormFields.Name]: Joi.string().required(),
    [SeniorityModalFormFields.VisibleName]: Joi.string()
      .max(VISIBLE_NAME_MAX_LENGTH)
      .trim()
      .replace(FIND_MULTIPLE_SPACES_REGEX, ' ')
      .allow('', null)
      .empty(['', null])
      .default(null)
      .messages(
        getErrorMessages({
          formatMessage,
          maxLength: VISIBLE_NAME_MAX_LENGTH,
        }),
      ),
  });

export const getDefaultValues = (seniority: Seniority) => ({
  [SeniorityModalFormFields.Name]: seniority.name,
  [SeniorityModalFormFields.VisibleName]: seniority.visibleName === null ? '' : seniority.visibleName,
});
