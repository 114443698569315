import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';

import { ExpireDateProps } from './ExpireDate.types';

export const ExpireDate = ({ expiredAt }: ExpireDateProps) => {
  const displayedText = expiredAt ? format(parseISO(expiredAt), 'yyyy-LL-dd') : '-';

  return <Box component="span">{displayedText}</Box>;
};
