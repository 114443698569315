import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Certificate } from 'api/actions/certificates/certificatesActions.types';
import { useTagsGet } from 'api/actions/tags/tagsActions';
import { Tags } from 'ui';

import { Actions } from './columns/Actions';

const columnHelper = createColumnHelper<Certificate>();

export const useCertificatesColumns = () => {
  const { data: tagsData } = useTagsGet();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          id: 'name',
          header: () => <FormattedMessage id="certificates.table.columns.name" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('tags', {
          id: 'tags',
          header: () => <FormattedMessage id="certificates.table.columns.tags" />,
          cell: ({ getValue }) =>
            getValue() ? <Tags tags={getValue()?.map((el) => ({ name: el.tag.name }))} /> : null,
          enableSorting: false,
          enableColumnFilter: false,
          meta: {
            filterType: 'select',
            filterOptions: tagsData?.result.map(({ id, name }) => ({ id, name })),
          },
        }),
        columnHelper.accessor('numberOfUsages', {
          id: 'numberOfUsages',
          header: () => <FormattedMessage id="certificates.table.columns.numberOfUsages" />,
          cell: ({ getValue }) => <>{getValue()}</>,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('id', {
          id: 'actions',
          header: () => <FormattedMessage id="certificates.table.columns.actions" />,
          cell: Actions,
          enableSorting: false,
          enableColumnFilter: false,
        }),
      ] as ColumnDef<Certificate>[],
    [tagsData],
  );

  return columns;
};
