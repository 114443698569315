import { Divider as MuiDivider } from '@mui/material';

import { mergeStyles } from 'utils/mergeStyles';

import { DividerProps } from './Divider.types';
import * as S from './Divider.styles';

export const Divider = ({ sx = {} }: DividerProps) => {
  return <MuiDivider sx={mergeStyles(S.divider, sx)} />;
};
