export const TECH_SKILLS_MOCK = [
  {
    category: 'PHP',
    skills: [
      { name: 'symphony', years: 5 },
      { name: 'zend', years: 2 },
    ],
  },
  {
    category: 'Node.js',
    skills: [
      { name: 'Express.js', years: 5 },
      { name: 'loopback', years: 2 },
    ],
  },
  {
    category: 'Frontend',
    skills: [
      { name: 'Angular 2+', years: 1 },
      { name: 'React', years: 2 },
      { name: 'Vue', years: 2 },
      { name: 'HTML', years: null },
    ],
  },
  {
    category: 'Databases',
    skills: [
      { name: 'MySQL', years: null },
      { name: 'PostgreSQL', years: null },
      { name: 'Redis', years: null },
      { name: 'Mongo DB', years: null },
      { name: 'ElasticSearch', years: null },
    ],
  },
];

const roundMonths = (months: number): number => {
  if (months < 10) {
    return 0;
  }

  if (months < 46) {
    return Math.round((months - 1) / 6) / 2;
  }

  return Math.round((months - 1) / 12);
};

export const calculateRoundedYears = (months: number) => {
  const roundedYears = roundMonths(months);
  const roundedYearsText = roundedYears <= 1 ? 'year' : 'years';

  return { years: roundedYears, yearsText: roundedYearsText };
};
