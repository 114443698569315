import { useGetLanguages } from 'api/actions/languages/languagesActions';
import { useGetQueryParams } from 'hooks';

export const useGetLanguagesQuery = () => {
  const { queryParams } = useGetQueryParams();

  return useGetLanguages({
    params: {
      ...queryParams,
    },
  });
};
