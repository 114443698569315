import { useEffect } from 'react';

import { useModal, useSetFormError } from 'hooks';
import { useGetAllTechnologies } from '../../hooks/useGetAllTechnologies';
import { useGetProjectRoles } from '../../hooks/useGetProjectRoles';
import { ConfirmClosingModal } from '../confirmClosingModal/ConfirmClosingModal';

import { ProjectModalContainerProps } from './ProjectModalContainer.types';
import { ProjectModalFormFields, ProjectModalFormValues } from './ProjectModal.types';
import { ProjectModal } from './ProjectModal';

export const ProjectModalContainer = ({
  onClose,
  isLoading,
  formMethods,
  isOpen,
  onSubmit,
  header,
  error,
  isDataFetching,
  initialValues,
  isAdminOrNewProject,
}: ProjectModalContainerProps) => {
  const { setFormError } = useSetFormError();
  const {
    isOpen: isConfirmCloseModalOpen,
    onOpen: onConfirmCloseModalOpen,
    onClose: onConfirmCloseModalClose,
  } = useModal();
  const { reset, setError, watch } = formMethods;

  const profileProjectTechnologies = watch(ProjectModalFormFields.ProfileProjectTechnologies);
  const projectTechnologies = watch(ProjectModalFormFields.ProjectTechnologies);
  const joinedAt = watch(ProjectModalFormFields.JoinedAt);

  const { data: technologies, isLoading: isTechnologiesLoading } = useGetAllTechnologies();
  const { data: projectRoles, isLoading: isProjectRolesLoading } = useGetProjectRoles();

  useEffect(() => {
    setFormError<ProjectModalFormValues>(setError, error, ProjectModalFormFields, 'name');
  }, [setFormError, setError, error]);

  return (
    <>
      <ProjectModal
        isAdminOrNewProject={isAdminOrNewProject}
        profileProjectTechnologies={profileProjectTechnologies}
        projectTechnologies={projectTechnologies}
        initialValues={initialValues}
        joinedAt={joinedAt}
        allTechnologies={technologies?.result || []}
        projectRoles={projectRoles?.result || []}
        isOpen={isOpen}
        isLoading={isLoading}
        formMethods={formMethods}
        onClose={onConfirmCloseModalOpen}
        onSubmit={onSubmit}
        header={header}
        isDataFetching={isTechnologiesLoading || isProjectRolesLoading || isDataFetching}
      />
      {isConfirmCloseModalOpen && (
        <ConfirmClosingModal
          onConfirm={() => {
            onConfirmCloseModalClose();
            onClose();
            reset();
          }}
          onModalClose={onConfirmCloseModalClose}
          isOpen={isConfirmCloseModalOpen}
        />
      )}
    </>
  );
};
