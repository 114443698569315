import { Box } from '@mui/material';

import { Typography } from 'ui';

import { SelectedRowsInfoProps } from './SelectedRowsInfo.types';
import * as S from './SelectedRowsInfo.styles';

export const SelectedRowsInfo = ({ selectedRows }: SelectedRowsInfoProps) => {
  if (!selectedRows) {
    return null;
  }

  return (
    <Box sx={S.wrapper}>
      <Typography sx={S.wrapper} id="profiles.table.selected" values={{ number: selectedRows }}>
        {'profiles.table.selected'}
      </Typography>
      {selectedRows > 1 && <Typography variant="helperText">{'profiles.table.rowsNumberWarning'}</Typography>}
    </Box>
  );
};
