import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { LoginContainer } from 'app/login/LoginContainer';
import { AppProviders } from '../providers/AppProviders';
import { App } from '../app/App';

import { AppRoute } from './AppRoute.enum';
import { AuthorizedRoute } from './AuthorizedRoute';
import { AppAuthorizedRoutes } from './AppAuthorizedRoutes';

export const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <AppProviders>
          <App />
        </AppProviders>
      }
    >
      <Route path={AppRoute.Login} element={<LoginContainer />} />
      <Route path="*" element={<AuthorizedRoute />}>
        <Route path="*" index element={<AppAuthorizedRoutes />} />
      </Route>
    </Route>,
  ),
);
