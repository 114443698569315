import { UseFormReturn } from 'react-hook-form';

import { ProfileData } from 'api/actions/resume/resumeActions.types';

export type EditorFormValues = Pick<ProfileData, 'positionName'>;

export type EditorProps = {
  formMethods: UseFormReturn<EditorFormValues>;
  defaultData: EditorFormValues;
};

export enum EditorFormFields {
  Position = 'positionName',
}
