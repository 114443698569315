import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';

import { BasicData } from './BasicData';
import { BasicDataContainerProps } from './BasicData.types';
import { usePatchBasicData } from './hooks/usePatchBasicData';

export const BasicDataContainer = ({ handleNext }: BasicDataContainerProps) => {
  const { formatMessage } = useLocale();

  const { updateBasicData, isUpdating } = usePatchBasicData(handleNext);

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.basicDataEditor' })}</title>
      </Helmet>
      <BasicData isPostLoading={isUpdating} onSubmit={updateBasicData} />
    </>
  );
};
