import { useParams } from 'react-router-dom';

import { useContentAccess, useCurrentUser } from 'hooks';
import { Loader } from 'ui';
import { useGetSkillsOrderEditorData } from '../adminSection/skillsOrderEditor/hooks/useGetSkillsOrderEditorData';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { SkillsOrderDefinition } from 'api/actions/skillsOrderEditor/skillsOrderEditorActions.types';
import { skillsOrderRecalculate } from '../adminSection/skillsOrderEditor/recalculator/skillsOrderRecalculate';

import { GeneratedProfile } from './GeneratedProfile';
import { useGetResumeData } from './editor/hooks/useGetResumeData';

export const GeneratedProfileContainer = () => {
  const { user } = useCurrentUser();
  const { variantId } = useParams();
  const { isAllowed } = useContentAccess({ allowedContent: ContentType.RapidResumeEditor });

  const { data, isLoading, isFetching } = useGetResumeData({ userId: user?.id, variantId });
  const { data: skillOrderData, isLoading: isSkillsOrderDataLoading } = useGetSkillsOrderEditorData();

  if (isLoading || isSkillsOrderDataLoading) return <Loader />;

  if (!data || !skillOrderData) return null;

  const recalculatedSkillOrderData: SkillsOrderDefinition = skillsOrderRecalculate(skillOrderData.skillsOrder);

  return (
    <>
      <GeneratedProfile
        isContentEditableEnabled={isAllowed}
        skillsOrder={recalculatedSkillOrderData}
        isLoading={isFetching || isLoading}
        profileData={data}
        userId={user?.id || ''}
      />
    </>
  );
};
