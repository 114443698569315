import { SxProps, Theme, ButtonProps } from '@mui/material';
import { Button } from 'lux/components';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { Tooltip } from 'ui';
import { mergeStyles } from 'utils/mergeStyles';

import * as S from './EditButton.styles';

type EditButtonProps = ButtonProps & {
  iconSx?: SxProps<Theme>;
};

export const EditButton = ({ sx, disabled, ...props }: EditButtonProps) => (
  <Tooltip title={disabled ? '' : 'tooltips.edit'}>
    <Button
      disabled={disabled}
      data-testid="edit"
      aria-label="edit"
      iconOnly
      sx={mergeStyles(S.editButton, sx || {})}
      {...props}
    >
      <EditIcon />
    </Button>
  </Tooltip>
);
