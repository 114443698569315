import { ProfileTechSkill } from 'api/actions/resume/resumeActions.types';
import { SKILLS_ORDER_GLOBAL_KEY } from '../adminSection/skillsOrderEditor/SkillsOrderEditor.utils';
import { CategoriesOrderResponse } from 'api/actions/categoriesOrder/categoriesOrderActions.types';

import { CategoryOrder, SkillCategoryGroup, SkillCategoryGroups } from './GeneratedProfile.types';

const formatCategoriesOrder = (categories?: CategoriesOrderResponse) => {
  return (
    categories?.result.map((category) => ({
      order: category.displayOrder,
      category: category.category?.name || '',
    })) || []
  );
};

function getSkillsCategoryGroups(
  techSkills: ProfileTechSkill[],
  categoriesOrder: CategoryOrder[],
): SkillCategoryGroups {
  const groupedSkills = techSkills.reduce(
    (prev: SkillCategoryGroups, curr) => {
      const skillsArray = curr.category === null ? prev.skillsWoCategory : prev.skillsWithCategory;
      const duplicatedCategory = skillsArray.find((group) => group.category === curr.category);

      if (!!duplicatedCategory) {
        duplicatedCategory.skills.push(curr);
      } else {
        skillsArray.push({
          category: curr.category,
          skills: [curr],
        });
      }

      return prev;
    },
    { skillsWoCategory: [], skillsWithCategory: [] },
  );

  groupedSkills.skillsWithCategory.sort((a, b) => {
    const orderA = categoriesOrder.find((item) => item.category === a.category)?.order || 0;
    const orderB = categoriesOrder.find((item) => item.category === b.category)?.order || 0;

    return orderA - orderB;
  });

  return groupedSkills;
}

function sortSkills(
  sortedSkills: Record<string, number>,
  skillsWithCategory: SkillCategoryGroup[],
  skillsWoCategory: SkillCategoryGroup[],
) {
  const sortedSkillsWithCategory: SkillCategoryGroup[] = skillsWithCategory.map((skillGroup) => {
    const sorted = skillGroup.skills.sort((a, b) => {
      const orderA = sortedSkills[a.name] ? Number(sortedSkills[a.name]) : 0;
      const orderB = sortedSkills[b.name] ? Number(sortedSkills[b.name]) : 0;

      return orderB - orderA;
    });

    return { ...skillGroup, skills: sorted };
  });

  return { skillsWoCategory, skillsWithCategory: sortedSkillsWithCategory };
}

export const getSkillsSorted = (
  techSkills: ProfileTechSkill[],
  skillsOrder: Record<string, unknown>,
  position: string | null,
  categoriesOrder?: CategoriesOrderResponse,
) => {
  const categoriesOrderFormatted = formatCategoriesOrder(categoriesOrder);

  const skillsByCategory = getSkillsCategoryGroups(techSkills, categoriesOrderFormatted);
  const isGlobalSorting = Object.keys(skillsOrder).includes(SKILLS_ORDER_GLOBAL_KEY);
  const { skillsWithCategory, skillsWoCategory } = skillsByCategory;

  const isPositionSorting = position ? Object.keys(skillsOrder).includes(position) : false;

  const sortingDefinition =
    position && isPositionSorting
      ? skillsOrder[position]
      : isGlobalSorting
      ? skillsOrder[SKILLS_ORDER_GLOBAL_KEY]
      : null;

  if (sortingDefinition) {
    return sortSkills(sortingDefinition as Record<string, number>, skillsWithCategory, skillsWoCategory);
  }

  return skillsByCategory;
};
