import { useDeleteProfileProject } from '../../hooks/useDeleteProfileProject';

import { ProjectDeletionModal } from './ProjectDeletionModal';
import { ProjectDeletionModalContainerProps } from './ProjectDeletionModal.types';

export const ProjectDeletionModalContainer = (props: ProjectDeletionModalContainerProps) => {
  const { profileProjectId, onClose } = props;
  const { deleteProfileProject, ...rest } = useDeleteProfileProject(profileProjectId, onClose);

  return <ProjectDeletionModal {...props} onDelete={deleteProfileProject} {...rest} />;
};
