import { Box } from '@mui/material';

import { Typography } from 'ui';

import { AchievementsProps } from './Achievements.types';
import * as S from './Achievements.styles';

export const Achievements = ({ achievements }: AchievementsProps) => (
  <Box sx={S.wrapper}>
    <Typography sx={S.title} variant="h2" color="text.primary">
      {'generatedProfile.achievements'}
    </Typography>

    <ul>
      {achievements.map(({ description }) => (
        <li key={description}>{description}</li>
      ))}
    </ul>
  </Box>
);
