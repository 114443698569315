import { FormInputText, FormModalWrapper, FormSelect, SelectOption } from 'ui';
import { Section } from 'views/profileEditor/components/section/Section';

import { ProfileModalFormFields, ProfileModalProps } from './ProfileModal.types';

export const ProfileModal = ({
  formMethods,
  header,
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  seniorityData,
  positionsData,
}: ProfileModalProps) => {
  return (
    <FormModalWrapper
      header={header}
      isOpen={isOpen}
      onSubmit={onSubmit}
      formMethods={formMethods}
      isLoading={isLoading}
      onClose={onClose}
    >
      <Section title="step.subTitle.basicInformation">
        <FormInputText
          name={ProfileModalFormFields.FirstName}
          label="form.firstName"
          required
          inputProps={{
            'data-test': 'inputFirstName',
          }}
        />
        <FormInputText
          name={ProfileModalFormFields.LastName}
          label="form.lastName"
          required
          inputProps={{
            'data-test': 'inputLastName',
          }}
        />
        <FormInputText
          name={ProfileModalFormFields.Email}
          label="form.email"
          required
          inputProps={{
            'data-test': 'inputEmail',
          }}
        />
      </Section>
      <Section title="step.subTitle.technicalInformation">
        <FormSelect
          name={ProfileModalFormFields.Position}
          label="form.position"
          options={positionsData as SelectOption}
          fullWidth
          required
        />
        <FormSelect
          name={ProfileModalFormFields.Seniority}
          label="form.seniority"
          options={seniorityData as SelectOption}
          fullWidth
          required
        />
      </Section>
    </FormModalWrapper>
  );
};
