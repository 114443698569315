import { createContext, Dispatch, SetStateAction, TransitionStartFunction, useContext } from 'react';

export const ProjectContext = createContext<
  | {
      isPending: boolean;
      startTransition: TransitionStartFunction;
      isProjectNew: boolean;
      setIsProjectNew: Dispatch<SetStateAction<boolean>>;
      isProjectSelected: boolean;
      setIsProjectSelected: Dispatch<SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const useProjectContext = () => {
  const context = useContext(ProjectContext);

  if (!context) {
    throw Error('Missing ProjectContext');
  }

  return context;
};
