import { useProfileLanguagesQuery } from './hooks/useProfileLanguagesQuery';
import { ProfileLanguages } from './ProfileLanguages';

export const ProfileLanguagesContainer = () => {
  const { languages, profileLanguages, isLoading, isError } = useProfileLanguagesQuery();

  return (
    <ProfileLanguages
      languages={languages?.result || []}
      profileLanguages={profileLanguages?.result || []}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
