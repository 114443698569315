import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { LanguageModal } from '../languageModal/LanguageModal';
import { useLocale } from 'hooks';
import { LanguageModalFormValues } from '../languageModal/LanguageModal.types';
import { usePostProfileLanguage } from '../../hooks/usePostProfileLanguage';
import { defaultValues, getFormSchema } from '../languageModal/LanguageModal.utils';

import { AddLanguageModalContainerProps } from './AddLanguageModalContainer.types';

export const AddLanguageModalContainer = ({
  isOpen,
  onModalClose,
  languageOptions,
}: AddLanguageModalContainerProps) => {
  const { formatMessage } = useLocale();

  const formMethods = useForm<LanguageModalFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { reset } = formMethods;

  const { addProfileLanguage, isLoading, error } = usePostProfileLanguage(() => {
    onModalClose();
    reset();
  });

  return (
    <LanguageModal
      error={error}
      isOpen={isOpen}
      onModalClose={onModalClose}
      formMethods={formMethods}
      onSubmit={addProfileLanguage}
      languageOptions={languageOptions}
      isLoading={isLoading}
      submitLabel={formatMessage({ id: 'form.save' })}
    />
  );
};
