import { FormSelect, Loader } from 'ui';
import { EditorFormFields } from '../../Editor.types';
import { useGetPositions } from 'api/actions/positions/positionsActions';
import { useLocale } from 'hooks';

import { PositionProps } from './Position.types';
import * as S from './Position.styles';

export const Position = ({ defaultValue }: PositionProps) => {
  const { formatMessage } = useLocale();
  const { data } = useGetPositions({
    params: {
      'sort[position.visibleName]': 'ASC',
    },
  });

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <FormSelect
        wrapperStyles={S.field}
        isValueName={true}
        defaultValue={defaultValue}
        options={data.result}
        name={EditorFormFields.Position}
        label="generatedProfile.editor.form.position"
        helperText={formatMessage({ id: 'generatedProfile.editor.form.positionInfo' })}
      />
    </>
  );
};
