import { Styles, componentsLibTheme as theme } from 'lux/theme';

export const inputWrapperStyles: Styles = {
  mb: 2.5,
};

export const errorContainer: Styles = {
  border: '1px solid',
  borderColor: theme.palette.error.main,
  borderRadius: '8px',
};

export const error: Styles = {
  mt: 0.625,
  color: theme.palette.error.main,
};
