import { Autocomplete, TextField } from '@mui/material';

import { DragAndDropTable } from 'ui/tables/dragAndDropTable/DragAndDropTable';
import { Divider } from 'ui';
import { useCategoriesOrder } from 'api/actions/categoriesOrder/categoriesOrderActions';
import { CategoryOrder } from 'api/actions/categoriesOrder/categoriesOrderActions.types';
import { useGetQueryParams, useLocale } from 'hooks';

import { useCategoriesOrderColumns } from './hooks/useCategoriesOrderColumns';
import { usePositions } from './hooks/usePositions';
import { useUpdateCategoryOrder } from './hooks/useUpdateCategoryOrder';
import { getCategoriesOrderParams } from './CategoriesOrder.utils';

export const CategoriesOrder = () => {
  const { queryParams } = useGetQueryParams();
  const { formatMessage } = useLocale();

  const { columns } = useCategoriesOrderColumns();

  const { selectedPosition, setSelectedPosition, positions, formattedPositions } = usePositions();

  const {
    data: categories,
    isLoading: areCategoriesLoading,
    isError,
  } = useCategoriesOrder({
    config: {
      params: getCategoriesOrderParams(queryParams, selectedPosition),
    },
    enabled: Boolean(selectedPosition && positions),
  });

  const { reorderCategory } = useUpdateCategoryOrder();

  return (
    <>
      <Autocomplete
        onChange={(_, option) => setSelectedPosition(option ? option.value : '')}
        options={formattedPositions}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} label={formatMessage({ id: 'categoriesOrder.table.searchPosition' })} />
        )}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />

      <Divider />
      <DragAndDropTable<CategoryOrder>
        columns={columns}
        isLoading={areCategoriesLoading}
        isError={isError}
        filtersOperators={{}}
        keysMapper={{}}
        droppableId="categories-order"
        onDragAndDropEnd={reorderCategory}
        total={categories?.total}
        data={categories?.result}
        globalFilter={selectedPosition}
        pageSizeOptions={[50, 100]}
      />
    </>
  );
};
