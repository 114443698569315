import { useEffect, useMemo, useRef, useState } from 'react';

import { QueryParams } from 'api/types/types';

import { UsePageArgs } from './usePage.types';

export const usePage = ({ initialQueryParams, total, pageSize, globalFilter }: UsePageArgs) => {
  const [page, setPage] = useState(Number(initialQueryParams[QueryParams.PAGE]) || 1);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (total) {
      const totalPages = Math.ceil(total / pageSize);

      if (page > totalPages) {
        setPage(1);
      }
    }
  }, [total]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }
    setPage(1);
  }, [globalFilter]);

  const pageQueryParams = useMemo(
    () => ({
      page: String(page),
    }),
    [page],
  );

  return { page, setPage, pageQueryParams };
};
