import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useSnackbar } from 'hooks';
import { mutationUpdater } from 'utils/mutationUpdater/mutationUpdater';
import { useProfileCertificatePatch } from 'api/actions/profile/certificates/profileCertificatesActions';
import { ProfileCertificatesGetResponse } from 'api/actions/profile/certificates/profileCertificatesActions.types';
import { CertificateModalFormValues } from '../modals/certificateModal/CertificateModal.types';

export const usePatchProfileCertificate = (profileCertificateId: string, onSuccess: VoidFunction) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const certificatesQueryKey = `profiles/${user?.id}/certificates`;

  const { mutateAsync, isError, isLoading, error } = useProfileCertificatePatch({
    onSuccess: ({ data }) => {
      queryClient.setQueryData<ProfileCertificatesGetResponse>(certificatesQueryKey, (certificates) =>
        mutationUpdater('PATCH', data, certificates),
      );
      onSuccess();
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => showSnackbarError(error),
  });

  const editProfileCertificate = useCallback(
    async (formValues: CertificateModalFormValues) => {
      const { certificate, obtainedAt, isHidden } = formValues;

      if (certificate === null || obtainedAt === null) return;

      const body = {
        certificateId: certificate.id,
        obtainedAt,
        isHidden,
      };

      await mutateAsync({ profileId: user?.id, profileCertificateId, body });
    },
    [mutateAsync, user?.id, profileCertificateId],
  );

  return { editProfileCertificate, isError, isLoading, error };
};
