import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { TextField } from 'lux/components';
import { TextFieldProps } from 'lux/components/atoms/textField/TextField.types';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Typography } from 'ui';

import * as S from './FormInputText.styles';

type FormInputTextProps = {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
} & TextFieldProps;

export const FormInputText = ({ name, label, size = 'small', placeholder, ...props }: FormInputTextProps) => {
  const { formatMessage } = useLocale();

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box sx={S.inputWrapperStyles}>
          <TextField
            size={size}
            onChange={onChange}
            value={value || ''}
            fullWidth
            {...props}
            inputProps={{ ...props.inputProps, 'data-testid': name || 'formInput' }}
            {...(placeholder && { placeholder: formatMessage({ id: placeholder }) })}
            {...(label && { label: formatMessage({ id: label }) })}
            error={!!error}
            sx={error && props.disabled ? S.errorContainer : {}}
          />
          {error && (
            <Typography sx={S.error} variant="helperText">
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};
