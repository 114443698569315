import { ColumnFilter } from '@tanstack/react-table';

import { QueryParams } from 'api/types/types';
import { FilterOperator } from 'utils';

export const getFilterQueryParams = (
  filter: ColumnFilter[],
  keysMapper: Record<string, string>,
  filtersOperators: Record<string, FilterOperator>,
) =>
  filter.reduce(
    (acc, curr) => ({
      ...acc,
      [`${QueryParams.FILTER}[${keysMapper[curr.id]}][${filtersOperators[curr.id]}]`]: curr.value,
    }),
    {},
  );
