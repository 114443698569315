import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { LanguageModalContainer } from '../languageModal/LanguageModalContainer';
import { useLocale } from 'hooks';
import { LanguageModalFormValues } from '../languageModal/LanguageModal.types';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { getDefaultValues, getFormSchema } from '../languageModal/LanguageModal.utils';
import { usePatchLanguage } from '../../hooks/usePatchLanguage';

import { EditLanguageModalContainerProps } from './EditLanguageModalContainer.types';

export const EditLanguageModalContainer = ({ onClose, isOpen, languageData }: EditLanguageModalContainerProps) => {
  const { formatMessage } = useLocale();

  const formMethods = useForm<LanguageModalFormValues>({
    defaultValues: getDefaultValues(languageData),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });
  const { handleSubmit } = formMethods;

  const { editLanguage, isLoading, error } = usePatchLanguage(languageData.id, onClose);

  return (
    <LanguageModalContainer
      isOpen={isOpen}
      isLoading={isLoading}
      formMode={FormMode.edit}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={handleSubmit(editLanguage)}
      header="languages.form.edit"
      error={error}
    />
  );
};
