import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { CategoryModalContainer } from '../categoryModal/CategoryModalContainer';
import { useLocale } from 'hooks';
import { CategoryModalFormValues } from '../categoryModal/CategoryModal.types';
import { getFormSchema } from '../categoryModal/CategoryModal.utils';
import { usePatchCategory } from '../../hooks/usePatchCategory';

import { EditCategoryModalContainerProps } from './EditCategoryModalContainer.types';

export const EditCategoryModalContainer = ({
  onClose,
  isOpen,
  category: { name, id },
}: EditCategoryModalContainerProps) => {
  const { formatMessage } = useLocale();

  const formMethods = useForm<CategoryModalFormValues>({
    defaultValues: { name },
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { editCategory, isLoading, error } = usePatchCategory(id, onClose);

  const { handleSubmit } = formMethods;

  return (
    <CategoryModalContainer
      isOpen={isOpen}
      isLoading={isLoading}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={handleSubmit(editCategory)}
      header="categories.form.edit"
      error={error}
    />
  );
};
