import { useProjectPatch } from 'views/adminSection/projects/hooks/useProjectPatch';
import { ProjectModal } from '../projectModal/ProjectModal';

import { ProjectModificationModalContainerProps } from './ProjectModificationModal.types';

export const ProjectModificationModalContainer = ({
  project: { id, name, description, shortDescription, isPrivate, technologies },
  ...props
}: ProjectModificationModalContainerProps) => {
  const { modifyProject, ...rest } = useProjectPatch(id, props.onClose);

  return (
    <ProjectModal
      initialValues={{
        name,
        description,
        shortDescription,
        isPrivate,
        technologies: technologies.map(({ technology }) => ({ id: technology.id, name: technology.name })),
      }}
      onSubmit={modifyProject}
      title="form.editProject"
      buttonLabel="form.save"
      {...props}
      {...rest}
    />
  );
};
