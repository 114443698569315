import { useResumeDataGet } from 'api/actions/resume/resumeActions';
import { GetResumeDataArgs, GetResumeResponse } from 'api/actions/resume/resumeActions.types';

const getDataRegardingType = (data?: GetResumeResponse) => {
  if (!data) {
    return;
  }
  if ('variant' in data) {
    return Object.assign(data.base, data.variant);
  } else {
    return data;
  }
};

export const useGetResumeData = (args: GetResumeDataArgs) => {
  const { data, isLoading, isFetching } = useResumeDataGet(args);

  return { data: getDataRegardingType(data), isLoading, isFetching };
};
