import { format, parseISO } from 'date-fns';
import { IntlShape } from 'react-intl';

export const formatDuration = (
  joinedAt: string | null,
  leftAt: string | null,
  formatMessage: IntlShape['formatMessage'],
) => {
  if (joinedAt === null) {
    return '';
  }

  return leftAt
    ? `${format(parseISO(joinedAt), 'yyyy-MM')} - ${format(parseISO(leftAt), 'yyyy-MM')}`
    : `${formatMessage({ id: 'step.experience.since' })} ${format(parseISO(joinedAt), 'yyyy-MM')}`;
};
