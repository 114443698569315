import { Styles } from 'lux/theme';

export const tableHead: Styles = {
  '& tr th:first-of-type': {
    width: '100%',
  },
  '& tr th:last-child': {
    minWidth: '110px',
  },
  '& tr th:last-of-type div': {
    justifyContent: 'center',
  },
};
