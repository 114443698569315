import { NavLink } from 'react-router-dom';
import * as React from 'react';
import { PropsWithChildren } from 'react';

import { Typography } from 'ui/typography/Typography';

import { UiLinkProps } from './UiLInk.types';

export const UiLink = ({
  target,
  linkWithQueryParams,
  to,
  children,
  bindHover,
  isActive,
}: PropsWithChildren<UiLinkProps>) => {
  return (
    <NavLink
      className={isActive ? 'active' : ''}
      target={target}
      to={linkWithQueryParams}
      data-testid={to.replace('/', '')}
      {...bindHover}
    >
      {typeof children === 'string' ? <Typography>{children}</Typography> : children}
    </NavLink>
  );
};
