import { Styles } from 'views/generatedProfile/theme/theme';

export const skillWrapper: Styles = {
  py: 1,
  pageBreakInside: 'avoid',
};

export const category: Styles = ({ spacing }) => ({
  fontSize: spacing(1.25),
  fontWeight: 500,
  mb: 1,
});
