import Joi from 'joi';
import { IntlShape } from 'react-intl';
import { endOfToday } from 'date-fns';

import { SchoolModalFormFields } from './EducationModal.types';
import { EditedProfileEducation } from './editEducationModalContainer/EditEducationModalContainer.types';

export const DATE_LIMITS = {
  min: new Date(1900, 0, 1),
  max: endOfToday(),
};

export const getFormSchema = (formatMessage: IntlShape['formatMessage'], isOngoing: boolean) => {
  const emptyStringErrorMessages = { 'string.empty': formatMessage({ id: 'error.required' }) };
  const errorMessages = {
    'date.base': formatMessage({ id: 'error.required' }),
    'date.min': formatMessage({ id: 'validation.number.min' }, { min: DATE_LIMITS.min.getFullYear() }),
    'date.max': formatMessage({ id: 'validation.number.max' }, { max: DATE_LIMITS.max.getFullYear() }),
  };

  return Joi.object({
    [SchoolModalFormFields.School]: Joi.string().messages(emptyStringErrorMessages),
    [SchoolModalFormFields.Degree]: Joi.string().messages(emptyStringErrorMessages),
    [SchoolModalFormFields.MajorOfStudies]: Joi.string().messages(emptyStringErrorMessages),
    [SchoolModalFormFields.Year]: isOngoing
      ? Joi.allow(Date, null).empty([Date]).default(null)
      : Joi.date().min(DATE_LIMITS.min).max(DATE_LIMITS.max).messages(errorMessages),
  });
};

export const getDefaultValues = (editedProfileEducation?: EditedProfileEducation) => {
  return {
    [SchoolModalFormFields.School]: editedProfileEducation?.school || '',
    [SchoolModalFormFields.Degree]: editedProfileEducation?.degree || '',
    [SchoolModalFormFields.MajorOfStudies]: editedProfileEducation?.majorOfStudies || '',
    [SchoolModalFormFields.Year]: editedProfileEducation?.year || null,
  };
};
