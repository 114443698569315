import { Table } from 'ui/tables/table/Table';
import { Divider, GlobalFilter } from 'ui';
import { useGlobalFilter } from 'hooks';
import { virtualSortingDecode } from 'utils';
import { useProfilesCertificatesGet } from 'api/actions/certificates/certificatesActions';

import { useProfilesCertificatesColumns } from './hooks/useProfilesCertificatesColumns';
import {
  filtersOperators,
  getProfilesCertificatesParams,
  keysMapper,
  virtualSortingMapper,
} from './ProfilesCertifiactes.utils';
import * as S from './ProfilesCertificates.styles';

export const ProfilesCertificates = () => {
  const { globalFilter, setGlobalFilter, queryParamsDecoded } = useGlobalFilter({
    keysMapper,
    filtersOperators,
  });

  const queryParams = virtualSortingDecode({
    queryParams: queryParamsDecoded,
    virtualSortingMapper: virtualSortingMapper,
  });

  const { data, isLoading, isError } = useProfilesCertificatesGet({
    params: getProfilesCertificatesParams(queryParams),
  });

  const columns = useProfilesCertificatesColumns();

  return (
    <>
      <GlobalFilter
        onValueChange={setGlobalFilter}
        labelPlaceholder="profilesCertificates.table.searchProfilesCertificates"
        value={globalFilter}
      />
      <Divider />
      <Table
        data={data?.result}
        total={data?.total}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        filtersOperators={filtersOperators}
        keysMapper={keysMapper}
        sx={{ tableHead: S.tableHead }}
        globalFilter={globalFilter}
        virtualSortingMap={virtualSortingMapper}
        defaultSort={[{ id: 'name', desc: false }]}
      />
    </>
  );
};
