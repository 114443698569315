import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useIntl } from 'react-intl';

import { useCurrentUser, useSnackbar } from 'hooks';
import { useEducationDelete } from 'api/actions/profile/education/profileEducationActions';

export const useDeleteProfileEducation = (educationId: string) => {
  const { user } = useCurrentUser();
  const intl = useIntl();
  const { showSnackbar, showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();
  const profileSkillsQueryKey = `profiles/${user?.id}/educations`;

  const { mutateAsync } = useEducationDelete({
    onSuccess: () => {
      queryClient.invalidateQueries(profileSkillsQueryKey);
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error),
  });

  return useCallback(async () => {
    if (!user?.id) {
      showSnackbar(intl.formatMessage({ id: 'error.tryToRelogin' }), 'error');

      return;
    }

    await mutateAsync({ profileId: user?.id, educationId });
  }, [educationId, mutateAsync, user?.id]);
};
