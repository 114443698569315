import { PositionModalContainerProps } from './PositionModalContainer.types';

export enum PositionModalFormFields {
  VisibleName = 'visibleName',
  DwhCode = 'dwhCode',
}

export type PositionModalFormValues = {
  [PositionModalFormFields.VisibleName]: string;
  [PositionModalFormFields.DwhCode]: string;
};

export type PositionModalProps = Omit<PositionModalContainerProps, 'error'>;
