import { FilterOperator, VirtualSortingMapper } from 'utils';

export const filtersOperators: Record<string, FilterOperator> = {
  name: FilterOperator.includeOr,
  certificate: FilterOperator.includeOr,
};

export const keysMapper = {
  name: "profileCertificateProfile.firstName || ' ' || profileCertificateProfile.lastName",
  certificate: 'certificate.name',
  obtainedAt: 'profileCertificate.obtainedAt',
  expiredAt: 'profileCertificate.expiredAt',
};

export const virtualSortingMapper: VirtualSortingMapper = {
  name: ['profileCertificateProfile.lastName', 'profileCertificateProfile.firstName'],
  updateConfirmedAt: ['profile.updateConfirmedAt'],
};

export const getProfilesCertificatesParams = (queryParams: Record<string, string | number>) => {
  const isSortParams = Object.keys(queryParams).some((key) => key.startsWith('sort'));

  const defaultSortParams = isSortParams
    ? {}
    : { 'sort[profileCertificateProfile.lastName]': 'ASC', 'sort[profileCertificateProfile.firstName]': 'ASC' };

  return {
    page: '1',
    limit: '10',
    relations: ['profileCertificate.profile'],
    ...defaultSortParams,
    ...queryParams,
  };
};
