import { Styles } from 'lux/theme';

export const previewButton: Styles = {
  p: '0px !important',
  height: 40,
  width: 40,
  svg: {
    width: 24,
    height: 24,
  },
};
