import { Navigate } from 'react-router-dom';

import { AccessControl } from 'ui';
import { AppRoute } from '../AppRoute.enum';
import { TagsContainer } from 'views/adminSection/tags/TagsContainer';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const AuthorizedTags = () => (
  <AccessControl allowedContent={ContentType.Tags} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <TagsContainer />
  </AccessControl>
);
