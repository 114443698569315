import { endOfMonth } from 'date-fns';

import { FormDatePicker } from 'ui';
import { DateFormats } from 'ui/formDatePicker/FormDatePicker.types';
import { MIN_DATE } from 'ui/formDatePicker/FormDatePicker.utils';
import { ProjectModalFormFields } from '../../ProjectModal.types';

import { WorkedToProps } from './WorkedTo.types';
import * as S from './WorkedTo.styles';

export const WorkedTo = ({ joinedAt }: WorkedToProps) => {
  return (
    <FormDatePicker
      dateFormat={DateFormats.yyyyMM}
      placeholder="profileProject.placeholder.date"
      name={ProjectModalFormFields.LeftAt}
      label="profileProject.label.workedTo"
      minDate={endOfMonth(joinedAt ?? new Date(MIN_DATE))}
      maxDate={endOfMonth(new Date())}
      views={['year', 'month']}
      transformValue={(date: Date | null) => (date ? endOfMonth(date) : null)}
      clearable
      size="medium"
      datePickerWrapperSx={S.datePickerWrapper}
    />
  );
};
