import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { getTagsQueryKey, useTagPost } from 'api/actions/tags/tagsActions';
import { TagPostBody } from 'api/actions/tags/tagsActions.types';
import { UsePostTagArgs } from 'views/adminSection/tags/hooks/usePostTag.types';
import { useSnackbar } from 'hooks';

export const usePostTag = ({ onSuccess }: UsePostTagArgs) => {
  const queryClient = useQueryClient();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const { mutate, error, isLoading } = useTagPost({
    onSuccess: () => {
      queryClient.invalidateQueries(getTagsQueryKey);
      showSnackbarSuccess('success.itemAdded');
      onSuccess();
    },
    onError: (error) => {
      showSnackbarError(error);
    },
  });

  const postTag = useCallback(
    (body: TagPostBody) => {
      mutate(body);
    },
    [mutate],
  );

  return { postTag, postTagError: error, isPostTagLoading: isLoading };
};
