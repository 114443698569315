import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';
import { AdminSectionWrapper } from 'ui';

import { CategoriesOrder } from './CategoriesOrder';

export const CategoriesOrderContainer = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.categoriesOrder' })}</title>
      </Helmet>
      <AdminSectionWrapper title="navigator.categoriesOrder" tabs="skillsTabs">
        <CategoriesOrder />
      </AdminSectionWrapper>
    </>
  );
};
