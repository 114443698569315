import { MutationFn } from 'hooks/useMutation/useMutation.types';

import {
  DeleteProfileArgs,
  DeleteProfileResponse,
  PostProfileUpdateConfirmArgs,
  PostProfileUpdateConfirmResponse,
} from './profileSummaryActions.types';

export const postProfileUpdateConfirmMutation: MutationFn<
  PostProfileUpdateConfirmArgs,
  PostProfileUpdateConfirmResponse
> = ({ profileId }) => ({
  endpoint: `/profiles/profile/${profileId}/confirm-profile-data`,
  method: 'POST',
});

export const deleteProfileMutation: MutationFn<DeleteProfileArgs, DeleteProfileResponse> = ({ profileId }) => ({
  endpoint: `profiles/profile/${profileId}`,
  method: 'DELETE',
});
