import { Styles } from 'lux/theme';

export const hr: Styles = {
  mt: 3,
  mb: 3,
  width: '100%',
};

export const formContainer: Styles = {
  border: '1px solid #C7D6ED',
  borderRadius: '8px',
  padding: '24px',
};

export const lastUpdateDate: Styles = {
  backgroundColor: '#F5EBFF',
  padding: '6px 10px',
  borderRadius: '4px',
  display: 'inline-block',
  color: 'black',
};

export const header: Styles = {
  fontStyle: 'italic',
  color: '#666',
  letterSpacing: '8px',
};
