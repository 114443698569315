import { Styles } from 'lux/theme';

export const wrapper: Styles = {
  pr: 15,

  '& .MuiListItemSecondaryAction-root': {
    top: 6,
    transform: 'translateY(0)',
  },
};

export const name: Styles = {
  wordBreak: 'break-word',
  fontWeight: 500,
};

export const role: Styles = {
  fontStyle: 'italic',
  mb: 0.75,
};

export const description: Styles = {
  color: 'text.secondary',
};

export const projectWrapper: Styles = {
  color: 'text.primary',
  px: 2,
  py: 2.5,
  display: 'flex',
  justifyContent: 'space-between',

  '&:hover': {
    bgcolor: 'primary.light',
    boxShadow: ({ palette }) => `0px 1px 0 ${palette.border.default}, 0px -1px 0 ${palette.border.default}`,
  },
};

export const actions: Styles = {
  display: 'flex',
  alignItems: 'center',
};

const commonIconStyles: Styles = ({ spacing }) => ({
  borderRadius: '50%',
  width: spacing(4),
  height: spacing(4),
  p: '3px',
  mt: 2,
  mb: 2,
});

export const iconStyles: Styles = (theme) => ({
  fill: 'none',
  bgcolor: 'primary.light',
  border: '1px solid',
  borderColor: 'primary.main',
  '& path': {
    stroke: ({ palette }) => palette.primary.main,
  },
  ...commonIconStyles(theme),
});

export const tshIconStyles: Styles = (theme) => ({
  bgcolor: 'success.main',
  '& > svg': {
    width: '100%',
    height: '100%',
  },
  ...commonIconStyles(theme),
});
