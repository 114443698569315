import { Box } from '@mui/material';

import { mergeStyles } from 'utils/mergeStyles';

import { ActionsWrapperProps } from './ActionsWrapper.types';
import * as S from './ActionsWrapper.styles';

export const ActionsWrapper = ({ children, sx, ...props }: ActionsWrapperProps) => {
  return (
    <Box sx={mergeStyles(S.wrapper, sx || {})} {...props} className="actionsCell">
      {children}
    </Box>
  );
};
