const skillEndpointsMap = {
  getSkills: 'skills',
  getSpecialSkills: 'special-skills',
  mutateSkill: 'skill',
  mutateSpecialSkill: 'special-skill',
};

const actionTypes = {
  get: (isSpecial: boolean) => (isSpecial ? skillEndpointsMap.getSpecialSkills : skillEndpointsMap.getSkills),
  mutate: (isSpecial: boolean) => (isSpecial ? skillEndpointsMap.mutateSpecialSkill : skillEndpointsMap.mutateSkill),
};

export const generateEndpointPart = (isSpecial: boolean, actionType: 'get' | 'mutate') => {
  return actionTypes[actionType](isSpecial);
};

export const generateGetProfileSkillsQueryUrl = (isSpecial: boolean, profileId?: string) => {
  return `/profiles/${profileId}/${generateEndpointPart(isSpecial, 'get')}`;
};

export const generateGetProfileSkillsQueryKey = (isSpecial: boolean, profileId?: string) => {
  return ['profile-skills', { profileId, endpoint: generateEndpointPart(isSpecial, 'get') }];
};
