import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { ClientErrorResponse } from 'api/types/types';

export type ProjectModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  initialValues: ProjectModalFormValues;
  onSubmit: (data: ProjectModalFormValues) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  title: string;
  buttonLabel: string;
  error: ClientErrorResponse | null;
};

export enum ProjectModalFormFields {
  Name = 'name',
  ShortDescription = 'shortDescription',
  Description = 'description',
  IsPrivate = 'isPrivate',
  Technologies = 'technologies',
}

export type ProjectModalFormValues = {
  [ProjectModalFormFields.Name]: string;
  [ProjectModalFormFields.ShortDescription]: string;
  [ProjectModalFormFields.Description]: string;
  [ProjectModalFormFields.IsPrivate]: boolean;
  [ProjectModalFormFields.Technologies]: AutocompleteOption[];
};
