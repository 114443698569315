import { ActionsWrapper, EditButtonLink, PreviewButtonLink } from 'ui';
import { AppRoute } from 'routing/AppRoute.enum';
import { Steps, StepsRoutesNames } from 'views/profileEditor/ProfileEditor.types';
import { useContentAccess } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { ActionsProps } from './Actions.types';

export const Actions = ({ profileId, certificateName }: ActionsProps) => {
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.ProfileCertificates });

  return (
    <ActionsWrapper>
      <PreviewButtonLink
        testId="show-certificate"
        target={certificateName.toLowerCase()}
        to={`/certificates?search=${certificateName.toLowerCase()}`}
        tooltipTitle="tooltips.showCertificate"
      />
      {!isReadOnly && (
        <EditButtonLink
          tooltipTitle="tooltips.editProfile"
          target={profileId}
          to={`${AppRoute.ProfileEditor}/${StepsRoutesNames[Steps.BasicData]}/${profileId}`}
          testId={'edit-profile'}
        />
      )}
    </ActionsWrapper>
  );
};
