import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse, ErrorData } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';

import {
  PostTechnologiesSurveyBody,
  PostTechnologiesSurveyMutationArgs,
  TechnologiesSurveyPostResponse,
  TechnologiesSurveyGetResponse,
} from './technologiesSurveyActions.types';

export const useTechnologiesSurveyGet = (profileId?: string, config?: AxiosRequestConfig) =>
  useQuery<TechnologiesSurveyGetResponse>(
    `profiles/${profileId}/skills-survey`,
    `profiles/${profileId}/skills-survey`,
    {
      refetchOnMount: 'always',
    },
    config,
  );

export const useTechnologiesSurveyPost = (
  options: UseMutationOptions<
    ClientResponse<TechnologiesSurveyPostResponse>,
    ClientErrorResponse,
    PostTechnologiesSurveyMutationArgs
  >,
) =>
  useMutation<TechnologiesSurveyPostResponse, ErrorData, { profileId?: string; body: PostTechnologiesSurveyBody }>(
    'TechnologiesSurvey',
    ({ profileId, body }) => ({
      endpoint: `/profiles/${profileId}/skills-survey`,
      method: 'POST',
      params: body,
    }),
    options,
  );
