import { useMemo, useState } from 'react';
import { ColumnSort } from '@tanstack/table-core';

import { useSortProps } from './useSort.types';
import { getSortQueryParams } from './useSort.utils';

export const useSort = ({ defaultSort = [], keysMapper, isAutoDefaultSort, virtualSortingMap }: useSortProps) => {
  const [sort, setSort] = useState<ColumnSort[]>(defaultSort);

  const sortQueryParams = useMemo(() => {
    if (isAutoDefaultSort && !sort.length) {
      return getSortQueryParams(defaultSort, keysMapper, virtualSortingMap);
    } else {
      return getSortQueryParams(sort, keysMapper, virtualSortingMap);
    }
  }, [sort]);

  return { sort, setSort, sortQueryParams };
};
