import { Styles } from 'lux/theme';

export const section: Styles = {
  borderBottom: '1px solid',
  borderColor: 'border.default',
  pb: 3,

  '.MuiTypography-helperText': {
    fontWeight: 400,
  },
};

export const infoSection: Styles = {
  borderBottom: '2px solid',
  borderColor: 'other.tooltip',
  pb: 3,
};

export const dateRangeWrapper: Styles = {
  display: 'flex',
  gap: 1,
};

export const helperTextWrapper: Styles = {
  pl: 1.75,
};
