import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks';
import { ProjectModalFormFields, ProjectModalFormValues } from '../../ProjectModal.types';
import { Button } from 'ui';

export const CopyTechnologiesButton = () => {
  const { formatMessage } = useLocale();
  const formMethods = useFormContext<ProjectModalFormValues>();

  const projectTechnologies = formMethods.watch(ProjectModalFormFields.ProjectTechnologies);
  const usedTechnologies = formMethods.watch(ProjectModalFormFields.ProfileProjectTechnologies);

  const copyTechnologies = () => {
    const technologies = [...usedTechnologies, ...projectTechnologies];

    const uniqueTechnologies = technologies.filter((technology, index, self) => {
      return index === self.findIndex((t) => technology.id === t.id);
    });

    formMethods.setValue(ProjectModalFormFields.ProfileProjectTechnologies, uniqueTechnologies, {
      shouldDirty: true,
    });
    formMethods.trigger(ProjectModalFormFields.ProfileProjectTechnologies);
  };

  const areUniqueValuesInProjectTechnologies = projectTechnologies.some(
    (technology) => !usedTechnologies.some((usedTechnology) => usedTechnology.id === technology.id),
  );

  if (areUniqueValuesInProjectTechnologies) {
    return (
      <Button
        variant="text"
        startIcon={<ContentCopyIcon />}
        onClick={copyTechnologies}
        label={formatMessage({ id: 'form.useProjectTechnologies' })}
        size="small"
      />
    );
  }

  return null;
};
