import { Styles } from 'lux/theme';

export const wrapperStyles: Styles = {
  [`& form`]: {
    display: 'flex',
    gap: '6px',
  },
  [`& form > div:first-of-type`]: {
    flex: 1,
    mb: '-3px',
  },
  [`& form > div`]: {
    position: 'static',
    m: 'initial',
  },
};

export const displayDataWrapperStyles: Styles = {
  display: 'flex',
  width: '100%',
  alignItems: 'flex-start',
};

export const inputWrapperStyles: Styles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
};

export const inputDisabledStyles: Styles = {
  width: '100%',
  pointerEvents: 'none',
};

export const editButtonStyles: Styles = {
  mx: '0.5rem',
  minWidth: '40px !important',
};
