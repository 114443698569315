import { Styles } from 'lux/theme';

export const wrapper: Styles = {
  overflowX: 'scroll',
  padding: 3,
  position: 'relative',
};

export const buttonsContainer: Styles = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 5,
};

export const button: Styles = {
  minHeight: 50,
};

export const downloadSection: Styles = {
  paddingLeft: 0,
};

export const globalFilter: Styles = {
  width: '100%',
};

export const hr: Styles = {
  mt: 3,
  mb: 3,
};
