import { Seniority } from 'api/actions/seniorities/senioritiesActions.types';

export type SeniorityContainerProps = {
  seniority: Seniority;
  dragAndDropIndex?: number;
  disabled: boolean;
};

export type SeniorityProps = SeniorityContainerProps & {
  onRemoveButtonClick: () => Promise<void>;
};

export enum SeniorityFormFields {
  Name = 'name',
  VisibleName = 'visibleName',
}

export type SeniorityFormValues = {
  [SeniorityFormFields.Name]: string;
  [SeniorityFormFields.VisibleName]: string | null;
};
