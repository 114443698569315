import { Styles } from 'lux/theme';

export const modalInnerWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const cancelButton: Styles = {
  color: 'grey.900',
  borderColor: 'grey.300',

  '&:hover': {
    backgroundColor: 'grey.300',
    borderColor: 'grey.300',
  },
};
