import { Navigate, Route, Routes } from 'react-router-dom';

import { HomeContainer } from 'views/home/HomeContainer';
import { ProfileEditorProviders } from 'views/profileEditor/ProfileEditorProviders';
import { GeneratedProfileProviders } from 'views/generatedProfile/GeneratedProfileProviders';
import { useContentAccess } from 'hooks';
import { Dashboard } from 'ui';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { AppRoute } from './AppRoute.enum';
import {
  AuthorizedCategories,
  AuthorizedCertificates,
  AuthorizedLanguages,
  AuthorizedPositions,
  AuthorizedProfiles,
  AuthorizedProfilesCertificates,
  AuthorizedProjectRoles,
  AuthorizedProjects,
  AuthorizedSeniority,
  AuthorizedSkills,
  AuthorizedTags,
  AuthorizedJsonEditor,
  AuthorizedCategoriesOrder,
  TechnologiesSurvey,
} from './AuthorizedRoutesElements';

export const AppAuthorizedRoutes = () => {
  const { hasAccess } = useContentAccess({});

  return (
    <Routes>
      <Route path={`${AppRoute.GeneratedProfile}/:id?`} element={<GeneratedProfileProviders />} />
      <Route path={`${AppRoute.GeneratedProfile}/:id?/:variantId?`} element={<GeneratedProfileProviders />} />

      <Route element={<Dashboard />}>
        <Route path={AppRoute.Home} element={<HomeContainer />} />
        <Route path={`${AppRoute.ProfileEditor}/:step/:id`} element={<ProfileEditorProviders />} />
        <Route path={`${AppRoute.ProfileEditor}/:step`} element={<ProfileEditorProviders />} />
        <Route path={AppRoute.TechnologiesSurvey} element={<TechnologiesSurvey />} />

        {hasAccess(ContentType.AdminPanel) && (
          <>
            <Route path={AppRoute.Profiles} element={<AuthorizedProfiles />} />
            <Route path={AppRoute.Positions} element={<AuthorizedPositions />} />
            <Route path={AppRoute.Seniority} element={<AuthorizedSeniority />} />
            <Route path={AppRoute.Languages} element={<AuthorizedLanguages />} />
            <Route path={AppRoute.TechnologyCategories} element={<AuthorizedCategories />} />
            <Route path={AppRoute.TechnologyCategoriesOrder} element={<AuthorizedCategoriesOrder />} />
            <Route path={AppRoute.Projects} element={<AuthorizedProjects />} />
            <Route path={AppRoute.ProjectRoles} element={<AuthorizedProjectRoles />} />
            <Route path={AppRoute.Skills} element={<AuthorizedSkills />} />
            <Route path={AppRoute.Certificates} element={<AuthorizedCertificates />} />
            <Route path={AppRoute.ProfileCertificates} element={<AuthorizedProfilesCertificates />} />
            <Route path={AppRoute.Tags} element={<AuthorizedTags />} />
            <Route path={AppRoute.SkillsOrderEditor} element={<AuthorizedJsonEditor />} />
          </>
        )}
      </Route>
      <Route path="*" element={<Navigate to={AppRoute.Home} replace />} />
    </Routes>
  );
};
