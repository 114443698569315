import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { TagModalFormFields } from 'views/adminSection/tags/modals/TagModal/TagModal.types';
import { Tag } from 'api/actions/tags/tagsActions.types';

export const defaultValues = {
  [TagModalFormFields.Name]: '',
};

export const getDefaultValues = ({ name }: Pick<Tag, 'name'>) => ({ [TagModalFormFields.Name]: name });

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  const errorMessages = {
    'string.empty': formatMessage({ id: 'error.required' }),
    'string.max': formatMessage({ id: 'validation.string.max' }),
  };

  return Joi.object({
    [TagModalFormFields.Name]: Joi.string()
      .max(15)
      .label(formatMessage({ id: 'tags.form.tag' }))
      .messages(errorMessages),
  });
};
