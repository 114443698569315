import { Styles } from 'views/generatedProfile/theme/theme';

export const section: Styles = ({ spacing }) => ({
  pageBreakInside: 'avoid',
  display: 'flex',
  pb: 1,

  '& svg': {
    fontSize: spacing(2),
    color: 'rgb(0, 27, 177)',
  },
});

export const sectionContent: Styles = {
  pl: 1,
};

export const title: Styles = ({ spacing }) => ({
  fontSize: spacing(1.625),
  fontWeight: 600,
  mb: 1,
});

export const content: Styles = ({ spacing }) => ({
  fontSize: spacing(1.25),
  color: 'text.secondary',
  mb: 0.5,
});
