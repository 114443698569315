import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { EditedProfileLanguage } from '../editLanguageModal/EditLanguageModalContainer.types';

import { LanguageModalFormFields } from './LanguageModal.types';

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  const errorMessages = { 'string.base': formatMessage({ id: 'error.required' }) };

  return Joi.object({
    [LanguageModalFormFields.LanguageId]: Joi.string().messages(errorMessages),
    [LanguageModalFormFields.Proficiency]: Joi.string().messages(errorMessages),
  });
};

export const getDefaultValues = (editedProfileLanguage: EditedProfileLanguage) => {
  return {
    [LanguageModalFormFields.LanguageId]: editedProfileLanguage.id,
    [LanguageModalFormFields.Proficiency]: editedProfileLanguage.proficiency,
  };
};

export const defaultValues = {
  [LanguageModalFormFields.LanguageId]: null,
  [LanguageModalFormFields.Proficiency]: null,
};
