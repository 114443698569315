import { Styles } from 'lux/theme';

export const textfield: Styles = {
  '& textarea': {
    minHeight: '200px',
  },
};

export const button: Styles = {
  display: 'block',
  margin: '0 0 0 auto',
};
