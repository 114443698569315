import { userPermissions } from 'config/userPermisions/userPermisions';
import { Actions } from 'config/userPermisions/userPermisions.types';

import { HasPermissionArgs } from './AccessControl.types';

export const hasPermission = ({ userRole, hasProfileAccess, allowedContent }: HasPermissionArgs) => {
  if (!userRole) return { isAllowed: false, isReadOnly: true };

  if (userPermissions.has(userRole)) {
    const rolePermissions = userPermissions.get(userRole);
    const permissions = rolePermissions.filter((el: string[]) => el.includes(allowedContent)).flat();

    if (allowedContent === 'userPanel' && !hasProfileAccess) {
      return { isAllowed: false, isReadOnly: true };
    }

    return {
      isAllowed: !!permissions.length,
      isReadOnly: !permissions.includes(Actions.WRITE),
    };
  }

  return { isAllowed: false, isReadOnly: true };
};
