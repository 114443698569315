export type SeparatorProps = {
  height?: number;
  margin?: number;
};

export const Separator = ({ height = 30, margin = 0 }: SeparatorProps) => {
  const styles = {
    height: `${height}px`,
    margin: `${margin}px`,
  };

  return <div style={styles} />;
};
