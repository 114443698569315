import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { getCertificatesQueryKey, useCertificateDelete } from 'api/actions/certificates/certificatesActions';
import { CertificateDeleteArgs } from 'api/actions/certificates/certificatesActions.types';
import { useSnackbar } from 'hooks';

import { UseDeleteCertificateArgs } from './useDeleteCertificate.types';

export const useDeleteCertificate = ({ onSuccess, onForceDelete }: UseDeleteCertificateArgs) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate } = useCertificateDelete({
    onSuccess: () => {
      onSuccess();
      showSnackbarSuccess('success.itemDeleted');
      queryClient.invalidateQueries(getCertificatesQueryKey);
    },
    onError: (error) => {
      showSnackbarError(error, [409]);
      if (error?.response?.status === 409) {
        onForceDelete();
      }
    },
  });

  const deleteCertificate = useCallback(
    ({ certificateId, forceDelete }: CertificateDeleteArgs) => {
      mutate({ certificateId, forceDelete });
    },
    [mutate],
  );

  return { deleteCertificate };
};
