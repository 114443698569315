import { Styles } from 'lux/theme';

export const selectStyles: Styles = {
  marginBottom: 2,
  '.MuiSelect-select': {
    paddingBottom: 1.25,
    paddingTop: 1.25,
  },
};

export const autocompleteStyles: Styles = {
  marginBottom: 2,
};

export const buttonsWrapperStyles: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
};
