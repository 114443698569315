import { Box, Typography } from '@mui/material';

import TSHLogo from 'assets/images/tsh-logo.png';
import { useLocale } from 'hooks';

import { HeaderProps } from './Header.types';
import * as S from './Header.styles';

export const Header = ({ firstName, positionName, seniorityVisibleName, code, about }: HeaderProps) => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={S.header}>
      <Box sx={S.headerContent}>
        <Box sx={S.logoNameContainer}>
          <Typography sx={S.name} variant="h1">
            {firstName}
          </Typography>
          <Box sx={S.headerImage}>
            <img src={TSHLogo} alt="TSH logo" />
          </Box>
        </Box>
        <Typography sx={S.userCode} variant="body2">
          {`${formatMessage({ id: 'generatedProfile.internalId' })} ${code}`}
        </Typography>
        <Typography sx={S.position} variant="h3">
          {seniorityVisibleName && `${seniorityVisibleName} `}
          {positionName}
        </Typography>
        {about && <Box dangerouslySetInnerHTML={{ __html: about }} sx={S.about} />}
      </Box>
    </Box>
  );
};
