import { Box, IconButton, Autocomplete, TextField } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import { DragHandle } from '@mui/icons-material';
import { Table } from 'lux/components';

import { FormInputText, DeleteButton, Separator, Typography, EditButton } from 'ui';
import { ErrorMessage } from '../../errorMessage/ErrorMessage';

import * as S from './AdminSectionTableRow.styles';
import { TableRowProps } from './AdminSectionTableRow.types';

export const AdminSectionTableRow = <TFormValues extends Record<string, unknown>>({
  formMethods,
  onSubmit,
  inputName,
  inputLabel,
  isPatchError,
  patchErrorLabel,
  isDeleteError,
  deleteErrorLabel,
  name,
  visibleName,
  onRemoveButtonClick,
  isEdit,
  onCloseEditClick,
  onEditClick,
  category,
  allCategories,
  onCategorySelectChange,
  providedDND,
  snapshotDND,
  error,
  onErrorMessageClose,
  isReadOnly,
}: TableRowProps<TFormValues>) => (
  <Table.Row
    sx={snapshotDND?.isDragging ? S.draggedItemStyles : undefined}
    {...(providedDND && { ref: providedDND.innerRef, ...providedDND.draggableProps })}
  >
    <Table.Cell>
      {isEdit && formMethods && onSubmit && onCloseEditClick && visibleName === undefined ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Box sx={S.editStyles}>
              <FormInputText name={inputName} label={inputLabel} required />
              <IconButton type="submit">
                <CheckBoxIcon />
              </IconButton>
              <IconButton onClick={onCloseEditClick}>
                <CancelIcon />
              </IconButton>
            </Box>

            {isPatchError && patchErrorLabel && (
              <>
                <Separator height={10} />
                <Typography variant="helperText">{patchErrorLabel}</Typography>
              </>
            )}
          </form>
        </FormProvider>
      ) : (
        <>
          {providedDND && !isReadOnly && (
            <Box {...providedDND.dragHandleProps} sx={S.dragHandleStyles} component="span">
              <DragHandle />
            </Box>
          )}

          {name}
          {isDeleteError && deleteErrorLabel && (
            <>
              <Separator height={10} />
              <Typography variant="helperText">{deleteErrorLabel}</Typography>
            </>
          )}
          {error?.response && <ErrorMessage onMessageClose={onErrorMessageClose} error={error.response.data} />}
        </>
      )}
    </Table.Cell>

    {category !== undefined && allCategories && (
      <Table.Cell>
        <Autocomplete
          size="small"
          options={allCategories}
          renderInput={(params) => <TextField {...params} label="Category" />}
          getOptionLabel={({ name }) => name || ''}
          onChange={(_, value) => onCategorySelectChange && onCategorySelectChange(value)}
          value={category}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Table.Cell>
    )}

    {visibleName !== undefined && <Table.Cell align="left">{visibleName === null ? '-' : visibleName}</Table.Cell>}

    <Table.Cell sx={S.actionsCell} align="center">
      {!isReadOnly && (
        <Box sx={S.actionsWrapper}>
          <EditButton onClick={onEditClick} />
          <DeleteButton onClick={onRemoveButtonClick} />
        </Box>
      )}
    </Table.Cell>
  </Table.Row>
);
