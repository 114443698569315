import { useEffect, useRef, useState } from 'react';

import { AdminSectionWrapper, Divider, GlobalFilter, OpenModalButton, Separator } from 'ui';
import { Table } from 'ui/tables/table/Table';
import { DeleteModal } from 'ui/modals/forceDeleteModal/DeleteModal';
import { ProjectRoleModal } from 'views/adminSection/projectRoles/modals/projectRoleModal/ProjectRoleModal';
import {
  ProjectRole,
  ProjectRolesDeleteArgs,
  ProjectRolesPatchArgs,
} from 'api/actions/projectRoles/projectRolesActions.types';
import {
  filtersOperators,
  getModalErrorHandler,
  getProjectsRolesParams,
  keysMapper,
} from 'views/adminSection/projectRoles/ProjectRoles.utils';
import { useContentAccess, useGlobalFilter, useModal } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { ProjectRolesProps } from './ProjectRoles.types';
import { useProjectRolesQuery } from './hooks/useProjectRolesQuery';
import { useProjectRolesColumns } from './hooks/useProjectRolesColumns';
import * as S from './ProjectRoles.styles';

export const ProjectRoles = ({
  onPatchProjectRole,
  onDeleteProjectRole,
  onAddProjectRole,
  errors,
  isMutationLoading,
  isMutationSuccess,
  mutationErrorReset,
}: ProjectRolesProps) => {
  const [isForceDeleteModalOpen, setIsForceDeleteModalOpen] = useState(false);

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useModal();
  const { isOpen: isAddModalOpen, onOpen: onAddModalOpen, onClose: onAddModalClose } = useModal();

  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.ProjectRoles });

  const selectedRole = useRef({ id: '', name: '' });
  const modalTypography = useRef({ title: '', submitButton: '' });

  const handleModalClose = () => {
    onEditModalClose();
    onAddModalClose();
  };

  const handleDelete = ({ id, forceDelete }: ProjectRolesDeleteArgs) => {
    onDeleteProjectRole({ id, forceDelete });
    selectedRole.current.id = id;
  };

  const handleEdit = ({ id, name }: ProjectRolesPatchArgs) => {
    modalTypography.current = { title: 'projectRoles.form.edit', submitButton: 'form.save' };
    selectedRole.current = { id, name };
    onEditModalOpen();
  };

  const handleAddNew = () => {
    modalTypography.current = { title: 'projectRoles.form.add', submitButton: 'form.save' };
    selectedRole.current = { id: '', name: '' };
    onAddModalOpen();
  };

  const handleSubmit = (id: string, name: string) => {
    isEditModalOpen && onPatchProjectRole({ name, id });
    isAddModalOpen && onAddProjectRole({ name });
  };

  const modalErrorHandler = getModalErrorHandler(errors, mutationErrorReset, {
    patch: isEditModalOpen,
    post: isAddModalOpen,
  });

  const columns = useProjectRolesColumns(handleDelete, handleEdit);

  const { globalFilter, setGlobalFilter, queryParamsDecoded } = useGlobalFilter({
    keysMapper,
    filtersOperators,
  });

  const { data, isLoading, isError } = useProjectRolesQuery({ params: getProjectsRolesParams(queryParamsDecoded) });

  useEffect(() => {
    if (errors?.deleteError?.response?.status === 409) {
      setIsForceDeleteModalOpen(true);
    }
  }, [errors]);

  useEffect(() => {
    isMutationSuccess.delete && setIsForceDeleteModalOpen(false);
    isMutationSuccess.patch && onEditModalClose();
    isMutationSuccess.post && onAddModalClose();
  }, [isMutationSuccess, setIsForceDeleteModalOpen]);

  return (
    <AdminSectionWrapper
      title="navigator.projectRoles"
      tabs="projectsTabs"
      action={isReadOnly ? null : <OpenModalButton label="projectRoles.form.add" onClick={handleAddNew} />}
    >
      <GlobalFilter
        onValueChange={setGlobalFilter}
        labelPlaceholder="projectRoles.table.searchProjectRoles"
        value={globalFilter}
      />
      <Divider />
      <Table<ProjectRole>
        keysMapper={keysMapper}
        filtersOperators={filtersOperators}
        sx={{ tableHead: S.tableHead }}
        data={data?.result}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        total={data?.total}
        globalFilter={globalFilter}
        defaultSort={[{ id: 'name', desc: false }]}
      />
      <Separator />
      {errors && (
        <DeleteModal
          isOpen={isForceDeleteModalOpen}
          onModalClose={() => {
            setIsForceDeleteModalOpen(false);
            mutationErrorReset.delete();
          }}
          onItemDelete={() => onDeleteProjectRole({ id: selectedRole.current.id, forceDelete: true })}
        />
      )}
      {(isAddModalOpen || isEditModalOpen) && (
        <ProjectRoleModal
          mutationErrorReset={modalErrorHandler.reset}
          error={modalErrorHandler.error}
          onSubmit={({ name }) => handleSubmit(selectedRole.current.id, name)}
          isLoading={isMutationLoading}
          onModalClose={handleModalClose}
          selectedRole={selectedRole.current}
          isOpen={isAddModalOpen || isEditModalOpen}
          modalTypography={modalTypography.current}
        />
      )}
    </AdminSectionWrapper>
  );
};
