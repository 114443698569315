import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { AppLocale } from '../AppLocale.enum';
import { defaultLocale } from '../defaultLocale';
import { translations } from 'i18n/messages';
import { LocaleContext } from '../localeContext/LocaleContext';

import { LocaleContextControllerProps } from './LocaleContextController.types';

const KEY = 'lang';

const getDefaultLanguage = () => {
  const lang = localStorage.getItem(KEY);

  if (!lang) {
    return AppLocale.en;
  }

  return lang as AppLocale;
};

export const LocaleContextController = ({ children }: LocaleContextControllerProps) => {
  const [locale, setLocale] = useState<AppLocale>(getDefaultLanguage());

  useEffect(() => {
    localStorage.setItem(KEY, locale);
  }, [locale]);

  return (
    <IntlProvider defaultLocale={defaultLocale} locale={locale} messages={translations[locale]}>
      <LocaleContext.Provider value={{ defaultLocale, locale, setLocale }}>{children}</LocaleContext.Provider>
    </IntlProvider>
  );
};
