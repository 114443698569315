import { FormAutocomplete, RichTextEditor, Separator, Typography } from 'ui';
import { ProjectModalFormFields } from '../../ProjectModal.types';
import { useProjectContext } from '../../../../context/ProjectContext';

import { ProjectRoleProps } from './MyRole.types';

export const MyRole = ({ projectRoles, initialDescriptionValue }: ProjectRoleProps) => {
  const { isPending } = useProjectContext();

  return (
    <>
      <Typography variant="h6">{'profileProject.header.role'}</Typography>
      <Separator height={12} />
      <Typography variant="body1">{'profileProject.header.role.description'}</Typography>
      <Separator height={24} />
      <FormAutocomplete
        options={projectRoles}
        label="profileProject.placeholder.projectRole"
        name={ProjectModalFormFields.Role}
        blurOnSelect
        freeSolo
        forcePopupIcon
        withAddNewButton
        required
        disabledItemsFocusable
        size="medium"
      />
      <Separator height={24} />
      <RichTextEditor
        name={ProjectModalFormFields.AdditionalDescription}
        placeholder="profileProject.placeholder.roleDescription"
        initialValue={initialDescriptionValue}
        isLoading={isPending}
        helperText={'form.additionalDescriptionInfo'}
      />
      <Separator height={14} />
    </>
  );
};
