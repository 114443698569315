import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';

import { useCurrentUser, useSnackbar } from 'hooks';
import { profileProjectGetKey, useProfileProjectPatch } from 'api/actions/profile/projects/profileProjectsActions';
import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { profileProjectRolesGetKey } from 'views/profileEditor/steps/experience/projects/hooks/useGetProjectRoles';
import { ProjectModalFormValues, ProjectType } from '../modals/projectModal/ProjectModal.types';
import { projectsGetQueryKey } from 'api/actions/projects/projectsActions';
import { skillQueryKey } from 'api/actions/skills/skillsActions';

import { removeDuplicates } from './utils/removeDuplicates';

export const usePatchProfileProject = (profileProjectId: string, onSuccess: VoidFunction) => {
  const { user } = useCurrentUser();
  const queryClient = useQueryClient();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const profileProjectsQueryKey = `/profiles/${user?.id}/projects`;
  const editedProjectQueryKey = [profileProjectGetKey, `/profiles/${user?.id}/project/${profileProjectId}`];
  const profileProjectSkillsQueryKey = `/profiles/${user?.id}/profile-project/${profileProjectId}/skills`;

  const { mutateAsync, isError, isLoading, error } = useProfileProjectPatch({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(editedProjectQueryKey);
      queryClient.invalidateQueries(['profile-projects', profileProjectsQueryKey]);
      queryClient.invalidateQueries('project');
      queryClient.invalidateQueries([profileProjectRolesGetKey]);
      queryClient.invalidateQueries([projectsGetQueryKey]);
      queryClient.invalidateQueries(['get-profile-project-skills', { details: profileProjectSkillsQueryKey }]);
      queryClient.invalidateQueries([skillQueryKey]);
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => showSnackbarError(error),
  });

  const mapSkillsToBeSubmitted = (options: AutocompleteOption[]) =>
    options.map(({ id, name }) => (id === name ? { name } : { id }));

  const filterRole = (role: AutocompleteOption) => (role.id === role.name ? { name: role.name } : { id: role.id });

  const editProject = useCallback(
    async ({
      profileProjectTechnologies,
      projectTechnologies,
      role,
      joinedAt,
      leftAt,
      projectType,
      ...body
    }: ProjectModalFormValues) => {
      if (!role) {
        throw new Error('Project role is null');
      }

      await mutateAsync({
        profileId: user?.id,
        profileProjectId,
        body: {
          ...body,
          role: filterRole(role),
          profileProjectTechnologies: mapSkillsToBeSubmitted(profileProjectTechnologies),
          projectTechnologies: removeDuplicates([
            ...mapSkillsToBeSubmitted(projectTechnologies),
            ...mapSkillsToBeSubmitted(profileProjectTechnologies),
          ]),
          joinedAt: joinedAt ? format(joinedAt, 'yyyy-MM-dd') : null,
          leftAt: leftAt ? format(leftAt, 'yyyy-MM-dd') : null,
          isPrivate: projectType === ProjectType.Private ? true : false,
        },
      });
    },
    [mutateAsync, profileProjectId, user?.id],
  );

  return { editProject, isError, isLoading, error };
};
