import { Box } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import * as styles from './Technology.styles';
import { TechnologyProps } from './Technology.types';

export const Technology = ({ technology: { id, name }, onClick }: TechnologyProps) => (
  <Box onClick={() => onClick(id)} sx={styles.technology} component="span">
    {name}
    <HighlightOffIcon color="secondary" />
  </Box>
);
