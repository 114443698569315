import { Styles } from 'lux/theme';

export const titleWrapper: Styles = {
  mb: 5,
  justifyContent: 'space-between',
  display: 'flex',
  minHeight: 74,

  '.MuiButtonBase-root': {
    marginBottom: 0,
  },
};

export const breadcrumbs: Styles = {
  '&.MuiGrid-item': {
    mt: 4,
    pt: 0,
    display: 'flex',
  },
};

export const tab = (isActive: boolean): Styles => ({
  flex: 1,
  maxWidth: '100%',
  pointerEvents: isActive ? 'none' : 'auto',
});

export const tabs: Styles = {
  paddingY: '0 !important',
};

export const content: Styles = {
  marginTop: 5,
  paddingTop: '0 !important',
};
