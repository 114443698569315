import { Box } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import PublicIcon from '@mui/icons-material/Public';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { formatDuration } from 'date-fns';

import { Typography } from 'ui';

import { AdditionalInformationsProps } from './AdditionalInformations.types';
import * as S from './AdditionalInformations.styles';

export const AdditionalInformations = ({ education, languages, experienceYears }: AdditionalInformationsProps) => (
  <>
    {!!education.length && (
      <Box sx={S.section}>
        <SchoolIcon />
        <Box sx={S.sectionContent}>
          <Typography sx={S.title} variant="h2">
            {'generatedProfile.additionalInfo.education'}
          </Typography>

          {education.map(({ degree, majorOfStudies }) => (
            <Typography
              sx={S.content}
              key={`${degree}${majorOfStudies}`}
            >{`${degree} in ${majorOfStudies}`}</Typography>
          ))}
        </Box>
      </Box>
    )}

    {!!languages.length && (
      <Box sx={S.section}>
        <PublicIcon />
        <Box sx={S.sectionContent}>
          <Typography sx={S.title} variant="h2">
            {'generatedProfile.additionalInfo.languages'}
          </Typography>

          {languages.map(({ language, proficiency }) => (
            <Typography sx={S.content} key={language}>{`${language} (${proficiency})`}</Typography>
          ))}
        </Box>
      </Box>
    )}

    {experienceYears !== null && (
      <Box sx={S.section}>
        <PendingActionsIcon />
        <Box sx={S.sectionContent}>
          <Typography sx={S.title} variant="h2">
            {'generatedProfile.additionalInfo.experience'}
          </Typography>

          <Typography sx={S.content}>
            {experienceYears > 0 ? formatDuration({ years: experienceYears }) : '< 1 year '}
          </Typography>
        </Box>
      </Box>
    )}
  </>
);
