import { FilterOperator } from 'utils';

export const filtersOperators = {
  name: FilterOperator.include,
  type: FilterOperator.eq,
};

export const keysMapper = {
  name: 'skill.name',
  type: 'skill.type',
};

export const getSkillsParams = (queryParams: Record<string, string | number>) => {
  const isSortParams = Object.keys(queryParams).some((key) => key.startsWith('sort'));

  const defaultSortParams = isSortParams ? {} : { 'sort[skill.name]': 'ASC' };

  return {
    page: '1',
    limit: '10',
    ...defaultSortParams,
    ...queryParams,
    relations: ['skill.category'],
  };
};
