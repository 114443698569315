import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

import { useCurrentUser, useSnackbar } from 'hooks';
import { useProfileLanguagePatch as useProfileLanguagePatchHook } from 'api/actions/profile/languages/profileLanguagesActions';
import {
  ProfileLanguagesGetResponse,
  PatchProfileLanguageBody,
} from 'api/actions/profile/languages/profileLanguagesActions.types';
import { mutationUpdater } from 'utils/mutationUpdater/mutationUpdater';

export const usePatchProfileLanguage = (profileLanguageId: string, onSuccess?: VoidFunction) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const languagesQueryKey = `profiles/${user?.id}/languages`;

  const { mutateAsync, isError, isLoading, error } = useProfileLanguagePatchHook({
    onSuccess: ({ data }) => {
      queryClient.setQueryData<ProfileLanguagesGetResponse>(languagesQueryKey, (languages) =>
        mutationUpdater('PATCH', data, languages),
      );
      onSuccess && onSuccess();
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => showSnackbarError(error),
  });

  const editProfileLanguage = useCallback(
    async (body: PatchProfileLanguageBody) => await mutateAsync({ profileId: user?.id, profileLanguageId, body }),
    [mutateAsync, user?.id, profileLanguageId],
  );

  return { editProfileLanguage, isError, isLoading, error };
};
