import { FilterOptionsState, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

import { Autocomplete } from 'ui';
import { CertificateOption } from '../../../ProfileCertificates.types';
import { CertificateModalFormFields } from '../CertificateModal.types';

import * as S from './CertificateAutocomplete.styles';
import { CertificateAutocompleteProps } from './CertificateAutocomplete.types';

export const CertificateAutocomplete = ({ certificatesOptions }: CertificateAutocompleteProps) => {
  const autocompleteFilterOptions = (_: CertificateOption[], state: FilterOptionsState<CertificateOption>) => {
    const inputValue = state.inputValue.toLowerCase().trim();

    const filterOptions = certificatesOptions.filter((option) => {
      const optionName = option.name.toLowerCase().trim();
      const isNameMatch = optionName.includes(inputValue);

      const optionTags = option.tags.map(({ tag }) => tag.name.toLowerCase().trim());
      const isTagMatch = optionTags.some((tag) => tag.includes(inputValue));

      return isNameMatch || isTagMatch;
    });

    return filterOptions;
  };

  return (
    <Controller
      name={CertificateModalFormFields.Certificate}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Autocomplete
            onChange={onChange}
            value={value}
            options={certificatesOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={autocompleteFilterOptions}
            label="form.certificate"
            blurOnSelect
            disablePortal={false}
            forcePopupIcon
          />

          {error && (
            <Typography sx={S.error} variant="helperText">
              {error.message}
            </Typography>
          )}
        </>
      )}
    />
  );
};
