import { Navigate } from 'react-router-dom';

import { CurrentUserContextController } from 'context/currentUser/currentUserContextController/CurrentUserContextController';
import { AccessControl } from 'ui';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { AppRoute } from 'routing/AppRoute.enum';

import { GeneratedProfileContainer } from './GeneratedProfileContainer';

export const GeneratedProfileProviders = () => (
  <AccessControl allowedContent={ContentType.GeneratedProfile} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <CurrentUserContextController>
      <GeneratedProfileContainer />
    </CurrentUserContextController>
  </AccessControl>
);
