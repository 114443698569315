import Joi from 'joi';
import { IntlShape } from 'react-intl';

import { Profile } from 'api/actions/profiles/profilesActions.types';

import { BasicDataFormFields } from './BasicData.types';

export const getDefaultValues = (user?: Profile) => {
  return {
    [BasicDataFormFields.CareerStart]: user?.startCareerAt || null,
    [BasicDataFormFields.About]: user?.about || '',
  };
};

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) =>
  Joi.object({
    [BasicDataFormFields.CareerStart]: Joi.date()
      .optional()
      .allow(null)
      .label(formatMessage({ id: 'form.careerStart' })),
    [BasicDataFormFields.About]: Joi.string().optional().allow(null, ''),
  });
