import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { CategoryOrder } from 'api/actions/categoriesOrder/categoriesOrderActions.types';

export const useCategoriesOrderColumns = () => {
  const columnHelper = createColumnHelper<CategoryOrder>();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('category.name', {
          id: 'name',
          header: () => <FormattedMessage id="categoriesOrder.table.headers.name" />,
          enableColumnFilter: false,
          enableSorting: false,
        }),
      ] as ColumnDef<CategoryOrder>[],
    [columnHelper],
  );

  return { columns };
};
