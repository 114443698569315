import { stepLabelClasses } from '@mui/material';
import { Styles } from 'lux/theme';

export const wrapperStyles: Styles = {
  mb: 5,
};

export const stepStyles: Styles = {
  '& svg': {
    fontSize: 34,
    mr: 1,
    color: 'grey.500',
  },

  [`& .${stepLabelClasses.label}`]: {
    fontSize: 16,
  },
};
