import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { DragAndDropBody } from 'api/types/types';
import {
  categoriesOrderQueryKey,
  useCategoryOrderUpdateMutation,
} from 'api/actions/categoriesOrder/categoriesOrderActions';
import { useSnackbar } from 'hooks';
import { CategoriesOrderResponse, CategoryOrder } from 'api/actions/categoriesOrder/categoriesOrderActions.types';
import { dragAndDropDataReorder } from 'utils';
import { getCategoriesOrderParams } from '../CategoriesOrder.utils';

export const useUpdateCategoryOrder = () => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync } = useCategoryOrderUpdateMutation({
    onMutate: ({ queryParams, sourceIndex, destinationIndex }) => {
      queryClient.cancelQueries([categoriesOrderQueryKey]);

      const paramsKey = getCategoriesOrderParams(queryParams, queryParams?.search.toString());

      const previousData = queryClient.getQueryData<CategoriesOrderResponse>([categoriesOrderQueryKey, paramsKey]);

      if (!previousData || sourceIndex === undefined || destinationIndex === undefined) {
        return;
      }

      const reorderedData = dragAndDropDataReorder<CategoryOrder>({
        data: previousData,
        sourceIndex,
        destinationIndex,
      });

      queryClient.setQueryData<CategoriesOrderResponse>([categoriesOrderQueryKey, paramsKey], reorderedData);

      return reorderedData;
    },
    onSuccess: () => {
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => {
      showSnackbarError(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries([categoriesOrderQueryKey]);
    },
  });

  const reorderCategory = useCallback(
    async (body: DragAndDropBody) => {
      await mutateAsync(body);
    },
    [mutateAsync],
  );

  return { reorderCategory };
};
