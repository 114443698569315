import { ReactNode } from 'react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { componentsLibTheme as theme } from 'lux/theme';

type ThemeProviderTypes = {
  children: ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderTypes) => (
  <MaterialThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MaterialThemeProvider>
);
