import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useSnackbar } from 'hooks';
import { ProfileCertificatesGetResponse } from 'api/actions/profile/certificates/profileCertificatesActions.types';
import { mutationUpdater } from 'utils/mutationUpdater/mutationUpdater';
import { useProfileCertificatePost } from 'api/actions/profile/certificates/profileCertificatesActions';
import { CertificateModalFormValues } from '../modals/certificateModal/CertificateModal.types';

export const usePostProfileCertificate = (onSuccess: VoidFunction) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();
  const queryClient = useQueryClient();

  const profileCertificatesQueryKey = `profiles/${user?.id}/certificates`;

  const { mutateAsync, isLoading, isError } = useProfileCertificatePost({
    onSuccess: ({ data }) => {
      onSuccess();
      queryClient.setQueryData<ProfileCertificatesGetResponse>(profileCertificatesQueryKey, (certificates) =>
        mutationUpdater('POST', data, certificates),
      );
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const addProfileCertificate = useCallback(
    async (formValues: CertificateModalFormValues) => {
      const { certificate, obtainedAt, isHidden } = formValues;

      if (certificate === null || obtainedAt === null) return;

      const body = {
        certificateId: certificate.id,
        obtainedAt,
        isHidden,
      };

      await mutateAsync({ profileId: user?.id, body });
    },
    [mutateAsync, user?.id],
  );

  return { addProfileCertificate, isLoading, isError };
};
