import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { ProjectRoleFormFields } from 'views/adminSection/projectRoles/modals/projectRoleModal/ProjectRoleModal.types';

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  const errorMessages = { 'string.empty': formatMessage({ id: 'error.required' }) };

  return Joi.object({
    [ProjectRoleFormFields.Name]: Joi.string().required().messages(errorMessages),
  });
};
