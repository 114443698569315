import {
  ProjectRolesDeleteArgs,
  ProjectRolesPatchArgs,
  ProjectRolesPostArgs,
} from 'api/actions/projectRoles/projectRolesActions.types';
import { ClientErrorResponse } from 'api/types/types';

export enum ProjectRolesFormFields {
  Name = 'name',
}

export type ProjectRolesFormValues = {
  [ProjectRolesFormFields.Name]: string;
};

export type ProjectRolesProps = {
  onAddProjectRole: (body: ProjectRolesPostArgs, onSuccess?: VoidFunction) => void;
  onDeleteProjectRole: (body: ProjectRolesDeleteArgs) => void;
  onPatchProjectRole: (body: ProjectRolesPatchArgs) => void;
  isMutationLoading: boolean;
  isMutationSuccess: {
    patch: boolean;
    post: boolean;
    delete: boolean;
  };
  errors: {
    deleteError: ClientErrorResponse | null;
    patchError: ClientErrorResponse | null;
    postError: ClientErrorResponse | null;
  };
  mutationErrorReset: {
    post: VoidFunction;
    patch: VoidFunction;
    delete: VoidFunction;
  };
};
