import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { CertificateGetResponse } from 'api/actions/certificates/certificatesActions.types';
import { Tag } from 'api/actions/tags/tagsActions.types';
import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';

import { CertificateFormFields, CertificateFormValues } from './CertificateForm.types';

export const getDefaultValues: (data?: CertificateGetResponse) => CertificateFormValues = (data) => ({
  name: data?.name || '',
  description: data?.description || '',
  periodOfValidity: data?.periodOfValidity || undefined,
  url: data?.url || undefined,
  tags: data?.tags?.map((el) => el.tag.name) || undefined,
});

export const getTagsToSubmit = (tags?: Tag[], addedTags?: string[]) => {
  if (!tags || !addedTags) {
    return [];
  }

  return addedTags.map((tag) => {
    const foundTag = tags.filter((el) => el.name === tag).pop();

    return foundTag ? { id: foundTag.id } : { name: tag };
  });
};

const NAME_MAX_LENGTH = 100;
const DESCRIPTION_MAX_LENGTH = 300;
const PERIOD_OF_VALIDITY_MIN_NUMBER = 1;
const TAGS_MAX_LENGTH = 25;

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  return Joi.object({
    [CertificateFormFields.Name]: Joi.string()
      .required()
      .max(NAME_MAX_LENGTH)
      .label(formatMessage({ id: 'certificates.form.name' }))
      .messages(getErrorMessages({ formatMessage, maxLength: NAME_MAX_LENGTH })),
    [CertificateFormFields.Description]: Joi.string()
      .required()
      .max(DESCRIPTION_MAX_LENGTH)
      .label(formatMessage({ id: 'certificates.form.description' }))
      .messages(getErrorMessages({ formatMessage, maxLength: DESCRIPTION_MAX_LENGTH })),
    [CertificateFormFields.PeriodOfValidity]: Joi.number()
      .label(formatMessage({ id: 'certificates.form.yearsOfValidity' }))
      .min(PERIOD_OF_VALIDITY_MIN_NUMBER)
      .integer()
      .allow(null, '')
      .messages(getErrorMessages({ formatMessage, min: PERIOD_OF_VALIDITY_MIN_NUMBER })),
    [CertificateFormFields.Url]: Joi.string()
      .label(formatMessage({ id: 'certificates.form.url' }))
      .uri()
      .allow(null, '')
      .messages(getErrorMessages({ formatMessage })),
    [CertificateFormFields.Tags]: Joi.array().items(
      Joi.string()
        .label(formatMessage({ id: 'certificates.form.tags' }))
        .max(TAGS_MAX_LENGTH)
        .messages(getErrorMessages({ formatMessage, maxLength: TAGS_MAX_LENGTH })),
    ),
  });
};
