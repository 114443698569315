import { FilterOperator } from 'utils';

export const filtersOperators = {
  name: FilterOperator.eq,
};

export const keysMapper = {
  name: 'name',
  displayOrder: 'display_order',
};

export const getCategoriesParams = (queryParams: Record<string, string | number>) => {
  const isSortParams = Object.keys(queryParams).some((key) => key.startsWith('sort'));

  const defaultSortParams = isSortParams ? {} : { 'sort[name]': 'ASC' };

  return {
    page: '1',
    limit: '10',
    ...defaultSortParams,
    ...queryParams,
  };
};
