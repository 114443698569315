import { useProjectPost } from 'views/adminSection/projects/hooks/useProjectPost';
import { ProjectModal } from '../projectModal/ProjectModal';

import { ProjectCreationModalContainerProps } from './ProjectCreationModal.types';

export const ProjectCreationModalContainer = ({ isOpen, onClose }: ProjectCreationModalContainerProps) => {
  const { addProject, ...rest } = useProjectPost(onClose);

  return (
    <ProjectModal
      initialValues={{ name: '', description: '', shortDescription: '', isPrivate: false, technologies: [] }}
      onSubmit={addProject}
      title="form.addProject"
      buttonLabel="form.save"
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
    />
  );
};
