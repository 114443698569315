import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Table } from 'lux/components';
import AddIcon from '@mui/icons-material/Add';

import { BorderBox, Loader, Separator, Typography } from 'ui';
import { Section } from '../../../components/section/Section';
import { useLocale } from 'hooks';

import { EducationContainer } from './school/EducationContainer';
import { EducationProps } from './Education.types';
import { AddEducationModalContainer } from './schoolModal/addEducationModalContainer/AddEducationModalContainer';

export const Education = ({ schools, isLoading, isError }: EducationProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formatMessage } = useLocale();

  return (
    <Section title="step.subTitle.education" subtitle="step.subTitle.educationInfo">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isError ? (
            <Typography variant="helperText">{'error.errorOccurred'}</Typography>
          ) : (
            <BorderBox>
              <Table>
                <Table.Table aria-label="Education table">
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell>
                        <FormattedMessage id="form.school" />
                      </Table.Cell>
                      <Table.Cell align="center">
                        <FormattedMessage id="form.subject" />
                      </Table.Cell>
                      <Table.Cell align="center">
                        <FormattedMessage id="form.degree" />
                      </Table.Cell>
                      <Table.Cell align="center">
                        <FormattedMessage id="form.graduation" />
                      </Table.Cell>
                      <Table.Cell align="right" />
                    </Table.Row>
                  </Table.Head>

                  <Table.Body>
                    {schools.map(({ id, school, degree, majorOfStudies, year }) => (
                      <EducationContainer
                        key={id}
                        id={id}
                        school={school}
                        degree={degree}
                        subject={majorOfStudies}
                        year={year}
                      />
                    ))}
                  </Table.Body>
                </Table.Table>
              </Table>

              <Separator height={20} />
              <Button
                data-testid="addEducation"
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => setIsModalOpen(true)}
              >
                {formatMessage({ id: 'form.addSchool' })}
              </Button>
              {isModalOpen && (
                <AddEducationModalContainer isOpen={isModalOpen} onModalClose={() => setIsModalOpen(false)} />
              )}
            </BorderBox>
          )}
        </>
      )}
    </Section>
  );
};
