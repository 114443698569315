import { Category } from 'api/actions/categories/categoriesActions.types';

export enum SkillsQueryKeys {
  Delete = 'deleteSkill',
  Post = 'postSkill',
  Patch = 'patchSkill',
  Get = 'skills',
}

export type GetSkillsQueryKeyType = [SkillsQueryKeys.Get, { limit?: number; page?: number; type?: SkillType }];

export enum SkillType {
  Tech = 1,
  Soft = 2,
  Custom = 3,
}

export type Skill = {
  type: SkillType;
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  categoryId: string | null;
  category: Category | null;
  isSurveyItem: boolean;
};

export type PatchSkillBody = {
  name: string;
  type: SkillType;
  categoryId: string | null;
  isSurveyItem: boolean;
};

export type PatchSkillMutationArgs = {
  skillId: string;
  body: Partial<PatchSkillBody>;
};

export type DeleteSkillMutationArgs = {
  skillId: string;
  isForceDelete: boolean;
  onSuccess?: VoidFunction;
};

export type GetSkillsResponse = {
  result: Skill[];
  total: number;
};

export type GetSkillsQueryParams = {
  limit?: number;
  page?: number;
  type?: SkillType;
  sortQueryString?: Record<string, string>;
  filterQueryString?: Record<string, unknown>;
};

export type PostSkillBody = {
  name: string;
  type: SkillType;
  categoryId: string | null;
  isSurveyItem: boolean;
};

export type PostSkillMutationArgs = {
  body: Partial<PostSkillBody>;
};
