import { Styles } from 'lux/theme';

const TOP_BAR_ZINDEX = 1000;

export const topBarWrapper: Styles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: TOP_BAR_ZINDEX,
  backgroundColor: 'common.white',
};
