import { Styles, componentsLibTheme as theme } from 'lux/theme';

const surfacePaperDefault = theme.palette.surface.paper.default;
const textMain = theme.palette.text.primary;

export const wrapper: Styles = {
  padding: 2,
  borderRadius: '10px',
  backgroundColor: surfacePaperDefault,
  position: 'relative',

  [theme.breakpoints.up('lg')]: {
    width: 660,
  },
};

export const formControl = (isBlocked: boolean): Styles => ({
  pointerEvents: isBlocked ? 'none' : 'auto',
});

export const loaderOverlay: Styles = {
  background: surfacePaperDefault,
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: -2,
  top: -2,
  opacity: 0.8,
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const confirmLabel: Styles = {
  fontWeight: 500,
  color: textMain,
};
