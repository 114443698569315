import { UserRole } from 'api/types/types';

import { USER_PERMISSIONS } from './userPermisions.types';

export const userPermissions = new Map();

userPermissions.set(UserRole.ADMIN, USER_PERMISSIONS['ADMIN']);
userPermissions.set(UserRole.USER, USER_PERMISSIONS['USER']);
userPermissions.set(UserRole.PROFILE_READ, USER_PERMISSIONS['PROFILE_READ']);
userPermissions.set(UserRole.NO_ACCESS, USER_PERMISSIONS['NO_ACCESS']);
