import { CellContext } from '@tanstack/react-table';

import { ActionsWrapper, EditButton, DeleteButton } from 'ui';
import { useContentAccess, useModal } from 'hooks';
import { useDeleteLanguage } from '../../hooks/useDeleteLanguage';
import { Language } from 'api/actions/languages/languagesActions.types';
import { EditLanguageModalContainer } from '../../modals/editLanguageModal/EditLanguageModalContainer';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const TableActions = (cell: CellContext<Language, string>) => {
  const { isOpen: isPatchModalOpen, onOpen: onPatchModalOpen, onClose: onPatchModalClose } = useModal();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Languages });

  const { deleteLanguage } = useDeleteLanguage();

  return isReadOnly ? null : (
    <ActionsWrapper>
      <EditButton onClick={onPatchModalOpen} />
      <DeleteButton onClick={() => deleteLanguage(cell.getValue())} />

      {isPatchModalOpen && (
        <EditLanguageModalContainer
          isOpen={isPatchModalOpen}
          onClose={onPatchModalClose}
          languageData={cell.row.original}
        />
      )}
    </ActionsWrapper>
  );
};
