import { FormProvider } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import { Button, FormCheckbox, FormDatePicker, Modal, Separator, Typography, ModalButtonsWrapper } from 'ui';
import { DateFormats } from 'ui/formDatePicker/FormDatePicker.types';
import { usePrompt } from 'hooks';

import { CertificateModalFormFields, CertificateModalProps } from './CertificateModal.types';
import { CertificateAutocomplete } from './certificateAutocomplete/CertificateAutocomplete';

export const CertificateModal = ({
  isOpen,
  onModalClose,
  formMethods,
  onSubmit,
  certificatesOptions,
  isLoading,
  submitLabel,
}: CertificateModalProps) => {
  usePrompt({ when: formMethods.formState.isDirty });

  return (
    <Modal isOpen={isOpen} onModalClose={onModalClose} dataTestId="certificateModal">
      <Typography variant="h3">{'form.certificate'}</Typography>
      <Typography variant="body2" pt={1}>
        {'form.certificateDescription'}
      </Typography>
      <Separator />

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <CertificateAutocomplete certificatesOptions={certificatesOptions} />
          <Separator height={20} />

          <FormDatePicker
            placeholder="form.dateFieldPlaceholder"
            name={CertificateModalFormFields.ObtainedAt}
            label="form.obtained"
            maxDate={new Date()}
            views={['year', 'month']}
            dateFormat={DateFormats.yyyyMM}
          />
          <FormCheckbox label="certificates.form.hide" name={CertificateModalFormFields.IsHidden} />

          <Separator />

          <ModalButtonsWrapper>
            <Button label="form.cancel" onClick={onModalClose} dataTestId="cancel" variant="outlined" />
            <LoadingButton data-testid="submit" loading={isLoading} variant="contained" type="submit">
              {submitLabel}
            </LoadingButton>
          </ModalButtonsWrapper>
        </form>
      </FormProvider>
    </Modal>
  );
};
