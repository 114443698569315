import { BaseSyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';

export enum CertificateFormFields {
  Name = 'name',
  Description = 'description',
  PeriodOfValidity = 'periodOfValidity',
  Url = 'url',
  Tags = 'tags',
}

export type CertificateFormValues = {
  [CertificateFormFields.Name]: string;
  [CertificateFormFields.Description]: string;
  [CertificateFormFields.PeriodOfValidity]: number | string | undefined;
  [CertificateFormFields.Url]: string | undefined;
  [CertificateFormFields.Tags]?: string[];
};

export type CertificateFormContainerProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export type CertificateFormProps = CertificateFormContainerProps & {
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
  formMethods: UseFormReturn<CertificateFormValues>;
  isLoading: boolean;
  formMode: FormMode;
  tags?: string[];
};
