import { useMemo, useState } from 'react';

import { useDeleteProfileEducation } from '../hooks/useDeleteProfileEducation';
import { EditEducationModalContainer } from '../schoolModal/editEducationModalContainer/EditEducationModalContainer';

import { Education } from './Education';
import { SchoolContainerProps } from './Education.types';

export const EducationContainer = ({ id, school, degree, subject, year }: SchoolContainerProps) => {
  const removeSchool = useDeleteProfileEducation(id);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const yearDate = year === null ? null : new Date(year, 1);

  const editedProfileEducation = useMemo(
    () => ({ school, degree, majorOfStudies: subject, year: yearDate }),
    [school, degree, subject, year],
  );

  return (
    <>
      <Education
        school={school}
        degree={degree}
        subject={subject}
        year={year}
        onEditButtonClick={() => setIsEditModalOpen(true)}
        onRemoveButtonClick={removeSchool}
      />
      {isEditModalOpen && (
        <EditEducationModalContainer
          isOpen={isEditModalOpen}
          onModalClose={() => setIsEditModalOpen(false)}
          profileEducationId={id}
          editedProfileEducation={editedProfileEducation}
        />
      )}
    </>
  );
};
