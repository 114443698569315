import { CategoryModalContainerProps } from './CategoryModalContainer.types';

export enum CategoryModalFormFields {
  Name = 'name',
}

export type CategoryModalFormValues = {
  [CategoryModalFormFields.Name]: string;
};

export type CategoryModalProps = Omit<CategoryModalContainerProps, 'error'>;
