import { useMemo } from 'react';

import { useDebounce } from 'hooks';
import { globalFilterParamEncode } from 'utils';

export const useGlobalFilter = (globalFilter: string) => {
  const debouncedGlobalFilter = useDebounce(globalFilter);

  const globalFilterQueryParams = useMemo(() => globalFilterParamEncode(globalFilter), [globalFilter]);

  return { globalFilterQueryParams, debouncedGlobalFilter };
};
