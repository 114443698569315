import { Grid, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';

import { Typography } from 'ui';

import { SurveyRadioGroupProps } from './SurveyRadioGroup.types';

export const SurveyRadioGroup = ({ name, control, formatMessage, options, label, gridSize }: SurveyRadioGroupProps) => (
  <Controller
    name={name}
    control={control}
    rules={{ required: formatMessage({ id: `technologiesSurvey.form.${label}.required` }) }}
    render={({ field, fieldState: { invalid, error } }) => (
      <>
        <RadioGroup {...field} row>
          {options.map((option) => (
            <Grid item xs={gridSize} key={option.value}>
              <Radio value={option.value} />
            </Grid>
          ))}
        </RadioGroup>
        {invalid && (
          <Typography variant="helperText" color="error">
            {error?.message}
          </Typography>
        )}
      </>
    )}
  />
);
