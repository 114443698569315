import { TextField } from 'lux/components';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';

import { Separator } from 'ui';

import { ProfileSkillProps } from './ProfileSkill.types';

export const ProfileSkill = ({ name, onDelete }: ProfileSkillProps) => (
  <>
    <Box sx={{ display: 'flex' }}>
      <TextField value={name} size="small" sx={{ width: '100%', pointerEvents: 'none' }} />
      <IconButton onClick={onDelete}>
        <CloseIcon />
      </IconButton>
    </Box>
    <Separator />
  </>
);
