import { useQueryClient } from 'react-query';

import { getCertificatesQueryKey, useCertificatePost } from 'api/actions/certificates/certificatesActions';
import { useSnackbar } from 'hooks';

import { UseAddCertificateProps } from './useAddCertificate.types';

export const useAddCertificate = ({ onClose }: UseAddCertificateProps) => {
  const queryClient = useQueryClient();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const {
    mutate: addCertificate,
    isError,
    isLoading,
    error,
  } = useCertificatePost({
    onSuccess: () => {
      showSnackbarSuccess('success.itemAdded');
      queryClient.invalidateQueries(getCertificatesQueryKey);
      onClose();
    },
    onError: (error) => showSnackbarError(error),
  });

  return { addCertificate, isError, isLoading, error };
};
