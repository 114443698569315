import { FormProvider } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';

import { usePrompt, useSetFormError } from 'hooks';
import {
  Button,
  FormCheckbox,
  FormDatePicker,
  FormInputText,
  Modal,
  Separator,
  Typography,
  ModalButtonsWrapper,
} from 'ui';
import { DateFormats } from '../../../../../../ui/formDatePicker/FormDatePicker.types';

import { EducationModalFormValues, EducationModalProps, SchoolModalFormFields } from './EducationModal.types';
import { DATE_LIMITS } from './EducationModal.utils';

export const EducationModal = <TResponse extends Record<string, unknown>>({
  onModalClose,
  onSubmit,
  isOpen,
  formMethods,
  submitLabel,
  isLoading,
  error,
  isOngoing,
  setIsOngoing,
}: EducationModalProps<TResponse>) => {
  const { setFormError } = useSetFormError();

  const { setError } = formMethods;

  useEffect(() => {
    setFormError<EducationModalFormValues>(setError, error, SchoolModalFormFields, 'school');
  }, [setFormError, setError, error]);

  usePrompt({ when: formMethods.formState.isDirty });

  return (
    <Modal dataTestId="school" isOpen={isOpen} onModalClose={onModalClose}>
      <Typography variant="h3">{'form.school'}</Typography>
      <Separator />

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <FormInputText name={SchoolModalFormFields.School} label="form.name" required />
          <FormInputText name={SchoolModalFormFields.MajorOfStudies} label="form.subject" required />
          <FormInputText
            placeholder="form.degreePlaceholder"
            name={SchoolModalFormFields.Degree}
            label="form.degree"
            required
          />
          <FormDatePicker
            dateFormat={DateFormats.yyyy}
            placeholder="form.graduationPlaceholder"
            name={SchoolModalFormFields.Year}
            label="form.graduation"
            maxDate={DATE_LIMITS.max}
            minDate={DATE_LIMITS.min}
            views={['year']}
            disabled={isOngoing}
            required
          />
          <FormCheckbox
            checked={isOngoing}
            value={isOngoing}
            onChange={() => setIsOngoing((prevState) => !prevState)}
            label="form.ongoingCheckbox"
          />
          <Separator />

          <ModalButtonsWrapper>
            <Button label="form.cancel" onClick={onModalClose} dataTestId="cancel" variant="outlined" />
            <LoadingButton data-testid="submit" loading={isLoading} variant="contained" type="submit">
              {submitLabel}
            </LoadingButton>
          </ModalButtonsWrapper>
        </form>
      </FormProvider>
    </Modal>
  );
};
