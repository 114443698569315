import { createTheme, SxProps, Theme } from '@mui/material/styles';

export type Styles = SxProps<Theme> | undefined;

export const generatedProfileTheme = createTheme({
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  palette: {
    primary: {
      main: '#25384d',
    },
    secondary: {
      light: '#b7dbfa',
      main: '#f8f8f8',
      dark: '#eee',
    },
    common: {
      white: '#fff',
      black: '#000',
      placeholder: '#636363',
      border: '#cdcdcd',
      error: '#df2c2c',
    },
    grey: {
      '300': 'rgba(255, 255, 255, 0.7)',
      '500': 'rgba(0, 0, 0, 0.1)',
      '600': '#9a9e9e',
      A100: '#f3fbfe',
    },
    background: {
      default: '#eee',
      paper: '#fff',
      messageInfo: '#1062a3',
      messageWarning: '#ff7a6a',
      messageSuccess: '#B7D7A5',
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 32,
      letterSpacing: 0.4,
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
      letterSpacing: 0.4,
    },
    h3: {
      fontSize: 22,
      letterSpacing: 0.4,
    },
    h4: {
      fontSize: 20,
      letterSpacing: 0.4,
    },
    h5: {
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: 0.4,
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.4,
    },
    body1: {
      lineHeight: 1.65,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0.4,
      lineHeight: 1.65,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});
