import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
// eslint-disable-next-line import/order
import Hotjar from '@hotjar/browser';

import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import 'assets/styles/main.css';

import { AppRoutes } from './routing/AppRoutes';

if (import.meta.env.DEV) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React);
}
if (import.meta.env.VITE_HOTJAR_ID) {
  Hotjar.init(Number(import.meta.env.VITE_HOTJAR_ID), 6);
}

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0,
    enabled: import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development',
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<RouterProvider router={AppRoutes} />);
