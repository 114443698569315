import { useQueryClient } from 'react-query';

import {
  skillsOrderEditorQueryKey,
  useSkillsOrderEditorPatch,
} from 'api/actions/skillsOrderEditor/skillsOrderEditorActions';
import { useSnackbar } from 'hooks';

export const usePatchSkillsOrderEditorData = () => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate, error } = useSkillsOrderEditorPatch({
    onSuccess: () => {
      queryClient.invalidateQueries(skillsOrderEditorQueryKey);
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => {
      showSnackbarError(error);
    },
  });

  return { editSkillsOrderEditorData: mutate, error };
};
