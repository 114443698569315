import { Styles, componentsLibTheme as theme } from 'lux/theme';
import { SystemStyleObject, Theme } from '@mui/system';

export const row: SystemStyleObject<Theme> = {
  '& .actionsCell': {
    opacity: 0,
  },
  '&:hover': {
    bgcolor: 'primary.light',
    '& .actionsCell': {
      opacity: 1,
    },
  },

  [theme.breakpoints.down('md')]: {
    '& td': {
      wordBreak: 'normal',
    },
  },
};

export const cursorPointer: SystemStyleObject<Theme> = {
  cursor: 'pointer',
};

export const icon: Styles = {
  fontSize: 'medium',
};

export const sort: Styles = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

export const handle: Styles = {
  display: 'inline-block',
  lineHeight: 0,
  verticalAlign: 'middle',
  marginRight: 2,
};

export const draggedItemStyles: Styles = {
  display: 'table',
  '& td:last-of-type': {
    width: '110px',
  },
};

export const tableHead: Styles = {
  '& tr th': {
    minWidth: 'max-content',
    whiteSpace: 'nowrap',
  },
};
