import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@mui/material';

import { Profile } from 'api/actions/profiles/profilesActions.types';
import { useGetPositions } from 'api/actions/positions/positionsActions';
import { useContentAccess, useLocale } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { Actions } from '../../components/actions/Actions';

const columnHelper = createColumnHelper<Profile>();

export const useProfilesListColumns = () => {
  const { formatMessage } = useLocale();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Profiles });

  const { data: positionsData } = useGetPositions();

  return useMemo(
    () =>
      [
        columnHelper.display({
          id: 'selectRow',
          header: ({ table }) => (
            <Checkbox
              indeterminate={table.getIsSomePageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
              checked={table.getIsAllPageRowsSelected()}
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              checked={row.getIsSelected()}
              onClick={(e) => {
                e.stopPropagation();
                row.toggleSelected();
              }}
            />
          ),
          enableColumnFilter: false,
        }),
        columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
          id: 'employee',
          header: () => <FormattedMessage id="profiles.table.employee" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('email', {
          id: 'email',
          header: () => <FormattedMessage id="profiles.table.email" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor((row) => row.position?.visibleName, {
          id: 'position',
          header: () => <FormattedMessage id="profiles.table.position" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('updateConfirmedAt', {
          id: 'updateConfirmedAt',
          header: () => <FormattedMessage id="profiles.table.recentUpdate" />,
          cell: ({ getValue }) => getValue()?.slice(0, 10) ?? '',
          enableColumnFilter: false,
        }),
        columnHelper.accessor('surveyUpdatedAt', {
          id: 'surveyUpdatedAt',
          header: () => <FormattedMessage id="profiles.table.surveyUpdatedAt" />,
          cell: ({ getValue }) => getValue()?.slice(0, 10) ?? '',
          enableColumnFilter: false,
        }),
        columnHelper.accessor('id', {
          id: 'actions',
          header: () => <FormattedMessage id="profiles.table.actions" />,
          cell: ({ getValue }) => <Actions isReadOnly={isReadOnly} profileId={getValue()} />,
          enableSorting: false,
          enableColumnFilter: false,
        }),
      ] as ColumnDef<Profile>[],
    [formatMessage, positionsData],
  );
};
