import { Styles } from 'lux/theme';

export const gridWrapper: Styles = {
  textAlign: 'center',
  mt: 5,
};

export const logoStyles: Styles = {
  width: '100%',
  maxWidth: 400,
  marginBottom: 6,
};

export const subtitle: Styles = {
  color: 'text.secondary',
  mb: 4,
};
export const subtitleUser: Styles = {
  color: 'text.secondary',
  mb: 3,
};

export const userButton: Styles = {
  ml: 3,
  mr: 3,
};
