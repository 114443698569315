import { useMemo } from 'react';

import { AppSettingsContext } from '../appSettingsContext/AppSettingsContext';
import { useLocale } from 'hooks';
import { useGetSettings } from 'api/actions/appSettings/appSettingsActions';

import { AppSettingsContextControllerProps } from './AppSettingsContextController.types';

export const AppSettingsContextController = ({ children }: AppSettingsContextControllerProps) => {
  const { data } = useGetSettings();
  const { formatMessage } = useLocale();

  const languageOptionsTranslated = useMemo(
    () =>
      data?.languageProficiency.map((language) => ({
        id: language,
        name: formatMessage({ id: `languages.${language}` }),
      })),
    [data?.languageProficiency, formatMessage],
  );

  const value = {
    languageOptionsTranslated,
  };

  return <AppSettingsContext.Provider value={value}>{children}</AppSettingsContext.Provider>;
};
