import { UserContextController } from 'context/user/userContextController/UserContextController';
import { AccessControl } from 'ui';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { Home } from './Home';

//@TODO no-access page

export const HomeContainer = () => (
  <AccessControl allowedContent={ContentType.Home} renderNoAccess={<h1>No access</h1>}>
    <UserContextController>
      <Home />
    </UserContextController>
  </AccessControl>
);
