import { Helmet } from 'react-helmet';

import { useContentAccess, useLocale, useModal } from 'hooks';
import { AdminSectionWrapper, OpenModalButton } from 'ui';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { Languages } from './Languages';
import { AddLanguageModalContainer } from './modals/addLanguageModal/AddLanguageModalContainer';

export const LanguagesContainer = () => {
  const { formatMessage } = useLocale();
  const { onClose, onOpen, isOpen } = useModal();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Languages });

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.languages' })}</title>
      </Helmet>
      <AdminSectionWrapper
        title="navigator.languages"
        tabs="profileTabs"
        action={isReadOnly ? null : <OpenModalButton label="languages.form.add" onClick={onOpen} />}
      >
        <Languages />
        {isOpen && <AddLanguageModalContainer isOpen={isOpen} onClose={onClose} />}
      </AdminSectionWrapper>
    </>
  );
};
