import { LanguageModalContainerProps } from './LanguageModalContainer.types';

export enum LanguageModalFormFields {
  Name = 'name',
}

export type LanguageModalFormValues = {
  [LanguageModalFormFields.Name]: string;
};

export type LanguageModalProps = Omit<LanguageModalContainerProps, 'error'>;
