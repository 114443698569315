import { SnackbarProvider } from 'notistack';
import { Fade } from '@mui/material';

import { SnackbarContextControllerProps } from './SnackbarContextController.types';

export const SnackbarContextController = ({ children }: SnackbarContextControllerProps) => {
  return (
    <SnackbarProvider
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      hideIconVariant
      preventDuplicate
      maxSnack={5}
      TransitionComponent={Fade}
    >
      {children}
    </SnackbarProvider>
  );
};
