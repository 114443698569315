import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';
import { CurrentlyEditedUser } from 'views/profileEditor/components/currentlyEditedUser/CurrentlyEditedUser';

import { EducationContainer } from './education/EducationContainer';
import { AchievementsContainer } from './achievements/AchievementsContainer';
import { ProjectsContainer } from './projects/ProjectsContainer';

export const Experience = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.experienceEditor' })}</title>
      </Helmet>
      <CurrentlyEditedUser />
      <EducationContainer />
      <AchievementsContainer />
      <ProjectsContainer />
    </>
  );
};
