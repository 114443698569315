import { useEffect } from 'react';

import { useSetFormError } from 'hooks';

import { PositionModalContainerProps } from './PositionModalContainer.types';
import { PositionModalFormFields, PositionModalFormValues } from './PositionModal.types';
import { PositionModal } from './PositionModal';

export const PositionModalContainer = ({
  onClose,
  isLoading,
  formMode,
  formMethods,
  isOpen,
  onSubmit,
  header,
  error,
}: PositionModalContainerProps) => {
  const { setFormError } = useSetFormError();
  const { reset, setError } = formMethods;

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  useEffect(() => {
    setFormError<PositionModalFormValues>(setError, error, PositionModalFormFields, 'visibleName');
  }, [setFormError, setError, error]);

  return (
    <PositionModal
      isOpen={isOpen}
      isLoading={isLoading}
      formMode={formMode}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={onSubmit}
      header={header}
    />
  );
};
