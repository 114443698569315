import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { useMutation, useQuery } from 'hooks';
import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

import {
  TagDeleteArgs,
  TagDeleteResponse,
  TagPatchBody,
  TagPatchResponse,
  TagPostBody,
  TagPostResponse,
  TagsGetResponse,
} from './tagsActions.types';

export const getTagsQueryKey = 'tags';

export const useTagsGet = (config?: AxiosRequestConfig) =>
  useQuery<TagsGetResponse>(
    '/tags',
    [getTagsQueryKey, config],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );

export const useTagDelete = (
  options?: UseMutationOptions<ClientResponse<TagDeleteResponse>, ClientErrorResponse, TagDeleteArgs>,
) =>
  useMutation(
    'deleteCertificate',
    ({ id, forceDelete = false }) => ({
      endpoint: `/tags/tag/${id}`,
      method: 'DELETE',
      params: { forceDelete },
    }),
    options,
  );

export const useTagPost = (
  options?: UseMutationOptions<ClientResponse<TagPostResponse>, ClientErrorResponse, TagPostBody>,
) =>
  useMutation(
    'deleteCertificate',
    (body) => ({
      endpoint: '/tags/tag/',
      method: 'POST',
      params: body,
    }),
    options,
  );

export const useTagPatch = (
  options?: UseMutationOptions<ClientResponse<TagPatchResponse>, ClientErrorResponse, TagPatchBody>,
) =>
  useMutation(
    'deleteCertificate',
    ({ id, name }) => ({
      endpoint: `/tags/tag/${id}`,
      method: 'PATCH',
      params: { name },
    }),
    options,
  );
