import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ListItemNode, ListNode } from '@lexical/list';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LexicalEditor } from 'lexical';

import { Typography } from '../typography/Typography';
import { Separator } from '../separator/Separator';
import { useLocale } from 'hooks';

import { RichTextPlugin } from './plugins/RichText/RichTextPlugin';
import { RichTextEditorProps } from './RichTextEditor.types';
import { ToolbarPlugin } from './plugins/Toolbar/ToolbarPlugin';
import { richTextTheme } from './theme/theme';
import { getInitialValues, setEditorAsActive, handleChange } from './RichTextEditor.utils';
import * as S from './RichTextEditor.styles';
import { RichTextEditorSkeleton } from './skeleton/RichTextEditorSkeleton';

export const RichTextEditor = ({
  initialValue,
  placeholder,
  name,
  disabled = false,
  required,
  minHeight,
  isLoading = false,
  helperText,
}: RichTextEditorProps) => {
  const { formatMessage } = useLocale();

  const getInitialConfig = (value: string) => {
    return {
      namespace: 'RichTextEditor',
      theme: richTextTheme,
      nodes: [ListNode, ListItemNode],
      onError: (error: Error) => {
        throw new Error(error.message);
      },
      editorState: (editor: LexicalEditor) => getInitialValues(editor, initialValue || value),
      editable: !disabled,
    };
  };

  const placeholderMessage = formatMessage({ id: placeholder });

  const placeholderWithAsterisk = required ? `${placeholderMessage} *` : placeholderMessage;

  if (isLoading) {
    return <RichTextEditorSkeleton />;
  }

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <LexicalComposer initialConfig={getInitialConfig(value)}>
            <Box data-testid={name} className="editor-container" sx={error && S.errorContainer}>
              {!disabled && <ToolbarPlugin />}
              <Box onBlur={setEditorAsActive} sx={S.richTextInner(disabled)} className="editor-inner">
                <RichTextPlugin minHeight={minHeight} placeholder={placeholderWithAsterisk} disabled={disabled} />
                <HistoryPlugin />
                <ListPlugin />
                <OnChangePlugin onChange={(editorState, editor) => handleChange(editor, onChange)} />
              </Box>
            </Box>
          </LexicalComposer>
          {helperText && (
            <Typography variant="helperText" sx={S.helperText}>
              {helperText}
            </Typography>
          )}
          <Separator height={10} />
          {error && (
            <Typography variant="helperText" sx={S.errorMessage}>
              {error.message}
            </Typography>
          )}
        </>
      )}
    />
  );
};
