import { Styles, componentsLibTheme as theme } from 'lux/theme';
const { spacing, palette } = theme;

export const toolbar: Styles = {
  display: 'flex',
  backgroundColor: 'common.white',
  width: '100%',
  border: `1px solid ${palette.border.default}`,
  borderRadius: spacing(1, 1, 0, 0),
  borderBottom: 0,
  overflow: 'hidden',
};

export const toolbarItem: Styles = {
  '&.MuiButtonBase-root': {
    padding: 0.5,
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'common.white',
    cursor: 'pointer',
    transition: '0.2s background-color',
    '&.active': {
      backgroundColor: 'primary.main',
      '& .icon': {
        color: 'common.white',
      },
    },
    '&:not(.active):hover': {
      backgroundColor: `${palette.primary.main}cc`,
      '& .icon': {
        color: 'common.white',
      },
    },
  },
};

export const toolbarIcon = {
  color: 'common.black',
};
