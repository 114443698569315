import { Helmet } from 'react-helmet';

import { AdminSectionWrapper, Loader } from 'ui';
import { useLocale } from 'hooks';

import { SkillsOrderEditor } from './SkillsOrderEditor';
import { useGetSkillsOrderEditorData } from './hooks/useGetSkillsOrderEditorData';
import { usePatchSkillsOrderEditorData } from './hooks/usePatchSkillsOrderEditorData';

export const SkillsOrderEditorContainer = () => {
  const { formatMessage } = useLocale();
  const { data, isLoading } = useGetSkillsOrderEditorData();
  const { editSkillsOrderEditorData, error } = usePatchSkillsOrderEditorData();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'navigator.skillsOrderEditor' })}</title>
      </Helmet>
      <AdminSectionWrapper title="navigator.skillsOrderEditor" tabs="skillsTabs">
        {isLoading || !data ? (
          <Loader />
        ) : (
          <SkillsOrderEditor error={error} onSubmit={editSkillsOrderEditorData} skillsOrderData={data} />
        )}
      </AdminSectionWrapper>
    </>
  );
};
