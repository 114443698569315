import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';
import { useDeleteProjectRole } from 'views/adminSection/projectRoles/hooks/useDeleteProjectRole';
import { usePatchProjectRole } from 'views/adminSection/projectRoles/hooks/usePatchProjectRole';

import { ProjectRoles } from './ProjectRoles';
import { usePostProjectRole } from './hooks/usePostProjectRole';

export const ProjectRolesContainer = () => {
  const { formatMessage } = useLocale();
  const { addProjectRole, isPostLoading, postError, isPostSuccess, postReset } = usePostProjectRole();
  const { deleteProjectRole, deleteError, isDeleteSuccess, deleteReset } = useDeleteProjectRole();
  const { patchProjectRole, isPatchLoading, patchError, isPatchSuccess, patchReset } = usePatchProjectRole();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'navigator.projectRoles' })}</title>
      </Helmet>
      <ProjectRoles
        mutationErrorReset={{ post: postReset, patch: patchReset, delete: deleteReset }}
        errors={{ postError, patchError, deleteError }}
        isMutationLoading={isPostLoading || isPatchLoading}
        isMutationSuccess={{ post: isPostSuccess, patch: isPatchSuccess, delete: isDeleteSuccess }}
        onAddProjectRole={addProjectRole}
        onDeleteProjectRole={deleteProjectRole}
        onPatchProjectRole={patchProjectRole}
      />
    </>
  );
};
