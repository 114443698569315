import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';

import {
  ProfileProjectsGetResponse,
  DeleteProfileProjectMutationArgs,
  PatchProfileProjectMutationArgs,
  PostProfileProjectMutationArgs,
  ProfileProjectGetResponse,
} from './profileProjectsActions.types';

export const profileProjectGetKey = 'profile-project';

export const useProfileProjectGet = (profileId: string, profileProjectId: string, config?: AxiosRequestConfig) =>
  useQuery<ProfileProjectGetResponse>(
    `/profiles/${profileId}/project/${profileProjectId}`,
    [profileProjectGetKey, `/profiles/${profileId}/project/${profileProjectId}`],
    undefined,
    config,
  );

export const useProfileProjectPatch = (
  options: UseMutationOptions<
    ClientResponse<ProfileProjectGetResponse>,
    ClientErrorResponse,
    PatchProfileProjectMutationArgs
  >,
) =>
  useMutation(
    'patchProfileProject',
    ({ profileId, profileProjectId, body }) => ({
      endpoint: `/profiles/${profileId}/project/${profileProjectId}`,
      method: 'PATCH',
      params: body,
    }),
    options,
  );

export const useProfileProjectDelete = (
  options: UseMutationOptions<ClientResponse, ClientErrorResponse, DeleteProfileProjectMutationArgs>,
) =>
  useMutation(
    'deleteProfileProject',
    ({ profileId, profileProjectId }) => ({
      endpoint: `/profiles/${profileId}/project/${profileProjectId}`,
      method: 'DELETE',
    }),
    options,
  );

export const profileProjectsGetKey = 'profile-projects';

export const useProfileProjectsGet = (profileId: string, config?: AxiosRequestConfig) =>
  useQuery<ProfileProjectsGetResponse>(
    `/profiles/${profileId}/projects`,
    [profileProjectsGetKey, `/profiles/${profileId}/projects`],
    { cacheTime: 0 },
    config,
  );

export const useProfileProjectPost = (
  options: UseMutationOptions<
    ClientResponse<ProfileProjectGetResponse>,
    ClientErrorResponse,
    PostProfileProjectMutationArgs
  >,
) =>
  useMutation(
    'postProfileProject',
    ({ profileId, body }) => ({
      endpoint: `/profiles/${profileId}/project`,
      method: 'POST',
      params: body,
    }),
    options,
  );
