import { ErrorOption, FieldPath } from 'react-hook-form';
import { useCallback } from 'react';

import { useLocale } from 'hooks';
import { ClientErrorResponse } from 'api/types/types';
import { isTranslationString } from 'utils/isTranslationString/isTranslationString';
import { isClientError } from '../useQuery/useQuery.utils';

import { setSingleError } from './useSetFormErrors.utils';

export const useSetFormError = () => {
  const { formatMessage } = useLocale();

  const setFormError = useCallback(
    <TFieldValues extends Record<string, unknown>>(
      onSetError: (name: FieldPath<TFieldValues>, error: ErrorOption) => void,
      error: ClientErrorResponse | null,
      formFields: Record<string, string>,
      fieldName?: FieldPath<TFieldValues>,
      customErrMsg?: string,
      errorType?: string,
    ) => {
      if (!error?.response) {
        return;
      }

      const {
        response: {
          data: { errors, status },
        },
      } = error;

      if (!isClientError(status)) {
        return;
      }

      const translatedCustomMessage = isTranslationString(customErrMsg)
        ? formatMessage({ id: customErrMsg })
        : customErrMsg;

      errors.map((err) => {
        setSingleError(err, onSetError, formFields, formatMessage, fieldName, errorType, translatedCustomMessage);
      });
    },
    [formatMessage],
  );

  return { setFormError };
};
