import { useSnackbar as useNotistackSnackbar, VariantType } from 'notistack';
import VisibilityIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton } from '@mui/material';

import { Tooltip } from 'ui';
import { useLocale } from '../useLocale/useLocale';
import { isTranslationString } from 'utils/isTranslationString/isTranslationString';
import { ClientErrorResponse } from 'api/types/types';
import { AppMessages } from 'i18n/messages';

import { SuccessMessage } from './useSnackbar.types';

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();
  const { formatMessage } = useLocale();

  const renderCloseButton = (snackbarId: string | number) => (
    <Tooltip title={'tooltips.close'}>
      <IconButton data-testid="close" aria-label="close" onClick={() => closeSnackbar(snackbarId)}>
        <VisibilityIcon sx={{ color: '#fff' }} />
      </IconButton>
    </Tooltip>
  );

  const showSnackbar = (message: string, variant: VariantType) => {
    const translatedMessage = isTranslationString(message) ? formatMessage({ id: message }) : message;

    return enqueueSnackbar(translatedMessage, {
      variant,
      action: renderCloseButton,
    });
  };

  const showSnackbarError = (error?: Partial<ClientErrorResponse> | null, hideOnStatus?: number[]) => {
    if (!error || (error.response && hideOnStatus?.includes(error.response.status))) {
      return;
    }

    if (!error.response?.data && error.message) {
      showSnackbar(error.message, 'error');

      return;
    }

    const errorArr = error.response?.data;

    errorArr?.errors.forEach((err) => {
      const translationKey = Object.hasOwn(AppMessages, err.code) ? err.code : 'common.somethingWentWrong';

      showSnackbar(translationKey, 'error');
    });
  };

  const showSnackbarSuccess = (message: string | SuccessMessage) => showSnackbar(message, 'success');

  return { showSnackbar, showSnackbarError, showSnackbarSuccess };
};
