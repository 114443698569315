import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';
import { Profile } from 'api/actions/profiles/profilesActions.types';

import { ProfileModalFormFields } from './ProfileModal.types';

export const getDefaultValues = (user?: Profile) => {
  return {
    [ProfileModalFormFields.FirstName]: user?.firstName || '',
    [ProfileModalFormFields.LastName]: user?.lastName || '',
    [ProfileModalFormFields.Email]: user?.email || '',
    [ProfileModalFormFields.Position]: user?.positionId || '',
    [ProfileModalFormFields.Seniority]: user?.seniorityId || '',
  };
};

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) =>
  Joi.object({
    [ProfileModalFormFields.FirstName]: Joi.string().min(2).max(30).required(),
    [ProfileModalFormFields.LastName]: Joi.string().min(2).max(50).required(),
    [ProfileModalFormFields.Email]: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages(getErrorMessages({ formatMessage })),
    [ProfileModalFormFields.Position]: Joi.string().uuid().required(),
    [ProfileModalFormFields.Seniority]: Joi.string().uuid().required(),
  });
