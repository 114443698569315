import Joi from 'joi';
import { IntlShape } from 'react-intl';

import { AchievementsFormFields } from './Achievements.types';

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) =>
  Joi.object({
    [AchievementsFormFields.Achievement]: Joi.string()
      .required()
      .trim()
      .label(formatMessage({ id: 'form.achievement' })),
  });

export const defaultValues = {
  [AchievementsFormFields.Achievement]: '',
};

export const getEditFormSchema = () =>
  Joi.object({
    [AchievementsFormFields.Achievement]: Joi.string().required().trim(),
  });
