import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID as string,
    authority: import.meta.env.VITE_AZURE_AUTHORITY as string,
    redirectUri: import.meta.env.VITE_AZURE_REDIRECT_URI as string,
    postLogoutRedirectUri: import.meta.env.VITE_AZURE_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);

            return;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message);

            return;
          default:
            return;
        }
      },
    },
  },
};
