import { Styles } from './theme/theme';

export const wrapper: Styles = {
  display: 'flex',
  gap: 4,
  backgroundColor: '#eee',
};

export const resumeWrapper: Styles = ({ spacing }) => ({
  position: 'relative',
  minHeight: '100vh',
  width: spacing(98.75),
  minWidth: spacing(98.75),
  overflowX: 'auto',
  m: 'auto',
  p: 6,
  WebkitPrintColorAdjust: 'exact',
  colorAdjust: 'exact',
  bgcolor: 'common.white',
  '@page': {
    mt: 3,
  },
});

export const mainPart: Styles = {
  display: 'flex',
  gap: 5,
};

export const leftPart: Styles = {
  flex: 8,
};

export const rightPart: Styles = {
  flex: 4,
};
