import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect } from 'react';

import { useLocale, usePrompt, useSetFormError } from 'hooks';
import { CertificateForm } from '../CertificateForm';
import { CertificateFormFields, CertificateFormValues } from '../CertificateForm.types';
import { getDefaultValues, getFormSchema, getTagsToSubmit } from '../CertificateForm.utils';
import { useCertificateGet } from 'api/actions/certificates/certificatesActions';
import { useEditCertificate } from '../hooks/useEditCertificate/useEditCertificate';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { useTagsGet } from 'api/actions/tags/tagsActions';
import { CertificateFormBody } from 'api/actions/certificates/certificatesActions.types';

import { AddCertificateFormContainerProps } from './EditCertificateForm.types';

export const EditCertificateFormContainer = ({ certificateId, isOpen, onClose }: AddCertificateFormContainerProps) => {
  const { formatMessage } = useLocale();
  const { setFormError } = useSetFormError();

  const { data: certificates } = useCertificateGet(certificateId);
  const { data: tags } = useTagsGet();

  const formMethods = useForm<CertificateFormValues>({
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { editCertificate, isLoading: editCertificateIsLoading, error } = useEditCertificate({ onClose });

  useEffect(() => {
    formMethods.reset(getDefaultValues(certificates));
  }, [certificates, formMethods]);

  useEffect(() => {
    setFormError<CertificateFormValues>(formMethods.setError, error, CertificateFormFields, 'name');
  }, [setFormError, formMethods.setError, error]);

  const onSubmit = (formData: CertificateFormValues) => {
    const body: Partial<CertificateFormBody> = {
      ...formData,
      tags: getTagsToSubmit(tags?.result, formData?.tags),
      periodOfValidity: formData.periodOfValidity === '' ? null : Number(formData.periodOfValidity),
      url: formData.url || null,
    };
    editCertificate({ certificateId, body });
  };

  usePrompt({ when: formMethods.formState.isDirty });

  if (!certificates || !tags) {
    return null;
  }

  return (
    <CertificateForm
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={formMethods.handleSubmit(onSubmit)}
      formMethods={formMethods}
      isLoading={editCertificateIsLoading}
      formMode={FormMode.edit}
      tags={tags?.result.map((el) => el.name)}
    />
  );
};
