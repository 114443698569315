import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { deletePositionMutation } from 'api/actions/positions/positionsActions';

import { adminPositionsQueryKey } from './usePositionsQuery';
import { UseDeletePositionArgs } from './UseDeletePosition.types';

export const useDeletePosition = ({ onSuccess, onError }: UseDeletePositionArgs) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutateAsync, isError, error, reset } = useMutation('deletePosition', deletePositionMutation, {
    onSuccess: () => {
      showSnackbarSuccess('success.itemDeleted');
      onSuccess && onSuccess();
      queryClient.invalidateQueries(adminPositionsQueryKey);
    },
    onError: (error) => {
      showSnackbarError(error);
      onError && onError();
    },
  });

  const removePosition = useCallback(async (positionId: string) => await mutateAsync({ positionId }), [mutateAsync]);

  return { removePosition, isError, error, reset };
};
