import { Box } from '@mui/material';
import { TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Badge, Typography } from 'lux/components';
import { HideIcon, UserIcon } from 'lux/icons';

import { ReactComponent as TshIcon } from 'assets/icons/tsh-icon.svg';
import { useLocale } from 'hooks';
import { ProjectDeletionModalContainer } from '../modals/projectDeletionModal/ProjectDeletionModalContainer';
import { DeleteButton, EditButton, Separator } from 'ui';

import * as styles from './Project.styles';
import { ProjectProps } from './Project.types';
import { formatDuration } from './Project.utils';
import { actions, iconStyles, projectWrapper, tshIconStyles } from './Project.styles';

export const Project = ({
  profileProject: {
    id,
    project: { name, isPrivate },
    role,
    joinedAt,
    leftAt,
    isInvisible,
  },
  technologies,
  onCompleteDataClick,
  isModalOpen,
  onModalOpen,
  onModalClose,
  isFirst,
  isLast,
}: ProjectProps) => {
  const { formatMessage } = useLocale();

  const icon = {
    private: <UserIcon sx={iconStyles} />,
    notVisible: <HideIcon sx={iconStyles} />,
    public: (
      <Box sx={tshIconStyles}>
        <TshIcon />
      </Box>
    ),
  };
  const projectType = isInvisible ? 'notVisible' : isPrivate ? 'private' : 'public';

  return (
    <>
      <TimelineItem key={id}>
        <TimelineSeparator>
          <TimelineConnector sx={{ opacity: isFirst ? 0 : 1 }} />
          {icon[projectType]}
          <TimelineConnector sx={{ opacity: isLast ? 0 : 1 }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: 0, px: 2 }}>
          <Box sx={projectWrapper}>
            <Box>
              <Badge
                color={isPrivate ? 'red' : 'green'}
                badgeContent={joinedAt && formatDuration(joinedAt, leftAt, formatMessage)}
                isStatic
              />
              <Separator height={8} />
              <Typography variant="body2" sx={styles.name}>
                {name}
              </Typography>
              <Separator height={4} />
              <Typography variant="body2">{role?.name}</Typography>
              <Separator height={8} />
              {technologies.map((el, index) => (
                <Badge color="gray" badgeContent={el.skill.name} isStatic sx={{ mr: 1 }} key={index} max={9999} />
              ))}
            </Box>
            <Box sx={actions}>
              <EditButton sx={{ mr: 1 }} onClick={onCompleteDataClick} />
              <DeleteButton onClick={onModalOpen} />
            </Box>
          </Box>
        </TimelineContent>
      </TimelineItem>

      {isModalOpen && (
        <ProjectDeletionModalContainer
          profileProjectId={id}
          projectName={name}
          isOpen={isModalOpen}
          onClose={onModalClose}
        />
      )}
    </>
  );
};
