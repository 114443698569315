import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

import {
  PatchSkillMutationArgs,
  DeleteSkillMutationArgs,
  PostSkillMutationArgs,
  Skill,
  SkillsQueryKeys,
  GetSkillsResponse,
} from './skillsActions.types';

export const useSkillPatch = (
  options: UseMutationOptions<ClientResponse<Skill>, ClientErrorResponse, PatchSkillMutationArgs>,
) =>
  useMutation(
    SkillsQueryKeys.Patch,
    ({ skillId, body }) => ({
      endpoint: `/skills/skill/${skillId}`,
      method: 'PATCH',
      params: body,
    }),
    options,
  );

export const useSkillDelete = (
  options: UseMutationOptions<ClientResponse, ClientErrorResponse, DeleteSkillMutationArgs>,
) =>
  useMutation(
    SkillsQueryKeys.Delete,
    ({ skillId, isForceDelete }) => ({
      endpoint: `/skills/skill/${skillId}`,
      method: 'DELETE',
      params: { forceDelete: isForceDelete },
    }),
    options,
  );

export const skillQueryKey = 'skills';

export const useGetSkills = (config?: AxiosRequestConfig) => {
  return useQuery<GetSkillsResponse>(
    '/skills',
    [skillQueryKey, config],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );
};

export const useSkillPost = (
  options: UseMutationOptions<ClientResponse<Skill>, ClientErrorResponse, PostSkillMutationArgs>,
) =>
  useMutation(
    SkillsQueryKeys.Post,
    ({ body }) => ({
      endpoint: '/skills/skill',
      method: 'POST',
      params: body,
    }),
    options,
  );
