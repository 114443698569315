import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';

import {
  ProfileLanguagesGetResponse,
  DeleteProfileLanguageMutationArgs,
  ProfileLanguage,
  PatchProfileLanguageMutationArgs,
  PostProfileLanguageMutationArgs,
} from './profileLanguagesActions.types';

export const useProfileLanguageGet = (
  profileId: string | undefined,
  profileLanguageId: string,
  config?: AxiosRequestConfig,
) =>
  useQuery<ProfileLanguagesGetResponse>(
    `profiles/${profileId}/languages/${profileLanguageId}`,
    undefined,
    undefined,
    config,
  );

export const useProfileLanguagePatch = (
  options: UseMutationOptions<ClientResponse<ProfileLanguage>, ClientErrorResponse, PatchProfileLanguageMutationArgs>,
) =>
  useMutation(
    'patchProfileLanguage',
    ({ profileId, profileLanguageId, body }) => ({
      endpoint: `/profiles/${profileId}/language/${profileLanguageId}`,
      method: 'PATCH',
      params: body,
    }),
    options,
  );

export const useProfileLanguageDelete = (
  options: UseMutationOptions<ClientResponse, ClientErrorResponse, DeleteProfileLanguageMutationArgs>,
) =>
  useMutation(
    'deleteProfileLanguage',
    ({ profileId, profileLanguageId }) => ({
      endpoint: `/profiles/${profileId}/language/${profileLanguageId}`,
      method: 'DELETE',
    }),
    options,
  );

export const useProfileLanguagesGet = (profileId: string | undefined, config?: AxiosRequestConfig) =>
  useQuery<ProfileLanguagesGetResponse>(`profiles/${profileId}/languages`, undefined, undefined, config);

export const useProfileLanguagePost = (
  options: UseMutationOptions<ClientResponse<ProfileLanguage>, ClientErrorResponse, PostProfileLanguageMutationArgs>,
) =>
  useMutation(
    'postProfileLanguage',
    ({ profileId, body }) => ({
      endpoint: `/profiles/${profileId}/language`,
      method: 'POST',
      params: body,
    }),
    options,
  );
