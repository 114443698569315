import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFilter, useGlobalFilter, usePage, usePageSize, useRowSelection, useSort } from '../../../common';
import { isEmpty } from 'utils';
import { useGetQueryParams } from 'hooks';

import { getInitialFilterValues, getInitialSortValues } from './useTableLogic.utils';
import { UseTableLogicProps } from './useTableLogic.types';

export const useTableLogic = ({
  defaultSort,
  defaultFilters,
  keysMapper,
  filtersOperators,
  pageSizeOptions,
  total,
  isAutoDefaultSort,
  globalFilter = '',
  virtualSortingMap = {},
}: UseTableLogicProps) => {
  const [, setSearchParams] = useSearchParams();
  const { initialQueryParams } = useGetQueryParams();

  const { pageSize, setPageSize, pageSizeQueryParams } = usePageSize({ initialQueryParams, pageSizeOptions });

  const { page, setPage, pageQueryParams } = usePage({ initialQueryParams, total, pageSize, globalFilter });

  const { sort, setSort, sortQueryParams } = useSort({
    defaultSort,
    keysMapper,
    isAutoDefaultSort,
    virtualSortingMap,
  });

  const { filter, setFilter, filterQueryParams, debouncedFilters } = useFilter({
    defaultFilters,
    filtersOperators,
    keysMapper,
  });

  const { globalFilterQueryParams, debouncedGlobalFilter } = useGlobalFilter(globalFilter);

  const { selectedRows, setSelectedRows } = useRowSelection();

  useEffect(() => {
    if (!isEmpty(initialQueryParams)) {
      setSort(getInitialSortValues({ keysMapper, initialQueryParams }));
      setFilter(getInitialFilterValues({ filtersOperators, initialQueryParams, keysMapper }));
      // setGlobalFilter state is handled outside a table scope
    }
  }, []);

  const queryParams = useMemo(() => {
    return {
      ...pageQueryParams,
      ...pageSizeQueryParams,
      ...sortQueryParams,
      ...filterQueryParams,
      ...globalFilterQueryParams,
    };
  }, [page, pageSize, sort, debouncedFilters, debouncedGlobalFilter]);

  useEffect(() => {
    const timerId = setTimeout(() => setSearchParams(queryParams, { replace: true }), 200);

    return () => clearTimeout(timerId);
  }, [queryParams]);

  return {
    queryParams,
    page,
    setPage,
    pageSize,
    setPageSize,
    sort,
    setSort,
    filter,
    setFilter,
    selectedRows,
    setSelectedRows,
  };
};
