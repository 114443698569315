import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useLocale } from 'hooks';
import { usePatchSkill } from '../../hooks/usePatchSkill';
import { PatchSkillModalContainerProps } from 'views/adminSection/skills/modals/patchSkillModal/PatchSkillModalContainer.types';
import {
  SkillModalFormFields,
  SkillModalFormValues,
} from 'views/adminSection/skills/modals/skillModal/SkillModal.types';
import { getDefaultValues, getFormSchema } from 'views/adminSection/skills/modals/skillModal/SkillModal.utils';
import { SkillModalContainer } from 'views/adminSection/skills/modals/skillModal/SkillModalContainer';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';

export const PatchSkillModalContainer = ({ isOpen, onModalClose, skillData }: PatchSkillModalContainerProps) => {
  const { formatMessage } = useLocale();
  const { patchSkill, isLoading: isPatchLoading, error } = usePatchSkill(skillData.id, () => onModalClose());

  const formMethods = useForm<SkillModalFormValues>({
    defaultValues: getDefaultValues(skillData),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit, watch } = formMethods;

  const skillType = watch(SkillModalFormFields.Type);

  return (
    <SkillModalContainer
      category={skillData.category}
      isOpen={isOpen}
      isLoading={isPatchLoading}
      formMode={FormMode.edit}
      formMethods={formMethods}
      onClose={onModalClose}
      onSubmit={handleSubmit(patchSkill)}
      skillType={skillType}
      header="form.editSkill"
      error={error}
    />
  );
};
