import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { LanguageModalContainer } from '../languageModal/LanguageModalContainer';
import { useLocale } from 'hooks';
import { LanguageModalFormValues } from '../languageModal/LanguageModal.types';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { defaultValues, getFormSchema } from '../languageModal/LanguageModal.utils';
import { usePostLanguage } from '../../hooks/usePostLanguage';

import { AddLanguageModalContainerProps } from './AddLanguageModalContainer.types';

export const AddLanguageModalContainer = ({ onClose, isOpen }: AddLanguageModalContainerProps) => {
  const { formatMessage } = useLocale();

  const formMethods = useForm<LanguageModalFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });
  const { handleSubmit } = formMethods;

  const { addLanguage, isLoading, error } = usePostLanguage(onClose);

  return (
    <LanguageModalContainer
      isOpen={isOpen}
      isLoading={isLoading}
      formMode={FormMode.add}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={handleSubmit(addLanguage)}
      header="languages.form.add"
      error={error}
    />
  );
};
