import { Styles, componentsLibTheme as theme } from 'lux/theme';
export const i: Styles = {
  margin: 1,
};

export const table: Styles = {
  wordBreak: 'keep-all',
};

export const tableHead: Styles = {
  '& tr th: first-of-type': {
    padding: theme.spacing(2, 2, 0, 1),
  },
  '& tr th: last-of-type': {
    width: '110px',
    textAlign: 'center',
  },
  '& tr th: nth-last-of-type(2)': {
    width: '110px',
  },
  '& tr th: nth-of-type(3)': {
    wordBreak: 'keep-all',
  },
};
