import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useSkillPatch } from 'api/actions/skills/skillsActions';
import { ClientResponse } from 'api/types/types';
import { Skill, PatchSkillBody, SkillsQueryKeys } from 'api/actions/skills/skillsActions.types';
import { useSnackbar } from 'hooks';

export const usePatchSkill = (skillId: string, onSuccess?: (name: string) => void) => {
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const queryClient = useQueryClient();

  const handleSuccess = ({ data: { name } }: ClientResponse<Skill>) => {
    showSnackbarSuccess('success.itemEdited');
    onSuccess && onSuccess(name);
    queryClient.invalidateQueries(SkillsQueryKeys.Get);
    queryClient.invalidateQueries('get-profile-project-skills');
  };

  const { mutateAsync, isError, error, isLoading } = useSkillPatch({
    onSuccess: handleSuccess,
    onError: (error) => showSnackbarError(error),
  });

  const patchSkill = useCallback(
    async (body: PatchSkillBody) => {
      await mutateAsync({
        skillId: skillId,
        body,
      });
    },
    [mutateAsync, skillId],
  );

  return { patchSkill, isError, isLoading, error };
};
