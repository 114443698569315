import { Table } from 'ui/tables/table/Table';
import { Certificate } from 'api/actions/certificates/certificatesActions.types';
import { useContentAccess } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { CertificatesProps } from './Certificates.types';
import { useCertificatesColumns } from './hooks/useCertificatesColumns/useCertificatesColumns';
import * as S from './Certificates.styles';
import { filtersOperators, keysMapper } from './Certificates.utils';

export const Certificates = ({ data, total, isError, isLoading, defaultSort, globalFilter }: CertificatesProps) => {
  const columns = useCertificatesColumns();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Certificates });

  return (
    <Table<Certificate>
      defaultSort={defaultSort}
      keysMapper={keysMapper}
      filtersOperators={filtersOperators}
      sx={{ tableHead: S.tableHead }}
      columns={columns}
      data={data}
      total={total}
      isLoading={isLoading}
      isError={isError}
      globalFilter={globalFilter}
      columnVisibility={{
        actions: !isReadOnly,
      }}
      isRowClickable={false}
    />
  );
};
