import { useProjectsGet } from 'api/actions/projects/projectsActions';
import { useGlobalFilter } from 'hooks';
import { Divider, GlobalFilter } from 'ui';
import { Table } from 'ui/tables/table/Table';
import { Project as ProjectType } from 'api/actions/projects/projectsActions.types';

import * as S from './Projects.styles';
import { filtersOperators, getProjectsParams, keysMapper } from './Projects.utils';
import { useProjectsColumns } from './hooks/useProjectsColumns';

export const Projects = () => {
  const { globalFilter, setGlobalFilter, queryParamsDecoded } = useGlobalFilter({
    keysMapper,
    filtersOperators,
  });

  const { columns } = useProjectsColumns();

  const { data, isLoading, isError } = useProjectsGet({
    params: getProjectsParams(queryParamsDecoded),
  });

  return (
    <>
      <GlobalFilter
        onValueChange={setGlobalFilter}
        value={globalFilter}
        labelPlaceholder="projects.table.searchProjects"
      />
      <Divider />
      <Table<ProjectType>
        keysMapper={keysMapper}
        filtersOperators={filtersOperators}
        data={data?.result}
        total={data?.total}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        globalFilter={globalFilter}
        sx={{
          tableHead: S.tableHead,
        }}
      />
    </>
  );
};
