import { AxiosRequestConfig } from 'axios';

import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { useQuery } from 'hooks';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

import {
  DeletePositionMutationArgs,
  PositionsGetResponse,
  PatchPositionMutationArgs,
  Position,
  PostPositionMutationArgs,
} from './positionsActions.types';

export const useGetPositions = (config?: AxiosRequestConfig) =>
  useQuery<PositionsGetResponse>(
    '/positions',
    ['admin-positions', config],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );

export const postPositionMutation: MutationFn<PostPositionMutationArgs, Position> = ({ body }) => ({
  endpoint: `/positions/position`,
  method: 'POST',
  params: body,
});

export const patchPositionMutation: MutationFn<PatchPositionMutationArgs, Position> = ({ positionId, body }) => ({
  endpoint: `/positions/position/${positionId}`,
  method: 'PATCH',
  params: body,
});

export const deletePositionMutation: MutationFn<DeletePositionMutationArgs> = ({ positionId }) => ({
  endpoint: `/positions/position/${positionId}`,
  method: 'DELETE',
});
