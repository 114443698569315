import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { patchProjectRolesMutation } from 'api/actions/projectRoles/projectRolesActions';
import { ProjectRolesPatchArgs } from 'api/actions/projectRoles/projectRolesActions.types';
import { getProjectRolesTableKey } from 'views/adminSection/projectRoles/hooks/useProjectRolesQuery';
import { profileProjectGetKey, profileProjectsGetKey } from 'api/actions/profile/projects/profileProjectsActions';
import { profileProjectRolesGetKey } from 'views/profileEditor/steps/experience/projects/hooks/useGetProjectRoles';

export const usePatchProjectRole = () => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate, isLoading, error, isSuccess, reset } = useMutation('edit-project-role', patchProjectRolesMutation, {
    onSuccess: () => {
      queryClient.refetchQueries(profileProjectGetKey);
      queryClient.invalidateQueries(profileProjectsGetKey);
      queryClient.invalidateQueries(getProjectRolesTableKey);
      queryClient.invalidateQueries(profileProjectRolesGetKey);
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => showSnackbarError(error),
  });

  const patchProjectRole = ({ name, id }: ProjectRolesPatchArgs) => {
    return mutate({ id, name });
  };

  return {
    patchProjectRole,
    isPatchLoading: isLoading,
    isPatchSuccess: isSuccess,
    patchError: error,
    patchReset: reset,
  };
};
