import { CellContext } from '@tanstack/react-table';

import { Certificate } from 'api/actions/certificates/certificatesActions.types';
import { useContentAccess, useModal } from 'hooks';
import { EditCertificateFormContainer } from '../../../certificateForm/editCertificateForm/EditCertificateFormContainer';
import { DeleteModal } from 'ui/modals/forceDeleteModal/DeleteModal';
import { useDeleteCertificate } from '../../useDeleteCertificate/useDeleteCertificate';
import { ActionsWrapper, DeleteButton, EditButton } from 'ui';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const Actions = (cell: CellContext<Certificate, string>) => {
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useModal();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Certificates });

  const {
    isOpen: isForceDeleteModalOpen,
    onOpen: onForceDeleteModalOpen,
    onClose: onForceDeleteModalClose,
  } = useModal();

  const { deleteCertificate } = useDeleteCertificate({
    onSuccess: onForceDeleteModalClose,
    onForceDelete: onForceDeleteModalOpen,
  });

  return (
    <>
      {!isReadOnly && (
        <ActionsWrapper>
          <EditButton onClick={onEditModalOpen} />
          <DeleteButton onClick={() => deleteCertificate({ certificateId: cell.getValue() })} />
        </ActionsWrapper>
      )}
      {isEditModalOpen && (
        <EditCertificateFormContainer
          isOpen={isEditModalOpen}
          onClose={onEditModalClose}
          certificateId={cell.getValue()}
        />
      )}
      {isForceDeleteModalOpen && (
        <DeleteModal
          isOpen={isForceDeleteModalOpen}
          onModalClose={onForceDeleteModalClose}
          onItemDelete={() => deleteCertificate({ certificateId: cell.getValue(), forceDelete: true })}
        />
      )}
    </>
  );
};
