import { Box } from '@mui/material';

import { FormCheckbox, FormModalWrapper, FormRadioGroup, Separator, Typography } from 'ui';
import { useLocale } from 'hooks';

import { ProjectModalFormFields, ProjectModalProps } from './ProjectModal.types';
import { WorkedFrom } from './components/workedFrom/WorkedFrom';
import { WorkedTo } from './components/workedTo/WorkedTo';
import { MyRole } from './components/myRole/MyRole';
import { ProjectInformation } from './components/projectInformation/ProjectInformation';
import { dateRangeWrapper, helperTextWrapper, infoSection, section } from './ProjectModal.styles';
import { TechnologyAutocomplete } from './components/technologies/technologyAutocomplete/TechnologyAutocomplete';
import { CopyTechnologiesButton } from './components/copyTechnologiesButton/CopyTechnologiesButton';
import { formatTechnologies } from './ProjectModal.utils';

export const ProjectModal = ({
  formMethods,
  header,
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  allTechnologies,
  isDataFetching,
  projectRoles,
  joinedAt,
  initialValues,
  projectTechnologies,
  isAdminOrNewProject,
}: ProjectModalProps) => {
  const { formatMessage } = useLocale();

  return (
    <FormModalWrapper
      formMethods={formMethods}
      header={header}
      isOpen={isOpen}
      onSubmit={onSubmit}
      isLoading={isLoading}
      onClose={onClose}
      isDataFetching={isDataFetching}
    >
      <Typography variant="body1">{'profileProject.form.description'}</Typography>
      <Separator height={20} />
      <Box sx={infoSection}>
        <ProjectInformation
          initialValues={initialValues}
          projectTechnologies={projectTechnologies}
          allTechnologies={allTechnologies}
          isLoading={isLoading}
        />
      </Box>
      <Separator height={28} />
      <Box sx={section}>
        <MyRole projectRoles={projectRoles} initialDescriptionValue={initialValues?.additionalDescription} />
        <Box sx={section}>
          <Box sx={dateRangeWrapper}>
            <WorkedFrom />
            <WorkedTo joinedAt={joinedAt} />
          </Box>

          <Box sx={helperTextWrapper}>
            <Typography variant="helperText" color="text.secondary">
              {'profileProject.label.workedFrom.helperText'}
            </Typography>
          </Box>
        </Box>

        <Separator height={12} />
        <CopyTechnologiesButton />
        <Separator height={12} />

        <TechnologyAutocomplete
          name={ProjectModalFormFields.ProfileProjectTechnologies}
          options={formatTechnologies(allTechnologies)}
          groupBy={(option) => option.categoryName}
          required
          label="profileProject.placeholder.profileTechnologies"
          helperText={formatMessage({ id: 'profileProject.info.profileTechnologies' })}
          error={!!formMethods.formState.errors[ProjectModalFormFields.ProfileProjectTechnologies]}
        />
      </Box>
      {isAdminOrNewProject && (
        <>
          <Separator height={24} />
          <Box sx={section}>
            <FormRadioGroup
              row
              name={ProjectModalFormFields.ProjectType}
              options={[
                { label: 'form.privateProject', value: 'private' },
                { label: 'form.publicProject', value: 'public' },
              ]}
            />
            <Box sx={helperTextWrapper}>
              <Typography variant={'helperText'} sx={{ color: 'text.secondary' }}>
                {'projectType.description'}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      <Box sx={section}>
        <Separator height={24} />
        <FormCheckbox name={ProjectModalFormFields.IsInvisible} label="profileProject.label.invisible" sx={{ mb: 0 }} />
        <Box sx={helperTextWrapper}>
          <Typography variant={'helperText'} sx={{ color: 'text.secondary' }}>
            {'profileProject.info.invisible'}
          </Typography>
        </Box>
      </Box>
      <Separator height={24} />
    </FormModalWrapper>
  );
};
