import { Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from 'lux/components';
import { TextField } from 'lux/components';

import { useLocale } from 'hooks';
import { Typography } from 'ui';
import { mergeStyles } from 'utils/mergeStyles';

import { FormDatePickerProps } from './FormDatePicker.types';
import * as S from './FormDatePicker.styles';
import { MIN_DATE } from './FormDatePicker.utils';

export const FormDatePicker = ({
  name,
  label,
  size = 'small',
  required,
  transformValue,
  dataTest = 'formDatePicker',
  placeholder,
  disabled,
  dateFormat,
  minDate = new Date(MIN_DATE),
  helperText,
  datePickerWrapperSx,
  ...props
}: FormDatePickerProps) => {
  const { formatMessage } = useLocale();

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={mergeStyles(S.datePickerWrapperStyles, datePickerWrapperSx || {})}>
            <DateTimePicker
              {...(disabled ? { disabled } : {})}
              onChange={(date, input) => {
                if (!dateFormat) {
                  onChange(transformValue ? transformValue(date) : date);
                } else {
                  input && input.length >= dateFormat.length
                    ? onChange(transformValue ? transformValue(date) : date)
                    : onChange(date);
                }
              }}
              {...(dateFormat ? { inputFormat: dateFormat } : {})}
              label={`${formatMessage({ id: label })} ${required ? '*' : ''}`}
              value={value}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  size={size}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': name || dataTest,
                    placeholder: formatMessage({ id: placeholder }),
                  }}
                  error={!!error}
                />
              )}
              minDate={minDate}
              {...props}
            />
            {helperText && (
              <Typography variant={'helperText'} sx={{ color: 'text.secondary', ...S.helperText }}>
                {formatMessage({ id: helperText })}
              </Typography>
            )}
            {error && (
              <Typography sx={S.error} variant="helperText">
                {error.message}
              </Typography>
            )}
          </Box>
        </LocalizationProvider>
      )}
    />
  );
};
