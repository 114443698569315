import { Box } from '@mui/material';

import { startsWith } from '../../utils/startsWith/startsWith';
import { Typography } from '../typography/Typography';
import { Separator } from '../separator/Separator';

import { ErrorMessageProps } from './ErrorMessage.types';

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const isClientError = startsWith(4, error?.status);

  if (!isClientError || !error) {
    return null;
  }

  return (
    <>
      {error.errors.map((item, i) => (
        <Box key={i}>
          <Typography variant="helperText">{item.code}</Typography>
          <Separator height={5} />
        </Box>
      ))}
    </>
  );
};
