import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Table } from 'lux/components';
import AddIcon from '@mui/icons-material/Add';

import { BorderBox, Loader, Separator, Typography } from 'ui';
import { Section } from '../../../../components/section/Section';
import { useLocale } from 'hooks';

import { ProfileCertificatesProps } from './ProfileCertificates.types';
import { ProfileCertificateContainer } from './profileCertificate/ProfileCertificateContainer';
import { AddCertificateModal } from './modals/addCertificateModal/AddCertificateModal';

export const ProfileCertificates = ({
  certificatesOptions,
  profileCertificates,
  isLoading,
  isError,
}: ProfileCertificatesProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formatMessage } = useLocale();

  return (
    <Section title="step.subTitle.certificates" subtitle="step.description.certificates">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isError ? (
            <Typography variant="helperText">{'error.errorOccurred'}</Typography>
          ) : (
            <BorderBox>
              <Table>
                <Table.Table aria-label="Certificates table">
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell>
                        <FormattedMessage id="form.name" />
                      </Table.Cell>
                      <Table.Cell>
                        <FormattedMessage id="form.obtained" />
                      </Table.Cell>
                      <Table.Cell>
                        <FormattedMessage id="form.validUntil" />
                      </Table.Cell>
                      <Table.Cell align="right">
                        <FormattedMessage id="table.actions" />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Head>

                  <Table.Body>
                    {profileCertificates.map((profileCertificate) => (
                      <ProfileCertificateContainer
                        key={profileCertificate.id}
                        profileCertificate={profileCertificate}
                        certificatesOptions={certificatesOptions}
                      />
                    ))}
                  </Table.Body>
                </Table.Table>
              </Table>
              <Separator height={20} />
              <Button
                data-testid="addCetrificate"
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => setIsModalOpen(true)}
              >
                {formatMessage({ id: 'form.addCertificate' })}
              </Button>

              {isModalOpen && (
                <AddCertificateModal
                  isOpen={isModalOpen}
                  onModalClose={() => setIsModalOpen(false)}
                  certificatesOptions={certificatesOptions}
                />
              )}
            </BorderBox>
          )}
        </>
      )}
    </Section>
  );
};
