import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { Profile } from 'api/actions/profiles/profilesActions.types';
import { loggedUserUrl, userUrl } from 'api/actions/user/userActions';
import { Loader } from 'ui';
import { CurrentUserContext } from '../currentUserContext/CurrentUserContext';
import { useQuery } from 'hooks';

type CurrentUserContextControllerProps = {
  children: ReactNode;
};

export const currentUserContextQueryKey = 'user-profile';

export const CurrentUserContextController = ({ children }: CurrentUserContextControllerProps) => {
  const { id } = useParams<{ id: string }>();

  const queryUrl: string = id && id !== 'undefined' ? userUrl(id) : loggedUserUrl;
  const queryKey = [currentUserContextQueryKey, queryUrl];

  const { data, isLoading, refetch } = useQuery<Profile>(queryUrl, queryKey, { retry: false });

  if (isLoading) {
    return <Loader />;
  }

  const value = {
    user: data,
    queryKey,
    refetch,
  };

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
};
