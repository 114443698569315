import { QueryParams } from 'api/types/types';

import { DecodeParams, GLOBAL_FILTER_URL_QUERY_KEY } from './globalFilter.types';

export const globalFilterParamDecode = ({
  queryParams,
  keysMapper,
  filtersOperators,
}: DecodeParams): Record<string, string> => {
  if (!queryParams.hasOwnProperty(GLOBAL_FILTER_URL_QUERY_KEY)) {
    return queryParams;
  }

  const queryParamsDecoded: Record<string, string> = {};

  Object.entries(queryParams).forEach(([key, value]) => {
    if (key === GLOBAL_FILTER_URL_QUERY_KEY) {
      Object.entries<string>(keysMapper).forEach(([guiColumnName, sqlColumnExpression]) => {
        if (filtersOperators[guiColumnName]) {
          queryParamsDecoded[`${QueryParams.FILTER}[${sqlColumnExpression}][${filtersOperators[guiColumnName]}]`] =
            value;
        }
      });

      return;
    }

    queryParamsDecoded[key] = value;
  });

  return queryParamsDecoded;
};
