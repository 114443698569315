import { unstable_usePrompt } from 'react-router-dom';

import { useLocale } from 'hooks';

import { UsePromptProps } from './usePrompt.types';

export const usePrompt = ({ when }: UsePromptProps) => {
  const { formatMessage } = useLocale();

  return unstable_usePrompt({
    message: formatMessage({ id: 'form.unsavedData' }),
    when: ({ currentLocation, nextLocation }) => {
      const fullNextLocation = nextLocation.pathname + nextLocation.search;
      const fullCurrentLocation = currentLocation.pathname + currentLocation.search;

      return when && fullNextLocation !== fullCurrentLocation;
    },
  });
};
