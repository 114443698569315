import { Box } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Table } from 'lux/components';

import { TableFilters } from '../../tableFilters/TableFilters';
import { Styles } from '../../../common';

import { TableHeaderProps } from './TableHeader.types';

export const TableHeader = <T extends Record<string, unknown>>({ tableHeaders }: TableHeaderProps<T>) => {
  return (
    <>
      {tableHeaders.map((headerGroup) => (
        <Table.Row key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <Table.Cell key={header.id} sx={Styles.row}>
              <>
                {header.isPlaceholder ? null : (
                  <Box
                    sx={{ ...(header.column.getCanSort() && Styles.sort) }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <ArrowUpwardIcon sx={Styles.icon} />,
                      desc: <ArrowDownwardIcon sx={Styles.icon} />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </Box>
                )}
                {header.column.getCanFilter() && <TableFilters column={header.column} />}
              </>
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </>
  );
};
