import { Styles } from 'lux/theme';

export const visibility: Styles = {
  fontStyle: 'italic',
};

export const name: Styles = {
  fontWeight: 'bold',
};

export const description: Styles = {
  color: 'text.secondary',
};

export const technologies: Styles = {
  display: 'flex',
  flexWrap: 'wrap',
  mt: 0.75,
};

export const technology: Styles = {
  fontSize: 12,
  color: 'primary.main',
  borderColor: 'primary.main',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 2,
  px: 1.5,
  py: 0.25,
  mr: 1,
  mb: 1,
};
