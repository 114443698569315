import { Helmet } from 'react-helmet';

import { useContentAccess, useLocale, useModal } from 'hooks';
import { Tags } from 'views/adminSection/tags/Tags';
import { AdminSectionWrapper, OpenModalButton } from 'ui';
import { AddTagModalContainer } from 'views/adminSection/tags/modals/addTagModal/AddTagModalContainer';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const TagsContainer = () => {
  const { formatMessage } = useLocale();
  const { onOpen, onClose, isOpen } = useModal();

  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Tags });

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'navigator.tags' })}</title>
      </Helmet>
      <AdminSectionWrapper
        title="navigator.tags"
        action={isReadOnly ? null : <OpenModalButton label="tags.form.add" onClick={onOpen} />}
        tabs="certificatesTabs"
      >
        <Tags />
        {isOpen && <AddTagModalContainer isOpen={isOpen} onModalClose={onClose} />}
      </AdminSectionWrapper>
    </>
  );
};
