import { Box } from '@mui/material';

import * as S from '../achievements/Achievements.styles';
import { Typography } from 'ui';

import { SoftSkillsProps } from './SoftSkills.types';

export const SoftSkills = ({ skills }: SoftSkillsProps) => (
  <Box sx={S.wrapper}>
    <Typography sx={S.title} variant="h2" color="text.primary">
      {'generatedProfile.softSkills'}
    </Typography>

    <ul>
      {skills.map(({ name }) => (
        <li key={name}>{name}</li>
      ))}
    </ul>
  </Box>
);
