import { Table } from 'ui/tables/table/Table';
import { useSkillsColumns } from 'views/adminSection/skills/hooks/useSkillsColumns';
import { Skill } from 'api/actions/skills/skillsActions.types';
import { useGlobalFilter } from 'hooks';
import { useGetSkills } from 'api/actions/skills/skillsActions';

import * as S from './Skills.styles';
import { filtersOperators, getSkillsParams, keysMapper } from './Skills.utils';

export const Skills = () => {
  const { queryParamsDecoded } = useGlobalFilter({
    keysMapper,
    filtersOperators,
  });

  const { columns } = useSkillsColumns();

  const { data, isLoading, isError } = useGetSkills({ params: getSkillsParams(queryParamsDecoded) });

  return (
    <Table<Skill>
      keysMapper={keysMapper}
      filtersOperators={filtersOperators}
      sx={{ tableHead: S.tableHead }}
      data={data?.result}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      total={data?.total}
      defaultSort={[{ id: 'name', desc: false }]}
    />
  );
};
