import { componentsLibTheme as theme, Styles } from 'lux/theme';

const { spacing, palette } = theme;

export const editorInput = (minHeight?: number, disabled?: boolean): Styles => ({
  '& .content-editable': {
    minHeight: `${minHeight ? minHeight : 230}px`,
    backgroundColor: palette.surface.paper.default,
    border: `1px solid ${palette.border.default}`,
    borderRadius: disabled ? spacing(1, 1, 1, 1) : spacing(0, 0, 1, 1),
    padding: 1.25,
    '& .editor-paragraph': {
      marginX: 0,
      marginBottom: '0.75em',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    ':hover': {
      backgroundColor: 'primary.light',
    },
    '& .editor-list-ul': {
      margin: spacing(0, 0, 0, 2),
      padding: 0,
      '& li': {
        marginLeft: 2.5,
      },
    },
  },
});

export const placeholder: Styles = {
  position: 'absolute',
  pointerEvents: 'none',
  top: spacing(1.25),
  left: spacing(1.5),
  color: palette.common.placeholder,
};
