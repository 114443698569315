import { useCertificatesGet } from 'api/actions/certificates/certificatesActions';
import { useProfileCertificatesGet } from 'api/actions/profile/certificates/profileCertificatesActions';
import { useCurrentUser } from 'hooks';

export const useProfileCertificatesQuery = () => {
  const { user } = useCurrentUser();

  const { data: certificates, isLoading: isCertificatesLoading, isError: isCertificatesError } = useCertificatesGet();

  const {
    data: profileCertificates,
    isLoading: isProfileCertificatesLoading,
    isError: isProfileCertificatesError,
  } = useProfileCertificatesGet(user?.id, {
    params: {
      relations: ['profileCertificate.certificate'],
    },
  });

  return {
    certificates: certificates?.result || [],
    profileCertificates: profileCertificates?.result || [],
    isLoading: isCertificatesLoading || isProfileCertificatesLoading,
    isError: isCertificatesError || isProfileCertificatesError,
  };
};
