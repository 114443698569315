import { Styles } from 'lux/theme';

export const editStyles: Styles = {
  display: 'flex',
  gap: 0.5,

  '& > div': {
    mb: 0,
    mr: 2,
  },
};

export const editButtonStyles: Styles = {
  mr: 4,
};

export const dragHandleStyles: Styles = {
  float: 'left',
  marginRight: 0.75,
};

export const draggedItemStyles: Styles = {
  display: 'table',
};

export const actionsWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1.5,
  width: 110,
};

export const actionsCell: Styles = {
  width: 110,
};
