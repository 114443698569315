import { useProfileCertificatesQuery } from './hooks/useProfileCertificatesQuery';
import { ProfileCertificates } from './ProfileCertificates';

export const ProfileCertificatesContainer = () => {
  const { certificates, profileCertificates, isLoading, isError } = useProfileCertificatesQuery();

  const profileCertificatesIds = profileCertificates.map(({ certificateId }) => certificateId);
  const certificatesAvailable = certificates.filter(({ id }) => profileCertificatesIds.includes(id) === false);
  const certificatesOptions = certificatesAvailable.map(({ id, name, tags }) => ({ id, name, tags }));

  return (
    <ProfileCertificates
      certificatesOptions={certificatesOptions}
      profileCertificates={profileCertificates}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
