import { useState } from 'react';

import { useGetQueryParams } from 'hooks/useGetQueryParams/useGetQueryParams';
import { globalFilterParamDecode } from 'utils';

import { UseGlobalFilterProps } from './useGlobalFilter.types';

export const useGlobalFilter = ({ keysMapper, filtersOperators }: UseGlobalFilterProps) => {
  const { queryParams } = useGetQueryParams();
  const [globalFilter, setGlobalFilter] = useState(queryParams?.search || '');

  const queryParamsDecoded = globalFilterParamDecode({
    queryParams,
    keysMapper,
    filtersOperators,
  });

  return {
    globalFilter,
    setGlobalFilter,
    queryParamsDecoded,
  };
};
