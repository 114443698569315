import { UseFormReturn } from 'react-hook-form';

import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { CertificateOption } from '../../ProfileCertificates.types';

export type CertificateModalProps = {
  isOpen: boolean;
  onModalClose: VoidFunction;
  formMethods: UseFormReturn<CertificateModalFormValues>;
  onSubmit: (formValues: CertificateModalFormValues) => Promise<void>;
  certificatesOptions: CertificateOption[];
  isLoading: boolean;
  submitLabel: string;
};

export enum CertificateModalFormFields {
  Certificate = 'certificate',
  ObtainedAt = 'obtainedAt',
  IsHidden = 'isHidden',
}

export type CertificateModalFormValues = {
  [CertificateModalFormFields.Certificate]: AutocompleteOption | null;
  [CertificateModalFormFields.ObtainedAt]: string | null;
  [CertificateModalFormFields.IsHidden]: boolean;
};
