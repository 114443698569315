import { AutocompleteWithTags, FormInputText, FormModalWrapper } from 'ui';
import { useLocale } from 'hooks';

import { CertificateFormFields, CertificateFormProps, CertificateFormValues } from './CertificateForm.types';

export const CertificateForm = ({
  isOpen,
  onClose,
  onSubmit,
  formMethods,
  isLoading,
  formMode,
  tags,
}: CertificateFormProps) => {
  const { formatMessage } = useLocale();

  return (
    <FormModalWrapper<CertificateFormValues>
      isLoading={isLoading}
      onClose={onClose}
      formMethods={formMethods}
      isOpen={isOpen}
      onSubmit={onSubmit}
      header={`certificates.form.${formMode}.header`}
    >
      <FormInputText
        label="certificates.form.name"
        name={CertificateFormFields.Name}
        error={!!formMethods.formState.errors.name}
        required
      />
      <FormInputText
        label="certificates.form.description"
        name={CertificateFormFields.Description}
        error={!!formMethods.formState.errors.description}
        multiline
        minRows={3}
        maxRows={5}
        required
      />
      <FormInputText
        label="certificates.form.yearsOfValidity"
        name={CertificateFormFields.PeriodOfValidity}
        error={!!formMethods.formState.errors.periodOfValidity}
      />
      <FormInputText
        label="certificates.form.url"
        name={CertificateFormFields.Url}
        error={!!formMethods.formState.errors.url}
      />
      <AutocompleteWithTags
        label={formatMessage({ id: 'certificates.form.tags' })}
        name={CertificateFormFields.Tags}
        options={tags || []}
      />
    </FormModalWrapper>
  );
};
