import { Box, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Styles } from 'lux/theme';
import { Select } from 'lux/components';
import { SelectProps } from 'lux/components/atoms/select/Select.types';
import { forwardRef } from 'react';

import { Typography } from 'ui';
import { useLocale } from 'hooks';

import * as S from './FormSelect.styles';
import { selectWrapperStyles } from './FormSelect.styles';

export type SelectOption = (Record<string, string | number> & { name?: string; id?: string; visibleName?: string })[];

type FormSelectProps = {
  options: SelectOption;
  name: string;
  label: string;
  formControlStyles?: Styles;
  required?: boolean;
  isValueName?: boolean;
  wrapperStyles?: Styles;
} & SelectProps;

export const FormSelect = forwardRef<HTMLInputElement, FormSelectProps>(
  (
    {
      name,
      label,
      options,
      fullWidth = true,
      size = 'small',
      formControlStyles,
      required,
      wrapperStyles,
      isValueName,
      ...props
    },
    ref,
  ) => {
    const { formatMessage } = useLocale();
    const optionsAdapted = options?.map(({ id, name, visibleName }) => {
      const nameValue = name ? name : visibleName;

      return {
        id,
        children: nameValue,
        value: isValueName ? nameValue : id,
      };
    });
    const combinedWrapperStyles = {
      ...selectWrapperStyles,
      ...wrapperStyles,
    };

    return (
      <Controller
        name={name}
        render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
          <Box sx={combinedWrapperStyles}>
            <FormControl fullWidth={fullWidth} sx={formControlStyles}>
              <Select
                {...props}
                {...rest}
                ref={ref}
                value={value || ''}
                fullWidth={fullWidth}
                label={formatMessage({ id: label })}
                onChange={(e) => onChange(e.target.value)}
                inputProps={{ ...props.inputProps, 'data-testid': name }}
                options={optionsAdapted}
                size={size}
                required={required}
              />
              {error && (
                <Typography sx={S.error} variant="helperText">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          </Box>
        )}
      />
    );
  },
);
