import { useState } from 'react';
import { Box, Divider } from '@mui/material';
import { Grid, TextField } from 'lux/components';
import SearchIcon from '@mui/icons-material/Search';

import { AdminSectionWrapper, OpenModalButton } from 'ui';
import { useContentAccess, useGetQueryParams, useLocale, useModal } from 'hooks';
import { globalFilterParamDecode, virtualSortingDecode } from 'utils';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { AddProfileModalContainer } from './modals/addProfileModal/AddProfileModalContainer';
import * as S from './Profiles.styles';
import { DownloadResumeButton } from './components/downloadResumeButton/DownloadResumeButton';
import { ProfilesList } from './profilesList/ProfilesList';
import { SelectedRowsInfo } from './components/selectedRowsInfo/SelectedRowsInfo';
import { filtersOperators, keysMapper, virtualSortingMapper } from './profilesList/ProfilesList.utils';

export const Profiles = () => {
  const { isOpen, onClose, onOpen } = useModal();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Profiles });
  const { queryParams } = useGetQueryParams();
  const [selectedRowIds, setSelectedRowsIds] = useState<string[]>([]);
  const [globalFilter, setGlobalFilter] = useState(queryParams?.search || '');
  const { formatMessage } = useLocale();

  const globalFilterIncluded = globalFilterParamDecode({
    queryParams,
    keysMapper,
    filtersOperators,
  });
  const queryParamsDecoded = virtualSortingDecode({
    queryParams: globalFilterIncluded,
    virtualSortingMapper: virtualSortingMapper,
  });

  return (
    <AdminSectionWrapper
      title="navigator.profiles"
      tabs="profileTabs"
      action={
        isReadOnly ? null : <OpenModalButton sx={S.button} label="profiles.form.addNewProfile" onClick={onOpen} />
      }
    >
      {selectedRowIds.length > 0 && (
        <Box sx={S.buttonsContainer}>
          <Grid container gutter={0} spacing={2} sx={S.downloadSection}>
            <Grid item xs={12}>
              <SelectedRowsInfo selectedRows={selectedRowIds.length} />
            </Grid>
            <Grid item xs={12}>
              <DownloadResumeButton sx={S.button} userIds={selectedRowIds} />
            </Grid>
          </Grid>
        </Box>
      )}

      <TextField
        sx={S.globalFilter}
        endAdornment={<SearchIcon />}
        label={formatMessage({ id: 'profiles.table.searchProfile' })}
        value={globalFilter}
        onChange={(e) => {
          setGlobalFilter(String(e.target.value));
        }}
      />

      <Divider variant="middle" sx={S.hr} />

      <ProfilesList
        onRowSelect={setSelectedRowsIds}
        globalFilter={globalFilter}
        queryParamsDecoded={queryParamsDecoded}
      />
      {isOpen && <AddProfileModalContainer isOpen={isOpen} onModalClose={onClose} />}
    </AdminSectionWrapper>
  );
};
