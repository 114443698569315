import { AxiosRequestConfig } from 'axios';

import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { useQuery } from 'hooks';

import {
  DeleteProfileSkillsMutationArgs,
  ProfileSkillsGetResponse,
  PostProfileSkillMutationArgs,
  ProfileSkill,
} from './profileSkillsActions.types';
import {
  generateEndpointPart,
  generateGetProfileSkillsQueryKey,
  generateGetProfileSkillsQueryUrl,
} from './profileSkillsActions.utils';

export const useGetProfileSkills = (
  skillsType: number | undefined,
  profileId: string | undefined,
  isSpecial: boolean,
  config: AxiosRequestConfig = {},
) =>
  useQuery<ProfileSkillsGetResponse>(
    generateGetProfileSkillsQueryUrl(isSpecial, profileId),
    generateGetProfileSkillsQueryKey(isSpecial, profileId),
    undefined,
    config,
  );

export const postProfileSkillMutation: MutationFn<PostProfileSkillMutationArgs, ProfileSkill> = ({
  profileId,
  isSpecial,
  body,
}) => ({
  endpoint: `/profiles/${profileId}/${generateEndpointPart(isSpecial, 'mutate')}`,
  method: 'POST',
  params: body,
});

export const deleteProfileSkillMutation: MutationFn<DeleteProfileSkillsMutationArgs> = ({
  profileId,
  skillId,
  isSpecial,
}) => ({
  endpoint: `/profiles/${profileId}/${generateEndpointPart(isSpecial, 'mutate')}/${skillId}`,
  method: 'DELETE',
});
