import { Autocomplete, TextField } from '@mui/material';

import { FormCheckbox, FormInputText, FormModalWrapper, FormSelect } from 'ui';
import { SkillModalFormFields, SkillModalProps } from 'views/adminSection/skills/modals/skillModal/SkillModal.types';
import { SkillType } from 'api/actions/skills/skillsActions.types';
import * as S from 'views/adminSection/skills/modals/skillModal/SkillModal.styles';
import { useLocale } from 'hooks';

export const SkillModal = ({
  skillType,
  isOpen,
  onClose,
  isLoading,
  formMode,
  formMethods,
  onSubmit,
  header,
  isDataFetching,
  skillTypeOptions,
  categoriesOptions,
  category,
}: SkillModalProps) => {
  const { formatMessage } = useLocale();

  return (
    <FormModalWrapper
      isOpen={isOpen}
      isDataFetching={isDataFetching}
      isLoading={isLoading}
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      header={header}
    >
      <FormInputText label="form.skill" name={SkillModalFormFields.Name} />
      {formMode === 'add' && (
        <FormSelect
          options={skillTypeOptions}
          name={SkillModalFormFields.Type}
          value={skillType}
          label="form.skillType"
          required
        />
      )}
      {skillType === SkillType.Tech && (
        <Autocomplete
          value={category}
          sx={S.autocompleteStyles}
          size="small"
          options={categoriesOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, 'data-testid': SkillModalFormFields.CategoryId }}
              label={formatMessage({ id: 'form.category' })}
            />
          )}
          getOptionLabel={({ name }) => name || ''}
          onChange={(_, value) => formMethods.setValue(SkillModalFormFields.CategoryId, value ? value.id : null)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      )}
      <FormCheckbox name={SkillModalFormFields.IsSurveyItem} label="form.isSurveyItemCheckbox" />
    </FormModalWrapper>
  );
};
