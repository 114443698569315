import { AxiosRequestConfig } from 'axios';

import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { useQuery } from 'hooks';

import {
  DeleteSeniorityMutationArgs,
  SeniorityGetResponse,
  SeniorityPatchResponse,
  PatchSeniorityBody,
} from './senioritiesActions.types';

export const seniorityQueryKey = 'admin-seniority';
export const useGetSeniorities = (config?: AxiosRequestConfig) =>
  useQuery<SeniorityGetResponse>('/seniority', seniorityQueryKey, undefined, config);

export const patchSeniorityMutation: MutationFn<PatchSeniorityBody, SeniorityPatchResponse> = ({
  visibleName,
  displayOrder,
  id,
}) => ({
  endpoint: `/seniority/seniority/${id}`,
  method: 'PATCH',
  params: {
    visibleName,
    displayOrder,
  },
});

export const deleteSeniorityMutation: MutationFn<DeleteSeniorityMutationArgs> = ({ seniorityId }) => ({
  endpoint: `/seniority/seniority/${seniorityId}`,
  method: 'DELETE',
});
