import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { ProjectPatchBody } from 'api/actions/projects/projectsActions.types';
import { projectsGetQueryKey, useProjectPatch as useProjectPatchHook } from 'api/actions/projects/projectsActions';
import { useSnackbar } from 'hooks';

const profileProjectsQueryCacheKeyPattern = new RegExp('^/profiles/(.*?)/projects$');

export const useProjectPatch = (projectId: string, onSuccess: VoidFunction) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, isLoading, error } = useProjectPatchHook({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries([projectsGetQueryKey]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          profileProjectsQueryCacheKeyPattern.test(Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey),
        refetchInactive: true,
      });
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => showSnackbarError(error),
  });

  const modifyProject = useCallback(
    async (body: Pick<ProjectPatchBody, 'name' | 'description' | 'shortDescription'>) => {
      await mutateAsync({ projectId, body });
    },
    [projectId, mutateAsync],
  );

  return { modifyProject, isError, isLoading, error };
};
