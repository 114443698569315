import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Table } from 'lux/components';
import AddIcon from '@mui/icons-material/Add';

import { BorderBox, Loader, Separator, Typography } from 'ui';
import { Section } from '../../../../components/section/Section';
import { useLocale } from 'hooks';

import { ProfileLanguageContainer } from './profileLanguage/ProfileLanguageContainer';
import { ProfileLanguagesProps } from './ProfileLanguages.types';
import { AddLanguageModalContainer } from './modals/addLanguageModal/AddLanguageModalContainer';

export const ProfileLanguages = ({ languages, profileLanguages, isLoading, isError }: ProfileLanguagesProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedLanguagesIds = profileLanguages.map(({ languageId }) => languageId);
  const { formatMessage } = useLocale();

  const languageOptions = languages
    .filter(({ id }) => !selectedLanguagesIds.includes(id))
    .map(({ id, name }) => ({ id, name }));

  return (
    <Section title="step.subTitle.languages">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isError ? (
            <Typography variant="helperText">{'error.errorOccurred'}</Typography>
          ) : (
            <BorderBox>
              <Table>
                <Table.Table aria-label="Language table">
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell>
                        <FormattedMessage id="form.name" />
                      </Table.Cell>
                      <Table.Cell align="center">
                        <FormattedMessage id="form.proficiency" />
                      </Table.Cell>
                      <Table.Cell align="right">
                        <FormattedMessage id="table.actions" />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Head>

                  <Table.Body>
                    {profileLanguages.map((profileLanguage) => (
                      <ProfileLanguageContainer
                        key={profileLanguage.id}
                        profileLanguage={profileLanguage}
                        languageOptions={languageOptions}
                      />
                    ))}
                  </Table.Body>
                </Table.Table>
              </Table>
              {languageOptions.length > 0 && (
                <>
                  <Separator height={20} />
                  <Button
                    data-testid="addLanguage"
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => setIsModalOpen(true)}
                  >
                    {formatMessage({ id: 'form.addLanguage' })}
                  </Button>
                  {isModalOpen && (
                    <AddLanguageModalContainer
                      isOpen={isModalOpen}
                      onModalClose={() => setIsModalOpen(false)}
                      languageOptions={languageOptions}
                    />
                  )}
                </>
              )}
            </BorderBox>
          )}
        </>
      )}
    </Section>
  );
};
