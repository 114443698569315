import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';

import { Profiles } from './Profiles';

export const ProfilesContainer = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.profiles' })}</title>
      </Helmet>
      <Profiles />
    </>
  );
};
