import { Styles } from 'lux/theme';

export const chip: Styles = {
  '.MuiChip-deleteIcon': { display: 'none' },
  pr: 1,
};

export const error: Styles = {
  mt: 0.625,
};

export const addNewText: Styles = {
  fontWeight: '500',
  lineHeight: '100%',
  ml: '6px',
  mb: '-2px',
  color: 'primary.main',
};

export const addNewItemList: Styles = {
  cursor: 'pointer',
  strokeWidth: '0.1px',
  color: 'black',

  '&:hover': {
    backgroundColor: 'grey.100',
  },
};

export const popper: Styles = {
  '.MuiListSubheader-root': {
    backgroundColor: 'surface.paper.default',
    color: 'primary.main',
  },

  '&& .MuiMenuItem-root': {
    '&:hover': {
      backgroundColor: 'grey.50',
    },
  },
};

export const autocomplete: Styles = {
  '& .MuiInputLabel-shrink': {
    transform: 'translate(10px, -9px) scale(0.71);',
  },
};
