import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';
import { CurrentUserContextController } from 'context/currentUser/currentUserContextController/CurrentUserContextController';

import { TechnologiesSurvey } from './TechnologiesSurvey';

export const TechnologiesSurveyContainer = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.technologiesSurvey' })}</title>
      </Helmet>
      <CurrentUserContextController>
        <TechnologiesSurvey formatMessage={formatMessage} />
      </CurrentUserContextController>
    </>
  );
};
