import { Autocomplete, Box, ListItem, Paper, TextField } from '@mui/material';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from 'ui';

import * as S from './AutocompleteWithTags.styles';
import { AutocompleteOption, AutocompleteWithTagsProps } from './AutocompleteWithTags.types';

export const AutocompleteWithTags = <
  T extends AutocompleteOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean,
  FreeSolo extends boolean | undefined,
>({
  options,
  name,
  label,
  freeSolo = true,
  multiple = true,
  ...props
}: AutocompleteWithTagsProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { trigger } = useFormContext();

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => setTimeout(() => inputRef.current?.focus());

  return (
    <Controller
      name={name}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <Autocomplete
          {...props}
          freeSolo={freeSolo as FreeSolo}
          multiple={multiple as Multiple}
          autoHighlight
          value={value || []}
          onChange={(_, value) => {
            onChange(value);
            trigger(name);
          }}
          onBlur={() => {
            onBlur();
            trigger(name);
          }}
          options={options}
          inputValue={inputValue}
          onInputChange={(_, value) => setInputValue(value)}
          renderInput={(params) => (
            <Box sx={S.inputWrapperStyles}>
              <TextField
                {...params}
                label={label}
                size="small"
                inputRef={inputRef}
                inputProps={{ ...params.inputProps, 'data-testid': 'autocompleteWithTags' }}
              />

              {error && (
                <Typography sx={S.error} variant="helperText">
                  {(error as unknown as FieldError[]).map((e) => (
                    <div key={e.message}>{e.message}</div>
                  ))}
                </Typography>
              )}
            </Box>
          )}
          PaperComponent={(params) => {
            const { children, ...rest } = params;
            const shouldShowAddNewButton =
              !!inputValue && !value?.includes(inputValue) && !options?.includes(inputValue);

            const handleListItemMouseDown = () => {
              onChange([...(value ?? []), inputValue]);
              setInputValue('');
              focusInput();
            };

            return (
              <Paper {...rest}>
                {children}

                {shouldShowAddNewButton && (
                  <ListItem onMouseDown={handleListItemMouseDown} sx={S.addNewItemList}>
                    <AddIcon />
                    <Typography sx={S.addNewText}>
                      <FormattedMessage id="autocomplete.addNew" />: {inputValue}
                    </Typography>
                  </ListItem>
                )}
              </Paper>
            );
          }}
        />
      )}
    />
  );
};
