import { Styles, componentsLibTheme as theme } from 'lux/theme';
const { spacing } = theme;

export const wrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  alignSelf: 'flex-end',
};

export const label: Styles = {
  color: 'inherit',
  fontSize: spacing(1.75),
};
