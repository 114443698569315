import { ColumnSort } from '@tanstack/table-core';

import { QueryParams } from 'api/types/types';
import { virtualSortingEncode, VirtualSortingMapper } from 'utils';

export const getSortQueryParams = (
  sort: ColumnSort[],
  keysMapper: Record<string, string>,
  virtualSortingMap: VirtualSortingMapper,
) =>
  sort.reduce((acc, val) => {
    if (virtualSortingMap.hasOwnProperty(val.id)) {
      return {
        ...acc,
        ...virtualSortingEncode({ columnSort: val, virtualSortingMap }),
      };
    }

    return { ...acc, [`${QueryParams.SORT}[${keysMapper[val.id]}]`]: val.desc ? 'DESC' : 'ASC' };
  }, {});
