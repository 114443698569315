import { FilterOperator } from 'utils';

export const filtersOperators: Record<string, FilterOperator> = {
  name: FilterOperator.includeOr,
  tags: FilterOperator.includeOr,
};

export const keysMapper = {
  name: 'certificate.name',
  tags: 'tag.name',
  numberOfUsages: 'certificate.numberOfUsages',
};

export const getCertificatesParams = (queryParams: Record<string, string | number>) => {
  const isSortParams = Object.keys(queryParams).some((key) => key.startsWith('sort'));

  const defaultSortParams = isSortParams ? {} : { 'sort[certificate.name]': 'ASC' };

  return {
    page: '1',
    limit: '10',
    ...defaultSortParams,
    ...queryParams,
    relations: 'profileCertificate.profile',
  };
};
