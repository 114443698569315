interface Params {
  [key: string]: string | number;
}
const prefixOrder = ['filter', 'sort', 'relations', 'page', 'limit'];

const keyComplexity = (key: string) => {
  const elements = key.split(/./).length;

  return elements;
};

const filterObjectByPrefixes = (obj: Params): Params => {
  return Object.entries(obj)
    .filter(([key]) => prefixOrder.some((prefix) => key.startsWith(prefix)))
    .filter(([key]) => !['undefined', 'none', 'null'].some((substring) => key.includes(substring)))
    .reduce((acc: Params, [key, value]) => {
      acc[key] = value;

      return acc;
    }, {});
};

export const filteredAndSortedParams = (params: Params) => {
  if (!params) return undefined;

  const filteredParams = filterObjectByPrefixes(params);

  const keysSortedByPrefixAndComplexity = Object.keys(filteredParams).sort((a, b) => {
    if (a.startsWith('sort') && b.startsWith('sort')) return 0;

    const prefixA = prefixOrder.findIndex((prefix) => a.startsWith(prefix));
    const prefixB = prefixOrder.findIndex((prefix) => b.startsWith(prefix));
    if (prefixA === prefixB) {
      const complexityA = keyComplexity(a);
      const complexityB = keyComplexity(b);
      if (complexityA === complexityB) {
        return a.localeCompare(b);
      }

      return complexityB - complexityA;
    }

    return prefixA - prefixB;
  });

  return keysSortedByPrefixAndComplexity.reduce((acc: Params, key) => {
    acc[key] = filteredParams[key];

    return acc;
  }, {});
};
