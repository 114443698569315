import { Styles } from 'lux/theme';

export const wrapper: Styles = ({ palette, spacing }) => ({
  px: 1,
  mr: 1,
  mb: 0.5,
  borderRadius: 0.5,
  bgcolor: palette.grey['A100'],
  display: 'inline-block',
  fontSize: spacing(1.25),
});
