import { Styles } from 'lux/theme';

export const inputWrapperStyles: Styles = {
  mb: 2.5,
};

export const error: Styles = {
  mt: 0.625,
};

export const addNewText: Styles = {
  fontWeight: '500',
  lineHeight: '100%',
  ml: '6px',
  mb: '-2px',
  color: 'primary.main',
};

export const addNewItemList: Styles = {
  pt: '12px',
  pb: '12px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: 'grey.100',
  },
};
