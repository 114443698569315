import { useDeleteProfileLanguage } from '../hooks/useDeleteProfileLanguage';

import { ProfileLanguage } from './ProfileLanguage';
import { ProfileLanguageContainerProps } from './ProfileLanguage.types';

export const ProfileLanguageContainer = ({ profileLanguage, languageOptions }: ProfileLanguageContainerProps) => {
  const { deleteProfileLanguage } = useDeleteProfileLanguage(profileLanguage.id);

  return (
    <ProfileLanguage
      languageOptions={languageOptions}
      profileLanguage={profileLanguage}
      onRemoveButtonClick={deleteProfileLanguage}
    />
  );
};
