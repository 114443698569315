import { Box } from '@mui/material';
import { TextField } from 'lux/components';

import { useLocale } from 'hooks';

import * as Styles from './InputFilter.styles';
import { TableFiltersProps } from './InputFilter.types';

export const InputFilter = <T extends Record<string, unknown>>({ column }: TableFiltersProps<T>) => {
  const { formatMessage } = useLocale();

  return (
    <Box>
      <TextField
        inputProps={{ ['data-testid']: column.id }}
        value={(column.getFilterValue() as string) || ''}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder={formatMessage({ id: 'table.search' })}
        sx={Styles.filterInput}
      />
    </Box>
  );
};
