import { Navigate } from 'react-router-dom';

import { AccessControl } from 'ui';
import { AppRoute } from '../AppRoute.enum';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { TechnologiesSurveyContainer } from 'views/technologiesSurvey/TechnologiesSurveyContainer';

export const TechnologiesSurvey = () => (
  <AccessControl allowedContent={ContentType.TechnologiesSurvey} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <TechnologiesSurveyContainer />
  </AccessControl>
);
