import { Box } from '@mui/material';
import { useEffect } from 'react';

import { AdminSectionForm, FormInputText, Loader, Modal, Typography } from 'ui';
import { usePrompt, useSetFormError } from 'hooks';

import * as styles from './SeniorityModal.styles';
import { SeniorityModalFormFields, SeniorityModalProps, SeniorityModalFormValues } from './SeniorityModal.types';

export const SeniorityModal = ({
  handleSenioritySubmit,
  seniority,
  isOpen,
  onClose,
  isLoading,
  formMethods,
  submitButtonLabel,
  error,
}: SeniorityModalProps) => {
  const { setError } = formMethods;
  const { setFormError } = useSetFormError();

  useEffect(() => {
    setFormError<SeniorityModalFormValues>(setError, error, SeniorityModalFormFields, 'name');
  }, [error, setError, setFormError]);

  usePrompt({ when: formMethods.formState.isDirty });

  return (
    <Modal isOpen={isOpen} onModalClose={onClose}>
      {isLoading && <Loader fitToParent />}
      <AdminSectionForm
        {...(submitButtonLabel ? { submitButtonLabel: submitButtonLabel } : {})}
        formMethods={formMethods}
        onSubmit={(data) => handleSenioritySubmit({ ...data, id: seniority?.id })}
        isLoading={isLoading}
        onClose={onClose}
      >
        <Typography sx={styles.newSeniorityTitleStyles} variant="h3">
          {seniority ? 'form.editSeniority' : 'form.addNewSeniority'}
        </Typography>
        <Box sx={styles.inputsWrapperStyles}>
          <FormInputText
            autoFocus
            name={SeniorityModalFormFields.Name}
            label="form.seniority"
            required
            disabled={true}
          />

          <Typography sx={styles.infoText}>{'form.visibleSeniorityInfo'}</Typography>
          <FormInputText name={SeniorityModalFormFields.VisibleName} label="form.visibleSeniority" />
        </Box>
      </AdminSectionForm>
    </Modal>
  );
};
