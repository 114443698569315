import { useNavigate, useParams } from 'react-router-dom';

import { CurrentUserContextController } from 'context/currentUser/currentUserContextController/CurrentUserContextController';
import { AppRoute } from 'routing/AppRoute.enum';

import { ProfileEditor } from './ProfileEditor';
import { Steps, StepsRoutesNames } from './ProfileEditor.types';

export const ProfileEditorContainer = () => {
  const navigate = useNavigate();
  const { step, id } = useParams<{ step: keyof typeof StepsRoutesNames; id?: string }>();

  const activeStep = step ? StepsRoutesNames[step] : StepsRoutesNames['basic-data'];

  const setActiveStep = (step: Steps) => {
    const isEditingUser = !!id;
    const withId = isEditingUser ? `/${id}` : '';
    navigate(`${AppRoute.ProfileEditor}/${StepsRoutesNames[step]}${withId}`);
  };

  return (
    <CurrentUserContextController>
      <ProfileEditor activeStep={activeStep} setActiveStep={setActiveStep} />
    </CurrentUserContextController>
  );
};
