import { useUser } from 'hooks';
import { hasPermission } from 'ui/accessControl/AccessControl.utils';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { UseContentAccessProps } from './useContentAccess.types';

export const useContentAccess = ({ allowedContent }: UseContentAccessProps) => {
  const { user } = useUser();

  const hasAccess = (contentType: ContentType) => {
    const { isAllowed } = hasPermission({ userRole: user?.accessType, allowedContent: contentType });

    return isAllowed;
  };

  if (allowedContent) {
    const { isAllowed, isReadOnly } = hasPermission({ userRole: user?.accessType, allowedContent });

    return { isAllowed, isReadOnly, hasAccess };
  }

  return { hasAccess, isAllowed: false, isReadOnly: true };
};
