export const parseSearchParams = (searchParams: URLSearchParams) => {
  const paramsArr: [string, string][] = [];

  for (const param of searchParams.entries()) {
    paramsArr.push(param);
  }

  return paramsArr.reduce<Record<string, string>>((prev, curr) => {
    return { ...prev, [curr[0]]: curr[1] };
  }, {});
};
