import { useDeleteProfileSkill } from 'views/profileEditor/steps/skills/hooks';
import { SkillType } from 'api/actions/skills/skillsActions.types';

import { ProfileSkill } from './ProfileSkill';
import { ProfileSkillContainerProps } from './ProfileSkill.types';

export const ProfileSkillContainer = ({
  profileSkill: {
    id,
    skill: { name, type },
  },
  skillType,
}: ProfileSkillContainerProps) => {
  const handleDeleteSkill = useDeleteProfileSkill(id, type, false);
  const handleDeleteSpecialSkill = useDeleteProfileSkill(id, type, true);

  const handleDelete = skillType === SkillType.Custom ? handleDeleteSpecialSkill : handleDeleteSkill;

  return <ProfileSkill name={name} onDelete={handleDelete} />;
};
