import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useSnackbar } from 'hooks';
import { profileProjectsGetKey, useProfileProjectDelete } from 'api/actions/profile/projects/profileProjectsActions';

export const useDeleteProfileProject = (profileProjectId: string, onSuccess: VoidFunction) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError } = useProfileProjectDelete({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries([profileProjectsGetKey, `/profiles/${user?.id}/projects`]);
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error),
  });

  const deleteProfileProject = useCallback(async () => {
    await mutateAsync({ profileId: user?.id, profileProjectId });
  }, [mutateAsync, profileProjectId, user?.id]);

  return { deleteProfileProject, isLoading, isError };
};
