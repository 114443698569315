import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse, ErrorData } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';

import {
  DeleteProfileEducationMutationArgs,
  EducationGetResponse,
  PatchEducationMutationArgs,
  PostEducationMutationArgs,
  School,
} from './profileEducationActions.types';

export const useEducationGet = (profileId?: string, config?: AxiosRequestConfig) =>
  useQuery<EducationGetResponse>(
    `profiles/${profileId}/educations`,
    `profiles/${profileId}/educations`,
    undefined,
    config,
  );

export const useEducationDelete = (
  options: UseMutationOptions<ClientResponse, ClientErrorResponse, DeleteProfileEducationMutationArgs>,
) =>
  useMutation(
    'deleteProfileEducation',
    ({ profileId, educationId }) => ({
      endpoint: `/profiles/${profileId}/education/${educationId}`,
      method: 'DELETE',
    }),
    options,
  );

export const useEducationPost = (
  options: UseMutationOptions<ClientResponse<School>, ClientErrorResponse, PostEducationMutationArgs>,
) =>
  useMutation<School, ErrorData, { profileId: string; body: object }>(
    'postEducation',
    ({ profileId, body }) => ({
      endpoint: `/profiles/${profileId}/education`,
      method: 'POST',
      params: body,
    }),
    options,
  );
export const useEducationPatch = (
  options: UseMutationOptions<ClientResponse<School>, ClientErrorResponse, PatchEducationMutationArgs>,
) =>
  useMutation<School, ErrorData, { profileId: string; profileEducationId: string; body: object }>(
    'patchEducation',
    ({ profileId, profileEducationId, body }) => ({
      endpoint: `/profiles/${profileId}/education/${profileEducationId}`,
      method: 'PATCH',
      params: body,
    }),
    options,
  );
