import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse, MutationContext } from '../../types/types';
import { useMutation, useQuery } from 'hooks';

import {
  GetResumeDataArgs,
  GetResumeResponse,
  PostResumeVariantBody,
  PostResumeVariantResponse,
} from './resumeActions.types';

export const useResumeVariantPost = (
  options: UseMutationOptions<
    ClientResponse<PostResumeVariantResponse>,
    ClientErrorResponse,
    PostResumeVariantBody,
    MutationContext<PostResumeVariantResponse>
  >,
) =>
  useMutation(
    'postResumeVariant',
    ({ id, ...rest }) => ({
      endpoint: `/profiles/${id}/variant`,
      method: 'POST',
      params: rest,
    }),
    options,
  );

export const getResumeVariantQueryKey = 'resumeVariantDetail';

export const useResumeDataGet = ({ userId, variantId, config }: GetResumeDataArgs) =>
  useQuery<GetResumeResponse>(
    `/profiles/profile/${userId}/resume-details${variantId ? '/' + variantId : ''}`,
    [getResumeVariantQueryKey, variantId],
    { refetchOnMount: 'always', keepPreviousData: true },
    config,
  );
