import { CellContext } from '@tanstack/react-table';

import { ActionsWrapper, DeleteButton, EditButton } from 'ui';
import { useModal } from 'hooks';
import { ProjectDeletionModalContainer } from '../../modals/projectDeletionModal/ProjectDeletionModalContainer';
import { ProjectModificationModalContainer } from '../../modals/projectModificationModal/ProjectModificationModalContainer';
import { Project } from 'api/actions/projects/projectsActions.types';

import * as S from './ProjectsTableActions.styles';

export const ProjectsTableActions = (cell: CellContext<Project, string>) => {
  const {
    isOpen: isEditProjectModalOpen,
    onOpen: onEditProjectModalOpen,
    onClose: onEditProjectModalClose,
  } = useModal();

  const {
    isOpen: isDeleteProjectModalOpen,
    onOpen: onDeleteProjectModalOpen,
    onClose: onDeleteProjectModalClose,
  } = useModal();

  return (
    <>
      <ActionsWrapper sx={S.buttonsWrapper}>
        <EditButton onClick={onEditProjectModalOpen} />
        <DeleteButton onClick={onDeleteProjectModalOpen} />
      </ActionsWrapper>

      {isDeleteProjectModalOpen && (
        <ProjectDeletionModalContainer
          projectId={cell.getValue()}
          projectName={cell.row.original.name}
          isOpen={isDeleteProjectModalOpen}
          onClose={onDeleteProjectModalClose}
        />
      )}

      {isEditProjectModalOpen && (
        <ProjectModificationModalContainer
          isOpen={isEditProjectModalOpen}
          onClose={onEditProjectModalClose}
          project={cell.row.original}
        />
      )}
    </>
  );
};
