import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { format, parseISO, isAfter } from 'date-fns';
import { componentsLibTheme as theme } from 'lux/theme';

import { ProfileCertificate } from 'api/actions/certificates/certificatesActions.types';
import { ExpireDate } from '../components/expireDate/ExpireDate';
import { Actions } from '../components/actions/Actions';
export const useProfilesCertificatesColumns = () => {
  const columnHelper = createColumnHelper<ProfileCertificate>();

  return useMemo(
    () =>
      [
        columnHelper.accessor((row) => `${row.profile.firstName} ${row.profile.lastName}`, {
          id: 'name',
          header: () => <FormattedMessage id="profilesCertificates.table.headers.name" />,
          enableColumnFilter: false,
        }),

        columnHelper.accessor('certificate.name', {
          id: 'certificate',
          header: () => <FormattedMessage id="profilesCertificates.table.headers.certificate" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('obtainedAt', {
          id: 'obtainedAt',
          header: () => <FormattedMessage id="profilesCertificates.table.headers.obtainedAt" />,
          cell: (props) => <>{format(parseISO(props.getValue()), 'yyyy-LL-dd')}</>,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('expiredAt', {
          id: 'expiredAt',
          header: () => <FormattedMessage id="profilesCertificates.table.headers.expiredAt" />,
          cell: (props) => <ExpireDate expiredAt={props.getValue()} />,
          enableColumnFilter: false,
          meta: {
            getStyles: (row) => {
              const isExpired = isAfter(new Date(), parseISO(row.getValue('expiredAt')));

              return isExpired ? { color: theme.palette.error.main } : {};
            },
          },
        }),
        columnHelper.display({
          id: 'actions',
          header: () => <FormattedMessage id="profilesCertificates.table.headers.actions" />,
          cell: (props) => (
            <Actions certificateName={props.row.original.certificate.name} profileId={props.row.original.profileId} />
          ),
        }),
      ] as ColumnDef<ProfileCertificate>[],
    [columnHelper],
  );
};
