import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';
import { AdminSectionWrapper } from 'ui';

import { ProfilesCertificates } from './ProfilesCertificates';

export const ProfilesCertificatesContainer = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'navigator.profilesCertificates' })}</title>
      </Helmet>
      <AdminSectionWrapper title="navigator.profilesCertificates" tabs="certificatesTabs">
        <ProfilesCertificates />
      </AdminSectionWrapper>
    </>
  );
};
