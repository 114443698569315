import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useLocale } from 'hooks';
import { usePostProfileCertificate } from '../../hooks/usePostProfileCertificate';
import { CertificateModal } from '../certificateModal/CertificateModal';
import { CertificateModalFormValues } from '../certificateModal/CertificateModal.types';
import { defaultValues, getFormSchema } from '../certificateModal/CertificateModal.utils';

import { AddCertificateModalProps } from './AddCertificateModal.types';

export const AddCertificateModal = ({ isOpen, onModalClose, certificatesOptions }: AddCertificateModalProps) => {
  const { formatMessage } = useLocale();
  const formMethods = useForm<CertificateModalFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { addProfileCertificate, isLoading } = usePostProfileCertificate(() => {
    onModalClose();
    formMethods.reset();
  });

  return (
    <CertificateModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      formMethods={formMethods}
      onSubmit={addProfileCertificate}
      isLoading={isLoading}
      certificatesOptions={certificatesOptions}
      submitLabel={formatMessage({ id: 'form.save' })}
    />
  );
};
