import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect } from 'react';

import { EditProfileModalContainerProps } from 'views/adminSection/profiles/modals/editProfileModal/EditProfileModalContainer.types';
import { ProfileModalContainer } from '../profileModal/ProfileModalContainer';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { getQueryParams } from '../../Profiles.utils';
import { getDefaultValues, getFormSchema } from '../profileModal/ProfileModal.utils';
import { useCurrentUser, useLocale } from 'hooks';
import { ProfileModalFormValues } from 'views/adminSection/profiles/modals/profileModal/ProfileModal.types';
import { useProfilePatch } from '../../hooks/useProfilePatch/useProfilePatch';

export const EditProfileModalContainer = ({ onModalClose, isOpen }: EditProfileModalContainerProps) => {
  const { formatMessage } = useLocale();
  const { user } = useCurrentUser();

  const formMethods = useForm<ProfileModalFormValues>({
    defaultValues: getDefaultValues(user),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { reset, handleSubmit } = formMethods;

  const { updateProfile, isLoading, error } = useProfilePatch(getQueryParams(), onModalClose);

  const submit = async (data: ProfileModalFormValues) => {
    if (user) {
      await updateProfile(user.id, data);
    }
  };

  useEffect(() => {
    reset(getDefaultValues(user));
  }, [reset, isOpen]);

  return (
    <ProfileModalContainer
      isOpen={isOpen}
      isLoading={isLoading}
      formMode={FormMode.edit}
      formMethods={formMethods}
      onClose={onModalClose}
      onSubmit={handleSubmit(submit)}
      header="profiles.form.editProfile"
      error={error}
    />
  );
};
