import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { deleteSeniorityMutation, seniorityQueryKey } from 'api/actions/seniorities/senioritiesActions';

export const useDeleteSeniority = (seniorityId: string) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, error, reset } = useMutation('deleteSeniority', deleteSeniorityMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(seniorityQueryKey);
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error),
  });

  const removeSeniority = useCallback(async () => {
    await mutateAsync({ seniorityId });
  }, [mutateAsync, seniorityId]);

  return { removeSeniority, isError, error, reset };
};
