import { useDeleteProfileCertificate } from '../hooks/useDeleteProfileCertificate';

import { ProfileCertificate } from './ProfileCertificate';
import { ProfileCertificateContainerProps } from './ProfileCertificate.types';

export const ProfileCertificateContainer = ({
  profileCertificate,
  certificatesOptions,
}: ProfileCertificateContainerProps) => {
  const { deleteProfileCertificate } = useDeleteProfileCertificate(profileCertificate.id);

  return (
    <ProfileCertificate
      profileCertificate={profileCertificate}
      certificatesOptions={certificatesOptions}
      onRemoveButtonClick={deleteProfileCertificate}
    />
  );
};
