import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect } from 'react';

import { Button, FormInputText } from 'ui';
import { useLocale, useSetFormError } from 'hooks';

import * as S from './SkillsOrderEditor.styles';
import {
  SkillsOrderEditorFormFields,
  SkillsOrderEditorFormValues,
  SkillsOrderEditorProps,
} from './SkillsOrderEditor.types';
import { getFormSchema, jsonStringify } from './SkillsOrderEditor.utils';

export const SkillsOrderEditor = ({ skillsOrderData, onSubmit, error }: SkillsOrderEditorProps) => {
  const { formatMessage } = useLocale();
  const { setFormError } = useSetFormError();

  const formMethods = useForm<SkillsOrderEditorFormValues>({
    defaultValues: { skillsOrder: jsonStringify(skillsOrderData.skillsOrder) },
    resolver: joiResolver(getFormSchema(formatMessage)),
  });
  const { setError, setValue } = formMethods;

  const handleSubmit = (data: SkillsOrderEditorFormValues) => {
    setValue(SkillsOrderEditorFormFields.SkillsOrder, jsonStringify(data.skillsOrder));
    onSubmit({
      skillsOrder: data.skillsOrder,
      id: skillsOrderData.id,
    });
  };

  useEffect(() => {
    setFormError<SkillsOrderEditorFormValues>(setError, error, SkillsOrderEditorFormFields, 'skillsOrder');
  }, [setFormError, setError, error]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <FormInputText
          placeholder={'skillsOrderEditor.form.placeholder'}
          sx={S.textfield}
          name={SkillsOrderEditorFormFields.SkillsOrder}
          multiline
        />
        <Button sx={S.button} label="form.save" type="submit" />
      </form>
    </FormProvider>
  );
};
