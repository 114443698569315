import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { Button, FormDatePicker } from 'ui';
import { useCurrentUser, useLocale } from 'hooks';
import { nextButtonStyles } from '../../ProfileEditor.styles';
import { DateFormats } from 'ui/formDatePicker/FormDatePicker.types';
import { RichTextEditor } from 'ui';
import { CurrentlyEditedUser } from '../../components/currentlyEditedUser/CurrentlyEditedUser';
import { Section } from '../../components/section/Section';

import { BasicDataFormFields, BasicDataFormValues, BasicDataProps } from './BasicData.types';
import { getDefaultValues, getFormSchema } from './BasicData.utils';

export const BasicData = ({ isPostLoading, onSubmit }: BasicDataProps) => {
  const { formatMessage } = useLocale();
  const { user } = useCurrentUser();

  const formMethods = useForm<BasicDataFormValues>({
    defaultValues: getDefaultValues(user),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit } = formMethods;

  const submit = async (data: BasicDataFormValues) => {
    if (user) {
      await onSubmit(user.id, data);
    }
  };

  return (
    <>
      <CurrentlyEditedUser />
      <FormProvider {...formMethods}>
        <form>
          <Section>
            <FormDatePicker
              placeholder="form.dateFieldPlaceholder"
              name={BasicDataFormFields.CareerStart}
              label="form.careerStart"
              maxDate={new Date()}
              views={['year', 'month']}
              dataTest="inputCareerStart"
              dateFormat={DateFormats.yyyyMM}
            />
          </Section>
          <Section title="step.subTitle.aboutMe" subtitle="step.description.aboutMe">
            <RichTextEditor
              name={BasicDataFormFields.About}
              placeholder="form.about"
              initialValue={user?.about || ''}
              minHeight={145}
            />
          </Section>
          <Button
            dataTestId="nextButton"
            label="form.next"
            sx={nextButtonStyles}
            onClick={handleSubmit((values) => submit(values))}
            disabled={isPostLoading}
          />
        </form>
      </FormProvider>
    </>
  );
};
