import { Styles } from 'lux/theme';

export const windowStyles: Styles = {
  position: 'relative',
  bgcolor: 'surface.default',
  p: 3,
  maxWidth: '600px',
  margin: 'auto',
  width: '100%',
  borderRadius: 1,
};

export const windowWideStyles: Styles = {
  ...windowStyles,
  maxWidth: '1200px',
};

export const closeButtonStyles: Styles = {
  position: 'absolute',
  top: 10,
  right: 10,
};
