import { Box, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';
import { Checkbox } from 'lux/components';
import { CheckboxProps } from 'lux/components/atoms/checkbox/Checkbox.types';

import { useLocale } from 'hooks';

import * as S from './FormCheckbox.styles';

type FormCheckboxProps = {
  name?: string;
  label: string;
} & CheckboxProps;

export const FormCheckbox = ({ name, label, ...props }: FormCheckboxProps) => {
  const { formatMessage } = useLocale();

  const styles = {
    ...S.checkboxWrapperStyles,
    ...props.sx,
  };

  return (
    <Controller
      name={name ?? ''}
      render={({ field: { onChange, value } }) => (
        <Box sx={styles}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={onChange}
                checked={value}
                {...props}
                inputProps={
                  {
                    ...props.inputProps,
                    'data-testid': name || 'formCheckbox',
                  } as InputHTMLAttributes<HTMLInputElement>
                }
              />
            }
            label={formatMessage({ id: label })}
            aria-label={formatMessage({ id: label })}
          />
        </Box>
      )}
    />
  );
};
