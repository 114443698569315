import { Styles } from 'lux/theme';

export const editButtonStyles: Styles = {
  mr: 1,
};

export const actionButtons: Styles = {
  minWidth: '90px',
  ml: 'auto',
};
