import { Styles } from 'lux/theme';

export const stepStyles: Styles = {
  padding: '0 8px',
};

export const nextButtonStyles: Styles = {
  width: '100%',
};

export const sidebar: Styles = {
  height: 'inherit',
  overflow: 'visible',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const sidebarBox: Styles = {
  height: '100%',
};

export const stickyWhileScrolling: Styles = ({ spacing }) => ({
  position: 'sticky',
  top: spacing(10),
  marginTop: 1,
});

export const backButtonBottom: Styles = ({ spacing }) => ({
  mt: spacing(2),
  width: 'fit-content',
});
