import { Styles } from 'lux/theme';

export const submenu: Styles = {
  '& a': {
    textDecoration: 'inherit',
    color: 'text.primary',
  },
  '& ul': {
    minWidth: '220px',
  },
};
