import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser, useSnackbar } from 'hooks';
import { useTechnologiesSurveyPost } from 'api/actions/technologiesSurvey/technologiesSurveyActions';
import { AppRoute } from 'routing/AppRoute.enum';

import { TechnologiesSurveyModalFormValues } from './usePostTechnologiesSurvey.types';

export const usePostTechnologiesSurvey = () => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const TechnologiesSurveyQueryKey = `profiles/${user?.id}/skills-survey`;

  const { mutateAsync, isError, isLoading, error } = useTechnologiesSurveyPost({
    onSuccess: () => {
      queryClient.invalidateQueries(TechnologiesSurveyQueryKey);
      showSnackbarSuccess('technologiesSurvey.form.formSaved');
      navigate(AppRoute.Home);
    },
    onError: (error) => showSnackbarError(error, [400]),
  });

  const addTechnologiesSurvey = useCallback(
    async (body: TechnologiesSurveyModalFormValues) => {
      const items = body.skills.map(({ skillId, familiarity, preference }) => ({
        skillId,
        familiarity,
        preference,
      }));

      return await mutateAsync({
        profileId: user?.id,
        body: {
          items,
        },
      });
    },
    [mutateAsync, user?.id],
  );

  return { addTechnologiesSurvey, isError, isLoading, error };
};
