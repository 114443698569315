import { Box, Link, Paper } from '@mui/material';
import { InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';

import Logo from 'assets/images/logo.svg';
import { Button } from 'ui';
import { AppRoute } from 'routing/AppRoute.enum';

import * as S from './Login.styles';

export const Login = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const initializeSignIn = () => {
    if (accounts.length === 0) {
      instance.loginRedirect();
    }
  };

  if (!isAuthenticated && inProgress === InteractionType.None) {
    return (
      <Box sx={S.containerStyles}>
        <Paper sx={S.wrapperStyles}>
          <Box component="img" src={Logo} alt="" sx={S.logoStyles} />
          <Link onClick={initializeSignIn} underline="none" sx={S.linkStyles}>
            <Button label="login.microsoft" fullWidth />
          </Link>
        </Paper>
      </Box>
    );
  }

  return <Navigate to={AppRoute.Home} />;
};
