import { GLOBAL_FILTER_URL_QUERY_KEY } from './globalFilter.types';

export const globalFilterParamEncode = (globalFilter: string): { [key: string]: string } => {
  if (!globalFilter.length) {
    return {};
  }

  return {
    [GLOBAL_FILTER_URL_QUERY_KEY]: globalFilter,
  };
};
