import { useCurrentUser } from 'hooks';
import { useProfileProjectTechnologiesGet } from 'api/actions/profile/projects/technologies/profileProjectsTechnologiesActions';

export const useProfileProjectTechnologiesQuery = (profileProjectId: string) => {
  const { user } = useCurrentUser();

  return useProfileProjectTechnologiesGet(user?.id ?? '', profileProjectId, {
    params: {
      relations: ['profileProjectSkill.skill'],
    },
  });
};
