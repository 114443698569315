import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { adminCategoriesQueryKey, deleteCategoryMutation } from 'api/actions/categories/categoriesActions';

export const useDeleteCategory = (categoryId: string) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, error, reset } = useMutation('deleteCategory', deleteCategoryMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(adminCategoriesQueryKey);
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error),
  });

  const deleteCategory = useCallback(async () => {
    await mutateAsync({ categoryId });
  }, [mutateAsync, categoryId]);

  return { deleteCategory, isError, error, reset };
};
