import { Navigate, Outlet } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { Loader } from 'ui';
import { useAuth } from 'hooks';

import { AppRoute } from './AppRoute.enum';

export const AuthorizedRoute = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const { accessToken } = useAuth();

  if (inProgress !== InteractionType.None || (isAuthenticated && !accessToken)) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to={AppRoute.Login} />;
};
