import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { PostProjectBody, ProjectsGetResponse } from 'api/actions/projects/projectsActions.types';
import { projectsGetQueryKey, useProjectsPost } from 'api/actions/projects/projectsActions';
import { useSnackbar } from 'hooks';

export const useProjectPost = (onSuccess: VoidFunction) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, isLoading, error } = useProjectsPost({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries<ProjectsGetResponse>([projectsGetQueryKey]);
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const addProject = useCallback(
    async (body: PostProjectBody) => {
      await mutateAsync({
        body: {
          ...body,
          technologies: body.technologies.map(({ id, name }) => (id === name ? { name } : { id })),
        },
      });
    },
    [mutateAsync],
  );

  return { addProject, isError, isLoading, error };
};
