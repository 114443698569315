import { StepsRoutesNames } from 'views/profileEditor/ProfileEditor.types';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { AppRoute } from 'routing/AppRoute.enum';

import { MenuItemDefinition } from './MenuDefinitions.types';

export const userProfilesNavLinks: MenuItemDefinition[] = [
  { to: `${AppRoute.ProfileEditor}/${StepsRoutesNames[0]}`, label: 'profileEditor' },
  { to: AppRoute.TechnologiesSurvey, label: 'technologiesSurvey' },
];

export const adminProfilesSubMenu: MenuItemDefinition[] = [
  { to: AppRoute.Profiles, label: 'profiles', contentType: ContentType.Profiles },
  { to: AppRoute.Positions, label: 'positions', contentType: ContentType.Positions },
  { to: AppRoute.Seniority, label: 'seniorities', contentType: ContentType.Seniority },
  { to: AppRoute.Languages, label: 'languages', contentType: ContentType.Languages },
];

export const adminSkillsSubMenu: MenuItemDefinition[] = [
  { to: AppRoute.Skills, label: 'skills', contentType: ContentType.Skills },
  {
    to: AppRoute.SkillsOrderEditor,
    label: 'skillsOrderEditor',
    contentType: ContentType.SkillsOrderEditor,
  },
  {
    to: AppRoute.TechnologyCategories,
    label: 'categories',
    contentType: ContentType.Categories,
  },
  {
    to: AppRoute.TechnologyCategoriesOrder,
    label: 'categoriesOrder',
    contentType: ContentType.Categories,
  },
];

export const adminProjectsSubMenu: MenuItemDefinition[] = [
  { to: AppRoute.Projects, label: 'projects', contentType: ContentType.Projects },
  { to: AppRoute.ProjectRoles, label: 'projectRoles', contentType: ContentType.ProjectRoles },
];

export const adminCertificatesSubMenu: MenuItemDefinition[] = [
  {
    to: AppRoute.Certificates,
    label: 'certificates',
    contentType: ContentType.Certificates,
  },
  {
    to: AppRoute.ProfileCertificates,
    label: 'profilesCertificates',
    contentType: ContentType.ProfileCertificates,
  },
  { to: AppRoute.Tags, label: 'tags', contentType: ContentType.Tags },
];
