import { AxiosRequestConfig } from 'axios';

import { useQuery } from 'hooks';
import { ProjectRolesGetResponse } from 'api/actions/projectRoles/projectRolesActions.types';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

export const getProjectRolesTableKey = 'project-roles-table';

export const useProjectRolesQuery = (config?: AxiosRequestConfig) => {
  return useQuery<ProjectRolesGetResponse>(
    '/roles',
    [getProjectRolesTableKey, config],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );
};
