import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Button, Grid } from 'lux/components';
import { Link } from 'react-router-dom';

import { AccessControl, Typography } from 'ui';
import { StepsRoutesNames } from 'views/profileEditor/ProfileEditor.types';
import logoForAdmin from 'assets/images/search-people.svg';
import logoForUsers from 'assets/images/relax-people.svg';
import { AppRoute } from 'routing/AppRoute.enum';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { useContentAccess, useLocale, useUser } from 'hooks';

import * as S from './Home.styles';

export const Home = () => {
  const { user } = useUser();
  const { isAllowed: isAllowedHomeAdmin } = useContentAccess({ allowedContent: ContentType.HomeAdmin });
  const { formatMessage } = useLocale();

  if (isAllowedHomeAdmin) {
    return (
      <Grid item xs={6} sx={S.gridWrapper}>
        <Box sx={S.logoStyles} component="img" src={logoForAdmin} alt="Logo" />
        <Typography variant="h6">{'home.welcome'}</Typography>
        <AccessControl allowedContent={ContentType.Profiles}>
          <>
            <Typography sx={S.subtitle} variant="subtitle1">
              {'home.makeAnAction'}
            </Typography>
            <Button component={Link} to={AppRoute.Profiles}>
              <FormattedMessage id="home.buttonLabel" />
            </Button>
          </>
        </AccessControl>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={6} sx={S.gridWrapper}>
        <Box sx={S.logoStyles} component="img" src={logoForUsers} alt="Logo" />
        <Typography variant="h6">{formatMessage({ id: 'home.welcomeUser' }, { name: user?.firstName })}</Typography>
        <Typography sx={S.subtitleUser} variant="subtitle1">
          {'home.makeAnActionUser'}
        </Typography>
        <Typography sx={S.subtitle} variant="subtitle1">
          {'home.makeAnActionUserLong'}
        </Typography>
        <Button sx={S.userButton} component={Link} to={`${AppRoute.ProfileEditor}/${StepsRoutesNames[0]}`}>
          <FormattedMessage id="home.editYourProfile" />
        </Button>
        <Button component={Link} target={user?.id} to={AppRoute.GeneratedProfile} data-testid={'preview'}>
          <FormattedMessage id="home.previewYourCv" />
        </Button>
      </Grid>
    );
  }
};
