import { IPublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { authStorage } from 'context/auth/authStorage/AuthStorage';

export const responseSuccessInterceptor = (response: AxiosResponse) => response;

export const responseFailureInterceptor = (msalInstance: IPublicClientApplication) => async (error: AxiosError) => {
  const originalRequest = error.config;

  if (error.response?.status === 401 && originalRequest._retry) {
    authStorage.accessToken = null;
    authStorage.expires = null;
    authStorage.refreshToken = null;

    window.location.replace('/login');

    return Promise.reject(error);
  }

  if (error.response?.status === 401) {
    originalRequest._retry = true;

    try {
      const account = msalInstance.getActiveAccount();

      const result = await msalInstance.acquireTokenSilent({
        scopes: [`${import.meta.env.VITE_AZURE_CLIENT_ID}/.default`],
        account: account || undefined,
      });

      authStorage.accessToken = result.accessToken;

      return axios(originalRequest);
    } catch (e) {
      console.error('Token acquisition failed:', e);
      authStorage.accessToken = null;
      msalInstance.setActiveAccount(null);
      window.location.replace('/login');

      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
};
