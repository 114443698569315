import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { SkillsOrderEditorFormFields } from './SkillsOrderEditor.types';

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  return Joi.object({
    [SkillsOrderEditorFormFields.SkillsOrder]: Joi.custom((value) => {
      if (typeof JSON.parse(value) !== 'object') {
        throw new Error();
      }

      return JSON.parse(value);
    })
      .label(formatMessage({ id: 'skillsOrderEditor.form.editor' }))
      .messages({ 'any.custom': formatMessage({ id: 'skillsOrderEditor.form.error' }) }),
  });
};

export const jsonStringify = (data: string | Record<string, unknown>) => JSON.stringify(data, undefined, 2);

export const SKILLS_ORDER_GLOBAL_KEY = 'Global';
