import { useCallback, useMemo } from 'react';
import Axios, { AxiosRequestConfig } from 'axios';
import { MutationFunction, QueryFunction } from 'react-query';
import { IPublicClientApplication } from '@azure/msal-browser';

import { ApiClientContextValue } from 'context/client/clientContext/ClientContext.types';
import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { ClientResponse } from '../../api/types/types';

import { responseFailureInterceptor, responseSuccessInterceptor } from './interceptors/responseInterceptors';
import { requestSuccessInterceptor } from './interceptors/requestInterceptors';

export const useAxiosStrategy = (msalInstance: IPublicClientApplication): ApiClientContextValue => {
  const client = useMemo(() => {
    const axios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: `${import.meta.env.VITE_API_URL}`,
    });

    axios.interceptors.request.use(requestSuccessInterceptor);
    axios.interceptors.response.use(responseSuccessInterceptor, responseFailureInterceptor(msalInstance));

    return axios;
  }, []);

  const queryFn = useCallback(
    <TData>(url: unknown, config?: AxiosRequestConfig): QueryFunction<TData> =>
      async () => {
        if (typeof url === 'string') {
          const lowerCaseUrl = url.toLowerCase();
          const { data } = await client.get(`${lowerCaseUrl}`, config);

          return data;
        }
        throw new Error('Invalid QueryKey');
      },
    [client],
  );

  const mutationFn = useCallback(
    <TParams = unknown, TData = unknown>(
        mutation: MutationFn<TParams, TData>,
      ): MutationFunction<ClientResponse<TData>, TParams> =>
      async (variables) => {
        const { endpoint, url, method, params, ...mutationVariables } = mutation(variables);

        const axiosConfig: AxiosRequestConfig = {
          ...mutationVariables,
          url: endpoint ?? url,
          method: method || 'POST',
          [method === 'GET' ? 'params' : 'data']: params,
        };

        return await client.request(axiosConfig);
      },
    [client],
  );

  return {
    queryFn,
    mutationFn,
    axios: client,
  };
};
