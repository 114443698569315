import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useState } from 'react';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from 'lexical';
import { mergeRegister, $getNearestNodeOfType } from '@lexical/utils';
import { INSERT_UNORDERED_LIST_COMMAND, $isListNode, ListNode, REMOVE_LIST_COMMAND } from '@lexical/list';
import { Box, IconButton } from '@mui/material';
import { FormatBold, FormatListBulleted } from '@mui/icons-material';

import * as S from './ToolbarPlugin.styles';

export const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          setBlockType(element.getType());
        }
      }
      setIsBold(selection.hasFormat('bold'));
    }
  }, [editor]);

  const handleBold = () => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
  };

  const handleBulletList = () => {
    editor.focus(() => {
      editor.dispatchCommand(blockType !== 'ul' ? INSERT_UNORDERED_LIST_COMMAND : REMOVE_LIST_COMMAND, undefined);
    });
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [editor, updateToolbar]);

  return (
    <Box sx={S.toolbar} className="toolbar">
      <IconButton
        disableRipple
        sx={S.toolbarItem}
        onClick={handleBold}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        aria-label="Format Bold"
        data-testid="formatBold"
      >
        <FormatBold sx={S.toolbarIcon} className="icon" />
      </IconButton>
      <IconButton
        disableRipple
        sx={S.toolbarItem}
        onClick={handleBulletList}
        className={'toolbar-item spaced ' + (blockType === 'ul' ? 'active' : '')}
        aria-label="Format Bullet list"
        data-testid="formatBulletList"
      >
        <FormatListBulleted sx={S.toolbarIcon} className="icon" />
      </IconButton>
    </Box>
  );
};
