import { FormProvider } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';

import { Button, FormSelect, Modal, Separator, Typography, ModalButtonsWrapper } from 'ui';
import { useAppSettings } from 'hooks/useAppSettings/useAppSettings';
import { usePrompt, useSetFormError } from 'hooks';

import { LanguageModalFormFields, LanguageModalFormValues } from './LanguageModal.types';
import { LanguageModalProps } from './LanguageModal.types';

export const LanguageModal = <TResponse extends Record<string, unknown>>({
  languageOptions,
  onModalClose,
  onSubmit,
  isOpen,
  formMethods,
  submitLabel,
  isLoading,
  editedLanguage,
  error,
}: LanguageModalProps<TResponse>) => {
  const extendedLanguageOptions = editedLanguage ? [editedLanguage, ...languageOptions] : languageOptions;

  const { languageOptionsTranslated } = useAppSettings();
  const { setFormError } = useSetFormError();

  const { setError } = formMethods;

  useEffect(() => {
    setFormError<LanguageModalFormValues>(setError, error, LanguageModalFormFields);
  }, [setError, setFormError, error]);

  usePrompt({ when: formMethods.formState.isDirty });

  return (
    <Modal dataTestId="languageModal" isOpen={isOpen} onModalClose={onModalClose}>
      <Typography variant="h3">{'form.language'}</Typography>
      <Separator height={10} />
      <Typography variant="body1">{'form.language.subTitle'}</Typography>
      <Separator />

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <FormSelect
            name={LanguageModalFormFields.LanguageId}
            options={extendedLanguageOptions}
            label="form.language"
            required
          />
          <FormSelect
            name={LanguageModalFormFields.Proficiency}
            options={languageOptionsTranslated || []}
            label="form.proficiency"
            required
          />
          <Separator />

          <ModalButtonsWrapper>
            <Button label="form.cancel" onClick={onModalClose} dataTestId="cancel" variant="outlined" />
            <LoadingButton data-testid="submit" loading={isLoading} variant="contained" type="submit">
              {submitLabel}
            </LoadingButton>
          </ModalButtonsWrapper>
        </form>
      </FormProvider>
    </Modal>
  );
};
