import { Styles } from 'views/generatedProfile/theme/theme';

export const projectWrapper: Styles = ({ palette }) => ({
  pt: 3,
  pb: 2.25,
  pr: 5,
  borderBottom: `1px solid ${palette.grey[500]}`,

  '&:last-child': {
    borderBottom: 0,
  },
});

export const title: Styles = ({ spacing }) => ({
  fontSize: spacing(2.25),
  fontWeight: 600,
});

export const date: Styles = ({ spacing }) => ({
  fontSize: spacing(1.25),
  color: 'text.secondary',
  mb: 1,
});

export const techSkillsWrapper: Styles = {
  marginBottom: 1,
};

export const role: Styles = ({ spacing }) => ({
  fontSize: spacing(1.625),
  fontWeight: 500,
  mb: 1,
});

export const shortDescription: Styles = ({ spacing }) => ({
  fontSize: spacing(1.825),
  fontWeight: 500,
  mb: 1,
});

export const description: Styles = ({ spacing }) => ({
  fontSize: spacing(1.65),
  mb: 1.25,
});

export const additionalDescription: Styles = ({ spacing }) => ({
  fontSize: spacing(1.65),
  mb: 1.25,
  paddingLeft: spacing(1.65),
});

export const myDuties: Styles = ({ spacing }) => ({
  fontSize: spacing(1.625),
  paddingLeft: spacing(1.65),
  fontWeight: 500,
});
