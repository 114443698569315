import { useDeleteSeniority } from '../hooks/useDeleteSeniority';

import { Seniority } from './Seniority';
import { SeniorityContainerProps } from './Seniority.types';

export const SeniorityContainer = ({ seniority, dragAndDropIndex, disabled }: SeniorityContainerProps) => {
  const { removeSeniority } = useDeleteSeniority(seniority.id);

  return (
    <Seniority
      disabled={disabled}
      dragAndDropIndex={dragAndDropIndex}
      seniority={seniority}
      onRemoveButtonClick={removeSeniority}
    />
  );
};
