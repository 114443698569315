import { Box } from '@mui/material';

import * as styles from './Tags.styles';
import { TagsProps } from './Tags.types';

export const Tags = ({ tags }: TagsProps) => (
  <Box sx={styles.wrapper}>
    {tags.map(({ name }) => (
      <Box key={name} component="span" sx={styles.tag}>
        {name}
      </Box>
    ))}
  </Box>
);
