export enum Steps {
  BasicData = 0,
  Skills = 1,
  Experience = 2,
  Summary = 3,
}

export enum StepsRoutesNames {
  'basic-data',
  'skills',
  'experience',
  'summary',
}

export type ProfileEditorProps = {
  activeStep: number;
  setActiveStep: (step: Steps) => void;
};
