import { Item, MutationUpdaterData } from './mutationUpdater.types';
import { generateUpdatedData } from './mutationUpdater.utils';

export const mutationUpdater = <TDataResult extends Item>(
  method: 'POST' | 'PATCH' | 'DELETE',
  itemOrId?: TDataResult | string,
  dataToUpdate?: MutationUpdaterData<TDataResult>,
): MutationUpdaterData<TDataResult> => {
  if (!dataToUpdate || !Array.isArray(dataToUpdate?.result)) {
    throw new Error('Data is not an array type or is missing');
  }
  if (!itemOrId) {
    throw new Error('Item is missing');
  }

  return generateUpdatedData(method, itemOrId, dataToUpdate);
};
