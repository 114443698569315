import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { languagesQueryKey, useLanguageDelete as useLanguageDeleteHook } from 'api/actions/languages/languagesActions';
import { useSnackbar } from 'hooks';

export const useDeleteLanguage = () => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, error, reset } = useLanguageDeleteHook({
    onSuccess: () => {
      showSnackbarSuccess('success.itemDeleted');
      queryClient.invalidateQueries([languagesQueryKey]);
    },
    onError: (error) => showSnackbarError(error),
  });

  const deleteLanguage = useCallback(
    async (languageId: string) => {
      await mutateAsync({ languageId });
    },
    [mutateAsync],
  );

  return { deleteLanguage, isError, error, reset };
};
