import { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { generatedProfileTheme } from './theme/theme';
import {
  Achievements,
  AdditionalInformations,
  Certificates,
  Header,
  NotableProjects,
  OtherSkills,
  SoftSkills,
  TechSkills,
} from './content';
import { GeneratedProfileProps, SkillCategoryGroups } from './GeneratedProfile.types';
import * as S from './GeneratedProfile.styles';
import { EditorContainer } from './editor/EditorContainer';

export const GeneratedProfile = ({
  profileData,
  userId,
  isLoading,
  skillsOrder,
  isContentEditableEnabled,
}: GeneratedProfileProps) => {
  const [profileRenderData, setProfileRenderData] = useState(profileData);
  const [sortedSkills, setSortedSkills] = useState<SkillCategoryGroups>({
    skillsWithCategory: [],
    skillsWoCategory: [],
  });

  const {
    firstName,
    positionName,
    seniorityVisibleName,
    code,
    about,
    projects,
    achievements,
    softSkills,
    education,
    languages,
    startCareerAt,
    certificates,
  } = profileRenderData;

  const experienceYears = useMemo(
    () =>
      startCareerAt
        ? Number(new Date().toLocaleDateString('en-us', { year: 'numeric' })) -
          Number(new Date(startCareerAt).toLocaleDateString('en-us', { year: 'numeric' }))
        : null,
    [startCareerAt],
  );

  const { skillsWoCategory, skillsWithCategory } = sortedSkills;

  return (
    <Box sx={S.wrapper}>
      <EditorContainer
        onPositionChange={setSortedSkills}
        skillsOrder={skillsOrder}
        onFormChange={setProfileRenderData}
        isLoading={isLoading}
        defaultData={profileData}
        userId={userId}
      />
      <ThemeProvider theme={generatedProfileTheme}>
        <Box suppressContentEditableWarning contentEditable={isContentEditableEnabled} sx={S.resumeWrapper}>
          <Header
            firstName={firstName}
            positionName={positionName}
            seniorityVisibleName={seniorityVisibleName}
            code={code}
            about={about}
          />

          <Box sx={S.mainPart}>
            <Box sx={S.leftPart}>
              {!!achievements.length && <Achievements achievements={achievements} />}
              {!!projects.length && <NotableProjects projects={projects} />}
              {!!softSkills.length && <SoftSkills skills={softSkills} />}
              {!!certificates.length && <Certificates certificates={certificates} />}
            </Box>

            <Box sx={S.rightPart}>
              <AdditionalInformations education={education} languages={languages} experienceYears={experienceYears} />

              {!!skillsWithCategory.length && <TechSkills skillsGroup={skillsWithCategory} />}
              {!!skillsWoCategory.length && <OtherSkills skillsGroup={skillsWoCategory} />}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
