import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'lux/components';
import { FormattedMessage } from 'react-intl';
import { Box, Slide } from '@mui/material';

import { ReactComponent as PreviewIcon } from 'assets/icons/zip-file-search.svg';
import { AppRoute } from 'routing/AppRoute.enum';
import { useCurrentUser } from 'hooks';
import { Typography } from 'ui';

import * as S from './Summary.styles';
import { ConfirmUpdate } from './components/confirmUpdate/ConfirmUpdate';
import { usePostProfileUpdateConfirm } from './hooks/usePostProfileUpdateConfirm';

//export const Summary = (forwardRef(({ isLoading }: SummaryProps, ref)) => {

export const Summary = () => {
  const { user } = useCurrentUser();
  //const { formatMessage } = useLocale();
  const [isChecked, setIsChecked] = useState(false);

  const {
    confirmProfileUpdate,
    isSuccess: isUpdatingSuccess,
    isLoading: isUpdatingProfile,
  } = usePostProfileUpdateConfirm({
    onError: () => {
      setIsChecked(false);
    },
  });

  const handleUpdateProfile = () => {
    if (isUpdatingSuccess || isUpdatingProfile) return;
    setIsChecked(true);
    confirmProfileUpdate();
  };

  return (
    <Box>
      <ConfirmUpdate
        isChecked={isChecked}
        onUpdateProfile={handleUpdateProfile}
        isUpadatingProfile={isUpdatingProfile}
        isUpdatingSuccess={isUpdatingSuccess}
      />

      <Slide in={isUpdatingSuccess} direction="up">
        <Box mt={5} maxWidth={590}>
          <Typography variant="h6">{'step.confirmation.cvTitle'}</Typography>
          <Typography variant="body2" mt={2}>
            {'step.confirmation.cvDescription'}
          </Typography>

          <Box display="flex" gap={2} mt={4}>
            <Link target={user?.id} to={`${AppRoute.GeneratedProfile}/${user?.id}`} data-testid={'preview'}>
              <Button sx={S.previewCVButton} variant="outlinedGray" startIcon={<PreviewIcon />}>
                <FormattedMessage id="step.confirmation.preview" />
              </Button>
            </Link>

            {/* <LoadingButton
              data-testid="downloadCv"
              loading={isLoading}
              loadingPosition="center"
              variant="outlinedGray"
              sx={S.downloadCVButton}
              loadingIndicator={<CircularProgress />}
            >
              <Box ref={ref} component="a" display="flex" alignItems="center" gap={1}>
                <DownloadIcon /> {formatMessage({ id: 'step.confirmation.download' })}
              </Box>
            </LoadingButton> */}
          </Box>
        </Box>
      </Slide>
    </Box>
  );
};
