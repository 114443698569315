import { FilterOperator } from 'utils';

export const filtersOperators: { [key: string]: FilterOperator } = {
  visibleName: FilterOperator.includeOr,
};

export const keysMapper: { [key: string]: string } = {
  visibleName: 'position.visibleName',
  dwhCode: 'position.dwhCode',
};

export const getPositionParams = (queryParams: Record<string, string | number>) => {
  const isSortParams = Object.keys(queryParams).some((key) => key.startsWith('sort'));

  const defaultSortParams = isSortParams ? {} : { 'sort[position.visibleName]': 'ASC' };

  return {
    page: '1',
    limit: '10',
    ...defaultSortParams,
    ...queryParams,
  };
};
