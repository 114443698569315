import { useRef, useMemo, useState, HTMLAttributes, useEffect, KeyboardEvent } from 'react';
import { AutocompleteRenderOptionState, Paper, Typography } from '@mui/material';
import { AutocompleteGeneric } from 'lux/components';
import AddIcon from '@mui/icons-material/Add';

import { useLocale } from 'hooks';

import { AutocompleteOption, AutocompleteProps, AutocompleteValue } from './Autocomplete.types';
import { formatValue, normalizeValue } from './Autocomplete.utils';
import * as S from './Autocomplete.styles';

export const Autocomplete = <
  T extends AutocompleteOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  onChange,
  value,
  label,
  options,
  excludedIds = [],
  dataTestId = 'autocompleteInput',
  withAddNewButton,
  required,
  disablePortal = true,
  disabled,
  filterOptions,
  size = 'medium',
  onAddNew,
  error,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const [inputValue, setInputValue] = useState('');
  const { formatMessage } = useLocale();

  const addedNewProject = useRef(false);

  useEffect(() => {
    if (!value) {
      setInputValue('');
    }
  }, [value]);

  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => setTimeout(() => inputRef.current?.focus());

  const autocompleteOptions = useMemo(
    () => options.filter(({ id }) => !excludedIds.includes(id)),
    [options, excludedIds],
  );

  const filteredAutocompleteOptions = useMemo(
    () =>
      autocompleteOptions.filter((option) =>
        option.name?.toLowerCase()?.trim().includes(inputValue?.toLowerCase()?.trim()),
      ),
    [inputValue, autocompleteOptions],
  );

  const isOptionNameEqualToTypedInputValue = useMemo(
    () => options?.some((option) => option.name?.toLowerCase()?.trim() === inputValue?.toLowerCase()?.trim()),
    [inputValue, options],
  );

  const shouldShowAddNewButton = withAddNewButton && inputValue && !isOptionNameEqualToTypedInputValue;

  const addNewProject = () => {
    if (onAddNew) {
      onAddNew(inputValue);
      onChange(formatValue(inputValue as AutocompleteValue));
      focusInput();
      setInputValue('');
    }
  };

  const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addNewProject();
    }
  };

  return (
    <AutocompleteGeneric<T, Multiple, DisableClearable, FreeSolo>
      {...props}
      sx={{ width: '100%', pointerEvents: disabled ? 'none' : 'auto' }}
      size={size}
      disabled={disabled}
      disablePortal={disablePortal}
      autoHighlight
      options={autocompleteOptions}
      filterOptions={filterOptions ?? (() => filteredAutocompleteOptions)}
      getOptionLabel={normalizeValue}
      onChange={(_, value) => {
        addedNewProject.current = false;
        setInputValue('');
        focusInput();
        onChange(formatValue(value as AutocompleteValue));
      }}
      onBlur={() => {
        if (!addedNewProject.current) {
          setInputValue('');
        }
      }}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => {
        return (
          <AutocompleteGeneric.MenuItem {...props} selected={state.selected}>
            {option.name}
          </AutocompleteGeneric.MenuItem>
        );
      }}
      value={value}
      label={formatMessage({ id: label })}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      required={required}
      inputProps={{ 'data-testid': dataTestId }}
      error={error}
      onKeyDown={handleEnter}
      PaperComponent={(params) => {
        const { children, ...rest } = params;

        return (
          <Paper {...rest}>
            <>
              {children}
              {shouldShowAddNewButton && (
                <AutocompleteGeneric.MenuItem
                  onMouseDown={() => {
                    addNewProject();
                  }}
                  sx={S.addNewTechnologyListItem}
                >
                  <AddIcon />
                  <Typography sx={S.addNewTechnologyText}>
                    {formatMessage({ id: 'autocomplete.addNew' })}: {inputValue}
                  </Typography>
                </AutocompleteGeneric.MenuItem>
              )}
            </>
          </Paper>
        );
      }}
    />
  );
};
