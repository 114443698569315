import { AxiosRequestConfig } from 'axios';

import { useQuery } from 'hooks';

import { GetProfileProjectTechnologiesResponse } from './profileProjectsTechnologiesActions.types';

export const useProfileProjectTechnologiesGet = (
  profileId: string,
  profileProjectId: string,
  config?: AxiosRequestConfig,
) =>
  useQuery<GetProfileProjectTechnologiesResponse>(
    `/profiles/${profileId}/profile-project/${profileProjectId}/skills`,
    ['get-profile-project-skills', { details: `/profiles/${profileId}/profile-project/${profileProjectId}/skills` }],
    { cacheTime: 0 },
    config,
  );
