import { MutationFn } from 'hooks/useMutation/useMutation.types';

import {
  ProjectRolesPatchArgs,
  ProjectRolesPatchResponse,
  ProjectRolesPostArgs,
  ProjectRolesPostResponse,
  ProjectRolesDeleteArgs,
  ProjectRolesDeleteResponse,
} from './projectRolesActions.types';

export const postProjectRoleMutation: MutationFn<ProjectRolesPostArgs, ProjectRolesPostResponse> = (body) => ({
  endpoint: '/roles/role',
  method: 'POST',
  params: body,
});

export const patchProjectRolesMutation: MutationFn<ProjectRolesPatchArgs, ProjectRolesPatchResponse> = ({
  id,
  name,
}) => ({
  endpoint: `/roles/role/${id}`,
  method: 'PATCH',
  params: { name },
});

export const deleteProjectRoleMutation: MutationFn<ProjectRolesDeleteArgs, ProjectRolesDeleteResponse> = ({
  id,
  forceDelete,
}) => ({
  endpoint: `/roles/role/${id}`,
  method: 'DELETE',
  params: {
    forceDelete,
  },
});
