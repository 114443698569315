import { Styles } from 'views/generatedProfile/theme/theme';

export const wrapper: Styles = ({ spacing }) => ({
  pageBreakInside: 'avoid',
  pb: 5,

  '& ul': {
    pl: 2.25,
    pt: 2.5,
    m: 0,
  },

  '& li': {
    fontSize: spacing(1.625),
  },
});

export const title: Styles = ({ spacing }) => ({
  fontSize: spacing(2.25),
  fontWeight: 600,
});
