import { useEffect } from 'react';

import { useSetFormError } from 'hooks';
import { SkillType } from 'api/actions/skills/skillsActions.types';
import {
  SkillModalFormFields,
  SkillModalFormValues,
} from 'views/adminSection/skills/modals/skillModal/SkillModal.types';
import { SkillModalContainerProps } from 'views/adminSection/skills/modals/skillModal/SkillModalContainer.types';
import { SkillModal } from 'views/adminSection/skills/modals/skillModal/SkillModal';
import { skillTypeOptions } from 'views/adminSection/skills/modals/skillModal/SkillModal.utils';
import { useCategoriesQuery } from '../../hooks/useCategoriesQuery';

export const SkillModalContainer = ({
  skillType,
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  formMethods,
  formMode,
  error,
  header,
  category,
}: SkillModalContainerProps) => {
  const { data, isLoading: isCategoriesLoading } = useCategoriesQuery();
  const { setFormError } = useSetFormError();
  const { reset, setError, setValue } = formMethods;

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  useEffect(() => {
    setFormError<SkillModalFormValues>(setError, error, SkillModalFormFields, 'name');
  }, [setFormError, setError, error]);

  useEffect(() => {
    if (skillType !== SkillType.Tech) {
      setValue(SkillModalFormFields.CategoryId, null);
    }
  }, [skillType, setValue]);

  return (
    <SkillModal
      category={category}
      isDataFetching={isCategoriesLoading}
      isOpen={isOpen}
      isLoading={isLoading}
      formMode={formMode}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={onSubmit}
      skillType={skillType}
      header={header}
      skillTypeOptions={skillTypeOptions}
      categoriesOptions={data?.result || []}
    />
  );
};
