import { UseFormReturn } from 'react-hook-form';

import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { Proficiency, ProfileLanguage } from 'api/actions/profile/languages/profileLanguagesActions.types';
import { LanguageOption } from '../../profileLanguage/ProfileLanguage.types';

export type LanguageModalProps<TResponse extends Record<string, unknown>> = {
  editedLanguage?: LanguageOption;
  isOpen: boolean;
  onModalClose: VoidFunction;
  formMethods: UseFormReturn<LanguageModalFormValues>;
  onSubmit: (body: LanguageModalFormValues) => Promise<ClientResponse<TResponse>>;
  languageOptions: LanguageOption[];
  profileLanguage?: ProfileLanguage;
  isLoading: boolean;
  submitLabel: string;
  error: ClientErrorResponse | null;
};

export enum LanguageModalFormFields {
  LanguageId = 'languageId',
  Proficiency = 'proficiency',
}

export type LanguageModalFormValues = {
  [LanguageModalFormFields.LanguageId]: string | null;
  [LanguageModalFormFields.Proficiency]: Proficiency | null;
};
