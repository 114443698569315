import { IntlShape } from 'react-intl';
import Joi from 'joi';
import { format } from 'date-fns';

import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';
import { Skill } from 'api/actions/skills/skillsActions.types';

import { ProjectModalFormFields, ProjectType } from './ProjectModal.types';

export const MIN_DATE = new Date(1980, 0);
const UNASSIGNED_CATEGORY = '\u200B'; // zero-width space

export const defaultValues = {
  [ProjectModalFormFields.Name]: '',
  [ProjectModalFormFields.ShortDescription]: '',
  [ProjectModalFormFields.Description]: '',
  [ProjectModalFormFields.ProjectType]: ProjectType.Private,
  [ProjectModalFormFields.IsInvisible]: false,
  [ProjectModalFormFields.Role]: null,
  [ProjectModalFormFields.AdditionalDescription]: '',
  [ProjectModalFormFields.JoinedAt]: null,
  [ProjectModalFormFields.LeftAt]: null,
  [ProjectModalFormFields.ProfileProjectTechnologies]: [],
  [ProjectModalFormFields.ProjectTechnologies]: [],
};

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  const stringFieldSchema = Joi.string().required().trim().messages(getErrorMessages({ formatMessage }));

  return Joi.object({
    [ProjectModalFormFields.Name]: stringFieldSchema,
    [ProjectModalFormFields.ShortDescription]: stringFieldSchema,
    [ProjectModalFormFields.Description]: stringFieldSchema,
    [ProjectModalFormFields.AdditionalDescription]: Joi.string().allow('').optional().trim(),
    [ProjectModalFormFields.Role]: Joi.object().required().empty(null).messages(getErrorMessages({ formatMessage })),
    [ProjectModalFormFields.JoinedAt]: Joi.date()
      .empty(null)
      .default(null)
      .min(MIN_DATE)
      .required()
      .messages(getErrorMessages({ formatMessage, date: format(MIN_DATE, 'yyyy-LL') })),
    [ProjectModalFormFields.LeftAt]: Joi.date()
      .greater(Joi.ref(ProjectModalFormFields.JoinedAt))
      .empty(null)
      .default(null)
      .messages(getErrorMessages({ formatMessage })),
    [ProjectModalFormFields.ProjectType]: Joi.string().allow(...['private', 'public']),
    [ProjectModalFormFields.IsInvisible]: Joi.boolean().required(),
    [ProjectModalFormFields.ProfileProjectTechnologies]: Joi.array().required().min(3),
    [ProjectModalFormFields.ProjectTechnologies]: Joi.array().optional(),
  });
};

export const formatTechnologies = (technologies: Skill[]) => {
  return technologies
    .map((technology) => ({
      ...technology,
      categoryName: technology.category?.name || UNASSIGNED_CATEGORY,
    }))
    .sort((a, b) => {
      if (a.categoryName === UNASSIGNED_CATEGORY) {
        return 1;
      }

      if (b.categoryName === UNASSIGNED_CATEGORY) {
        return -1;
      }

      return a.categoryName.localeCompare(b.categoryName);
    });
};
