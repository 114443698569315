import { Navigate } from 'react-router-dom';

import { AccessControl } from 'ui';
import { AppRoute } from '../AppRoute.enum';
import { LanguagesContainer } from 'views/adminSection/languages/LanguagesContainer';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const AuthorizedLanguages = () => (
  <AccessControl allowedContent={ContentType.Languages} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <LanguagesContainer />
  </AccessControl>
);
