import { Box } from '@mui/material';

import { Separator } from '../../separator/Separator';

import { skeleton } from './RichTextEditorSkeleton.styles';

export const RichTextEditorSkeleton = () => (
  <>
    <Separator height={10} />
    <Box sx={skeleton} />
  </>
);
