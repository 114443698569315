import { useUser } from 'hooks';

import { AccessControlProps } from './AccessControl.types';
import { hasPermission } from './AccessControl.utils';

export const AccessControl = ({ allowedContent, children, hasProfileAccess, renderNoAccess }: AccessControlProps) => {
  const { user } = useUser();

  const { isAllowed } = hasPermission({ userRole: user?.accessType, allowedContent, hasProfileAccess });

  if (isAllowed) {
    return children;
  }

  return renderNoAccess || null;
};
