import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Skill, SkillType } from 'api/actions/skills/skillsActions.types';
import { useLocale } from 'hooks';
import { TableActions } from 'views/adminSection/skills/components/tableActions/TableActions';
import { skillTypeOptions } from 'views/adminSection/skills/modals/skillModal/SkillModal.utils';

export const useSkillsColumns = () => {
  const columnHelper = createColumnHelper<Skill>();
  const { formatMessage } = useLocale();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          id: 'name',
          header: () => <FormattedMessage id="skills.table.headers.name" />,
        }),
        columnHelper.accessor('type', {
          id: 'type',
          header: () => <FormattedMessage id="skills.table.headers.type" />,
          cell: (props) => formatMessage({ id: `skillType.${SkillType[props.getValue()].toLowerCase()}` }),
          filterFn: 'equals',
          meta: {
            filterType: 'select',
            filterOptions: skillTypeOptions,
          },
        }),
        columnHelper.accessor('category', {
          id: 'category',
          header: () => <FormattedMessage id="skills.table.headers.category" />,
          cell: (props) => props.getValue()?.name ?? '-',
          enableColumnFilter: false,
          enableSorting: false,
        }),
        columnHelper.accessor('isSurveyItem', {
          id: 'isSurveyItem',
          header: () => <FormattedMessage id="skills.table.headers.isSurveyItem" />,
          cell: (props) =>
            props.getValue() ? formatMessage({ id: 'skills.table.yes' }) : formatMessage({ id: 'skills.table.no' }),
          enableColumnFilter: false,
          enableSorting: false,
        }),
        columnHelper.accessor('id', {
          id: 'actions',
          header: () => <FormattedMessage id="skills.table.headers.actions" />,
          cell: TableActions,
          enableColumnFilter: false,
          enableSorting: false,
        }),
      ] as ColumnDef<Skill>[],
    [columnHelper, formatMessage],
  );

  return { columns };
};
