import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLocale } from 'hooks';
import { Language } from 'api/actions/languages/languagesActions.types';
import { TableActions } from '../components/tableActions/TableActions';

export const useLanguagesColumns = () => {
  const columnHelper = createColumnHelper<Language>();
  const { formatMessage } = useLocale();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          id: 'name',
          header: () => <FormattedMessage id="skills.table.headers.name" />,
        }),
        columnHelper.accessor('id', {
          id: 'actions',
          header: () => <FormattedMessage id="skills.table.headers.actions" />,
          cell: TableActions,
          enableColumnFilter: false,
          enableSorting: false,
        }),
      ] as ColumnDef<Language>[],
    [columnHelper, formatMessage],
  );

  return { columns };
};
