import Joi from 'joi';
import { IntlShape } from 'react-intl';

import { UsersFormFields } from './Profiles.types';

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) =>
  Joi.object({
    [UsersFormFields.Email]: Joi.string()
      .email({ tlds: false })
      .required()
      .trim()
      .label(formatMessage({ id: 'form.email' })),
  });

export const defaultValues = {
  [UsersFormFields.Email]: '',
};

export const getQueryParams = () => ({
  relations: ['profile.position'],
});
