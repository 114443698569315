import { QueryParams } from '../../api/types/types';

import { VirtualSortingDecodeParams } from './virtualSorting.types';

export const virtualSortingDecode = ({
  queryParams,
  virtualSortingMapper,
}: VirtualSortingDecodeParams): Record<string, string> => {
  const queryParamsDecoded: Record<string, string> = {};

  Object.entries(queryParams).forEach(([key, value]) => {
    let decoded = false;
    Object.entries(virtualSortingMapper).forEach(([virtualColumn, realColumns]) => {
      if (key === `${QueryParams.SORT}[${virtualColumn}]`) {
        realColumns.forEach((realColumn) => (queryParamsDecoded[`${QueryParams.SORT}[${realColumn}]`] = value));

        decoded = true;
      }
    });
    if (!decoded) queryParamsDecoded[key] = value;
  });

  return queryParamsDecoded;
};
