import { CellContext } from '@tanstack/react-table';

import { Skill } from 'api/actions/skills/skillsActions.types';
import { ActionsWrapper, EditButton, DeleteButton } from 'ui';
import { PatchSkillModalContainer } from 'views/adminSection/skills/modals/patchSkillModal/PatchSkillModalContainer';
import { useDeleteSkill } from 'views/adminSection/skills/hooks/useDeleteSkill';
import { DeleteModal } from 'ui/modals/forceDeleteModal/DeleteModal';
import { useContentAccess, useModal } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const TableActions = (cell: CellContext<Skill, string>) => {
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Skills });

  const { isOpen: isPatchModalOpen, onOpen: onPatchModalOpen, onClose: onPatchModalClose } = useModal();
  const {
    isOpen: isForceDeleteModalOpen,
    onOpen: onForceDeleteModalOpen,
    onClose: onForceDeleteModalClose,
  } = useModal();

  const { deleteSkill } = useDeleteSkill({ onSuccess: onForceDeleteModalClose, onForceDelete: onForceDeleteModalOpen });

  return isReadOnly ? null : (
    <ActionsWrapper>
      <EditButton onClick={onPatchModalOpen} />
      <DeleteButton onClick={() => deleteSkill(cell.getValue())} />

      {isPatchModalOpen && (
        <PatchSkillModalContainer
          isOpen={isPatchModalOpen}
          onModalClose={onPatchModalClose}
          skillData={cell.row.original}
        />
      )}
      {isForceDeleteModalOpen && (
        <DeleteModal
          isOpen={isForceDeleteModalOpen}
          onModalClose={onForceDeleteModalClose}
          onItemDelete={() => deleteSkill(cell.getValue(), true)}
        />
      )}
    </ActionsWrapper>
  );
};
