import { Profile } from 'api/actions/profiles/profilesActions.types';
import { ClientResponse } from 'api/types/types';

export enum BasicDataFormFields {
  CareerStart = 'startCareerAt',
  About = 'about',
}

export type BasicDataFormValues = {
  [BasicDataFormFields.CareerStart]: string | null;
  [BasicDataFormFields.About]: string | null;
};

export type BasicDataProps = {
  isPostLoading: boolean;
  onSubmit: (id: string, body: BasicDataFormValues) => Promise<ClientResponse<Profile>>;
};

export type BasicDataContainerProps = {
  handleNext: VoidFunction;
};
