import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useSnackbar } from 'hooks';
import { useProfileLanguageDelete as useProfileLanguageDeleteHook } from 'api/actions/profile/languages/profileLanguagesActions';
import { ProfileLanguagesGetResponse } from 'api/actions/profile/languages/profileLanguagesActions.types';
import { mutationUpdater } from 'utils/mutationUpdater/mutationUpdater';

export const useDeleteProfileLanguage = (profileLanguageId: string) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const languagesQueryKey = `profiles/${user?.id}/languages`;

  const { mutateAsync, error } = useProfileLanguageDeleteHook({
    onSuccess: () => {
      queryClient.setQueryData<ProfileLanguagesGetResponse>(languagesQueryKey, (languages) =>
        mutationUpdater('DELETE', profileLanguageId, languages),
      );
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error),
  });

  const deleteProfileLanguage = useCallback(async () => {
    await mutateAsync({ profileId: user?.id, profileLanguageId });
  }, [profileLanguageId, mutateAsync, user?.id]);

  return { deleteProfileLanguage, error };
};
