import { Helmet } from 'react-helmet';

import { useContentAccess, useLocale, useModal } from 'hooks';
import { AdminSectionWrapper, OpenModalButton } from 'ui';
import { PostSkillModalContainer } from 'views/adminSection/skills/modals/postSkillModal/PostSkillModalContainer';
import { Skills } from 'views/adminSection/skills/Skills';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const SkillsContainer = () => {
  const { formatMessage } = useLocale();
  const { onOpen, onClose, isOpen } = useModal();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Skills });

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'navigator.skills' })}</title>
      </Helmet>
      <AdminSectionWrapper
        tabs="skillsTabs"
        title="navigator.skills"
        action={isReadOnly ? null : <OpenModalButton label="form.addNewSkill" onClick={onOpen} />}
      >
        {isOpen && <PostSkillModalContainer isOpen={isOpen} onModalClose={onClose} />}
        <Skills />
      </AdminSectionWrapper>
    </>
  );
};
