import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';
import { dateLocalToUTC } from 'utils/handleTimezone/handleTimezone';

import {
  DeleteProfileCertificateMutationArgs,
  PatchProfileCertificateMutationArgs,
  PostProfileCertificateMutationArgs,
  ProfileCertificate,
  ProfileCertificatesGetResponse,
} from './profileCertificatesActions.types';

export const useProfileCertificatesGet = (profileId: string | undefined, config?: AxiosRequestConfig) =>
  useQuery<ProfileCertificatesGetResponse>(`profiles/${profileId}/certificates`, undefined, undefined, config);

export const useProfileCertificatePost = (
  options: UseMutationOptions<
    ClientResponse<ProfileCertificate>,
    ClientErrorResponse,
    PostProfileCertificateMutationArgs
  >,
) =>
  useMutation(
    'postProfileCertificate',
    ({ profileId, body: { obtainedAt, ...values } }) => ({
      endpoint: `/profiles/${profileId}/certificate`,
      method: 'POST',
      params: {
        ...values,
        obtainedAt: obtainedAt ? dateLocalToUTC(obtainedAt) : undefined,
      },
    }),
    options,
  );

export const useProfileCertificateDelete = (
  options: UseMutationOptions<ClientResponse, ClientErrorResponse, DeleteProfileCertificateMutationArgs>,
) =>
  useMutation(
    'deleteProfileCertificate',
    ({ profileId, profileCertificateId }) => ({
      endpoint: `/profiles/${profileId}/certificate/${profileCertificateId}`,
      method: 'DELETE',
    }),
    options,
  );

export const useProfileCertificatePatch = (
  options: UseMutationOptions<
    ClientResponse<ProfileCertificate>,
    ClientErrorResponse,
    PatchProfileCertificateMutationArgs
  >,
) =>
  useMutation(
    'patchProfileCertificate',
    ({ profileId, profileCertificateId, body: { obtainedAt, ...values } }) => ({
      endpoint: `/profiles/${profileId}/certificate/${profileCertificateId}`,
      method: 'PATCH',
      params: {
        ...values,
        obtainedAt: obtainedAt ? dateLocalToUTC(obtainedAt) : undefined,
      },
    }),
    options,
  );
