import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Button, Modal, Separator, Typography } from 'ui';
import { useLocale } from 'hooks';
import { ModalButtonsWrapper } from '../modalButtonsWrapper/ModalButtonsWrapper';

import * as S from './DeleteModal.styles';
import { DeleteModalProps } from './DeleteModal.types';

export const DeleteModal = ({
  isOpen,
  onModalClose,
  onItemDelete,
  shortConfirmMessage,
  title,
  isLoading,
  isError,
  maxWidth = 500,
}: DeleteModalProps) => {
  const { formatMessage } = useLocale();

  return (
    <Modal isOpen={isOpen} onModalClose={onModalClose} maxWidth={maxWidth}>
      <Box sx={S.modalInnerWrapper}>
        {title ? title : <Typography>{shortConfirmMessage ? 'form.deleteInfo' : 'form.forceDeleteInfo'}</Typography>}
        <Separator height={20} />

        {isError && (
          <>
            <Typography variant="helperText" color="error" textAlign="center">
              {'error.errorOccurred'}
            </Typography>
            <Separator height={20} />
          </>
        )}

        <ModalButtonsWrapper>
          <Button
            dataTestId="cancel"
            onClick={onModalClose}
            label="form.cancel"
            variant="outlined"
            sx={S.cancelButton}
          />

          <LoadingButton
            data-testid="delete"
            onClick={onItemDelete}
            loading={isLoading}
            variant="contained"
            color="error"
          >
            {formatMessage({ id: 'form.delete' })}
          </LoadingButton>
        </ModalButtonsWrapper>
      </Box>
    </Modal>
  );
};
