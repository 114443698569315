import Box from '@mui/material/Box';
import { Outlet, useLocation } from 'react-router-dom';
import { Grid } from 'lux/components';
import { useIntl } from 'react-intl';

import { UiTopBar } from 'ui';
import { AppRoute } from 'routing/AppRoute.enum';
import { canShowBreadcrumbs, getBreadcrumbs } from '../breadcrumbs/breadcrumbs.utils';

import * as S from './Dashboard.styles';

export const Dashboard = () => {
  const { pathname } = useLocation();
  const intl = useIntl();

  const isProfileEditor = pathname.includes(AppRoute.ProfileEditor);

  let showBreadcrumbs = false;
  if (!isProfileEditor) {
    showBreadcrumbs = canShowBreadcrumbs(pathname, getBreadcrumbs(intl));
  }

  return (
    <Box component="main" sx={S.dashboardContainerStyles}>
      <UiTopBar />
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: isProfileEditor ? 'flex-start' : 'center',
          alignContent: 'flex-start',
          pt: showBreadcrumbs ? 8 : 14,
          pb: 6,
        }}
      >
        <Outlet />
      </Grid>
    </Box>
  );
};
