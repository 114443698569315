import { Table } from 'lux/components';

import { TablePaginationProps } from './TablePagination.types';

export const TablePagination = ({
  pageSize,
  onPageSizeChange,
  page,
  total,
  pageSizeOptions,
  onPageChange,
}: TablePaginationProps) => (
  <Table.Pagination
    component="div"
    count={total}
    page={total === 0 ? 0 : page - 1}
    rowsPerPage={pageSize}
    onPageChange={(_, page) => onPageChange(page + 1)}
    rowsPerPageOptions={pageSizeOptions}
    onRowsPerPageChange={(event) => {
      onPageSizeChange(Number(event.target.value));
      onPageChange(1);
    }}
  />
);
