import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { AdminSectionTableRow } from 'ui';
import { SeniorityEditionModalContainer } from '../modals/seniorityEditionModal/SeniorityEditionModalContainer';

import { SeniorityFormFields, SeniorityProps } from './Seniority.types';

export const Seniority = ({ seniority, onRemoveButtonClick, dragAndDropIndex, disabled }: SeniorityProps) => {
  const [isEdit, setIsEdit] = useState(false);

  const commonProps = {
    inputName: SeniorityFormFields.Name,
    inputLabel: 'form.seniority',
    name: seniority.name,
    visibleName: seniority.visibleName,
    onRemoveButtonClick: onRemoveButtonClick,
    isEdit,
    onEditClick: () => setIsEdit(true),
  };

  return (
    <>
      {dragAndDropIndex !== undefined ? (
        <Draggable isDragDisabled={disabled} draggableId={seniority.id} index={dragAndDropIndex}>
          {(provided, snapshot) => (
            <AdminSectionTableRow
              isReadOnly={disabled}
              {...commonProps}
              providedDND={provided}
              snapshotDND={snapshot}
            />
          )}
        </Draggable>
      ) : (
        <AdminSectionTableRow {...commonProps} />
      )}
      {isEdit && <SeniorityEditionModalContainer isOpen={isEdit} seniority={seniority} setIsEdit={setIsEdit} />}
    </>
  );
};
