import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useMutation } from 'react-query';

import { AuthContext } from '../authContext/AuthContext';
import { authStorage } from '../authStorage/AuthStorage';
import { AppRoute } from 'routing/AppRoute.enum';
import { fetchAccessToken } from 'api/actions/auth/authActions';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const { mutateAsync: login, isSuccess } = useMutation(fetchAccessToken, {
    onSuccess: (data) => {
      authStorage.accessToken = data;
      instance.setActiveAccount(accounts[0]);
    },
    onError: (e) => {
      console.error('Fetch access token failed:', e);
      logout();
      instance.setActiveAccount(null);
    },
  });

  const logout = useCallback(async () => {
    authStorage.accessToken = null;
    /*
    HINT: Use fragment below to logout from microsoft account

    instance.logout({
      account: accounts[0],
      postLogoutRedirectUri: import.meta.env.VITE_AZURE_REDIRECT_URI as string,
    });
    */
    instance.logout({
      onRedirectNavigate: () => {
        return false;
      },
    });
    instance.setActiveAccount(null);
    navigate(AppRoute.Login);
  }, [navigate, instance, accounts]);

  const isAuthenticated = useMemo(
    () => !!authStorage.accessToken,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess],
  );

  const contextValue = {
    accessToken: authStorage.accessToken,
    isAuthenticated,
    login,
    logout,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
