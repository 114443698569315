import { Styles, componentsLibTheme as theme } from 'lux/theme';
const { palette } = theme;

export const wrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 3,
};

export const name: Styles = {
  fw: 500,
};

export const subtitle: Styles = {
  color: palette.text.secondary,
};

export const caption: Styles = {
  color: palette.text.secondary,
  fw: 700,
  display: 'block',
};

export const buttons: Styles = {
  display: 'flex',
  gap: 1,
  ml: 'auto',
};

export const titleTypography: Styles = {
  display: 'block',
  width: '80%',
};
