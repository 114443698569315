import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';

import { Positions } from './Positions';

export const PositionsContainer = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.positions' })}</title>
      </Helmet>
      <Positions />
    </>
  );
};
