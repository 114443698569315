import { useProfileProjectTechnologiesQuery } from '../hooks/useProfileProjectTechnologiesQuery';
import { useModal } from 'hooks';

import { Project } from './Project';
import { ProjectContainerProps } from './Project.types';

export const ProjectContainer = ({ isFirst, isLast, ...props }: ProjectContainerProps) => {
  const { profileProject } = props;

  const { isOpen, onOpen, onClose } = useModal();

  const { data: profileProjectTechnologies } = useProfileProjectTechnologiesQuery(profileProject.id);

  return (
    <Project
      {...props}
      technologies={profileProjectTechnologies?.result ?? []}
      isModalOpen={isOpen}
      onModalClose={onClose}
      onModalOpen={onOpen}
      isFirst={isFirst}
      isLast={isLast}
    />
  );
};
