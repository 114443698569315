import { Styles } from 'lux/theme';

export const paperStyles: Styles = {
  p: 3,
};

export const downloadCVButton: Styles = {
  minWidth: 185,

  a: {
    textDecoration: 'none',
    color: 'inherit',
  },
};

export const previewCVButton: Styles = {
  minWidth: 170,
};
