import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';

import { useSenioritiesQuery } from './hooks/useSenioritiesQuery';
import { Seniorities } from './Seniorities';

export const SenioritiesContainer = () => {
  const { formatMessage } = useLocale();
  const { data: seniorities, isLoading, isRefetching } = useSenioritiesQuery();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.seniorities' })}</title>
      </Helmet>
      <Seniorities seniorities={seniorities?.result || []} isLoading={isLoading} isRefetching={isRefetching} />
    </>
  );
};
