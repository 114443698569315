import { TextField } from 'lux/components';
import SearchIcon from '@mui/icons-material/Search';

import { useLocale } from 'hooks';

import * as S from './GlobalFilter.styles';
import { GlobalFilterProps } from './GlobalFilter.types';

export const GlobalFilter = ({ value, onValueChange, labelPlaceholder }: GlobalFilterProps) => {
  const { formatMessage } = useLocale();

  return (
    <TextField
      sx={S.globalFilter}
      endAdornment={<SearchIcon />}
      label={formatMessage({ id: labelPlaceholder })}
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
    />
  );
};
