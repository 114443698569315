import { Navigate } from 'react-router-dom';

import { AccessControl } from 'ui';
import { AppRoute } from '../AppRoute.enum';
import { ProjectRolesContainer } from 'views/adminSection/projectRoles/ProjectRolesContainer';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const AuthorizedProjectRoles = () => (
  <AccessControl allowedContent={ContentType.ProjectRoles} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <ProjectRolesContainer />
  </AccessControl>
);
