import { ClientResponse } from 'api/types/types';
import { InfiniteQueryFn } from 'hooks/useInfiniteQuery/useInfiniteQuery.types';

import { FetchAccessTokenProperties, GetUsersResponse } from './authActions.types';

export const getInfiniteUsersQuery: InfiniteQueryFn<{ count: number }, ClientResponse<GetUsersResponse>> = (args) => {
  return {
    endpoint: `/users`,
    args,
  };
};

export const fetchAccessToken = async ({ account, instance }: FetchAccessTokenProperties) => {
  try {
    const response = await instance.acquireTokenSilent({
      scopes: [`${import.meta.env.VITE_AZURE_CLIENT_ID}/.default`],
      account: account,
    });

    if (response) {
      return response.accessToken;
    }

    throw new Error('Failed to fetch access token');
  } catch (error) {
    throw new Error('Failed to fetch access token');
  }
};
