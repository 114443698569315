import { Styles, componentsLibTheme as theme } from 'lux/theme';

export const editButtonLink: Styles = {
  p: '0px !important',
  height: 40,
  width: 40,

  '&:disabled': {
    svg: {
      path: {
        fill: theme.palette.text.disabled,
      },
    },
  },

  svg: {
    path: {
      stroke: 'transparent',
    },
  },
};
