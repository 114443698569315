import { Grid } from '@mui/material';

import { Typography } from 'ui';

import * as S from './SurveyHeader.styles';

export const SurveyHeader = () => {
  return (
    <>
      <Grid container xs={12} sx={S.stickyHeader}>
        <Grid item xs={2}>
          <Typography>{'technologiesSurvey.form.technology'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <Typography>{'technologiesSurvey.form.comfortable'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{'technologiesSurvey.form.knowALittle'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{'technologiesSurvey.form.dontKnow'}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={3}>
              <Typography>{'technologiesSurvey.form.love'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{'technologiesSurvey.form.whatever'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{'technologiesSurvey.form.hate'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{'technologiesSurvey.form.notApplicable'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
