import { ClientErrorResponse } from 'api/types/types';

export enum ProjectRoleFormFields {
  Name = 'name',
}

export type ProjectRoleFormValues = {
  [ProjectRoleFormFields.Name]: string;
};

export type ProjectRolesModalProps = {
  onSubmit: (body: ProjectRoleFormValues) => void;
  isLoading: boolean;
  onModalClose: VoidFunction;
  selectedRole: {
    id: string;
    name: string;
  };
  error: ClientErrorResponse | null;
  isOpen: boolean;
  modalTypography: { title: string; submitButton: string };
  mutationErrorReset: VoidFunction | null;
};
