import { Styles } from 'views/generatedProfile/theme/theme';

export const wrapper: Styles = {
  my: 4,
};

export const title: Styles = ({ spacing }) => ({
  fontSize: spacing(1.625),
  fontWeight: 600,
  mb: 1,
});

export const categoryWrapper: Styles = {
  py: 1,
};

export const categoryContent: Styles = ({ palette, spacing }) => ({
  bgcolor: palette.grey['A100'],
  p: spacing(2, 1.5),
  borderRadius: 1.5,
});

export const categoryName: Styles = ({ spacing }) => ({
  fontSize: spacing(1.5),
  fontWeight: 600,
  mb: 1,
});

export const skillWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const skillName: Styles = () => ({
  fontSize: '13px',
});

export const skillYears: Styles = () => ({
  fontSize: '13px',
});
