import { useMemo, useState } from 'react';
import { ColumnFilter } from '@tanstack/react-table';

import { useDebounce } from 'hooks';

import { useFilterProps } from './useFilter.types';
import { getFilterQueryParams } from './useFilter.utils';

export const useFilter = ({ defaultFilters = [], filtersOperators, keysMapper }: useFilterProps) => {
  const [filter, setFilter] = useState<ColumnFilter[]>(defaultFilters);

  const debouncedFilters = useDebounce(filter);

  const filterQueryParams = useMemo(() => {
    return getFilterQueryParams(filter, keysMapper, filtersOperators);
  }, [filter]);

  return { filter, setFilter, filterQueryParams, debouncedFilters };
};
