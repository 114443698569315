import { useState } from 'react';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from 'lux/components';

import { AdminSectionWrapper, OpenModalButton } from 'ui';
import { useContentAccess, useGetQueryParams, useLocale, useModal } from 'hooks';
import { globalFilterParamDecode } from 'utils';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import * as S from './Positions.styles';
import { filtersOperators, keysMapper } from './position/PositionsList.utils';
import { PositionsList } from './position/PositionsList';
import { AddPositionModalContainer } from './modals/addPositionModal/AddPositionModalContainer';

export const Positions = () => {
  const { isOpen, onClose, onOpen } = useModal();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Positions });
  const { queryParams } = useGetQueryParams();
  const [globalFilter, setGlobalFilter] = useState(queryParams?.search || '');
  const { formatMessage } = useLocale();

  const queryParamsDecoded = globalFilterParamDecode({
    queryParams,
    keysMapper,
    filtersOperators,
  });

  return (
    <AdminSectionWrapper
      title="navigator.positions"
      tabs="profileTabs"
      action={isReadOnly ? null : <OpenModalButton sx={S.button} label="positions.form.add" onClick={onOpen} />}
    >
      <TextField
        sx={S.globalFilter}
        endAdornment={<SearchIcon />}
        label={formatMessage({ id: 'positions.table.searchPosition' })}
        value={globalFilter}
        onChange={(e) => {
          setGlobalFilter(String(e.target.value));
        }}
      />

      <Divider variant="middle" sx={S.hr} />

      <PositionsList globalFilter={globalFilter} queryParamsDecoded={queryParamsDecoded} />
      {isOpen && <AddPositionModalContainer isModalOpen={isOpen} onModalClose={onClose} />}
    </AdminSectionWrapper>
  );
};
