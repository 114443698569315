import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect } from 'react';

import { useLocale, usePrompt, useSetFormError } from 'hooks';
import { CertificateForm } from '../CertificateForm';
import { CertificateFormFields, CertificateFormValues } from '../CertificateForm.types';
import { getDefaultValues, getFormSchema, getTagsToSubmit } from '../CertificateForm.utils';
import { useAddCertificate } from '../hooks/useAddCertificate/useAddCertificate';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { useTagsGet } from 'api/actions/tags/tagsActions';
import { CertificateFormBody } from 'api/actions/certificates/certificatesActions.types';

import { AddCertificateFormContainerProps } from './AddCertificateForm.types';

export const AddCertificateFormContainer = ({ isOpen, onClose }: AddCertificateFormContainerProps) => {
  const { formatMessage } = useLocale();
  const { setFormError } = useSetFormError();

  const { data: tags } = useTagsGet();

  const formMethods = useForm<CertificateFormValues>({
    defaultValues: getDefaultValues(),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });
  const { addCertificate, isLoading, error } = useAddCertificate({ onClose });

  useEffect(() => {
    setFormError<CertificateFormValues>(formMethods.setError, error, CertificateFormFields, 'name');
  }, [setFormError, formMethods.setError, error]);

  const onSubmit = (formData: CertificateFormValues) => {
    const body: Partial<CertificateFormBody> = {
      ...formData,
      tags: getTagsToSubmit(tags?.result, formData?.tags),
      periodOfValidity: formData.periodOfValidity === '' ? null : Number(formData.periodOfValidity),
      url: formData.url || null,
    };
    addCertificate({ body });
  };

  usePrompt({ when: formMethods.formState.isDirty });

  return (
    <CertificateForm
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={formMethods.handleSubmit(onSubmit)}
      formMethods={formMethods}
      isLoading={isLoading}
      formMode={FormMode.add}
      tags={tags?.result.map((el) => el.name)}
    />
  );
};
