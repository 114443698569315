import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useMutation, useSnackbar } from 'hooks';
import { postProfileSkillMutation } from 'api/actions/profile/skills/profileSkillsActions';
import { SkillType } from 'api/actions/skills/skillsActions.types';
import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { generateGetProfileSkillsQueryKey } from 'api/actions/profile/skills/profileSkillsActions.utils';

export const usePostProfileSkill = () => {
  const { user } = useCurrentUser();
  const queryClient = useQueryClient();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { mutateAsync, isError } = useMutation('postProfileSkill', postProfileSkillMutation, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(generateGetProfileSkillsQueryKey(variables.isSpecial, user?.id));
      queryClient.invalidateQueries(['skills'], { exact: true });
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const addSkill = useCallback(
    async ({ id: skillId, name }: AutocompleteOption, isSpecial: boolean, type?: SkillType) => {
      await mutateAsync({
        profileId: user?.id,
        isSpecial,
        body: name === skillId ? { name: name.trim(), type } : { skillId },
      });
    },
    [mutateAsync, user?.id],
  );

  return { addSkill, isError };
};
