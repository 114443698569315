import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { Skill, SkillType } from 'api/actions/skills/skillsActions.types';
import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';

import { SkillModalFormFields, SkillModalFormValues } from './SkillModal.types';

export const defaultValues = {
  [SkillModalFormFields.Name]: '',
  [SkillModalFormFields.Type]: 1,
  [SkillModalFormFields.CategoryId]: null,
  [SkillModalFormFields.IsSurveyItem]: false,
};

export const getDefaultValues = ({ category, name, type, isSurveyItem }: Skill): SkillModalFormValues => {
  return {
    [SkillModalFormFields.CategoryId]: category?.id ?? null,
    [SkillModalFormFields.Name]: name,
    [SkillModalFormFields.Type]: type,
    [SkillModalFormFields.IsSurveyItem]: isSurveyItem,
  };
};

const FIND_MULTIPLE_SPACES_REGEX = /  +/g;

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  return Joi.object({
    [SkillModalFormFields.Name]: Joi.string()
      .required()
      .trim()
      .replace(FIND_MULTIPLE_SPACES_REGEX, ' ')
      .messages(getErrorMessages({ formatMessage })),
    [SkillModalFormFields.Type]: Joi.number().messages(getErrorMessages({ formatMessage })),
    [SkillModalFormFields.CategoryId]: Joi.string().allow(null).messages(getErrorMessages({ formatMessage })),
    [SkillModalFormFields.IsSurveyItem]: Joi.boolean().allow(null).messages(getErrorMessages({ formatMessage })),
  });
};

export const skillTypeOptions = Object.entries(SkillType)
  .filter((type) => !isNaN(Number(SkillType[type[0] as unknown as number])))
  .reduce((prev, curr) => [...prev, { id: curr[1], name: curr[0] }], [] as Record<string, string | SkillType>[]) as {
  id: number;
  name: string;
}[];
