import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';

import { useLocale, usePrompt, useSetFormError } from 'hooks';
import {
  Autocomplete,
  Button,
  FormInputText,
  Modal,
  ModalButtonsWrapper,
  RichTextEditor,
  Separator,
  Typography,
} from 'ui';
import { useGetSkills } from 'api/actions/skills/skillsActions';
import { SkillType } from 'api/actions/skills/skillsActions.types';

import { Technology } from './technology/Technology';
import { ProjectModalFormFields, ProjectModalFormValues, ProjectModalProps } from './ProjectModal.types';
import { getFormSchema } from './ProjectModal.utils';

export const ProjectModal = ({
  isOpen,
  onClose,
  initialValues: defaultValues,
  onSubmit,
  isLoading,
  title,
  buttonLabel,
  error,
}: ProjectModalProps) => {
  const { formatMessage } = useLocale();
  const { setFormError } = useSetFormError();

  const formMethods = useForm<ProjectModalFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit, setValue, watch, setError } = formMethods;

  const { data: allTechnologies } = useGetSkills({
    params: {
      'filter[skill.type][eq]': SkillType.Tech,
      'sort[skill.name]': 'ASC',
    },
  });

  const technologies = watch(ProjectModalFormFields.Technologies);

  useEffect(() => {
    setFormError<ProjectModalFormValues>(setError, error, ProjectModalFormFields, 'name');
  }, [setFormError, setError, error]);

  usePrompt({ when: formMethods.formState.isDirty });

  return (
    <Modal isOpen={isOpen} onModalClose={onClose}>
      <Typography variant="h3">{title}</Typography>
      <Separator />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInputText name={ProjectModalFormFields.Name} label="form.name" required />
          <FormInputText name={ProjectModalFormFields.ShortDescription} label="form.shortDescription" required />
          <RichTextEditor
            name={ProjectModalFormFields.Description}
            placeholder="form.description"
            initialValue={defaultValues[ProjectModalFormFields.Description]}
            required
          />

          <Separator height={5} />

          {technologies.length > 0 ? (
            technologies.map((technology) => (
              <Technology
                key={technology.id}
                technology={technology}
                onClick={(id) => {
                  setValue(
                    ProjectModalFormFields.Technologies,
                    technologies.filter((technology) => technology.id !== id),
                  );
                }}
              />
            ))
          ) : (
            <Typography variant="body2">{'form.selectTechnologies'}</Typography>
          )}

          <Separator height={10} />

          <Typography variant="h6">{'form.addTechnologies'}</Typography>

          <Separator height={10} />

          <Autocomplete
            onChange={(value) => {
              if (value !== null) {
                const { id, name } = value;
                setValue(ProjectModalFormFields.Technologies, [...technologies, { id, name }]);
              }
            }}
            value={null}
            options={allTechnologies?.result || []}
            excludedIds={technologies.map(({ id }) => id)}
            label="form.technology"
            disableCloseOnSelect
            freeSolo
            forcePopupIcon
            withAddNewButton
          />

          <Separator height={30} />

          <ModalButtonsWrapper>
            <Button label="form.cancel" onClick={onClose} dataTestId="cancel" variant="outlined" />
            <LoadingButton loading={isLoading} variant="contained" type="submit">
              <FormattedMessage id={buttonLabel} />
            </LoadingButton>
          </ModalButtonsWrapper>
        </form>
      </FormProvider>
    </Modal>
  );
};
