import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';

import { CategoryModalFormFields } from './CategoryModal.types';

const NAME_MAX_LENGTH = 100;

export const defaultValues = {
  [CategoryModalFormFields.Name]: '',
};

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  return Joi.object({
    [CategoryModalFormFields.Name]: Joi.string()
      .max(NAME_MAX_LENGTH)
      .trim()
      .required()
      .messages(getErrorMessages({ formatMessage, maxLength: NAME_MAX_LENGTH })),
  });
};
