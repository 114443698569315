import { Table } from 'ui/tables/table/Table';
import { useTagsColumns } from 'views/adminSection/tags/hooks/useTagsColumns';
import { useGlobalFilter } from 'hooks';
import { Divider, GlobalFilter } from 'ui';
import { useTagsGet } from 'api/actions/tags/tagsActions';

import * as S from './Tags.styles';
import { filtersOperators, getTagsParams, keysMapper } from './Tags.utils';

export const Tags = () => {
  const { globalFilter, setGlobalFilter, queryParamsDecoded } = useGlobalFilter({
    keysMapper,
    filtersOperators,
  });

  const { data: tags, isLoading, isError } = useTagsGet({ params: getTagsParams(queryParamsDecoded) });
  const { columns } = useTagsColumns();

  return (
    <>
      <GlobalFilter onValueChange={setGlobalFilter} labelPlaceholder="tags.table.searchTag" value={globalFilter} />
      <Divider />
      <Table
        filtersOperators={filtersOperators}
        keysMapper={keysMapper}
        sx={{ tableHead: S.tableHead }}
        data={tags?.result}
        total={tags?.total}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        globalFilter={globalFilter}
        defaultSort={[{ id: 'name', desc: false }]}
      />
    </>
  );
};
