import { useEffect } from 'react';

import { useSetFormError } from 'hooks';

import { LanguageModalContainerProps } from './LanguageModalContainer.types';
import { LanguageModalFormFields, LanguageModalFormValues } from './LanguageModal.types';
import { LanguageModal } from './LanguageModal';

export const LanguageModalContainer = ({
  onClose,
  isLoading,
  formMode,
  formMethods,
  isOpen,
  onSubmit,
  header,
  error,
}: LanguageModalContainerProps) => {
  const { setFormError } = useSetFormError();
  const { reset, setError } = formMethods;

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  useEffect(() => {
    setFormError<LanguageModalFormValues>(setError, error, LanguageModalFormFields, 'name');
  }, [setFormError, setError, error]);

  return (
    <LanguageModal
      isOpen={isOpen}
      isLoading={isLoading}
      formMode={formMode}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={onSubmit}
      header={header}
    />
  );
};
