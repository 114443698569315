import { useQueryClient } from 'react-query';

import { getCertificatesQueryKey, useCertificatePatch } from 'api/actions/certificates/certificatesActions';
import { useSnackbar } from 'hooks';

import { UseAddCertificateProps } from './useEditCertificate.types';

export const useEditCertificate = ({ onClose }: UseAddCertificateProps) => {
  const queryClient = useQueryClient();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const {
    mutate: editCertificate,
    isError,
    isLoading,
    error,
  } = useCertificatePatch({
    onSuccess: () => {
      showSnackbarSuccess('success.itemEdited');
      queryClient.invalidateQueries(getCertificatesQueryKey);
      onClose();
    },
    onError: (error) => showSnackbarError(error),
  });

  return { editCertificate, isError, isLoading, error };
};
