import { flexRender, useReactTable } from '@tanstack/react-table';
import { useEffect } from 'react';
import { Table as ICLTable } from 'lux/components';

import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  getTableOptions,
  Styles,
  TableHeader,
  TablePagination,
  TableProps,
  TableStatus,
  useTableLogic,
} from '../common';
import { mergeStyles } from 'utils/mergeStyles';

export const Table = <T extends Record<string, unknown>>({
  columns,
  data = [],
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  total = 0,
  isLoading,
  isError,
  sx,
  defaultSort,
  defaultFilters,
  keysMapper,
  filtersOperators,
  isAutoDefaultSort,
  enableRowsSelection,
  onRowSelect,
  globalFilter,
  virtualSortingMap = {},
  columnVisibility,
  onRowClick,
  isRowClickable = true,
}: TableProps<T>) => {
  const { page, pageSize, setPageSize, setPage, setFilter, sort, setSort, filter, setSelectedRows, selectedRows } =
    useTableLogic({
      defaultSort,
      defaultFilters,
      keysMapper,
      filtersOperators,
      pageSizeOptions,
      total,
      isAutoDefaultSort,
      globalFilter,
      virtualSortingMap,
    });

  const table = useReactTable(
    getTableOptions({
      data,
      columns,
      page,
      pageSize,
      sort,
      filter,
      total,
      onSort: setSort,
      onFilter: setFilter,
      selectedRows,
      onRowSelect: setSelectedRows,
      enableRowsSelection,
      globalFilter,
      columnVisibility,
    }),
  );

  useEffect(() => {
    if (enableRowsSelection && onRowSelect) {
      onRowSelect(Object.keys(selectedRows));
    }
  }, [selectedRows]);

  return (
    <>
      <ICLTable sx={sx?.table} component="div">
        <ICLTable.Table>
          <ICLTable.Head sx={mergeStyles(sx?.tableHead || {}, Styles.tableHead)}>
            <TableHeader tableHeaders={table.getHeaderGroups()} />
          </ICLTable.Head>
          <ICLTable.Body sx={sx?.tableBody}>
            {table.getRowModel().rows.map((row) => (
              <ICLTable.Row
                key={row.id}
                sx={[Styles.row, isRowClickable && Styles.cursorPointer]}
                onClick={() => onRowClick?.(row)}
              >
                {row.getVisibleCells().map((cell) => (
                  <ICLTable.Cell
                    sx={cell.column.columnDef.meta?.getStyles ? cell.column.columnDef.meta?.getStyles(row) : Styles.row}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </ICLTable.Cell>
                ))}
              </ICLTable.Row>
            ))}
          </ICLTable.Body>
        </ICLTable.Table>
      </ICLTable>
      <TableStatus data={data} isError={isError} isLoading={isLoading} />
      <TablePagination
        page={page}
        onPageChange={setPage}
        total={total}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
};
