import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect, useState } from 'react';

import { useLocale } from 'hooks';
import { EducationModalFormValues } from '../EducationModal.types';
import { getDefaultValues, getFormSchema } from '../EducationModal.utils';
import { usePatchEducation } from '../../hooks/usePatchEducation';
import { EducationModal } from '../EducationModal';

import { EditEducationModalContainerProps } from './EditEducationModalContainer.types';

export const EditEducationModalContainer = ({
  isOpen,
  onModalClose,
  editedProfileEducation,
  profileEducationId,
}: EditEducationModalContainerProps) => {
  const { formatMessage } = useLocale();
  const [isOngoing, setIsOngoing] = useState(editedProfileEducation.year === null);
  const formMethods = useForm<EducationModalFormValues>({
    defaultValues: getDefaultValues(editedProfileEducation),
    resolver: joiResolver(getFormSchema(formatMessage, isOngoing)),
  });
  const { reset } = formMethods;

  useEffect(() => {
    if (isOpen) {
      reset(getDefaultValues(editedProfileEducation));
    }
  }, [isOpen, reset, editedProfileEducation]);

  const { isLoading, editProfileEducation, error } = usePatchEducation(profileEducationId, () => {
    onModalClose();
  });

  return (
    <EducationModal
      error={error}
      isOpen={isOpen}
      onModalClose={onModalClose}
      formMethods={formMethods}
      onSubmit={editProfileEducation}
      isLoading={isLoading}
      submitLabel={formatMessage({ id: 'form.save' })}
      isOngoing={isOngoing}
      setIsOngoing={setIsOngoing}
    />
  );
};
