import { ReactNode, useMemo } from 'react';

import { loggedUserUrl } from 'api/actions/user/userActions';
import { Loader } from 'ui';
import { UserContext } from '../userContext/UserContext';
import { useAuth, useQuery } from 'hooks';
import { Profile } from 'api/actions/profiles/profilesActions.types';
import { UserRole } from 'api/types/types';

type UserContextControllerProps = {
  children: ReactNode;
};

export const userContextQueryKey = 'user';

export const UserContextController = ({ children }: UserContextControllerProps) => {
  const { isAuthenticated } = useAuth();
  const { data, isLoading } = useQuery<Profile>(loggedUserUrl, userContextQueryKey, {
    retry: true,
    enabled: isAuthenticated,
  });
  const isAdmin = useMemo(() => data?.accessType === UserRole.ADMIN, [data]);

  if (isLoading) {
    return <Loader />;
  }

  const value = {
    user: data,
    isAdmin,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
