import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse } from '../../types/types';
import { useMutation, useQuery } from 'hooks';

import { SkillsOrderEditorGetResponse, SkillsOrderEditorPatchBody } from './skillsOrderEditorActions.types';

export const skillsOrderEditorQueryKey = 'skills-order-editor';

export const useSkillEditorDataGet = (config?: AxiosRequestConfig) => {
  return useQuery<SkillsOrderEditorGetResponse>(
    '/skills/skills-order-editor',
    skillsOrderEditorQueryKey,
    undefined,
    config,
  );
};

export const useSkillsOrderEditorPatch = (
  options: UseMutationOptions<
    ClientResponse<SkillsOrderEditorGetResponse>,
    ClientErrorResponse,
    SkillsOrderEditorPatchBody
  >,
) =>
  useMutation(
    'skills-order-editor-patch',
    ({ id, skillsOrder }) => ({
      endpoint: `/skills/skills-order-editor/${id}`,
      method: 'PATCH',
      params: skillsOrder,
    }),
    options,
  );
