import { SkillsOrderDefinition } from 'api/actions/skillsOrderEditor/skillsOrderEditorActions.types';
import { SKILLS_ORDER_GLOBAL_KEY } from '../SkillsOrderEditor.utils';

export const skillsOrderRecalculate = (skillsOrder: SkillsOrderDefinition): SkillsOrderDefinition => {
  if (!skillsOrder.hasOwnProperty(SKILLS_ORDER_GLOBAL_KEY)) {
    return skillsOrder;
  }

  const globalConfig = skillsOrder[SKILLS_ORDER_GLOBAL_KEY];
  const recalculatedSkillOrderData: SkillsOrderDefinition = {};
  Object.entries(skillsOrder).map(([position, skillsOrderForPosition]) => {
    if (position !== SKILLS_ORDER_GLOBAL_KEY) {
      recalculatedSkillOrderData[position] = {
        ...globalConfig,
        ...skillsOrderForPosition,
      };
    }
  });

  return recalculatedSkillOrderData;
};
