import { Table } from 'ui/tables/table/Table';
import { usePositionsListColumns } from '../hooks/usePositionsListColumns';
import { usePositionsQuery } from '../hooks/usePositionsQuery';
import { Position } from 'api/actions/positions/positionsActions.types';

import * as S from './PositionsList.styles';
import { filtersOperators, getPositionParams, keysMapper } from './PositionsList.utils';
import { PositionsListProps } from './PositionsList.types';

export const PositionsList = ({ globalFilter, queryParamsDecoded }: PositionsListProps) => {
  const columns = usePositionsListColumns();

  const { data, isLoading, isError } = usePositionsQuery({
    params: getPositionParams(queryParamsDecoded),
  });

  return (
    <Table<Position>
      keysMapper={keysMapper}
      filtersOperators={filtersOperators}
      sx={{ tableHead: S.tableHead }}
      columns={columns}
      data={data?.result}
      total={data?.total}
      isLoading={isLoading}
      isError={isError}
      globalFilter={globalFilter}
      defaultSort={[{ id: 'visibleName', desc: false }]}
    />
  );
};
