import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useLocale, useMutation, useSnackbar } from 'hooks';
import { deleteProfileMutation } from 'api/actions/profile/summary/profileSummaryActions';

import { UseDeleteProfileProps } from './useDeleteProfile.types';

export const useDeleteProfile = ({ profileId, onError, onSuccess }: UseDeleteProfileProps) => {
  const { showSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess } = useMutation('delete-profile', deleteProfileMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries('profiles');
      showSnackbar(formatMessage({ id: 'success.itemDeleted' }), 'success');
      onSuccess?.();
    },
    onError: () => {
      showSnackbar(formatMessage({ id: 'error.errorOccurred' }), 'error');
      onError?.();
    },
  });
  const deleteProfile = useCallback(async () => {
    await mutateAsync({ profileId });
  }, [mutateAsync, profileId]);

  return { deleteProfile, isLoading, isSuccess };
};
