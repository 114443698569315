import { Styles } from 'views/generatedProfile/theme/theme';

export const buttonsWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1.25,
};

export const button: Styles = {
  width: '100%',
};
