import SaveAlt from '@mui/icons-material/SaveAlt';

import { Button, Loader } from 'ui';
import { useGetMultipleResumes } from '../../hooks/useGetMultipleResumes/useGetMultipleResumes';

import { DownloadResumeButtonProps } from './DownloadResumeButton.types';
import * as S from './DownloadResumeButton.styles';

export const DownloadResumeButton = ({ userIds, sx }: DownloadResumeButtonProps) => {
  const { isFetching, refetch } = useGetMultipleResumes(userIds);

  if (!userIds.length) return null;

  return (
    <>
      <Button
        startIcon={<SaveAlt sx={S.icon} />}
        variant={'outlinedGray'}
        disabled={isFetching}
        sx={sx}
        label="profiles.downloadCvs"
        onClick={() => refetch()}
      >
        {isFetching && <Loader size={20} sx={S.loaderWrapper} sxLoaderIcon={S.icon} />}
      </Button>
    </>
  );
};
