import { useCurrentUser } from 'hooks';
import { useGetAchievements } from 'api/actions/profile/achievements/profileAchievementsActions';

export const useGetProfileAchievements = () => {
  const { user } = useCurrentUser();

  const { data, isLoading } = useGetAchievements(user?.id);

  return { achievements: data, isLoading };
};
