import { FormattedMessage } from 'react-intl';
import { Droppable } from 'react-beautiful-dnd';
import { Table } from 'lux/components';

import * as S from './AdminSectionTable.styles';
import { AdminSectionTableProps } from './AdminSectionTable.types';

export const AdminSectionTable = ({ ariaLabel, children, dragAndDropID }: AdminSectionTableProps) => (
  <Table>
    <Table.Table aria-label={ariaLabel}>
      <Table.Head>
        <Table.Row>
          <Table.Cell sx={ariaLabel === 'Seniorities table' ? S.nameStyles : undefined}>
            <FormattedMessage id="form.name" />
          </Table.Cell>

          {ariaLabel === 'Seniorities table' && (
            <Table.Cell>
              <FormattedMessage id="form.visibleName" />
            </Table.Cell>
          )}

          {ariaLabel === 'Technologies table' && (
            <Table.Cell>
              <FormattedMessage id="form.category" />
            </Table.Cell>
          )}
          <Table.Cell align="right" />
        </Table.Row>
      </Table.Head>

      {dragAndDropID ? (
        <Droppable droppableId={dragAndDropID}>
          {(provided) => (
            <Table.Body ref={provided.innerRef} {...provided.droppableProps}>
              {children}
              {provided.placeholder}
            </Table.Body>
          )}
        </Droppable>
      ) : (
        <Table.Body>{children}</Table.Body>
      )}
    </Table.Table>
  </Table>
);
