import { Styles } from 'lux/theme';

export const containerStyles: Styles = {
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const wrapperStyles: Styles = {
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const logoStyles: Styles = {
  width: '300px',
  margin: '3rem 3rem',
};

export const linkStyles: Styles = {
  width: '100%',
};
