import { IconButton } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete-icon.svg';
import { Tooltip } from 'ui';

import { DeleteButtonProps } from './DeleteButton.types';

export const DeleteButton = (props: DeleteButtonProps) => (
  <Tooltip title={props.disabled ? '' : 'tooltips.delete'}>
    <IconButton data-testid="delete" aria-label="delete" sx={{ p: 0, borderRadius: 2 }} {...props}>
      {props.disabled ? <DeleteForeverOutlinedIcon /> : <DeleteIcon />}
    </IconButton>
  </Tooltip>
);
