import Joi from 'joi';
import { updatedDiff } from 'deep-object-diff';

import { ProfileData } from 'api/actions/resume/resumeActions.types';
import { isEmpty } from 'utils';

import { EditorFormFields, EditorFormValues } from './Editor.types';

export const getDefaultValues = (data?: ProfileData) => {
  return {
    [EditorFormFields.Position]: data?.positionName || '',
  };
};

export const formSchema = Joi.object({
  [EditorFormFields.Position]: Joi.string(),
});

export const compareData = (formData: EditorFormValues, defaultData?: ProfileData): Partial<ProfileData> | null => {
  const data = getDefaultValues(defaultData);
  const dataToSubmit = updatedDiff(data, formData);

  return isEmpty(dataToSubmit) ? null : dataToSubmit;
};
