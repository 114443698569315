import { useGetProfileSkills } from 'api/actions/profile/skills/profileSkillsActions';
import { useGetSkills } from 'api/actions/skills/skillsActions';
import { SkillType } from 'api/actions/skills/skillsActions.types';
import { useCurrentUser } from 'hooks';

export const useSkillsQuery = (skillsType?: SkillType) => {
  const { user } = useCurrentUser();

  const { data: skillOptions, isLoading: skillsIsLoading } = useGetSkills({
    params: {
      ...(skillsType ? { ['filter[skill.type][eq]']: skillsType } : {}),
      ['sort[skill.name]']: 'ASC',
    },
  });

  const { data: profileSkills, isLoading: profileSkillsIsLoading } = useGetProfileSkills(skillsType, user?.id, false, {
    params: {
      relations: ['profileSkill.skill'],
      ['filter[profileSkillSkill.type][eq]']: skillsType,
    },
  });

  const { data: profileSpecialSkills, isLoading: isProfileSpecialSkillsLoading } = useGetProfileSkills(
    skillsType,
    user?.id,
    true,
    {
      params: {
        relations: ['profileSpecialSkill.skill'],
      },
    },
  );

  const isLoading = skillsIsLoading || profileSkillsIsLoading || isProfileSpecialSkillsLoading;

  return {
    skillOptions: skillOptions?.result,
    profileSkills,
    profileSpecialSkills,
    isLoading,
  };
};
