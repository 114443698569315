import { FilterOperator } from 'utils';

export const TAGS_LIMIT = 10;

export const filtersOperators = {
  name: FilterOperator.include,
};

export const keysMapper = {
  name: 'tag.name',
};

export const getTagsParams = (queryParams: Record<string, string | number>) => {
  const isSortParams = Object.keys(queryParams).some((key) => key.startsWith('sort'));

  const defaultSortParams = isSortParams ? {} : { 'sort[tag.name]': 'ASC' };

  return {
    page: '1',
    limit: '10',
    ...defaultSortParams,
    ...queryParams,
  };
};
