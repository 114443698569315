import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

import {
  Project,
  DeleteProjectMutationArgs,
  ProjectsGetResponse,
  PatchProjectMutationArgs,
  PostProjectMutationArgs,
} from './projectsActions.types';

export const useProjectGet = (
  projectId: string,
  options?: UseQueryOptions<Project, ClientErrorResponse>,
  config?: AxiosRequestConfig,
) => useQuery<Project>(`/projects/project/${projectId}`, ['project', projectId], { cacheTime: 0, ...options }, config);

export const useProjectPatch = (
  options: UseMutationOptions<ClientResponse<Project>, ClientErrorResponse, PatchProjectMutationArgs>,
) =>
  useMutation(
    'patchProject',
    ({ projectId, body }) => ({
      endpoint: `/projects/project/${projectId}`,
      method: 'PATCH',
      params: body,
    }),
    options,
  );

export const useProjectDelete = (
  options: UseMutationOptions<ClientResponse<Project>, ClientErrorResponse, DeleteProjectMutationArgs>,
) =>
  useMutation(
    'deleteProject',
    ({ projectId }) => ({
      endpoint: `/projects/project/${projectId}`,
      method: 'DELETE',
    }),
    options,
  );

export const projectsGetQueryKey = 'projects';

export const useProjectsGet = (config?: AxiosRequestConfig) =>
  useQuery<ProjectsGetResponse>(
    '/projects',
    [projectsGetQueryKey, config],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );

export const useProjectsPost = (
  options: UseMutationOptions<ClientResponse<Project>, ClientErrorResponse, PostProjectMutationArgs>,
) =>
  useMutation(
    'postProject',
    ({ body }) => ({
      endpoint: '/projects/project',
      method: 'POST',
      params: body,
    }),
    options,
  );
