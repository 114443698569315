import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { getTagsQueryKey, useTagPatch } from 'api/actions/tags/tagsActions';
import { useSnackbar } from 'hooks';
import { UsePatchTagArgs } from 'views/adminSection/tags/hooks/usePatchTag.types';

export const usePatchTag = ({ tagId, onSuccess }: UsePatchTagArgs) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useTagPatch({
    onSuccess: () => {
      queryClient.invalidateQueries(getTagsQueryKey);
      showSnackbarSuccess('success.itemEdited');
      onSuccess();
    },
    onError: (error) => {
      showSnackbarError(error);
    },
  });

  const patchTag = useCallback(
    ({ name }: { name: string }) => {
      mutate({ id: tagId, name });
    },
    [mutate, tagId],
  );

  return { patchTag, isPatchTagLoading: isLoading, patchTagError: error };
};
