export const removeDuplicates = (values: ({ name: string; id?: undefined } | { id: string; name?: undefined })[]) =>
  values.filter(
    (v, i, a) =>
      a.findIndex((v2) => {
        if ('name' in v && 'name' in v2) {
          return v2.name === v.name;
        }
        if ('id' in v && 'id' in v2) {
          return v2.id === v.id;
        }
      }) === i,
  );
