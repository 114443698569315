import { ReactQueryDevtools } from 'react-query/devtools';
import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { LocaleContextController } from 'context/locale/localeContextController/LocaleContextController';
import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { SnackbarContextController } from 'context/snackbar/snackbarContextController/SnackbarContextController';
import { AppSettingsContextController } from 'context/appSettings/appSettingsContextController/AppSettingsContextController';
import { UserContextController } from '../context/user/userContextController/UserContextController';
import { msalConfig } from 'msalConfig';

import { AppProvidersProps } from './AppProviders.types';
import { ThemeProvider } from './ThemeProvider';

const openReactQueryDevtools = import.meta.env.DEV;

export const msalInstance = new PublicClientApplication(msalConfig);

export const AppProviders = ({ children }: AppProvidersProps) => (
  <MsalProvider instance={msalInstance}>
    <ThemeProvider>
      <LocaleContextController>
        <ClientContextController>
          <SnackbarContextController>
            <AuthContextController>
              <UserContextController>
                <AppSettingsContextController>{children}</AppSettingsContextController>
              </UserContextController>
            </AuthContextController>
          </SnackbarContextController>
          {openReactQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
        </ClientContextController>
      </LocaleContextController>
    </ThemeProvider>
  </MsalProvider>
);
