import { TextField, InlineEdit, Typography } from 'lux/components';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';

import { Separator, EditButton } from 'ui';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AchievementsEditFormValues, AchievementsFormValues } from '../Achievements.types';

import { AchievementProps } from './Achievement.types';
import * as S from './Achievement.styles';

export const Achievement = ({
  name,
  initialValue,
  onRemoveButtonClick,
  onEditButtonClick,
  isEditError,
  isEditLoading,
}: AchievementProps) => {
  const { formatMessage } = useLocale();
  const { handleSubmit, setValue } = useFormContext<AchievementsEditFormValues>();
  const setInitialValue = () => setValue(name, initialValue);

  const onError = (errors?: FieldErrors<AchievementsFormValues>) => {
    if (errors) {
      setInitialValue();
    }
  };

  return (
    <>
      <Box sx={S.wrapperStyles}>
        <InlineEdit
          header=""
          input={
            <Controller
              name={name}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <>
                    <TextField
                      size="small"
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      fullWidth
                    />
                    {error && <Typography variant="helperText">{error.message}</Typography>}
                  </>
                );
              }}
            />
          }
          onCancel={setInitialValue}
          loading={isEditLoading}
          onSubmit={handleSubmit(onEditButtonClick, onError)}
          renderDataDisplay={(params) => (
            <Box sx={S.displayDataWrapperStyles}>
              <Controller
                name={name}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <Box sx={S.inputWrapperStyles}>
                      <TextField
                        size="small"
                        onChange={(event) => onChange(event.target.value)}
                        value={value}
                        fullWidth
                        sx={S.inputDisabledStyles}
                      />
                      {error && <Typography variant="helperText">{error.message}</Typography>}
                    </Box>
                  );
                }}
              />
              <EditButton sx={S.editButtonStyles} {...params} />
              <IconButton onClick={onRemoveButtonClick}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        />
      </Box>
      {isEditError && <Typography variant="helperText">{formatMessage({ id: 'error.errorOccurred' })}</Typography>}
      <Separator />
    </>
  );
};
