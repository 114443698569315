import { Breadcrumbs, Button, Grid, Tabs } from 'lux/components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useIntl } from 'react-intl';

import { BorderBox, Typography } from 'ui';
import { canShowBreadcrumbs, getBreadcrumbs } from 'ui/breadcrumbs/breadcrumbs.utils';
import { useUser } from 'hooks';

import { AdminSectionWrapperProps } from './AdminSectionWrapper.types';
import * as S from './AdminSectionWrapper.styles';
import { adminSectionTabs } from './AdminSectionWrapper.utils';

export const AdminSectionWrapper = ({ title, children, action, tabs }: AdminSectionWrapperProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { isAdmin } = useUser();

  const breadcrumbs = getBreadcrumbs(intl);
  const showBreadcrumbs = canShowBreadcrumbs(location.pathname, breadcrumbs);

  return (
    <>
      {showBreadcrumbs && (
        <Grid item xs={12} sx={S.breadcrumbs}>
          <Breadcrumbs
            currentPath={location.pathname}
            linkNameMapper={breadcrumbs}
            matchPath={(pattern, path) => Boolean(matchPath(pattern, path))}
            renderButtonComponent={(props) => <Button component={Link} {...props} />}
          />
        </Grid>
      )}
      <Grid item xs={12} sx={S.titleWrapper}>
        <Typography variant="h4">{title}</Typography>
        {action}
      </Grid>

      {tabs && isAdmin && (
        <Grid item xs={12} lg={9} xl={8} sx={S.tabs}>
          <Tabs>
            <Tabs.TabList value={location.pathname}>
              {adminSectionTabs[tabs].map(({ label, href }) => (
                <Tabs.Tab
                  sx={S.tab(href === location.pathname)}
                  label={intl.formatMessage({ id: label })}
                  key={label}
                  value={href}
                  onClick={() => navigate(href)}
                />
              ))}
            </Tabs.TabList>
          </Tabs>
        </Grid>
      )}

      <Grid item xs={12} lg={9} xl={8} sx={S.content}>
        <BorderBox>{children}</BorderBox>
      </Grid>
    </>
  );
};
