import { AutocompleteRenderGetTagProps, Box, createFilterOptions, ListItemIcon } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { HTMLAttributes, useState } from 'react';
import { AutocompleteGeneric } from 'lux/components';
import AddIcon from '@mui/icons-material/Add';

import { normalizeValue } from 'ui/autocomplete/Autocomplete.utils';
import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { useLocale } from 'hooks';
import { ProjectModalFormFields } from '../../../ProjectModal.types';
import { mergeStyles } from 'utils/mergeStyles';

import { TechnologyAutocompleteProps } from './TechnologyAutocomplete.types';
import * as S from './TechnologyAutocomplete.styles';

export const TechnologyAutocomplete = <
  T extends AutocompleteOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean,
  FreeSolo extends boolean | undefined,
>({
  options,
  name,
  label,
  sx = {},
  ...props
}: TechnologyAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const [inputValue, setInputValue] = useState('');
  const { formatMessage } = useLocale();
  const { setValue, watch, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <AutocompleteGeneric
            {...props}
            sx={mergeStyles(S.autocomplete, sx)}
            label={formatMessage({ id: label })}
            freeSolo={true as FreeSolo}
            multiple={true as Multiple}
            componentsProps={{
              popper: {
                sx: S.popper,
              },
            }}
            value={value}
            onChange={(e, value) => {
              const newValue = (value as AutocompleteOption[]).map((v) => {
                if (v.name === `Add "${v.id}"`) {
                  return { id: v.id, name: v.id };
                }

                return v;
              });

              const uniqueIds: string[] = [];
              const valueWithoutDuplicates = newValue.filter((valueEntry: AutocompleteOption): boolean => {
                if (uniqueIds.indexOf(valueEntry.id) > -1) {
                  return false;
                }
                uniqueIds.push(valueEntry.id);

                return true;
              });
              onChange(valueWithoutDuplicates);
              trigger(name);
            }}
            onBlur={(e) => {
              if (props.onBlur) {
                props.onBlur(e);
              }

              trigger(name);
            }}
            disableCloseOnSelect
            options={options}
            inputValue={inputValue}
            onInputChange={(_, inputValue) => {
              setInputValue(inputValue);
            }}
            renderOption={(props: HTMLAttributes<HTMLLIElement>, option: AutocompleteOption) => {
              const isSelected = !!value.filter(({ id }: { id: string }) => id === option.id).length;
              const isFreeSolo = option.name === `Add "${option.id}"`;

              const customOnClick = () => {
                if (isSelected) {
                  return setValue(
                    name,
                    value.filter(({ id }: { id: string }) => id !== option.id),
                  );
                }

                const newValue = (option: AutocompleteOption) => {
                  if (isFreeSolo) {
                    return { id: option.id, name: option.id };
                  }

                  return option;
                };

                setValue(name, [...value, newValue(option)]);
                setInputValue('');
              };

              return (
                <Box onClick={customOnClick} key={option.id}>
                  <AutocompleteGeneric.MenuItem {...props} selected={isSelected}>
                    {isFreeSolo && (
                      <ListItemIcon sx={S.addNewItemList}>
                        <AddIcon />
                      </ListItemIcon>
                    )}
                    {option.name}
                  </AutocompleteGeneric.MenuItem>
                </Box>
              );
            }}
            renderTags={(value, getTagProps: AutocompleteRenderGetTagProps) =>
              value?.map(({ name, id }, index) => {
                const { key, onDelete, ...rest } = getTagProps({ index });
                const profileProjectTechnologies = watch(ProjectModalFormFields.ProfileProjectTechnologies);

                const handleProfileTechnologyDelete = () => {
                  setValue(
                    ProjectModalFormFields.ProfileProjectTechnologies,
                    profileProjectTechnologies.filter((t: AutocompleteOption) => t.id !== id),
                  );
                };

                return (
                  <AutocompleteGeneric.Chip
                    {...rest}
                    key={key}
                    label={name}
                    {...(props.disabled && {
                      sx: S.chip,
                    })}
                    onDelete={(e) => {
                      onDelete(e);
                      if (!!profileProjectTechnologies?.find((t: AutocompleteOption) => t.id === id)) {
                        handleProfileTechnologyDelete();
                      }
                    }}
                  />
                );
              })
            }
            getOptionLabel={normalizeValue}
            autoHighlight
            clearOnBlur
            filterOptions={(options, params) => {
              const { inputValue } = params;
              const trimmedInputValue = inputValue.trim();

              const filter = createFilterOptions<T>();
              const filtered = filter(options, { ...params, ...{ inputValue: trimmedInputValue } });

              const isExistingOption = options.some((option) => {
                return trimmedInputValue.toLowerCase() === option.name.toLowerCase();
              });

              if (trimmedInputValue !== '' && !isExistingOption) {
                filtered.push({
                  id: trimmedInputValue,
                  name: `Add "${trimmedInputValue}"`,
                } as T);
              }

              return filtered;
            }}
          />
        );
      }}
    />
  );
};
