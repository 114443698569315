import { AxiosRequestConfig } from 'axios';

import { authStorage } from 'context/auth/authStorage/AuthStorage';

import { filteredAndSortedParams } from './utils';

export const requestSuccessInterceptor = async (config: AxiosRequestConfig) => {
  if (authStorage.accessToken) {
    return {
      ...config,
      params: filteredAndSortedParams(config.params),
      headers: {
        ...config.headers,
        Authorization: `Bearer ${authStorage.accessToken}`,
      },
      withCredentials: false,
    };
  }

  return config;
};
