import { Profile } from '../profiles/profilesActions.types';
import { Skill } from '../skills/skillsActions.types';

export enum TechnologiesSurveyItemFamiliarity {
  COMFORTABLE = 'COMFORTABLE',
  ALITTLE = 'ALITTLE',
  DONTKNOW = 'DONTKNOW',
}
export enum TechnologiesSurveyItemPreference {
  LOVE = 'LOVE',
  WHATEVER = 'WHATEVER',
  HATE = 'HATE',
  NA = 'NA',
}

export type TechnologiesSurveyItem = {
  skillId: string;
  profileId: string;
  familiarity?: TechnologiesSurveyItemFamiliarity;
  preference?: TechnologiesSurveyItemPreference;
  skill: Skill;
};

export type PostTechnologiesSurveyBody = {
  items: Omit<TechnologiesSurveyItem, 'profileId' | 'skill'>[];
};

export type TechnologiesSurveyGetResponse = {
  profile: Profile;
  answeredItems: TechnologiesSurveyItem[];
  missingSkills: Skill[];
};

export type PostTechnologiesSurveyMutationArgs = {
  profileId?: string;
  body: Partial<PostTechnologiesSurveyBody>;
};

export type TechnologiesSurveyPostResponse = {
  result: {
    status: boolean;
  };
};
