import { Styles } from 'lux/theme';

export const inputWrapperStyles: Styles = {
  mb: 2.5,
};

export const addNewTechnologyListItem: Styles = {
  pt: '12px',
  pb: '12px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: 'grey.100',
  },
};

export const addNewTechnologyText: Styles = {
  fontWeight: '500',
  lineHeight: '100%',
  ml: '6px',
  mb: '-2px',
  color: 'primary.main',
};
