import { Typography, Box } from '@mui/material';

import { useLocale } from 'hooks';

import * as styles from './Project.styles';
import { ProjectProps } from './Project.types';

export const Project = ({ project }: ProjectProps) => {
  const { formatMessage } = useLocale();

  const { name, technologies, shortDescription, isPrivate } = project;

  return (
    <Box py={1}>
      <Typography variant="body1" component="span" sx={styles.name}>
        {name}
      </Typography>
      {isPrivate && (
        <Typography variant="body2" component="span" sx={styles.visibility}>{` (${formatMessage({
          id: 'profileProject.private',
        })})`}</Typography>
      )}

      <Typography variant="body2" color="text.primary" sx={styles.description}>
        {shortDescription}
      </Typography>
      <Box sx={styles.technologies}>
        {technologies.map(({ technology: { id, name } }) => (
          <Box key={id} component="span" sx={styles.technology}>
            {name}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
