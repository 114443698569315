import { GetErrorMessagesArgs } from './getErrorMessages.types';

export const getErrorMessages = ({ formatMessage, maxLength, pattern, date, min }: GetErrorMessagesArgs) => {
  return {
    'any.required': formatMessage({ id: 'validation.any.required' }),
    'string.base': formatMessage({ id: 'validation.string.base' }),
    'string.empty': formatMessage({ id: 'validation.string.empty' }),
    'string.email': formatMessage({ id: 'validation.string.email' }),
    'string.uri': formatMessage({ id: 'validation.string.uri' }),
    'object.empty': formatMessage({ id: 'validation.object.empty' }),
    'date.base': formatMessage({ id: 'validation.date.base' }),
    'date.greater': formatMessage({ id: 'validation.date.greater' }),
    'number.integer': formatMessage({ id: 'validation.number.integer' }),
    ...(date ? { 'date.min': formatMessage({ id: 'validation.date.min' }, { date }) } : {}),
    ...(pattern
      ? {
          'string.pattern.base': formatMessage({ id: 'validation.string.pattern.base' }, { pattern }),
        }
      : {}),
    ...(maxLength ? { 'string.max': formatMessage({ id: 'validation.string.maxLength' }, { maxLength }) } : {}),
    ...(min ? { 'number.min': formatMessage({ id: 'validation.number.min' }, { min }) } : {}),
  };
};
