import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tag } from 'api/actions/tags/tagsActions.types';
import { TableActions } from 'views/adminSection/tags/components/tableActions/TableActions';

export const useTagsColumns = () => {
  const columnHelper = createColumnHelper<Tag>();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          id: 'name',
          header: () => <FormattedMessage id="tags.table.headers.name" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('id', {
          id: 'actions',
          header: () => <FormattedMessage id="tags.table.headers.actions" />,
          cell: TableActions,
          enableColumnFilter: false,
          enableSorting: false,
        }),
      ] as ColumnDef<Tag>[],
    [columnHelper],
  );

  return { columns };
};
