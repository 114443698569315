import { AxiosRequestConfig } from 'axios';

import { useQuery } from 'hooks/useQuery/useQuery';
import { PositionsGetResponse } from 'api/actions/positions/positionsActions.types';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

export const adminPositionsQueryKey = 'admin-positions';

export const usePositionsQuery = (config?: AxiosRequestConfig) =>
  useQuery<PositionsGetResponse>(
    '/positions',
    [adminPositionsQueryKey, config],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );
