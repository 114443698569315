import { Navigate } from 'react-router-dom';

import { AccessControl } from 'ui';
import { AppRoute } from '../AppRoute.enum';
import { SenioritiesContainer } from 'views/adminSection/seniorities/SenioritiesContainer';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const AuthorizedSeniority = () => (
  <AccessControl allowedContent={ContentType.Seniority} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <SenioritiesContainer />
  </AccessControl>
);
