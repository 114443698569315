import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { languagesQueryKey, useLanguagePost as useLanguagePostHook } from 'api/actions/languages/languagesActions';
import { useSnackbar } from 'hooks';
import { PostLanguageBody } from 'api/actions/languages/languagesActions.types';

export const usePostLanguage = (onSuccess: VoidFunction) => {
  const queryClient = useQueryClient();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { mutateAsync, isError, isLoading, error } = useLanguagePostHook({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries([languagesQueryKey]);
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const addLanguage = useCallback(
    async (body: PostLanguageBody) => {
      await mutateAsync({ body });
    },
    [mutateAsync],
  );

  return { addLanguage, isError, isLoading, error };
};
