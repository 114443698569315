import { LinkComponentType, TopBarProps } from 'lux/components/organisms/topBar/TopBar.types';

import { ContentType } from 'config/userPermisions/userPermisions.types';
import { hasPermission } from 'ui/accessControl/AccessControl.utils';
import { Profile } from 'api/actions/profiles/profilesActions.types';

import { MenuItemDefinition } from './menu/MenuDefinitions.types';
import { UiLinkSubMenuLinkProps } from './subMenu/UiLInkSubMenu.types';
import { UiLinkSubMenu } from './subMenu/UiLinkSubMenu';
import { UiLink } from './menu/UiLink';
import { MenuItem } from './UiTopBar.types';

export const isAllowed = (user: Profile | undefined, allowedContent: ContentType, hasProfileAccess?: boolean) =>
  hasPermission({
    userRole: user?.accessType,
    allowedContent,
    hasProfileAccess,
  }).isAllowed;

export const getMenuItemWithSubMenu = (
  subMenuDefinition: MenuItemDefinition[],
  pathname: string,
  search: string,
  user: Profile | undefined,
): MenuItem | undefined => {
  const subMenuItems: UiLinkSubMenuLinkProps[] = [];
  subMenuDefinition.forEach((menuItemDefinition: MenuItemDefinition) => {
    if (menuItemDefinition.contentType && !isAllowed(user, menuItemDefinition.contentType)) {
      return;
    }
    subMenuItems.push({
      label: menuItemDefinition.label,
      linkWithQueryParams: pathname === menuItemDefinition.to ? menuItemDefinition.to + search : menuItemDefinition.to,
      target: menuItemDefinition.target,
      to: menuItemDefinition.to,
    });
  });

  if (!subMenuItems.length) {
    return;
  }

  return {
    component: <UiLinkSubMenu subMenuItems={subMenuItems} />,
    label: `navigator.${subMenuItems[0].label}`,
  };
};

export const getMenuItems = (
  menuItemDefinitions: MenuItemDefinition[],
  pathname: string,
  search: string,
  user: Profile | undefined,
) => {
  const menuItems: TopBarProps<LinkComponentType>['menuItems'] = [];
  menuItemDefinitions.forEach((menuItemDefinition) => {
    const menuItem = getMenuItem(menuItemDefinition, pathname, search, user);
    if (menuItem) {
      menuItems.push(menuItem);
    }
  });

  return menuItems;
};

export const getMenuItem = (
  menuItemDefinition: MenuItemDefinition,
  pathname: string,
  search: string,
  user: Profile | undefined,
) => {
  const { label, contentType, target, to } = menuItemDefinition;
  if (contentType && !isAllowed(user, contentType)) {
    return;
  }

  const linkWithQueryParams = pathname === to ? to + search : to;

  return {
    component: <UiLink target={target} linkWithQueryParams={linkWithQueryParams} to={to} />,
    label: `navigator.${label}`,
  };
};
