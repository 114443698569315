import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { useMutation, useQuery } from 'hooks';
import { ClientErrorResponse, ClientResponse, MutationContext } from '../../types/types';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

import {
  CategoriesGetResponse,
  Category,
  CategoryPostResponse,
  DeleteCategoryMutationArgs,
  PatchCategoryMutationArgs,
  PostCategoryMutationArgs,
} from './categoriesActions.types';

export const adminCategoriesQueryKey = 'admin-categories';

export const useGetCategories = (config?: AxiosRequestConfig) =>
  useQuery<CategoriesGetResponse>(
    '/skills/categories',
    [adminCategoriesQueryKey, config?.params],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );

export const postCategoryMutation: MutationFn<PostCategoryMutationArgs, CategoryPostResponse> = ({ body }) => ({
  endpoint: `/skills/category`,
  method: 'POST',
  params: body,
});

export const useCategoryPatch = (
  options: UseMutationOptions<
    ClientResponse<Category>,
    ClientErrorResponse,
    PatchCategoryMutationArgs,
    MutationContext<Category>
  >,
) =>
  useMutation(
    'patchLanguage',
    ({ id, name, displayOrder }) => ({
      endpoint: `/skills/category/${id}`,
      method: 'PATCH',
      params: { name, displayOrder },
    }),
    options,
  );

export const deleteCategoryMutation: MutationFn<DeleteCategoryMutationArgs> = ({ categoryId }) => ({
  endpoint: `/skills/category/${categoryId}`,
  method: 'DELETE',
});
