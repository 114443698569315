import { Styles } from 'lux/theme';

export const technology: Styles = {
  px: 1.5,
  py: 0.75,
  borderRadius: 20,
  mr: 1,
  mb: 1,
  fontSize: 14,
  cursor: 'pointer',
  backgroundColor: 'primary.main',
  color: 'common.white',
  fontWeight: 500,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 1.5,
};
