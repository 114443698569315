import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { postPositionMutation } from 'api/actions/positions/positionsActions';
import { PostPositionBody } from 'api/actions/positions/positionsActions.types';

import { adminPositionsQueryKey } from './usePositionsQuery';

export const usePostPosition = (onSuccess: VoidFunction) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, isLoading, error } = useMutation('postPosition', postPositionMutation, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(adminPositionsQueryKey);
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const addPosition = useCallback(
    async (body: PostPositionBody) => {
      await mutateAsync({
        body,
      });
    },
    [mutateAsync],
  );

  return { addPosition, isError, isLoading, error };
};
