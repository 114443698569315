import { Styles } from 'lux/theme';

export const wrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 8,
  minHeight: 300,
};

export const textPlaceholder: Styles = {
  display: 'flex',
  justifyContent: 'center',
  pb: 4,
  fontWeight: 500,
};

export const loader: Styles = {
  pb: 2,
};
