import { Box } from '@mui/material';

import { Button, Modal, Typography } from 'ui';

import { ConfirmClosingModalProps } from './ConfirmClosingModal.types';
import * as S from './ConfirmClosingModal.styles';

export const ConfirmClosingModal = ({ isOpen, onModalClose, onConfirm, maxWidth = 400 }: ConfirmClosingModalProps) => {
  return (
    <Modal dataTestId="confirmCloseModal" isOpen={isOpen} onModalClose={onModalClose} maxWidth={maxWidth}>
      <Typography sx={S.textStyles}>{'form.closeModalInfo'}</Typography>
      <Box sx={S.buttonBoxStyles}>
        <Button dataTestId="close" sx={S.buttonStyles} onClick={onConfirm} label="form.close"></Button>
        <Button dataTestId="back" sx={S.buttonStyles} onClick={onModalClose} label="form.backToEdit"></Button>
      </Box>
    </Modal>
  );
};
