import Joi from 'joi';
import { IntlShape } from 'react-intl';

import { ClientErrorResponse } from 'api/types/types';
import { FilterOperator } from 'utils';

import { ProjectRolesFormFields } from './ProjectRoles.types';

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) =>
  Joi.object({
    [ProjectRolesFormFields.Name]: Joi.string()
      .required()
      .max(100)
      .trim()
      .messages({
        'string.empty': formatMessage({ id: 'error.required' }),
        'string.max': formatMessage({ id: 'validation.string.max' }),
      }),
  });

export const defaultValues = {
  [ProjectRolesFormFields.Name]: '',
};

export const filtersOperators = {
  name: FilterOperator.include,
};

export const keysMapper = {
  name: 'role.name',
};

export const getModalErrorHandler = (
  error: {
    patchError: ClientErrorResponse | null;
    postError: ClientErrorResponse | null;
  },
  mutationErrorReset: {
    post: VoidFunction;
    patch: VoidFunction;
  },
  isOpen: { patch: boolean; post: boolean },
) => {
  if (isOpen.patch) {
    return { error: error.patchError, reset: mutationErrorReset.patch };
  }
  if (isOpen.post) {
    return { error: error.postError, reset: mutationErrorReset.post };
  }

  return { error: null, reset: null };
};

export const getProjectsRolesParams = (queryParams: Record<string, string | number>) => {
  const isSortParams = Object.keys(queryParams).some((key) => key.startsWith('sort'));

  const defaultSortParams = isSortParams ? {} : { 'sort[role.name]': 'ASC' };

  return {
    page: '1',
    limit: '10',
    ...defaultSortParams,
    ...queryParams,
  };
};
