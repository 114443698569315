import { Navigate } from 'react-router-dom';

import { ContentType } from 'config/userPermisions/userPermisions.types';
import { AppRoute } from '../AppRoute.enum';
import { AccessControl } from 'ui';
import { SkillsOrderEditorContainer } from 'views/adminSection/skillsOrderEditor/SkillsOrderEditorContainer';

export const AuthorizedJsonEditor = () => (
  <AccessControl allowedContent={ContentType.SkillsOrderEditor} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <SkillsOrderEditorContainer />
  </AccessControl>
);
