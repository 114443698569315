import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { languagesQueryKey, useLanguagePatch as useLanguagePatchHook } from 'api/actions/languages/languagesActions';
import { Language, LanguagesGetResponse } from 'api/actions/languages/languagesActions.types';
import { useSnackbar } from 'hooks';
import { DragAndDropBody } from 'api/types/types';
import { dragAndDropDataReorder } from 'utils/dragAndDropDataReorder/dragAndDropDataReorder';

export const usePatchLanguage = (languageId?: string, onSuccess?: VoidFunction) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, error, reset, isLoading } = useLanguagePatchHook({
    onMutate: ({ queryParams, sourceIndex, destinationIndex }) => {
      queryClient.cancelQueries([languagesQueryKey]);

      const previousData = queryClient.getQueryData<LanguagesGetResponse>([languagesQueryKey, queryParams]);

      if (!previousData || sourceIndex === undefined || destinationIndex === undefined) {
        return;
      }

      const reorderedData = dragAndDropDataReorder<Language>({ data: previousData, sourceIndex, destinationIndex });
      queryClient.setQueryData<LanguagesGetResponse>([languagesQueryKey, queryParams], reorderedData);

      return reorderedData;
    },

    onSuccess: () => {
      onSuccess && onSuccess();
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error, { queryParams }, context) => {
      if (context) {
        queryClient.setQueryData<LanguagesGetResponse>([languagesQueryKey, queryParams], context);
      }
      showSnackbarError(error);
    },
    onSettled: () => {
      queryClient.refetchQueries([languagesQueryKey]);
    },
  });

  const editLanguage = useCallback(
    async (body: Pick<Language, 'name'>) => {
      if (!languageId) {
        throw new Error('Language id is missing');
      }
      await mutateAsync({ ...body, id: languageId });
    },
    [mutateAsync, languageId],
  );

  const reorderLanguage = useCallback(
    async (body: DragAndDropBody) => {
      await mutateAsync(body);
    },
    [mutateAsync],
  );

  return { editLanguage, reorderLanguage, isError, error, reset, isLoading };
};
