import { Link } from 'react-router-dom';
import { Button } from 'lux/components';

import { ReactComponent as PreviewIcon } from 'assets/icons/zip-file-search.svg';
import { Tooltip } from 'ui';

import { PreviewButtonLinkProps } from './PreviewButtonLink.types';
import * as S from './PreviewButtonLink.styles';

export const PreviewButtonLink = ({ disabled, tooltipTitle, testId, ...props }: PreviewButtonLinkProps) => {
  if (disabled) {
    return (
      <Tooltip title={disabled ? '' : tooltipTitle || 'tooltips.preview'}>
        <Button adata-testid={testId || 'preview'} iconOnly disabled aria-label="preview" sx={S.previewButton}>
          <PreviewIcon />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipTitle || 'tooltips.preview'}>
      <Link data-testid={testId || 'preview'} {...props}>
        <Button aria-label="preview" iconOnly sx={S.previewButton}>
          <PreviewIcon />
        </Button>
      </Link>
    </Tooltip>
  );
};
