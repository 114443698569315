import { componentsLibTheme, Styles } from 'lux/theme';

export const sectionStyles: Styles = {
  '&:not(:first-of-type)': {
    mt: 5,
  },
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${componentsLibTheme.palette.border.default}`,
  },
  pb: 5,
  '& > div:last-of-type': {
    mb: 0,
  },
};
