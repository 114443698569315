import { UserRole } from 'api/types/types';

export enum Actions {
  READ = 'READ',
  WRITE = 'WRITE',
}

export enum ContentType {
  AppSettings = 'appSettings',
  UserPanel = 'userPanel',
  AdminPanel = 'adminPanel',
  Dashboard = 'dashboard',
  Home = 'home',
  HomeAdmin = 'homeAdmin',
  GeneratedProfile = 'generatedProfile',
  ProfileEditor = 'profileEditor',
  RapidResumeEditor = 'rapidResumeEditor',
  Profiles = 'profiles',
  Positions = 'positions',
  Seniority = 'seniority',
  Languages = 'languages',
  Categories = 'categories',
  Projects = 'projects',
  ProjectRoles = 'projectRoles',
  Skills = 'skills',
  Certificates = 'certificates',
  ProfileCertificates = 'profileCertificates',
  Tags = 'tags',
  SkillsOrderEditor = 'skillsOrderEditor',
  TechnologiesSurvey = 'technologiesSurvey',
}

export const USER_PERMISSIONS = {
  [UserRole.ADMIN]: [
    ContentType.AppSettings,
    ContentType.Dashboard,
    ContentType.AdminPanel,
    ContentType.UserPanel,
    ContentType.Home,
    ContentType.HomeAdmin,
    ContentType.GeneratedProfile,
    ContentType.ProfileEditor,
    ContentType.TechnologiesSurvey,
    ContentType.RapidResumeEditor,
    [ContentType.Profiles, Actions.READ, Actions.WRITE],
    [ContentType.Positions, Actions.READ, Actions.WRITE],
    [ContentType.Seniority, Actions.READ, Actions.WRITE],
    [ContentType.Languages, Actions.READ, Actions.WRITE],
    [ContentType.Categories, Actions.READ, Actions.WRITE],
    [ContentType.Projects, Actions.READ, Actions.WRITE],
    [ContentType.ProjectRoles, Actions.READ, Actions.WRITE],
    [ContentType.Skills, Actions.READ, Actions.WRITE],
    [ContentType.Certificates, Actions.READ, Actions.WRITE],
    [ContentType.ProfileCertificates, Actions.READ, Actions.WRITE],
    [ContentType.Tags, Actions.READ, Actions.WRITE],
    [ContentType.SkillsOrderEditor, Actions.READ, Actions.WRITE],
  ],
  [UserRole.USER]: [
    ContentType.AppSettings,
    ContentType.Dashboard,
    ContentType.UserPanel,
    ContentType.Home,
    ContentType.ProfileEditor,
    ContentType.TechnologiesSurvey,
    ContentType.GeneratedProfile,
  ],
  [UserRole.PROFILE_READ]: [
    ContentType.AppSettings,
    ContentType.UserPanel,
    ContentType.ProfileEditor,
    ContentType.TechnologiesSurvey,
    ContentType.Dashboard,
    ContentType.AdminPanel,
    ContentType.GeneratedProfile,
    ContentType.RapidResumeEditor,
    ContentType.Home,
    ContentType.HomeAdmin,
    [ContentType.Profiles, Actions.READ],
    [ContentType.Certificates, Actions.READ],
    [ContentType.ProfileCertificates, Actions.READ],
  ],
  [UserRole.NO_ACCESS]: [],
};
