import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useLocale, useMutation, useSnackbar } from 'hooks';
import { postProfileUpdateConfirmMutation } from 'api/actions/profile/summary/profileSummaryActions';

import { UsePostProfileUpdateConfirmProps } from './usePostProfileUpdateConfirm.types';

export const usePostProfileUpdateConfirm = ({ onError }: UsePostProfileUpdateConfirmProps) => {
  const { user } = useCurrentUser();
  const { showSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess } = useMutation(
    'profile-update-confirm',
    postProfileUpdateConfirmMutation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('user-profile');
        showSnackbar(formatMessage({ id: 'success.profileUpdated' }), 'success');
      },
      onError: () => {
        showSnackbar(formatMessage({ id: 'error.errorOccurred' }), 'error');
        onError?.();
      },
    },
  );

  const confirmProfileUpdate = useCallback(async () => {
    await mutateAsync({
      profileId: user?.id,
    });
  }, [mutateAsync, user?.id]);

  return { confirmProfileUpdate, isLoading, isSuccess };
};
