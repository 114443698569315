import { UseFormReturn } from 'react-hook-form';

import { ClientErrorResponse, ClientResponse } from 'api/types/types';

export type SchoolModalProps = {
  isOpen: boolean;
  onModalClose: VoidFunction;
};

export type EducationModalProps<TResponse extends Record<string, unknown>> = {
  isOpen: boolean;
  onModalClose: VoidFunction;
  formMethods: UseFormReturn<EducationModalFormValues>;
  onSubmit: (body: EducationModalFormValues) => Promise<ClientResponse<TResponse>>;
  isLoading: boolean;
  submitLabel: string;
  error: ClientErrorResponse | null;
  setIsOngoing: (isOngoing: boolean | ((prevState: boolean) => boolean)) => void;
  isOngoing: boolean;
};

export enum SchoolModalFormFields {
  School = 'school',
  Degree = 'degree',
  MajorOfStudies = 'majorOfStudies',
  Year = 'year',
}

export type EducationModalFormValues = {
  [SchoolModalFormFields.School]: string;
  [SchoolModalFormFields.Degree]: string;
  [SchoolModalFormFields.MajorOfStudies]: string;
  [SchoolModalFormFields.Year]: Date | null;
};
