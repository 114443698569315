import { Collection } from 'api/types/types';

import { DragAndDropDataReorderArgs } from './dragAndDropDataReorder.types';
import { replaceElementInArray } from './dragAndDropDataReorder.utils';

export const dragAndDropDataReorder = <TData extends Record<string, unknown>>({
  data,
  sourceIndex,
  destinationIndex,
}: DragAndDropDataReorderArgs<TData>): Collection<TData> => {
  const result = replaceElementInArray<TData>(data.result, sourceIndex, destinationIndex);

  return { result, total: data.total };
};
