import { ActionsWrapper, EditButtonLink, PreviewButtonLink } from 'ui';
import { AppRoute } from 'routing/AppRoute.enum';
import { Steps, StepsRoutesNames } from 'views/profileEditor/ProfileEditor.types';

import { ActionsProps } from './Actions.types';

export const Actions = ({ isReadOnly, profileId }: ActionsProps) => (
  <ActionsWrapper onClick={(e) => e.stopPropagation()}>
    {!isReadOnly && (
      <EditButtonLink
        tooltipTitle="profiles.table.edit"
        to={`${AppRoute.ProfileEditor}/${StepsRoutesNames[Steps.BasicData]}/${profileId}`}
      />
    )}

    <PreviewButtonLink
      tooltipTitle="profiles.table.previewCV"
      target={profileId}
      to={`${AppRoute.GeneratedProfile}/${profileId}`}
    />
  </ActionsWrapper>
);
