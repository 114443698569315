import { FormInputText, FormModalWrapper } from 'ui';
import { TagModalFormFields, TagModalProps } from 'views/adminSection/tags/modals/TagModal/TagModal.types';

export const TagModal = ({
  header,
  isOpen,
  onClose,
  isLoading,
  formMethods,
  onSubmit,
  isDataFetching,
}: TagModalProps) => {
  return (
    <FormModalWrapper
      isOpen={isOpen}
      formMethods={formMethods}
      header={header}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      isDataFetching={isDataFetching}
    >
      <FormInputText label="tags.form.tag" name={TagModalFormFields.Name} required />
    </FormModalWrapper>
  );
};
