import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useMutation, useSnackbar } from 'hooks';
import { deleteProfileAchievementMutation } from 'api/actions/profile/achievements/profileAchievementsActions';

export const useDeleteProfileAchievement = (achievementId: string) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const achievementsQueryKey = `profiles/${user?.id}/achievements`;

  const { mutateAsync } = useMutation('deleteProfileAchievement', deleteProfileAchievementMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(achievementsQueryKey);
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error),
  });

  return useCallback(async () => {
    await mutateAsync({ profileId: user?.id, achievementId });
  }, [achievementId, mutateAsync, user?.id]);
};
