import { Box } from '@mui/material';

import { Typography } from 'ui';

import { TechSkillsProps } from './TechSkills.types';
import * as S from './TechSkills.styles';
import { calculateRoundedYears } from './TechSkills.utils';

export const TechSkills = ({ skillsGroup }: TechSkillsProps) => (
  <Box sx={S.wrapper}>
    <Typography sx={S.title} variant="h2">
      {'generatedProfile.techSkills'}
    </Typography>

    {skillsGroup.map(({ category, skills }) => (
      <Box key={category} sx={S.categoryWrapper}>
        <Box sx={S.categoryContent}>
          <Typography sx={S.categoryName} variant="h3">
            {category}
          </Typography>

          {skills.map(({ name, experience }) => {
            const { years, yearsText } = calculateRoundedYears(experience);

            return (
              <Box sx={S.skillWrapper} key={name}>
                <Typography sx={S.skillName}>{name}</Typography>
                <Typography sx={S.skillYears}>{years > 0 ? `${years} ${yearsText}` : `< 1 ${yearsText}`}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    ))}
  </Box>
);
