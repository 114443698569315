import { FormProvider } from 'react-hook-form';

import { EditorProps } from './Editor.types';
import { Position } from './fields/position/Position';

export const Editor = ({ formMethods, defaultData }: EditorProps) => {
  const { positionName } = defaultData;

  return (
    <FormProvider {...formMethods}>
      <form>
        <Position defaultValue={positionName || ''} />
      </form>
    </FormProvider>
  );
};
