import { $getRoot, LexicalEditor, $createParagraphNode, $createTextNode } from 'lexical';
import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html';
import { $rootTextContent } from '@lexical/text';
import { FocusEvent } from 'react';

export const isHTML = (data: string) => {
  if (!data) {
    return false;
  }
  const fragment = document.createRange().createContextualFragment(data);

  fragment.querySelectorAll('*').forEach((el) => {
    if (!el.parentNode) return;
    el.parentNode.removeChild(el);
  });

  return !(fragment.textContent || '').trim();
};

export const getInitialValues = (editor: LexicalEditor, initialData: string) => {
  editor.update(() => {
    const root = $getRoot();

    if (!initialData) {
      const paragraph = $createParagraphNode();
      root.append(paragraph);

      return;
    }

    if (!isHTML(initialData)) {
      const paragraph = $createParagraphNode();
      const textNode = $createTextNode(initialData);
      paragraph.append(textNode);
      root.append(paragraph);

      return;
    }

    const parser = new DOMParser();
    const dom = parser.parseFromString(initialData, 'text/html');
    const nodes = $generateNodesFromDOM(editor, dom);
    root.append(...nodes);
  });
};

export const handleChange = (editor: LexicalEditor, onChange: (data: string) => void) => {
  editor.update(() => {
    const isEmpty = !$rootTextContent();
    const htmlString = $generateHtmlFromNodes(editor, null);
    onChange(isEmpty ? '' : htmlString);
  });
};

export const setEditorAsActive = (event: FocusEvent<HTMLDivElement>) => {
  const relatedTarget = event.relatedTarget?.parentElement;
  if (relatedTarget?.classList.contains('toolbar')) {
    event.currentTarget.classList.add('active');
  } else {
    event.currentTarget.classList.remove('active');
  }
};
