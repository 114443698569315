import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { mergeStyles } from '../../utils/mergeStyles';

import { loaderStyles, fitToParentStyles } from './Loader.styles';
import { LoaderProps } from './Loader.types';

export const Loader = ({ fitToParent, sx = {}, sxLoaderIcon, size }: LoaderProps) => (
  <Box sx={mergeStyles(fitToParent ? fitToParentStyles : loaderStyles, sx)}>
    <CircularProgress size={size} sx={sxLoaderIcon} />
  </Box>
);
