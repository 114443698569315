import { ErrorOption, FieldPath } from 'react-hook-form';
import { IntlShape } from 'react-intl';

import { SingleError } from 'api/types/types';

const isValidField = (field = '', formFields: Record<string, string>) => Object.values(formFields).includes(field);

export const setSingleError = <TFieldValues extends Record<string, unknown>>(
  error: SingleError,
  onSetError: (name: FieldPath<TFieldValues>, error: ErrorOption) => void,
  formFields: Record<string, string>,
  formatMessage: IntlShape['formatMessage'],
  fieldName?: FieldPath<TFieldValues>,
  errorType?: string,
  message?: string,
) => {
  if (!isValidField(error.path, formFields) && !fieldName) {
    throw new Error('Provide fieldName value or check error response');
  }
  if (isValidField(error.path, formFields) || fieldName) {
    return onSetError((error.path as FieldPath<TFieldValues>) ?? fieldName, {
      message: message || formatMessage({ id: error.code }),
      type: errorType || error.code.replace('validation.', ''),
    });
  }
  throw new Error(`${error.path} is not valid field name`);
};
