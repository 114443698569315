import { Styles } from 'lux/theme';

export const buttonsWrapperStyles: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  justifyContent: 'flex-end',
};

export const header: Styles = { pb: 3 };
