import { AxiosRequestConfig } from 'axios';

import { MutationFn } from 'hooks/useMutation/useMutation.types';
import {
  Achievement,
  AchievementsGetResponse,
} from 'views/profileEditor/steps/experience/achievements/Achievements.types';
import { useQuery } from 'hooks';

import {
  DeleteProfileAchievementMutationArgs,
  PostAchievementMutationArgs,
  PatchProfileAchievementMutationArgs,
} from './profileAchievementsActions.types';

export const useGetAchievements = (profileId?: string, config?: AxiosRequestConfig) =>
  useQuery<AchievementsGetResponse>(
    `profiles/${profileId}/achievements`,
    `profiles/${profileId}/achievements`,
    undefined,
    config,
  );

export const postAchievementMutation: MutationFn<PostAchievementMutationArgs, Achievement> = ({ profileId, body }) => ({
  endpoint: `/profiles/${profileId}/achievement`,
  method: 'POST',
  params: body,
});

export const deleteProfileAchievementMutation: MutationFn<DeleteProfileAchievementMutationArgs> = ({
  profileId,
  achievementId,
}) => ({
  endpoint: `/profiles/${profileId}/achievement/${achievementId}`,
  method: 'DELETE',
});

export const patchAchievementMutation: MutationFn<PatchProfileAchievementMutationArgs, Achievement> = ({
  profileId,
  achievementId,
  body,
}) => ({
  endpoint: `/profiles/${profileId}/achievement/${achievementId}`,
  method: 'PATCH',
  params: body,
});
