import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { TypographyProps as MaterialTypographyProps } from '@mui/material';
import { Typography as ICLTypography } from 'lux/components';

import { isTranslationString } from 'utils/isTranslationString/isTranslationString';

type TypographyProps = {
  children?: ReactNode | string;
  values?: Record<string, ReactNode>;
} & MaterialTypographyProps;

export const Typography = ({ children, values, ...props }: TypographyProps) => {
  const usesTranslation = isTranslationString(children);

  return (
    <ICLTypography {...props}>
      {typeof children === 'string' && usesTranslation ? <FormattedMessage id={children} values={values} /> : children}
    </ICLTypography>
  );
};
