import { Grid } from '@mui/material';

import {
  TechnologiesSurveyItemFamiliarity,
  TechnologiesSurveyItemPreference,
} from 'api/actions/technologiesSurvey/technologiesSurveyActions.types';
import { SurveyRadioGroup } from '../surveyRadioGroup/SurveyRadioGroup';

import * as S from './SurveyAnswers.styles';
import { SurveyAnswersProps } from './SurveyAnswers.types';

export const SurveyAnswers = ({ fields, control, formatMessage }: SurveyAnswersProps) => {
  return (
    <>
      {fields.map((field, index) => (
        <Grid item xs={12} key={field.id} sx={index % 2 !== 0 ? S.zebraStripe : undefined}>
          <Grid container>
            <Grid item xs={2} sx={S.technologyName}>
              {field.name}
            </Grid>
            <Grid item xs={4}>
              <SurveyRadioGroup
                name={`skills.${index}.familiarity`}
                control={control}
                label="familiarity"
                formatMessage={formatMessage}
                gridSize={4}
                options={[
                  { value: TechnologiesSurveyItemFamiliarity.COMFORTABLE },
                  { value: TechnologiesSurveyItemFamiliarity.ALITTLE },
                  { value: TechnologiesSurveyItemFamiliarity.DONTKNOW },
                ]}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <SurveyRadioGroup
                name={`skills.${index}.preference`}
                control={control}
                label="preference"
                formatMessage={formatMessage}
                gridSize={3}
                options={[
                  { value: TechnologiesSurveyItemPreference.LOVE },
                  { value: TechnologiesSurveyItemPreference.WHATEVER },
                  { value: TechnologiesSurveyItemPreference.HATE },
                  { value: TechnologiesSurveyItemPreference.NA },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
