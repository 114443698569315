import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { useLocale } from 'hooks';
import { Project as ProjectType } from 'api/actions/projects/projectsActions.types';
import { ProjectsTableActions } from '../components/projectsTableActions/ProjectsTableActions';
import { Project } from '../project/Project';

export const useProjectsColumns = () => {
  const columnHelper = createColumnHelper<ProjectType>();
  const { formatMessage } = useLocale();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          id: 'name',
          cell: ({ row }) => <Project project={row.original} />,
          enableColumnFilter: false,
          enableSorting: false,
        }),
        columnHelper.accessor('id', {
          id: 'actions',
          cell: ProjectsTableActions,
          enableSorting: false,
          enableColumnFilter: false,
        }),
      ] as ColumnDef<ProjectType>[],
    [columnHelper, formatMessage],
  );

  return { columns };
};
