import { parseISO } from 'date-fns';

import { ProjectModalFormFields, ProjectType } from '../projectModal/ProjectModal.types';

import { GetDefaultValuesArgs } from './EditProjectModalContainer.types';

export const getDefaultValues = ({ profileProjectData }: GetDefaultValuesArgs) => {
  const { profileProject, projectTechnologies, profileProjectTechnologies } = profileProjectData;

  const profileProjectTechnologiesOptions = profileProjectTechnologies
    ? profileProjectTechnologies.map(({ skill: { id, name } }) => ({ id, name }))
    : [];
  const projectTechnologiesOptions = projectTechnologies
    ? projectTechnologies.map(({ technology: { id, name } }) => ({ id, name }))
    : [];

  return {
    [ProjectModalFormFields.Name]: profileProject?.project.name,
    [ProjectModalFormFields.ShortDescription]: profileProject?.project.shortDescription || '',
    [ProjectModalFormFields.Description]: profileProject?.project.description || '',
    [ProjectModalFormFields.Role]: profileProject?.role,
    [ProjectModalFormFields.AdditionalDescription]: profileProject?.additionalDescription || '',
    [ProjectModalFormFields.JoinedAt]: profileProject?.joinedAt ? parseISO(profileProject.joinedAt) : null,
    [ProjectModalFormFields.LeftAt]: profileProject?.leftAt ? parseISO(profileProject.leftAt) : null,
    [ProjectModalFormFields.ProjectType]: profileProject?.project.isPrivate ? ProjectType.Private : ProjectType.Public,
    [ProjectModalFormFields.IsInvisible]: profileProject?.isInvisible || false,
    [ProjectModalFormFields.ProfileProjectTechnologies]: profileProjectTechnologiesOptions,
    [ProjectModalFormFields.ProjectTechnologies]: projectTechnologiesOptions,
  };
};
