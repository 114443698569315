import { Styles } from 'views/generatedProfile/theme/theme';

export const sidebarWrapper: Styles = {
  position: 'relative',
  width: 432,
  '@media print': {
    display: 'none',
  },
  padding: 3,
  fontFamily: 'Inter, sans-serif',
  bgcolor: '#eee',
};

export const button: Styles = {
  width: '100%',
};

export const editor: Styles = {
  px: 3,
  pt: 3,
  pb: 4,
  border: '1px solid',
  borderColor: 'border.default',
  borderRadius: 2,
  bgcolor: 'primary.contrast',
};
