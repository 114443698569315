import { startOfMonth } from 'date-fns';

import { FormDatePicker } from 'ui';
import { DateFormats } from 'ui/formDatePicker/FormDatePicker.types';
import { ProjectModalFormFields } from '../../ProjectModal.types';

import * as S from './WorkedFrom.styles';

export const WorkedFrom = () => {
  return (
    <FormDatePicker
      dateFormat={DateFormats.yyyyMM}
      placeholder="profileProject.placeholder.date"
      name={ProjectModalFormFields.JoinedAt}
      label="profileProject.label.workedFrom"
      maxDate={new Date()}
      views={['year', 'month']}
      transformValue={(date: Date | null) => (date ? startOfMonth(date) : null)}
      clearable
      required
      size="medium"
      datePickerWrapperSx={S.datePickerWrapper}
    />
  );
};
