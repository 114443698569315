import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useLocale } from 'hooks';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { usePatchPosition } from '../../hooks/usePatchPosition';
import { getDefaultValues, getFormSchema } from '../positionModal/PositionModal.utils';
import { PositionModalFormFields, PositionModalFormValues } from '../positionModal/PositionModal.types';
import { PositionModalContainer } from '../positionModal/PositionModalContainer';
import { PatchPositionBody } from 'api/actions/positions/positionsActions.types';

import { PatchPositionModalContainerProps } from './PatchPositionModalContainer.types';

export const PatchPositionModalContainer = ({
  isOpen,
  onModalClose,
  positionData,
}: PatchPositionModalContainerProps) => {
  const { formatMessage } = useLocale();
  const { editPosition, error, isLoading } = usePatchPosition(positionData.id, () => onModalClose());

  const formMethods = useForm<PositionModalFormValues>({
    defaultValues: getDefaultValues(positionData),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit } = formMethods;
  const submitOnlyVisibleName = (formBody: PatchPositionBody) =>
    editPosition({ [PositionModalFormFields.VisibleName]: formBody[PositionModalFormFields.VisibleName] });

  return (
    <PositionModalContainer
      isOpen={isOpen}
      isLoading={isLoading}
      formMethods={formMethods}
      onClose={onModalClose}
      onSubmit={handleSubmit(submitOnlyVisibleName)}
      header={positionData.dwhCode ? 'positions.form.edit' : 'positions.form.editCustom'}
      error={error}
      formMode={FormMode.edit}
    />
  );
};
