import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useState } from 'react';

import { useLocale } from 'hooks';
import { EducationModalFormValues } from '../EducationModal.types';
import { usePostEducation } from '../../hooks/usePostEducation';
import { EducationModal } from '../EducationModal';
import { getDefaultValues, getFormSchema } from '../EducationModal.utils';

import { AddEducationModalContainerProps } from './AddEducationModalContainer.types';

export const AddEducationModalContainer = ({ isOpen, onModalClose }: AddEducationModalContainerProps) => {
  const { formatMessage } = useLocale();
  const [isOngoing, setIsOngoing] = useState(false);

  const formMethods = useForm<EducationModalFormValues>({
    defaultValues: getDefaultValues(),
    resolver: joiResolver(getFormSchema(formatMessage, isOngoing)),
  });
  const { reset } = formMethods;
  const { addEducation, isLoading, error } = usePostEducation(() => {
    onModalClose();
    setIsOngoing(false);
    reset();
  });

  return (
    <EducationModal
      error={error}
      isOpen={isOpen}
      onModalClose={onModalClose}
      formMethods={formMethods}
      onSubmit={addEducation}
      isLoading={isLoading}
      isOngoing={isOngoing}
      setIsOngoing={setIsOngoing}
      submitLabel={formatMessage({ id: 'form.save' })}
    />
  );
};
