import { useCurrentUser } from 'hooks';
import { useEducationGet } from 'api/actions/profile/education/profileEducationActions';

export const useGetProfileEducation = () => {
  const { user } = useCurrentUser();

  const { data, isLoading, isError } = useEducationGet(user?.id);

  return { schools: data, isLoading, isError };
};
