import { Styles } from 'lux/theme';

export const inputsWrapperStyles: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
};

export const newSeniorityTitleStyles: Styles = {
  marginBottom: 3,
};

export const infoText: Styles = {
  fontSize: '13px',
  color: 'grey.600',
  fontStyle: 'italic',
};
