import { Navigate } from 'react-router-dom';

import { AccessControl } from 'ui';
import { AppRoute } from '../AppRoute.enum';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { CategoriesOrderContainer } from 'views/adminSection/categoriesOrder/CategoriesOrderContainer';

export const AuthorizedCategoriesOrder = () => (
  <AccessControl allowedContent={ContentType.Categories} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <CategoriesOrderContainer />
  </AccessControl>
);
