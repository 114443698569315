import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { adminCategoriesQueryKey, postCategoryMutation } from 'api/actions/categories/categoriesActions';
import { PostCategoryBody } from 'api/actions/categories/categoriesActions.types';

export const usePostCategory = (onSuccess: VoidFunction) => {
  const queryClient = useQueryClient();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const { mutateAsync, isError, isLoading, error } = useMutation('postCategory', postCategoryMutation, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(adminCategoriesQueryKey);
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const addCategory = useCallback(
    async (body: PostCategoryBody) => {
      await mutateAsync({
        body,
      });
    },
    [mutateAsync],
  );

  return { addCategory, isError, isLoading, error };
};
