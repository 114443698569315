import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

import {
  PatchProfileMutationArgs,
  PostProfileMutationArgs,
  Profile,
  ProfilesGetResponse,
} from './profilesActions.types';

export const getProfilesQueryKey = 'profiles';

export const useProfilesGet = (config?: AxiosRequestConfig) => {
  return useQuery<ProfilesGetResponse>(
    '/profiles',
    [getProfilesQueryKey, config],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );
};

export const useProfilePost = (
  options: UseMutationOptions<ClientResponse<Profile>, ClientErrorResponse, PostProfileMutationArgs>,
) =>
  useMutation(
    'postProfile',
    ({ body }) => ({
      endpoint: '/profiles/profile',
      method: 'POST',
      params: body,
    }),
    options,
  );

export const useProfilePatch = (
  options: UseMutationOptions<ClientResponse<Profile>, ClientErrorResponse, PatchProfileMutationArgs>,
) =>
  useMutation(
    'patchProfile',
    ({ id, body }) => ({
      endpoint: `profiles/profile/${id}`,
      method: 'PATCH',
      params: body,
    }),
    options,
  );
