import { QueryParams, Sort } from '../../api/types/types';

import { VirtualSortingEncodeParams } from './virtualSorting.types';

export const virtualSortingEncode = ({
  columnSort,
  virtualSortingMap,
}: VirtualSortingEncodeParams): Record<string, string> =>
  virtualSortingMap[columnSort.id].reduce(
    (acc, realColumn) => ({
      ...acc,
      [`${QueryParams.SORT}[${realColumn}]`]: columnSort.desc ? Sort.DESC : Sort.ASC,
    }),
    {},
  );
