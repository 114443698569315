import { SkillType } from 'api/actions/skills/skillsActions.types';
import { Category } from 'api/actions/categories/categoriesActions.types';
import { SkillModalContainerProps } from 'views/adminSection/skills/modals/skillModal/SkillModalContainer.types';

export type SkillModalProps = Omit<SkillModalContainerProps, 'error'> & {
  skillTypeOptions: Record<string, string | SkillType>[];
  categoriesOptions: Category[];
  isDataFetching: boolean;
};

export enum SkillModalFormFields {
  Name = 'name',
  Type = 'type',
  CategoryId = 'categoryId',
  IsSurveyItem = 'isSurveyItem',
}

export type SkillModalFormValues = {
  [SkillModalFormFields.Name]: string;
  [SkillModalFormFields.Type]: SkillType;
  [SkillModalFormFields.CategoryId]: string | null;
  [SkillModalFormFields.IsSurveyItem]: boolean;
};
