import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import { useEffect } from 'react';

import { ProjectModalFormValues } from '../projectModal/ProjectModal.types';
import { useGetProfileProjectData } from '../../hooks/useGetProfileProjectData';
import { useLocale, useUser } from 'hooks';
import { getFormSchema } from '../projectModal/ProjectModal.utils';
import { usePatchProfileProject } from '../../hooks/usePatchProfileProject';
import { ProjectModalContainer } from '../projectModal/ProjectModalContainer';

import { EditProjectModalContainerProps } from './EditProjectModalContainer.types';
import { getDefaultValues } from './EditProjectModalContainer.utils';

export const EditProjectModalContainer = ({
  onClose,
  isOpen,
  projectId,
  profileProjectId,
}: EditProjectModalContainerProps) => {
  const { formatMessage } = useLocale();
  const { isAdmin } = useUser();

  const { profileProjectData, isLoading: isProfileProjectDataLoading } = useGetProfileProjectData(
    projectId,
    profileProjectId,
  );

  const { editProject, isLoading, error } = usePatchProfileProject(profileProjectId, onClose);

  const formMethods = useForm<ProjectModalFormValues>({
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(getDefaultValues({ profileProjectData }));
  }, [reset, isProfileProjectDataLoading]);

  return (
    <ProjectModalContainer
      isAdminOrNewProject={isAdmin}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit(editProject)}
      header="profileProject.form.edit"
      error={error}
      isDataFetching={isProfileProjectDataLoading}
      formMethods={formMethods}
      initialValues={{
        description: profileProjectData.profileProject?.project.description || '',
        additionalDescription: profileProjectData.profileProject?.additionalDescription || '',
      }}
    />
  );
};
