import { useProjectDelete } from '../../hooks/useProjectDelete';

import { ProjectDeletionModal } from './ProjectDeletionModal';
import { ProjectDeletionModalContainerProps } from './ProjectDeletionModal.types';

export const ProjectDeletionModalContainer = (props: ProjectDeletionModalContainerProps) => {
  const { projectId, onClose } = props;

  const { deleteProject, isError, isLoading } = useProjectDelete(projectId, onClose);

  return <ProjectDeletionModal {...props} onDelete={deleteProject} isError={isError} isLoading={isLoading} />;
};
