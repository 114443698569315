import { Helmet } from 'react-helmet';

import { useLocale } from 'hooks';

import { Summary } from './Summary';

export const SummaryContainer = () => {
  const { formatMessage } = useLocale();
  // const { user } = useCurrentUser();
  // const { axios } = useClient();
  // const downloadButton = useRef<HTMLAnchorElement | null>(null);

  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get(`/profiles/profile/${user?.id}/download-cv`, {
  //       responseType: 'blob',
  //     })
  //     .then(({ data, headers }) => {
  //       if (!downloadButton.current) return;

  //       const filenameMatch = (headers['content-disposition'] || '').match(/filename=(.*)$/);
  //       const filename = filenameMatch ? decodeURI(filenameMatch[1]) : `${user?.firstName}_${user?.code}.pdf`;

  //       downloadButton.current.href = URL.createObjectURL(data);
  //       downloadButton.current.download = filename;
  //       setIsLoading(false);
  //     });
  // }, [axios, user?.code, user?.firstName, user?.id]);

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.profileSummary' })}</title>
      </Helmet>
      <Summary />
    </>
  );
};
