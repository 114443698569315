import { Helmet } from 'react-helmet';

import { useContentAccess, useLocale, useModal } from 'hooks';
import { AdminSectionWrapper, OpenModalButton } from 'ui';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { Categories } from './Categories';
import { AddCategoryModalContainer } from './modals/addCategoryModal/AddCategoryModalContainer';

export const CategoriesContainer = () => {
  const { formatMessage } = useLocale();

  const { onOpen, isOpen, onClose } = useModal();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Categories });

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.categories' })}</title>
      </Helmet>
      <AdminSectionWrapper
        title="navigator.categories"
        tabs="skillsTabs"
        action={
          isReadOnly ? null : <OpenModalButton disabled={isReadOnly} label="categories.form.add" onClick={onOpen} />
        }
      >
        <Categories />
      </AdminSectionWrapper>
      {isOpen && <AddCategoryModalContainer isOpen={isOpen} onClose={onClose} />}
    </>
  );
};
