import { Timeline } from '@mui/lab';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from 'lux/components';

import { BorderBox, Loader } from 'ui';
import { Section } from '../../../components/section/Section';
import { useLocale } from 'hooks';
import { ProfileProjectGetResponse } from 'api/actions/profile/projects/profileProjectsActions.types';

import { ProjectContainer } from './project/ProjectContainer';
import { ProjectsProps } from './Projects.types';
import { EditProjectModalContainer } from './modals/editProjectModal/EditProjectModalContainer';
import { projectListTitle, timelineStyles } from './Projects.styles';
import { AddProjectModalContainer } from './modals/addProjectModal/AddProjectModalContainer';
import { useProjectContext } from './context/ProjectContext';

const editModalStateInitialValue = {
  isOpen: false,
  projectId: '',
  profileProjectId: '',
};

export const Projects = ({ isLoading, profileProjects }: ProjectsProps) => {
  const [isProjectTypeModalOpen, setIsProjectTypeModalOpen] = useState(false);
  const [editModalState, setEditModalState] = useState(editModalStateInitialValue);
  const { formatMessage } = useLocale();
  const { setIsProjectNew, setIsProjectSelected } = useProjectContext();

  const handleCompleteDataClick = (profileProject: ProfileProjectGetResponse) => {
    const {
      id: profileProjectId,
      projectId,
      project: { isPrivate },
    } = profileProject;

    if (isPrivate) {
      setIsProjectNew(true);
    }

    setEditModalState({
      isOpen: true,
      projectId,
      profileProjectId,
    });
  };

  const handleAddProjectModalClose = () => {
    setIsProjectNew(false);
    setIsProjectTypeModalOpen(false);
    setIsProjectNew(false);
    setIsProjectSelected(false);
  };

  const handleEditProjectModalClose = () => {
    setIsProjectNew(false);
    setEditModalState(editModalStateInitialValue);
  };

  return (
    <Section title="step.subTitle.projects" subtitle="step.subTitle.projectsInfo">
      {isLoading ? (
        <Loader />
      ) : (
        <BorderBox>
          <Typography variant="caption" sx={projectListTitle}>
            {formatMessage({ id: 'profileProject.listOfProjects' })}
          </Typography>
          <Timeline sx={timelineStyles}>
            {profileProjects.map((profileProject, index) => (
              <ProjectContainer
                key={index}
                profileProject={profileProject}
                onCompleteDataClick={() => handleCompleteDataClick(profileProject)}
                isFirst={index === 0}
                isLast={index === profileProjects.length - 1}
              />
            ))}
          </Timeline>
          <Button
            startIcon={<AddIcon />}
            variant="text"
            onClick={() => setIsProjectTypeModalOpen(true)}
            data-testid="addNewProject"
          >
            {formatMessage({ id: 'form.addNewProject' })}
          </Button>
        </BorderBox>
      )}
      {isProjectTypeModalOpen && (
        <AddProjectModalContainer isOpen={isProjectTypeModalOpen} onClose={handleAddProjectModalClose} />
      )}
      {editModalState.isOpen && (
        <EditProjectModalContainer
          isOpen={editModalState.isOpen}
          onClose={handleEditProjectModalClose}
          profileProjectId={editModalState.profileProjectId}
          projectId={editModalState.projectId}
        />
      )}
    </Section>
  );
};
