import { useGetSeniorities } from 'api/actions/seniorities/senioritiesActions';
import { Sort } from 'api/types/types';

export const useSenioritiesQuery = () => {
  return useGetSeniorities({
    params: {
      'sort[display_order]': Sort.ASC,
    },
  });
};
