import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';
import { Position } from 'api/actions/positions/positionsActions.types';

import { PositionModalFormFields } from './PositionModal.types';

const NAME_MAX_LENGTH = 100;
export const getDefaultValues = (position?: Position) => {
  return {
    [PositionModalFormFields.VisibleName]: position?.visibleName || '',
    [PositionModalFormFields.DwhCode]: position?.dwhCode || '',
  };
};
export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  return Joi.object({
    [PositionModalFormFields.VisibleName]: Joi.string()
      .max(NAME_MAX_LENGTH)
      .trim()
      .required()
      .messages(getErrorMessages({ formatMessage, maxLength: NAME_MAX_LENGTH })),
    [PositionModalFormFields.DwhCode]: Joi.string().allow('').optional(),
  });
};
