import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useSnackbar } from 'hooks';
import { adminCategoriesQueryKey, useCategoryPatch } from 'api/actions/categories/categoriesActions';

export const usePatchCategory = (categoryId?: string, onSuccess?: (name: string) => void) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, isLoading, error } = useCategoryPatch({
    onSuccess: ({ data: { name } }) => {
      onSuccess && onSuccess(name);
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => {
      showSnackbarError(error);
    },
    onSettled: () => {
      queryClient.refetchQueries([adminCategoriesQueryKey]);
    },
  });

  const editCategory = useCallback(
    async (body: { name: string }) => {
      if (!categoryId) {
        throw new Error('Category id is missing');
      }
      await mutateAsync({ ...body, id: categoryId });
    },
    [mutateAsync, categoryId],
  );

  return { editCategory, isError, isLoading, error };
};
