import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { ProfileModalContainer } from '../profileModal/ProfileModalContainer';
import { useProfilePost } from '../../hooks/useProfilePost/useProfilePost';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { getQueryParams } from '../../Profiles.utils';
import { getDefaultValues, getFormSchema } from '../profileModal/ProfileModal.utils';
import { useLocale } from 'hooks';
import { ProfileModalFormValues } from 'views/adminSection/profiles/modals/profileModal/ProfileModal.types';
import { AddProfileModalContainerProps } from 'views/adminSection/profiles/modals/addProfileModal/AddProfileModalContainer.types';

export const AddProfileModalContainer = ({ onModalClose, isOpen }: AddProfileModalContainerProps) => {
  const { formatMessage } = useLocale();
  const formMethods = useForm<ProfileModalFormValues>({
    defaultValues: getDefaultValues(),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit } = formMethods;

  const { addProfile, isLoading, error } = useProfilePost(getQueryParams(), onModalClose);

  return (
    <ProfileModalContainer
      isOpen={isOpen}
      isLoading={isLoading}
      formMode={FormMode.add}
      formMethods={formMethods}
      onClose={onModalClose}
      onSubmit={handleSubmit(addProfile)}
      header="profiles.form.addNewProfile"
      error={error}
    />
  );
};
