import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useMutation, useSnackbar } from 'hooks';
import { postAchievementMutation } from 'api/actions/profile/achievements/profileAchievementsActions';
import { PatchAchievementBody } from 'api/actions/profile/achievements/profileAchievementsActions.types';

export const usePostProfileAchievement = (onSuccess: VoidFunction) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const achievementsQueryKey = `profiles/${user?.id}/achievements`;

  const mutation = useMutation('postAchievement', postAchievementMutation, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(achievementsQueryKey);
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const { mutateAsync, isError, isLoading } = mutation;

  const addAchievement = useCallback(
    async (body: PatchAchievementBody) => {
      await mutateAsync({
        profileId: user?.id,
        body,
      });
    },
    [mutateAsync, user?.id],
  );

  return { addAchievement, isError, isLoading };
};
