import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { projectsGetQueryKey, useProjectDelete as useProjectDeleteHook } from 'api/actions/projects/projectsActions';
import { useSnackbar } from 'hooks';

export const useProjectDelete = (projectId: string, onClose: VoidFunction) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, isLoading } = useProjectDeleteHook({
    onSuccess: () => {
      queryClient.invalidateQueries([projectsGetQueryKey]);
      onClose();
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error),
  });

  const deleteProject = useCallback(async () => {
    await mutateAsync({ projectId });
  }, [mutateAsync, projectId]);

  return { deleteProject, isError, isLoading };
};
