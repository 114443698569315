import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from 'lux/components';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

import { FormInputText, Loader, Separator, Typography } from 'ui';
import { useLocale } from 'hooks';
import { Section } from '../../../components/section/Section';

import { AchievementContainer } from './achievement/AchievementContainer';
import { AchievementsFormValues, AchievementsProps, AchievementsFormFields } from './Achievements.types';
import { usePostProfileAchievement } from './hooks/usePostProfileAchievement';
import { getFormSchema, defaultValues } from './Achievements.utils';

export const Achievements = ({ isLoading, achievements }: AchievementsProps) => {
  const { formatMessage } = useLocale();

  const formMethods = useForm<AchievementsFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit, reset } = formMethods;
  const { addAchievement, isError, isLoading: isPostLoading } = usePostProfileAchievement(reset);

  return (
    <Section title="step.subTitle.achievements" subtitle="step.description.achievements">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box>
            {achievements.map(({ id, description }) => (
              <AchievementContainer name={description} id={id} key={id} />
            ))}
          </Box>

          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(addAchievement)}>
              <FormInputText
                name={AchievementsFormFields.Achievement}
                label="form.achievement"
                placeholder="form.achievementPlaceholder"
              />

              {isError && (
                <>
                  <Typography variant="helperText">{'error.errorOccurred'}</Typography>
                  <Separator height={30} />
                </>
              )}

              <Button
                loading={isPostLoading}
                variant="text"
                type="submit"
                data-testid="addAchievement"
                startIcon={<AddIcon />}
              >
                {formatMessage({ id: 'form.addAchievement' })}
              </Button>
            </form>
          </FormProvider>
        </>
      )}
    </Section>
  );
};
