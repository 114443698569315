import { Dialog, IconButton, PaperProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import * as S from './Modal.styles';
import { ModalProps } from './Modal.types';

export const Modal = ({
  isOpen,
  onModalClose,
  children,
  wideModal,
  hideBackdrop,
  maxWidth,
  dataTestId,
}: ModalProps) => {
  return (
    <Dialog
      transitionDuration={{ enter: 200, exit: 200 }}
      PaperProps={
        {
          sx: { ...(wideModal ? S.windowWideStyles : S.windowStyles), ...(maxWidth ? { maxWidth } : {}) },
          'data-testid': dataTestId || 'modal',
        } as PaperProps
      }
      hideBackdrop={hideBackdrop}
      open={isOpen}
      onClose={onModalClose}
    >
      <IconButton data-testid="closeModal" sx={S.closeButtonStyles} onClick={onModalClose}>
        <CloseIcon />
      </IconButton>
      {children}
    </Dialog>
  );
};
