import { useContext } from 'react';

import { CurrentUserContext } from 'context/currentUser/currentUserContext/CurrentUserContext';

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error('CurrentUserContext must be within CurrentUserProvider');
  }

  return context;
};
