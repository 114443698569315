import { ProfileModalContainerProps } from 'views/adminSection/profiles/modals/profileModal/ProfileModalContainer.types';
import { Position } from 'api/actions/positions/positionsActions.types';
import { Seniority } from 'api/actions/seniorities/senioritiesActions.types';

export enum ProfileModalFormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Position = 'positionId',
  Seniority = 'seniorityId',
}

export type ProfileModalFormValues = {
  [ProfileModalFormFields.FirstName]: string;
  [ProfileModalFormFields.LastName]: string;
  [ProfileModalFormFields.Email]: string;
  [ProfileModalFormFields.Position]: string;
  [ProfileModalFormFields.Seniority]: string;
};

export type ProfileModalProps = Omit<ProfileModalContainerProps, 'error'> & {
  positionsData: Position[];
  seniorityData: Seniority[];
};
