import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useMutation, useSnackbar } from 'hooks';
import { patchAchievementMutation } from 'api/actions/profile/achievements/profileAchievementsActions';
import { PatchAchievementBody } from 'api/actions/profile/achievements/profileAchievementsActions.types';

export const usePatchProfileAchievement = (achievementId: string) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const achievementsQueryKey = `profiles/${user?.id}/achievements`;

  const mutation = useMutation('patchAchievement', patchAchievementMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(achievementsQueryKey);
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => showSnackbarError(error),
  });

  const { mutateAsync, isError, isLoading } = mutation;

  const editAchievement = useCallback(
    async (body: PatchAchievementBody) => {
      await mutateAsync({
        profileId: user?.id,
        achievementId,
        body,
      });
    },
    [mutateAsync, user?.id],
  );

  return { editAchievement, isError, isLoading };
};
