import { ColumnSort } from '@tanstack/table-core';
import { ColumnFilter } from '@tanstack/react-table';

import { QueryParams, Sort } from 'api/types/types';

import { GetInitialFilterValuesArgs, GetInitialSortValuesArgs } from './useTableLogic.types';

export const getInitialSortValues = ({ initialQueryParams, keysMapper }: GetInitialSortValuesArgs) => {
  const sortKeys = Object.values(keysMapper).map((el) => `${QueryParams.SORT}[${el}]`);

  return sortKeys.reduce((prev, curr, index) => {
    const id = Object.keys(keysMapper);
    const desc = initialQueryParams[curr as keyof unknown];

    if (desc === undefined) {
      return [...prev];
    }

    return [...prev, { id: id[index], desc: desc === Sort.DESC }];
  }, [] as ColumnSort[]);
};

export const getInitialFilterValues = ({
  filtersOperators,
  initialQueryParams,
  keysMapper,
}: GetInitialFilterValuesArgs) => {
  const fields = Object.keys(keysMapper);
  const filterKeys = Object.values(keysMapper).map(
    (el, index) => `${QueryParams.FILTER}[${el}][${filtersOperators[fields[index]]}]`,
  );

  return filterKeys.reduce((prev, curr, index) => {
    const id = Object.keys(keysMapper);
    const value = initialQueryParams[curr];
    if (value === undefined) {
      return [...prev];
    }

    return [...prev, { id: id[index], value }];
  }, [] as ColumnFilter[]);
};
