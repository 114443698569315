import { useContext } from 'react';

import { AppSettingsContext } from 'context/appSettings/appSettingsContext/AppSettingsContext';

export const useAppSettings = () => {
  const context = useContext(AppSettingsContext);
  if (context === undefined) {
    throw new Error('AppSettingsContext must be within AppSettingsProvider');
  }

  return context;
};
