import { Navigate } from 'react-router-dom';

import { AccessControl } from 'ui';
import { AppRoute } from '../AppRoute.enum';
import { ProfilesCertificatesContainer } from 'views/adminSection/profilesCertificates/ProfilesCertificatesContainer';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const AuthorizedProfilesCertificates = () => (
  <AccessControl allowedContent={ContentType.ProfileCertificates} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <ProfilesCertificatesContainer />
  </AccessControl>
);
