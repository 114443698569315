import { DatePickerProps } from '@mui/lab/DatePicker';
import { Styles } from 'lux/theme';

export enum DateFormats {
  yyyy = 'yyyy',
  yyyyMM = 'yyyy-MM',
  yyyyMMdd = 'yyyy-MM-dd',
}

export type FormDatePickerProps = Pick<DatePickerProps<Date>, 'views'> & {
  name: string;
  label: string;
  placeholder: string;
  size?: 'small' | 'medium';
  minDate?: Date;
  maxDate: Date;
  transformValue?: (date: Date | null) => Date | null;
  required?: boolean;
  dataTest?: string;
  disabled?: boolean;
  dateFormat?: DateFormats;
  clearable?: boolean;
  helperText?: string;
  datePickerWrapperSx?: Styles;
};
