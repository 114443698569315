import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useProfilePost as useProfilePostHook } from 'api/actions/profiles/profilesActions';
import { getProfilesQueryKey } from 'api/actions/profiles/profilesActions';
import { useSnackbar } from 'hooks';
import { PostProfileBody } from 'api/actions/profiles/profilesActions.types';

export const useProfilePost = (params: Record<string, unknown>, onSuccess: VoidFunction) => {
  const queryClient = useQueryClient();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const { mutateAsync, isError, isLoading, error } = useProfilePostHook({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(getProfilesQueryKey);
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const addProfile = useCallback(
    async (body: PostProfileBody) => {
      await mutateAsync({ body });
    },
    [mutateAsync],
  );

  return { addProfile, isError, isLoading, error };
};
