import { UseFormReturn } from 'react-hook-form';

import { PatchSeniorityBody, Seniority } from 'api/actions/seniorities/senioritiesActions.types';
import { ClientErrorResponse } from 'api/types/types';

export enum SeniorityModalFormFields {
  Name = 'name',
  VisibleName = 'visibleName',
}

export type SeniorityModalFormValues = {
  [SeniorityModalFormFields.Name]: string;
  [SeniorityModalFormFields.VisibleName]: string | null;
};

export type SeniorityModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  isLoading: boolean;
  isErrorLabel?: 'string';
  isError?: boolean;
  seniority?: Seniority;
  submitButtonLabel?: string;
  handleSenioritySubmit: (body: PatchSeniorityBody) => Promise<void>;
  formMethods: UseFormReturn<SeniorityModalFormValues, Record<string, unknown>>;
  error: ClientErrorResponse | null;
};
