import { FormControl } from '@mui/material';
import { Select } from 'lux/components';

import { useLocale } from 'hooks';

import * as Styles from './SelectFilter.styles';
import { TableFiltersProps } from './SelectFilter.types';

export const SelectFilter = <T extends Record<string, unknown>>({ column }: TableFiltersProps<T>) => {
  const filterOptions = column.columnDef.meta?.filterOptions;
  const { formatMessage } = useLocale();
  const allOptionLabel = formatMessage({ id: 'table.search' });
  const optionsAdapted = [
    ...[
      {
        id: '',
        children: allOptionLabel,
        value: allOptionLabel,
      },
    ],
    ...(filterOptions?.map(({ id, name }) => ({ id: String(id) || '', children: name, value: id })) || []),
  ];

  return (
    <FormControl sx={Styles.wrapper}>
      <Select
        defaultValue={''}
        value={column.getFilterValue() || allOptionLabel}
        inputProps={{ 'aria-label': 'Without label', ['data-testid']: column.id }}
        onChange={(e) => {
          if (e.target.value !== allOptionLabel) {
            column.setFilterValue(e.target.value);
          } else {
            column.setFilterValue('');
          }
        }}
        options={optionsAdapted}
        size="small"
      />
    </FormControl>
  );
};
