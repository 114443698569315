import { CellContext } from '@tanstack/react-table';

import { ActionsWrapper, DeleteButton, EditButton } from 'ui';
import { DeleteModal } from 'ui/modals/forceDeleteModal/DeleteModal';
import { useContentAccess, useModal } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { useDeletePosition } from '../../hooks/useDeletePosition';
import { Position } from '../../../../../api/actions/positions/positionsActions.types';
import { PatchPositionModalContainer } from '../../modals/patchPositionModal/PatchPositionModalContainer';

export const TableActions = (cell: CellContext<Position, string>) => {
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Positions });

  const { isOpen: isPatchModalOpen, onOpen: onPatchModalOpen, onClose: onPatchModalClose } = useModal();
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();

  const { removePosition } = useDeletePosition({ onSuccess: onDeleteModalClose, onError: onDeleteModalClose });

  return isReadOnly ? null : (
    <ActionsWrapper>
      <EditButton onClick={onPatchModalOpen} />
      <DeleteButton onClick={onDeleteModalOpen} />

      {isPatchModalOpen && (
        <PatchPositionModalContainer
          isOpen={isPatchModalOpen}
          onModalClose={onPatchModalClose}
          positionData={cell.row.original}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onModalClose={onDeleteModalClose}
          onItemDelete={() => removePosition(cell.getValue())}
          shortConfirmMessage={true}
        />
      )}
    </ActionsWrapper>
  );
};
