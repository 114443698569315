import { Box } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { AccessControl, Separator, Typography } from 'ui';
import { useGetQueryParams } from 'hooks';
import { getSkillsSorted } from '../GeneratedProfile.utils';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { useCategoriesOrder } from 'api/actions/categoriesOrder/categoriesOrderActions';

import * as S from './EditorContainer.styles';
import { EditorFormValues } from './Editor.types';
import { Editor } from './Editor';
import { formSchema, getDefaultValues } from './EditorContainer.utils';
import { EditorContainerProps } from './EditorContainer.types';
import { ActionButtons } from './components/actionButtons/ActionButtons';

const formatResumeFileName = (positionName: string | null, firstName: string | null, profileCode: string): string => {
  return ['TSH Profile -', positionName, firstName, profileCode].filter((phrase) => phrase).join(' ');
};

export const EditorContainer = ({ defaultData, onFormChange, onPositionChange, skillsOrder }: EditorContainerProps) => {
  const { queryParams } = useGetQueryParams();

  const formMethods = useForm<EditorFormValues>({
    defaultValues: getDefaultValues(defaultData),
    resolver: joiResolver(formSchema),
  });

  const formData = useWatch({ control: formMethods.control });

  const [positionName, setPositionName] = useState(defaultData.positionName);

  let formatedTitle = '';
  const { data: orderCategories } = useCategoriesOrder({
    config: {
      params: {
        ['filter[positionCategoryOrderPosition.visibleName][eq]']: positionName,
        relations: ['positionCategoryOrder.category', 'positionCategoryOrder.position'],
      },
    },
    enabled: Boolean(positionName),
  });

  useEffect(() => {
    if (orderCategories || defaultData.positionName === null) {
      const sortedSkills = getSkillsSorted(
        defaultData.techSkills,
        skillsOrder,
        formData.positionName || '',
        orderCategories,
      );
      onPositionChange(sortedSkills);
    }

    onFormChange({ ...defaultData, ...formData });
    if (formData.positionName !== undefined) {
      setPositionName(formData.positionName);
    }
    formatedTitle = formatResumeFileName(positionName, defaultData.firstName, defaultData.code);
    document.title = formatedTitle;
  }, [formData, orderCategories]);

  if (queryParams.editorDisabled) {
    return null;
  }
  const handlePrintResume = () => {
    window.print();
  };

  return (
    <>
      <Helmet titleTemplate={`%s`} defaultTitle={formatedTitle} />
      <AccessControl allowedContent={ContentType.RapidResumeEditor}>
        <Box sx={S.sidebarWrapper}>
          <Box sx={S.editor}>
            <Typography variant="h6">{'generatedProfile.editor.title'}</Typography>
            <Separator />
            <Editor defaultData={{ positionName: defaultData.positionName }} formMethods={formMethods} />
          </Box>
          <Separator />
          <ActionButtons onPrintResume={handlePrintResume} />
        </Box>
      </AccessControl>
    </>
  );
};
