import { useQuery } from 'hooks';

export const useGetMultipleResumes = (userIds: string[]) =>
  useQuery<ArrayBuffer>(
    '/profiles/download-cvs',
    ['getProfilesCvs'],
    {
      enabled: false,
      onSuccess: (data) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
        a.download = 'profilesResumes.zip';
        a.click();
        a.remove();
      },
    },
    {
      responseType: 'arraybuffer',
      params: {
        ids: userIds,
      },
    },
  );
