import { useForm, FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';

import { Button, FormInputText, Modal, ModalButtonsWrapper, Separator, Typography } from 'ui';
import {
  ProjectRoleFormFields,
  ProjectRoleFormValues,
  ProjectRolesModalProps,
} from 'views/adminSection/projectRoles/modals/projectRoleModal/ProjectRoleModal.types';
import { useLocale, usePrompt, useSetFormError } from 'hooks';
import { getFormSchema } from 'views/adminSection/projectRoles/modals/projectRoleModal/ProjectRoleModal.utils';

export const ProjectRoleModal = ({
  onModalClose,
  isLoading,
  selectedRole: { name },
  onSubmit,
  error,
  isOpen,
  modalTypography,
  mutationErrorReset,
}: ProjectRolesModalProps) => {
  const { formatMessage } = useLocale();
  const { setFormError } = useSetFormError();
  const formMethods = useForm<ProjectRoleFormValues>({
    defaultValues: { name },
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit, reset, setError } = formMethods;

  useEffect(() => {
    if (isOpen) {
      reset({ name });
    }
  }, [name, reset, isOpen]);

  useEffect(() => {
    setFormError<ProjectRoleFormValues>(setError, error, ProjectRoleFormFields, 'name');
  }, [setFormError, setError, error]);

  usePrompt({ when: formMethods.formState.isDirty });

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={() => {
        onModalClose();
        mutationErrorReset && mutationErrorReset();
      }}
    >
      <Typography variant="h5">{modalTypography.title}</Typography>
      <Separator />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInputText label="projectRoles.placeholder.name" name={ProjectRoleFormFields.Name} required />

          <ModalButtonsWrapper>
            <Button label="form.cancel" onClick={onModalClose} dataTestId="cancel" variant="outlined" />
            <LoadingButton loading={isLoading} variant="contained" type="submit" data-testid="submit">
              <FormattedMessage id={modalTypography.submitButton} />
            </LoadingButton>
          </ModalButtonsWrapper>
        </form>
      </FormProvider>
    </Modal>
  );
};
