import { Navigate } from 'react-router-dom';

import { AccessControl } from 'ui';
import { AppRoute } from '../AppRoute.enum';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { CategoriesContainer } from 'views/adminSection/categories/CategoriesContainer';

export const AuthorizedCategories = () => (
  <AccessControl allowedContent={ContentType.Categories} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <CategoriesContainer />
  </AccessControl>
);
