import { Box } from '@mui/material';

import { Tag, Typography } from 'ui';

import { OtherSkillsProps } from './OtherSkills.types';
import * as S from './OtherSkills.styles';

export const OtherSkills = ({ skillsGroup }: OtherSkillsProps) => (
  <Box sx={S.skillWrapper}>
    <Typography sx={S.category} variant="h3">
      {'generatedProfile.otherSkills'}
    </Typography>

    {skillsGroup[0].skills.map(({ name }) => (
      <Tag key={name} name={name} />
    ))}
  </Box>
);
