import { Button as ICLButton } from 'lux/components';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';

import * as S from './Button.styles';
import { ButtonProps } from './Buttons.types';

export const Button = ({ label, dataTestId, children, isLoading, ...props }: ButtonProps) => (
  <ICLButton
    endIcon={isLoading && <CircularProgress sx={S.loader} size={20} />}
    disabled={isLoading}
    variant="contained"
    {...props}
    data-testid={dataTestId || 'button'}
  >
    {children}
    <FormattedMessage id={label} />
  </ICLButton>
);
