import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect, useState } from 'react';

import { useLocale } from 'hooks';
import { ProjectModalFormFields, ProjectModalFormValues } from '../projectModal/ProjectModal.types';
import { defaultValues, getFormSchema } from '../projectModal/ProjectModal.utils';
import { usePostProfileProject } from '../../hooks/usePostProfileProject';
import { ProjectModalContainer } from '../projectModal/ProjectModalContainer';
import { useProjectGet } from 'api/actions/projects/projectsActions';
import { Project } from 'api/actions/projects/projectsActions.types';
import { useProjectContext } from '../../context/ProjectContext';

import { AddNewProjectModalContainerProps } from './AddProjectModalContainer.types';

export const getDefaultValues = (projectData: Project) => {
  const projectTechnologiesOptions = projectData
    ? projectData.technologies.map(({ technology: { id, name } }) => ({ id, name }))
    : [];

  return {
    [ProjectModalFormFields.Name]: projectData.name || '',
    [ProjectModalFormFields.ShortDescription]: projectData?.shortDescription || '',
    [ProjectModalFormFields.Description]: projectData?.description || '',
    [ProjectModalFormFields.ProjectTechnologies]: projectTechnologiesOptions,
    [ProjectModalFormFields.ProfileProjectTechnologies]: [],
  };
};

export const AddProjectModalContainer = ({ onClose, isOpen }: AddNewProjectModalContainerProps) => {
  const { formatMessage } = useLocale();
  const [projectId, setProjectId] = useState<string>('');

  const formMethods = useForm<ProjectModalFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit, watch, reset } = formMethods;
  const { isProjectNew, setIsProjectSelected, setIsProjectNew, startTransition } = useProjectContext();

  const project = watch('name') as Project;

  const { addNewProject, addExistingProfileProject, isLoading, error } = usePostProfileProject(onClose);

  const { data: projectData, isLoading: isProjectDataLoading } = useProjectGet(project?.id, {
    enabled: !!project?.id && !isProjectNew,
  });

  useEffect(() => {
    projectData && reset(getDefaultValues(projectData), { keepDefaultValues: true });
  }, [projectData]);

  useEffect(() => {
    if (project.id) {
      setProjectId(project.id);
    }

    if (isProjectNew) {
      setProjectId('');
    }
  }, [isProjectNew, project]);

  const handleSubmitExistingProject = (data: ProjectModalFormValues) => {
    startTransition(() => {
      addExistingProfileProject(projectId, data);
      setIsProjectSelected(false);
      setIsProjectNew(false);
    });
  };

  const action = isProjectNew ? addNewProject : handleSubmitExistingProject;

  return (
    <ProjectModalContainer
      isAdminOrNewProject={isProjectNew}
      isOpen={isOpen}
      isLoading={isLoading || isProjectDataLoading}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={handleSubmit(action)}
      header="profileProject.form.add"
      error={error}
      initialValues={{ description: projectData?.description || '' }}
    />
  );
};
