import { Table } from 'lux/components';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { SchoolProps } from './Education.types';

export const Education = ({ school, degree, subject, year, onRemoveButtonClick, onEditButtonClick }: SchoolProps) => (
  <Table.Row>
    <Table.Cell>{school}</Table.Cell>
    <Table.Cell align="center">{subject}</Table.Cell>
    <Table.Cell align="center">{degree}</Table.Cell>
    <Table.Cell align="center">{year ? year : 'ongoing'}</Table.Cell>

    <Table.Cell align="right">
      <IconButton onClick={onEditButtonClick}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={onRemoveButtonClick}>
        <CloseIcon />
      </IconButton>
    </Table.Cell>
  </Table.Row>
);
