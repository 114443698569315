import { Box } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';

import { useProjectsQuery } from '../../../../hooks/useProjectsQuery';
import { ProjectModalFormFields, ProjectModalFormValues } from '../../ProjectModal.types';
import { Button, FormAutocomplete, FormInputText, RichTextEditor, Separator, Typography } from 'ui';
import { defaultValues } from '../../ProjectModal.utils';
import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { useProjectContext } from '../../../../context/ProjectContext';
import { TechnologyAutocomplete } from '../technologies/technologyAutocomplete/TechnologyAutocomplete';

import { ProjectInformationProps } from './ProjectInformation.types';
import { headerWrapper, technologyAutocomplete } from './ProjectInformation.styles';

export const ProjectInformation = ({
  initialValues,
  projectTechnologies,
  allTechnologies,
  isLoading,
}: ProjectInformationProps) => {
  const { reset, trigger, formState } = useFormContext();
  const { projects } = useProjectsQuery();

  const { startTransition, isPending, setIsProjectNew, isProjectNew, isProjectSelected, setIsProjectSelected } =
    useProjectContext();

  const watchedValues = useWatch<ProjectModalFormValues>();

  const clearProject = () => {
    setIsProjectSelected(false);
    setIsProjectNew(false);
    startTransition(() => reset(defaultValues));
  };

  const mapProjectValue = (
    selectedValue: AutocompleteOption | null,
  ): string | AutocompleteOption | undefined | null => {
    setIsProjectSelected(true);
    const isProjectNew = selectedValue?.name === selectedValue?.id;
    if (isProjectNew) {
      return selectedValue?.name;
    }

    return selectedValue;
  };

  const handleAddNewProject = () => {
    startTransition(() => {
      setIsProjectSelected(true);
      setIsProjectNew(true);
    });
  };

  useEffect(() => {
    const dirtyFields = Object.keys(formState.dirtyFields);

    if (dirtyFields) {
      dirtyFields.forEach((value) => {
        trigger(value as keyof ProjectModalFormValues);
      });
    }
  }, [watchedValues]);

  return (
    <>
      {!isProjectSelected && <Separator height={8} />}
      <Box sx={headerWrapper}>
        <Typography variant="h6">{'profileProject.header.projectInformation'}</Typography>
        {isProjectSelected && <Button onClick={clearProject} label="form.clearProject" variant="text" />}
      </Box>
      <Separator height={isProjectSelected ? 22 : 30} />
      <>
        <FormAutocomplete
          name={ProjectModalFormFields.Name}
          options={projects?.result || []}
          label="form.project"
          blurOnSelect
          freeSolo
          forcePopupIcon
          data-testid="inputProject"
          isOptionEqualToValue={(option, value: AutocompleteOption | string) =>
            option.name === value || (typeof value === 'object' && 'name' in value && option.name === value?.name)
          }
          withAddNewButton
          required
          disabled={isProjectSelected}
          onChangeValueMapper={mapProjectValue}
          onAddNew={handleAddNewProject}
        />
        <Separator height={24} />
      </>
      <>
        <FormInputText
          size="medium"
          name={ProjectModalFormFields.ShortDescription}
          label="profileProject.placeholder.shortDescription"
          required
          disabled={!isProjectNew}
        />
        <RichTextEditor
          placeholder="profileProject.placeholder.projectDetails"
          name={ProjectModalFormFields.Description}
          disabled={!isProjectNew}
          initialValue={initialValues?.description}
          isLoading={isLoading || isPending}
          required
        />
        <Separator height={10} />

        <TechnologyAutocomplete
          name={ProjectModalFormFields.ProjectTechnologies}
          options={!isProjectNew ? projectTechnologies : allTechnologies}
          label="profileProject.placeholder.technology"
          disabled={!isProjectNew}
          sx={technologyAutocomplete}
        />
      </>
    </>
  );
};
