import { Styles } from 'lux/theme';

export const loaderStyles: Styles = {
  display: 'flex',
  justifyContent: 'center',
  mt: 2,
  mb: 3,
};

export const fitToParentStyles: Styles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.5)',
  zIndex: '100',
};
