import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, Typography } from 'ui';
import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { FormAutocompleteProps } from 'ui/formAutocomplete/FormAutocomplete.types';

import * as S from './FormAutocomplete.styles';

export const FormAutocomplete = ({ name, options, onChangeValueMapper, onAddNew, ...props }: FormAutocompleteProps) => {
  const { trigger } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ fieldState: { error }, field: { onChange, value } }) => {
        const handleChange = (selectedValue: AutocompleteOption | null) => {
          const mappedValue = onChangeValueMapper ? onChangeValueMapper(selectedValue) : selectedValue;
          onChange(mappedValue);
        };

        return (
          <>
            <Autocomplete
              onChange={handleChange}
              value={value || null}
              options={options}
              onAddNew={onAddNew}
              {...props}
              error={!!error}
              onBlur={() => trigger(name)}
            />
            {error && (
              <Typography variant="helperText" sx={S.errorMessage}>
                {error.message}
              </Typography>
            )}
          </>
        );
      }}
    />
  );
};
