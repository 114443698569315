import { useEffect } from 'react';

import { useSetFormError } from 'hooks';

import { CategoryModalContainerProps } from './CategoryModalContainer.types';
import { CategoryModalFormFields, CategoryModalFormValues } from './CategoryModal.types';
import { CategoryModal } from './CategoryModal';

export const CategoryModalContainer = ({
  onClose,
  isLoading,
  formMethods,
  isOpen,
  onSubmit,
  header,
  error,
}: CategoryModalContainerProps) => {
  const { setFormError } = useSetFormError();
  const { reset, setError } = formMethods;

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  useEffect(() => {
    setFormError<CategoryModalFormValues>(setError, error, CategoryModalFormFields, 'name');
  }, [setFormError, setError, error]);

  return (
    <CategoryModal
      isOpen={isOpen}
      isLoading={isLoading}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={onSubmit}
      header={header}
    />
  );
};
