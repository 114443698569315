import { Styles } from 'views/generatedProfile/theme/theme';
import BG from 'assets/images/cv-header-bg.png';

export const header: Styles = ({ spacing }) => ({
  backgroundImage: `url(${BG})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  p: spacing(3),
  borderRadius: 1.5,
  display: 'flex',
  gap: 5,
  mb: 5,
});

export const logoNameContainer: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const headerContent: Styles = {
  flex: 8,
};

export const headerImage: Styles = ({ spacing }) => ({
  '& img': {
    width: spacing(10),
    float: 'right',
    m: 1,
  },
});

export const name: Styles = ({ spacing }) => ({
  fontSize: spacing(5.25),
  fontWeight: 600,
});

export const position: Styles = ({ spacing }) => ({
  fontSize: spacing(1.75),
  fontWeight: 600,
  color: 'rgb(0, 27, 177)',
  mb: 2,
});

export const userCode: Styles = ({ spacing }) => ({
  fontSize: spacing(1),
  mb: 0.5,
});

export const about: Styles = ({ spacing }) => ({
  fontSize: spacing(1.625),
  lineHeight: 1.6,
});
