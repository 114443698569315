import { BaseSyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ModalProps } from '../modals/muiModal/Modal.types';

export enum FormMode {
  add = 'add',
  edit = 'edit',
}

export type FormModalWrapperProps<T extends Record<string, unknown>> = Omit<ModalProps, 'onModalClose'> & {
  header: string;
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
  formMethods: UseFormReturn<T>;
  isLoading: boolean;
  isDataFetching?: boolean;
  onClose: VoidFunction;
};
