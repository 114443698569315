import { AxiosRequestConfig } from 'axios';

import { useContentAccess, useQuery } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { AppSettingsResponse } from './appSettingsActions.types';

export const useGetSettings = (config?: AxiosRequestConfig) => {
  const { hasAccess } = useContentAccess({});

  return useQuery<AppSettingsResponse>(
    '/settings',
    'app-settings',
    { enabled: hasAccess(ContentType.AppSettings) },
    config,
  );
};
