import { Helmet } from 'react-helmet';

import { useContentAccess, useGlobalFilter, useLocale } from 'hooks';
import { AdminSectionWrapper, Divider, GlobalFilter, OpenModalButton } from 'ui';
import { useCertificatesGet } from 'api/actions/certificates/certificatesActions';
import { useModal } from 'hooks/useModal/useModal';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { filtersOperators, getCertificatesParams, keysMapper } from './Certificates.utils';
import { Certificates } from './Certificates';
import { AddCertificateFormContainer } from './certificateForm/addCertificateForm/AddCertificateFormContainer';

export const CertificatesContainer = () => {
  const { formatMessage } = useLocale();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Certificates });

  const { globalFilter, setGlobalFilter, queryParamsDecoded } = useGlobalFilter({
    keysMapper,
    filtersOperators,
  });

  const { data, isLoading, isError } = useCertificatesGet({
    params: getCertificatesParams(queryParamsDecoded),
  });

  const { isOpen, onOpen, onClose } = useModal();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.certificates' })}</title>
      </Helmet>
      <AdminSectionWrapper
        title="navigator.certificates"
        action={
          isReadOnly ? null : <OpenModalButton label="certificates.form.add.buttons.addCertificate" onClick={onOpen} />
        }
        tabs="certificatesTabs"
      >
        {isOpen && <AddCertificateFormContainer isOpen={isOpen} onClose={onClose} />}

        <GlobalFilter
          onValueChange={setGlobalFilter}
          labelPlaceholder="certificates.table.searchCertificates"
          value={globalFilter}
        />
        <Divider />
        <Certificates
          defaultSort={[{ id: 'name', desc: false }]}
          data={data?.result}
          total={data?.total}
          isLoading={isLoading}
          isError={isError}
          globalFilter={globalFilter}
        />
      </AdminSectionWrapper>
    </>
  );
};
