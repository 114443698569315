import { Styles, componentsLibTheme as theme } from 'lux/theme';
const { spacing } = theme;

export const wrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(1),
  width: 110,
};
