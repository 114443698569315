import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useSkillDelete } from 'api/actions/skills/skillsActions';
import { SkillsQueryKeys } from 'api/actions/skills/skillsActions.types';
import { useSnackbar } from 'hooks';
import { UseDeleteSkillArgs } from 'views/adminSection/skills/hooks/useDeleteSkill.types';

export const useDeleteSkill = ({ onSuccess, onForceDelete }: UseDeleteSkillArgs) => {
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, isSuccess, error, isLoading } = useSkillDelete({
    onSuccess: () => {
      showSnackbarSuccess('success.itemDeleted');
      onSuccess && onSuccess();
      queryClient.invalidateQueries(SkillsQueryKeys.Get);
      queryClient.invalidateQueries('profile-skills');
    },
    onError: (error) => {
      showSnackbarError(error, [409]);
      if (error?.response?.status === 409) {
        onForceDelete && onForceDelete();
      }
    },
  });

  const deleteSkill = useCallback(
    async (skillId: string, isForceDelete = false, onSuccess?: VoidFunction) => {
      const result = await mutateAsync({ skillId, isForceDelete });

      if (result) {
        onSuccess && onSuccess();
      }

      return result;
    },
    [mutateAsync],
  );

  return { deleteSkill, isError, isSuccess, isLoading, error };
};
