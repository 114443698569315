import { AppRoute } from 'routing/AppRoute.enum';

export const projectsTabs = [
  { label: 'tabs.projects', href: AppRoute.Projects },
  { label: 'tabs.roles', href: AppRoute.ProjectRoles },
];

export const certificatesTabs = [
  { label: 'tabs.certificates', href: AppRoute.Certificates },
  { label: 'tabs.tags', href: AppRoute.Tags },
  { label: 'tabs.peopleCertificates', href: AppRoute.ProfileCertificates },
];

export const skillsTabs = [
  { label: 'tabs.skills', href: AppRoute.Skills },
  { label: 'tabs.skillsEditor', href: AppRoute.SkillsOrderEditor },
  { label: 'tabs.categories', href: AppRoute.TechnologyCategories },
  { label: 'tabs.categoriesOrder', href: AppRoute.TechnologyCategoriesOrder },
];

export const profileTabs = [
  { label: 'tabs.profiles', href: AppRoute.Profiles },
  { label: 'tabs.positions', href: AppRoute.Positions },
  { label: 'tabs.seniorities', href: AppRoute.Seniority },
  { label: 'tabs.languages', href: AppRoute.Languages },
];

export const adminSectionTabs = { projectsTabs, certificatesTabs, skillsTabs, profileTabs };
