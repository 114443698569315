import { Box } from '@mui/material';
import { CellContext } from '@tanstack/react-table';

import * as S from 'views/adminSection/tags/components/tableActions/TableActions.styles';
import { EditButton, DeleteButton, DeleteModal, ActionsWrapper } from 'ui';
import { useDeleteTag } from 'views/adminSection/tags/hooks/useDeleteTag';
import { Tag } from 'api/actions/tags/tagsActions.types';
import { useContentAccess, useModal } from 'hooks';
import { EditTagModalContainer } from 'views/adminSection/tags/modals/editTagModal/EditTagModalContainer';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const TableActions = (cell: CellContext<Tag, string>) => {
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Tags });

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useModal();
  const {
    isOpen: isForceDeleteModalOpen,
    onOpen: onForceDeleteModalOpen,
    onClose: onForceDeleteModalClose,
  } = useModal();

  const { deleteTag } = useDeleteTag({ onForceDelete: onForceDeleteModalOpen, onSuccess: onForceDeleteModalClose });

  return (
    <Box sx={S.wrapper}>
      {!isReadOnly && (
        <ActionsWrapper>
          <EditButton onClick={onEditModalOpen} />
          <DeleteButton onClick={() => deleteTag({ id: cell.getValue() })} />
        </ActionsWrapper>
      )}
      {isEditModalOpen && (
        <EditTagModalContainer tagData={cell.row.original} isOpen={isEditModalOpen} onModalClose={onEditModalClose} />
      )}
      {isForceDeleteModalOpen && (
        <DeleteModal
          isOpen={isForceDeleteModalOpen}
          onModalClose={onForceDeleteModalClose}
          onItemDelete={() => deleteTag({ id: cell.getValue(), forceDelete: true })}
        />
      )}
    </Box>
  );
};
