import { TechnologiesSurveyGetResponse } from 'api/actions/technologiesSurvey/technologiesSurveyActions.types';

import { TechnologiesSurveyModalFormValues } from './hooks/usePostTechnologiesSurvey.types';

export const mapTechnologiesSurveyToFormValues = (
  data?: TechnologiesSurveyGetResponse,
): TechnologiesSurveyModalFormValues => {
  if (!data) {
    return { skills: [] };
  }
  const { answeredItems, missingSkills } = data;

  const mappedAnsweredItems = answeredItems.map((item) => ({
    skillId: item.skillId,
    name: item.skill.name,
    familiarity: item.familiarity,
    preference: item.preference,
  }));

  const mappedMissingSkills = missingSkills.map((skill) => ({
    skillId: skill.id,
    name: skill.name,
  }));

  return {
    skills: [...mappedAnsweredItems, ...mappedMissingSkills].sort((a, b) => a.name.localeCompare(b.name)),
  };
};
