import { TopBar } from 'lux/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, ButtonBase } from '@mui/material';

import { ContentType } from 'config/userPermisions/userPermisions.types';
import DefaultAvatar from 'assets/images/default-avatar.png';
import Logo from 'assets/images/logo.svg';
import { useAuth, useUser } from 'hooks';

import {
  adminCertificatesSubMenu,
  adminProfilesSubMenu,
  adminProjectsSubMenu,
  adminSkillsSubMenu,
  userProfilesNavLinks,
} from './menu/MenuDefinitions.utils';
import { getMenuItems, getMenuItemWithSubMenu, isAllowed } from './UiTopBar.utils';
import { MenuItems } from './UiTopBar.types';
import * as S from './UiTopBar.styles';

export const UiTopBar = () => {
  const { logout } = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const hasProfileAccess = user?.hasProfileAccess;
  const menuItems: MenuItems = [];
  const isAdminPanelAllowed = isAllowed(user, ContentType.AdminPanel);
  const isUserMenuAllowed = isAllowed(user, ContentType.UserPanel, hasProfileAccess);

  if (isUserMenuAllowed) {
    menuItems.push(...getMenuItems(userProfilesNavLinks, pathname, search, user));
  }

  if (isAdminPanelAllowed) {
    [
      getMenuItemWithSubMenu(adminProfilesSubMenu, pathname, search, user),
      getMenuItemWithSubMenu(adminSkillsSubMenu, pathname, search, user),
      getMenuItemWithSubMenu(adminProjectsSubMenu, pathname, search, user),
      getMenuItemWithSubMenu(adminCertificatesSubMenu, pathname, search, user),
    ].forEach((menuItem) => {
      if (typeof menuItem !== 'undefined') {
        menuItems.push(menuItem);
      }
    });
  }

  // @todo Was in previous design
  // Use it as notification: <ProfileExpirationMessage updateConfirmedAt={user?.updateConfirmedAt} />
  return user ? (
    <Box sx={S.topBarWrapper}>
      <TopBar
        avatarImage={user.pictureUrl ?? DefaultAvatar}
        menuItems={menuItems}
        onLogoutClick={logout}
        hasSearch={false}
        logoImage={Logo}
        renderLogo={(logo) => <ButtonBase onClick={() => navigate('/')}>{logo}</ButtonBase>}
      />
    </Box>
  ) : null;
};
