import { Box } from '@mui/material';
import { CellContext } from '@tanstack/react-table';

import * as S from 'views/adminSection/tags/components/tableActions/TableActions.styles';
import { ActionsWrapper, DeleteButton, EditButton } from 'ui';
import { useContentAccess, useModal } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';
import { EditCategoryModalContainer } from '../../modals/editCategoryModal/EditCategoryModalContainer';
import { Category } from 'api/actions/categories/categoriesActions.types';
import { useDeleteCategory } from '../../hooks/useDeleteCategory';

export const TableActions = (cell: CellContext<Category, string>) => {
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Tags });

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useModal();

  const { deleteCategory } = useDeleteCategory(cell.getValue());

  return (
    <Box sx={S.wrapper}>
      {!isReadOnly && (
        <ActionsWrapper>
          <EditButton onClick={onEditModalOpen} />
          <DeleteButton onClick={deleteCategory} />
        </ActionsWrapper>
      )}

      {isEditModalOpen && (
        <EditCategoryModalContainer category={cell.row.original} isOpen={isEditModalOpen} onClose={onEditModalClose} />
      )}
    </Box>
  );
};
