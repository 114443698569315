import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import { useMemo } from 'react';

import {
  ProjectRole,
  ProjectRolesDeleteArgs,
  ProjectRolesPatchArgs,
} from 'api/actions/projectRoles/projectRolesActions.types';
import { ActionsWrapper, EditButton, DeleteButton } from 'ui';
import { useContentAccess } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';

export const useProjectRolesColumns = (
  onDelete: (body: ProjectRolesDeleteArgs) => void,
  onEdit: (body: ProjectRolesPatchArgs) => void,
) => {
  const columnHelper = createColumnHelper<ProjectRole>();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.ProjectRoles });

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          header: () => <FormattedMessage id="projectRoles.table.headers.name" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('id', {
          id: 'actions',
          header: () => <FormattedMessage id="projectRoles.table.headers.actions" />,
          enableColumnFilter: false,
          enableSorting: false,
          cell: (props) =>
            isReadOnly ? null : (
              <ActionsWrapper>
                <EditButton onClick={() => onEdit({ id: props.getValue(), name: props.row.original.name })} />

                <DeleteButton
                  onClick={() => {
                    onDelete({ id: props.getValue(), forceDelete: false });
                  }}
                />
              </ActionsWrapper>
            ),
        }),
      ] as ColumnDef<ProjectRole>[],
    [columnHelper, onDelete, onEdit],
  );

  return columns;
};
