import { Styles } from 'lux/theme';
import { timelineConnectorClasses, timelineItemClasses } from '@mui/lab';

export const projectListTitle: Styles = {
  color: 'text.secondary',
  fontWeight: 600,
  borderBottom: '1px solid',
  borderColor: 'border.default',
  display: 'inline-block',
  width: '100%',
  pl: 1,
};

export const timelineStyles: Styles = {
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
  [`& .${timelineConnectorClasses.root}`]: {
    width: '1px',
    bgcolor: 'border.default',
  },
};
