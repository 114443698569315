import { useEffect } from 'react';

import { ProfileModal } from 'views/adminSection/profiles/modals/profileModal/ProfileModal';
import { ProfileModalContainerProps } from 'views/adminSection/profiles/modals/profileModal/ProfileModalContainer.types';
import { useSetFormError } from 'hooks';
import {
  ProfileModalFormFields,
  ProfileModalFormValues,
} from 'views/adminSection/profiles/modals/profileModal/ProfileModal.types';
import { useGetPositions } from 'api/actions/positions/positionsActions';
import { useGetSeniorities } from 'api/actions/seniorities/senioritiesActions';

export const ProfileModalContainer = ({
  onClose,
  isLoading,
  formMode,
  formMethods,
  isOpen,
  onSubmit,
  header,
  error,
}: ProfileModalContainerProps) => {
  const { setFormError } = useSetFormError();
  const { reset, setError } = formMethods;

  const { data, isLoading: positionsLoading } = useGetPositions({
    params: {
      'sort[position.visibleName]': 'ASC',
    },
  });

  const { data: seniorityData, isLoading: seniorityLoading } = useGetSeniorities({
    params: {
      'sort[display_order]': 'ASC',
    },
  });

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  useEffect(() => {
    setFormError<ProfileModalFormValues>(setError, error, ProfileModalFormFields, 'email');
  }, [setFormError, setError, error]);

  return (
    <ProfileModal
      isOpen={isOpen}
      isLoading={isLoading || positionsLoading || seniorityLoading}
      formMode={formMode}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={onSubmit}
      header={header}
      seniorityData={seniorityData?.result || []}
      positionsData={data?.result || []}
    />
  );
};
