import { LoadingButton } from '@mui/lab';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button, ModalButtonsWrapper, Separator, Typography } from 'ui';

import { AdminSectionFormProps } from './AdminSectionForm.types';

export const AdminSectionForm = <TFormValues extends Record<string, unknown>>({
  children,
  formMethods,
  onSubmit,
  isError,
  isLoading,
  submitButtonLabel,
  errorLabel = 'error.errorOccurred',
  onClose,
}: AdminSectionFormProps<TFormValues>) => (
  <FormProvider {...formMethods}>
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      {children}

      {isError && (
        <>
          <Typography variant="helperText">{errorLabel}</Typography>
          <Separator />
        </>
      )}

      <ModalButtonsWrapper>
        <Button label="form.cancel" onClick={onClose} dataTestId="cancel" variant="outlined" />
        <LoadingButton data-testid="submit" loading={isLoading} variant="contained" type="submit">
          <FormattedMessage id={submitButtonLabel ? submitButtonLabel : 'form.add'} />
        </LoadingButton>
      </ModalButtonsWrapper>
    </form>
  </FormProvider>
);
