import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useLocale } from 'hooks';
import { usePatchProfileCertificate } from '../../hooks/usePatchProfileCertificate';
import { CertificateModal } from '../certificateModal/CertificateModal';
import { getDefaultValues, getFormSchema } from '../certificateModal/CertificateModal.utils';
import { CertificateModalFormValues } from '../certificateModal/CertificateModal.types';

import { EditCertificateModalProps } from './EditCertificateModal.types';

export const EditCertificateModal = ({
  isOpen,
  onModalClose,
  certificatesOptions,
  profileCertificate,
}: EditCertificateModalProps) => {
  const { formatMessage } = useLocale();
  const formMethods = useForm<CertificateModalFormValues>({
    defaultValues: getDefaultValues(profileCertificate),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { editProfileCertificate, isLoading } = usePatchProfileCertificate(profileCertificate.id, onModalClose);

  return (
    <CertificateModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      formMethods={formMethods}
      onSubmit={editProfileCertificate}
      isLoading={isLoading}
      certificatesOptions={certificatesOptions}
      submitLabel={formatMessage({ id: 'form.save' })}
    />
  );
};
