import { Item, MutationUpdaterData } from './mutationUpdater.types';

export const generateUpdatedData = <TDataResult extends Item>(
  method: 'POST' | 'PATCH' | 'DELETE',
  itemOrId: TDataResult | string,
  dataToUpdate: MutationUpdaterData<TDataResult>,
): MutationUpdaterData<TDataResult> => {
  const updaters = {
    DELETE: dataToUpdate.result.filter((el) => (typeof itemOrId === 'string' ? el.id !== itemOrId : el)),
    POST: typeof itemOrId !== 'string' ? [...dataToUpdate.result, itemOrId] : dataToUpdate.result,
    PATCH: dataToUpdate.result.map((el) => {
      return typeof itemOrId !== 'string' ? (el.id === itemOrId.id ? itemOrId : el) : el;
    }),
  };

  const updatedData = updaters[method];

  return { result: updatedData, total: updatedData.length };
};
