import { flexRender, useReactTable } from '@tanstack/react-table';
import { Box } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DragHandle } from '@mui/icons-material';
import { Table } from 'lux/components';

import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  getTableOptions,
  handleDragEnd,
  Styles,
  TableHeader,
  TableStatus,
  TablePagination,
  useTableLogic,
} from '../common';

import { DragAndDropTableProps } from './DragAndDropTable.types';

export const DragAndDropTable = <T extends Record<string, unknown>>({
  columns,
  data = [],
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  total = 0,
  isLoading,
  isError,
  droppableId,
  onDragAndDropEnd,
  sx,
  keysMapper,
  filtersOperators,
  defaultSort = [],
  defaultFilters = [],
  isAutoDefaultSort,
  isReadOnly,
  globalFilter,
}: DragAndDropTableProps<T>) => {
  const {
    page,
    pageSize,
    setPageSize,
    setPage,
    setFilter,
    filter,
    sort,
    setSort,
    queryParams,
    selectedRows,
    setSelectedRows,
  } = useTableLogic({
    defaultSort,
    defaultFilters,
    keysMapper,
    filtersOperators,
    pageSizeOptions,
    total,
    isAutoDefaultSort,
    globalFilter,
  });

  const table = useReactTable(
    getTableOptions({
      data,
      columns,
      page,
      pageSize,
      sort,
      filter,
      total,
      onSort: setSort,
      onFilter: setFilter,
      selectedRows,
      onRowSelect: setSelectedRows,
      enableRowsSelection: false,
      globalFilter,
    }),
  );

  return (
    <>
      <Table sx={sx?.table}>
        <Table.Table>
          <Table.Head sx={sx?.tableHead}>
            <TableHeader tableHeaders={table.getHeaderGroups()} />
          </Table.Head>
          <DragDropContext
            onDragEnd={(result) => {
              handleDragEnd(data, result, onDragAndDropEnd, queryParams);
            }}
          >
            <Droppable droppableId={droppableId}>
              {(provided) => (
                <Table.Body ref={provided.innerRef} {...provided.droppableProps} sx={sx?.tableBody}>
                  {table.getRowModel().rows.map((row) => (
                    <Draggable
                      isDragDisabled={isReadOnly}
                      draggableId={String(row.original.id)}
                      key={String(row.original.id)}
                      index={row.index}
                    >
                      {(provided, snapshot) => (
                        <Table.Row
                          sx={snapshot.isDragging ? Styles.draggedItemStyles : undefined}
                          ref={provided.innerRef}
                          key={row.id}
                          {...provided.draggableProps}
                        >
                          {row.getVisibleCells().map((cell, cellIndex) => (
                            <Table.Cell key={cell.id}>
                              {cellIndex === 0 && !isReadOnly && (
                                <Box sx={Styles.handle} key={row.id} {...provided.dragHandleProps}>
                                  <DragHandle />
                                </Box>
                              )}
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Table.Body>
              )}
            </Droppable>
          </DragDropContext>
        </Table.Table>
      </Table>
      <TableStatus data={data} isError={isError} isLoading={isLoading} />
      <TablePagination
        page={page}
        onPageChange={setPage}
        total={total}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
};
