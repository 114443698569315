import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAccount, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { useAuth, useLocale } from 'hooks';

export const App = () => {
  const { formatMessage } = useLocale();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { login, accessToken } = useAuth();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('profile-editor')) {
      if (typeof window !== 'undefined') {
        window.scrollTo({ top: 0 });
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (account && inProgress === InteractionType.None && !accessToken) {
      login({
        instance,
        account,
      });
    }
  }, [account, instance, inProgress, accessToken]);

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${formatMessage({ id: 'title.default' })}`}
        defaultTitle={formatMessage({ id: 'title.default' })}
      ></Helmet>
      <Outlet />
    </>
  );
};
