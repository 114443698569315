import { Typography, DeleteModal } from 'ui';

import { ProjectDeletionModalProps } from './ProjectDeletionModal.types';

export const ProjectDeletionModal = ({
  projectName: name,
  onDelete,
  isLoading,
  isError,
  isOpen,
  onClose,
}: ProjectDeletionModalProps) => {
  return (
    <DeleteModal
      onModalClose={onClose}
      onItemDelete={onDelete}
      isLoading={isLoading}
      isError={isError}
      isOpen={isOpen}
      title={
        <Typography variant="body1" values={{ name }}>
          {'step.experience.projectDeletionConfirmation'}
        </Typography>
      }
      maxWidth={600}
    />
  );
};
