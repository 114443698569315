import { Styles, componentsLibTheme as theme } from 'lux/theme';

export const richTextInner = (disabled: boolean): Styles => ({
  position: 'relative',
  borderRadius: theme.spacing(0, 0, 1, 1),
  '&.active, &:focus-within': {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: -2,
  },

  '& .content-editable, .content-editable:hover': {
    backgroundColor: disabled ? theme.palette.surface.disabled : 'inherit',
    color: disabled ? theme.palette.text.disabled : 'inherit',
  },
});

export const helperText: Styles = {
  color: theme.palette.text.secondary,
  pl: '14px',
  boxDecorationBreak: 'clone',
};

export const errorContainer: Styles = {
  border: '1px solid',
  borderColor: theme.palette.error.main,
  borderRadius: '8px',
};

export const errorMessage: Styles = {
  color: theme.palette.error.main,
};
