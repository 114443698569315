import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useLocale } from 'hooks';
import {
  SkillModalFormFields,
  SkillModalFormValues,
} from 'views/adminSection/skills/modals/skillModal/SkillModal.types';
import { defaultValues, getFormSchema } from 'views/adminSection/skills/modals/skillModal/SkillModal.utils';
import { usePostSkill } from '../../hooks/usePostSkill';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { SkillModalContainer } from 'views/adminSection/skills/modals/skillModal/SkillModalContainer';

import { PostSkillModalContainerProps } from './PostSkillModalContainer.types';

export const PostSkillModalContainer = ({ onModalClose, isOpen }: PostSkillModalContainerProps) => {
  const { postSkill, isLoading: isPostLoading, error } = usePostSkill(onModalClose);
  const { formatMessage } = useLocale();

  const formMethods = useForm<SkillModalFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit, watch } = formMethods;

  const skillType = watch(SkillModalFormFields.Type);

  return (
    <SkillModalContainer
      error={error}
      isOpen={isOpen}
      isLoading={isPostLoading}
      formMode={FormMode.add}
      formMethods={formMethods}
      onClose={onModalClose}
      onSubmit={handleSubmit(postSkill)}
      skillType={skillType}
      header="form.addNewSkill"
    />
  );
};
