import { getCoreRowModel, TableOptions } from '@tanstack/react-table';
import { DropResult } from 'react-beautiful-dnd';

import { DragAndDropBody } from 'api/types/types';

import { GetTableOptionsProps } from './Tables.types';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const getTableOptions = <T extends Record<string, unknown>>({
  columns,
  data,
  page,
  pageSize,
  sort,
  filter,
  total,
  onSort,
  onFilter,
  onRowSelect,
  selectedRows,
  enableRowsSelection = false,
  columnVisibility,
}: GetTableOptionsProps<T>) => {
  return {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: selectedRows,
      pagination: {
        pageSize: pageSize,
        pageIndex: page,
      },
      sorting: sort,
      columnFilters: filter,
      columnVisibility,
    },
    pageCount: total,
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: true,
    manualFiltering: true,
    enableRowSelection: enableRowsSelection,
    getRowId: (originalRow, index) => (originalRow.id ? originalRow.id : index),
    onRowSelectionChange: onRowSelect,
    onSortingChange: onSort,
    onColumnFiltersChange: (customFilters) => {
      onFilter && onFilter(customFilters);
    },
  } as TableOptions<T>;
};

export const handleDragEnd = async <TData extends Record<string, unknown>>(
  data: TData[],
  dropResult: DropResult,
  onDragAndDropEnd: (body: DragAndDropBody) => Promise<void>,
  queryParams?: Record<string, string | number>,
) => {
  if (!dropResult.destination || dropResult.destination.index === dropResult.source.index) {
    return;
  }

  const {
    draggableId: id,
    destination: { index: destinationIndex },
    source: { index: sourceIndex },
  } = dropResult;

  const droppedItemDisplayOrder = data[dropResult.destination.index].displayOrder as number;
  await onDragAndDropEnd({
    displayOrder: droppedItemDisplayOrder,
    id,
    sourceIndex,
    destinationIndex,
    queryParams,
  });
};
