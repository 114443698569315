import { useMutation, useQuery } from 'hooks';

import {
  CategoriesOrderResponse,
  UpdateCategoryOrderOptions,
  UseCategoriesOrderProps,
} from './categoriesOrderActions.types';

export const categoriesOrderQueryKey = 'categories-order';

export const useCategoriesOrder = ({ config, enabled }: UseCategoriesOrderProps) => {
  return useQuery<CategoriesOrderResponse>(
    '/skills/position-category-order',
    [categoriesOrderQueryKey, config?.params],
    { enabled },
    config,
  );
};

export const useCategoryOrderUpdateMutation = (options: UpdateCategoryOrderOptions) => {
  return useMutation(
    'updateCategoryOrder',
    ({ id, displayOrder }) => ({
      endpoint: `/skills/position-category-order/${id}`,
      method: 'PATCH',
      params: { displayOrder },
    }),
    options,
  );
};
