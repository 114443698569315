import { usePostProfileSkill, useSkillsQuery } from 'views/profileEditor/steps/skills/hooks';
import { SkillType } from 'api/actions/skills/skillsActions.types';

import { ProfileSkills } from './ProfileSkills';
import { ProfileSkillsContainerProps } from './ProfileSkills.types';

export const ProfileSkillsContainer = ({ type }: ProfileSkillsContainerProps) => {
  const { skillOptions, profileSkills, isLoading: isSkillsLoading } = useSkillsQuery(type);
  const { skillOptions: allSkillOptions, profileSpecialSkills, isLoading: isSpecialSkillsLoading } = useSkillsQuery();
  const { addSkill } = usePostProfileSkill();

  const isLoading = isSkillsLoading || isSpecialSkillsLoading;

  if (type === SkillType.Custom) {
    return (
      <ProfileSkills
        type={type}
        skillsOptions={allSkillOptions || []}
        isLoading={isLoading}
        profileSkills={profileSpecialSkills?.result || []}
        onAutocompleteChange={(value) => {
          if (value !== null) {
            const { id, name } = value;
            addSkill({ id, name }, true, type);
          }
        }}
      />
    );
  } else {
    return (
      <ProfileSkills
        type={type}
        skillsOptions={skillOptions || []}
        isLoading={isLoading}
        profileSkills={profileSkills?.result || []}
        onAutocompleteChange={(value) => {
          if (value !== null) {
            const { id, name } = value;
            addSkill({ id, name }, false, type);
          }
        }}
      />
    );
  }
};
