import { FilterType } from 'types/table';

import { TableFiltersProps } from './TableFilters.types';
import { InputFilter } from './inputFilter/InputFilter';
import { SelectFilter } from './selectFilter/SelectFilter';

export const TableFilters = <T extends Record<string, unknown>>({ column }: TableFiltersProps<T>) => {
  const filterType: FilterType = column.columnDef?.meta?.filterType ?? 'input';

  return {
    input: <InputFilter column={column} />,
    select: <SelectFilter column={column} />,
  }[filterType];
};
