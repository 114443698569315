import { Navigate } from 'react-router-dom';

import { ContentType } from 'config/userPermisions/userPermisions.types';
import { AccessControl } from 'ui';
import { CurrentUserContextController } from 'context/currentUser/currentUserContextController/CurrentUserContextController';
import { AppRoute } from 'routing/AppRoute.enum';

import { ProfileEditorContainer } from './ProfileEditorContainer';

export const ProfileEditorProviders = () => (
  <AccessControl allowedContent={ContentType.ProfileEditor} renderNoAccess={<Navigate to={AppRoute.Home} />}>
    <CurrentUserContextController>
      <ProfileEditorContainer />
    </CurrentUserContextController>
  </AccessControl>
);
