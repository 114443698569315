import { FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { RadioGroup, Radio } from 'lux/components';

import { useLocale } from 'hooks';

import { FormRadioGroupProps } from './FormRadioGroup.types';

export const FormRadioGroup = ({ name, options, ...props }: FormRadioGroupProps) => {
  const { formatMessage } = useLocale();

  return (
    <Controller
      name={name ?? ''}
      render={({ field }) => (
        <RadioGroup {...field} {...props}>
          {options.map(({ label, value }) => (
            <FormControlLabel
              control={<Radio value={value} key={value} />}
              label={formatMessage({ id: label })}
              aria-label={label}
              key={value}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
};
