import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { patchBasicDataMutation } from 'api/actions/user/userActions';
import { BasicDataFormValues } from '../BasicData.types';
import { currentUserContextQueryKey } from 'context/currentUser/currentUserContextController/CurrentUserContextController';
import { userContextQueryKey } from 'context/user/userContextController/UserContextController';

export const usePatchBasicData = (onSuccess?: VoidFunction) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation('basicData', patchBasicDataMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(currentUserContextQueryKey);
      queryClient.invalidateQueries(userContextQueryKey);
      onSuccess && onSuccess();
      showSnackbarSuccess('success.dataUpdated');
    },
    onError: (error) => showSnackbarError(error),
  });

  const updateBasicData = useCallback(
    async (id: string, body: BasicDataFormValues) => await mutateAsync({ id, ...body }),
    [mutateAsync],
  );

  return { updateBasicData, isUpdating: isLoading };
};
