import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { SeniorityModalFormValues } from '../seniorityModal/SeniorityModal.types';
import { SeniorityModal } from '../seniorityModal/SeniorityModal';
import { usePatchSeniority } from '../../hooks/usePatchSeniority';
import { getFormSchema, getDefaultValues } from '../seniorityModal/SeniorityModal.utils';
import { useLocale } from 'hooks';

import { SeniorityEditionModalContainerProps } from './SeniorityEditionModalContainer.types';

export const SeniorityEditionModalContainer = ({
  seniority,
  isOpen,
  setIsEdit,
}: SeniorityEditionModalContainerProps) => {
  const { formatMessage } = useLocale();
  const formMethods = useForm<SeniorityModalFormValues>({
    defaultValues: getDefaultValues(seniority),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { editSeniority, isLoading: isPostLoading, error } = usePatchSeniority(seniority.id, () => setIsEdit(false));

  return (
    <SeniorityModal
      seniority={seniority}
      isOpen={isOpen}
      onClose={() => setIsEdit(false)}
      isLoading={isPostLoading}
      handleSenioritySubmit={editSeniority}
      formMethods={formMethods}
      submitButtonLabel={'form.save'}
      error={error}
    />
  );
};
