import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useDeleteProfileAchievement } from '../hooks/useDeleteProfileAchievement';
import { usePatchProfileAchievement } from '../hooks/usePatchProfileAchievement';
import { getEditFormSchema } from '../Achievements.utils';
import { AchievementsEditFormValues, AchievementsFormFields } from '../Achievements.types';

import { Achievement } from './Achievement';
import { AchievementContainerProps } from './Achievement.types';

export const AchievementContainer = ({ name, id }: AchievementContainerProps) => {
  const removeProfileAchievement = useDeleteProfileAchievement(id);
  const {
    editAchievement,
    isError: isEditAchievementError,
    isLoading: isEditAchievementLoading,
  } = usePatchProfileAchievement(id);

  const defaultValues = {
    description: name,
  };
  const editFormMethods = useForm<AchievementsEditFormValues>({
    defaultValues,
    resolver: joiResolver(getEditFormSchema()),
  });

  return (
    <FormProvider {...editFormMethods} key={id}>
      <Achievement
        name={AchievementsFormFields.Achievement}
        id={id}
        placeholder={name}
        initialValue={name}
        onRemoveButtonClick={removeProfileAchievement}
        onEditButtonClick={editAchievement}
        isEditError={isEditAchievementError}
        isEditLoading={isEditAchievementLoading}
      />
    </FormProvider>
  );
};
