import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLocale } from 'hooks';
import { Position } from 'api/actions/positions/positionsActions.types';
import { TableActions } from '../components/tableActions/TableActions';

const columnHelper = createColumnHelper<Position>();

export const usePositionsListColumns = () => {
  const { formatMessage } = useLocale();

  return useMemo(
    () =>
      [
        columnHelper.accessor('visibleName', {
          id: 'visibleName',
          header: () => <FormattedMessage id="positions.table.visibleName" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('dwhCode', {
          id: 'dwhCode',
          header: () => <FormattedMessage id="positions.table.dwhCode" />,
          enableColumnFilter: false,
        }),
        columnHelper.accessor('id', {
          id: 'actions',
          header: () => <FormattedMessage id="positions.table.actions" />,
          cell: TableActions,
          enableColumnFilter: false,
          enableSorting: false,
        }),
      ] as ColumnDef<Position>[],
    [formatMessage],
  );
};
