import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { usePostTag } from 'views/adminSection/tags/hooks/usePostTag';
import { AddTagModalContainerProps } from 'views/adminSection/tags/modals/addTagModal/AddTagModalContainer.types';
import { TagModalFormValues } from 'views/adminSection/tags/modals/TagModal/TagModal.types';
import { defaultValues, getFormSchema } from 'views/adminSection/tags/modals/TagModal/TagModal.utils';
import { useLocale } from 'hooks';
import { TagModalContainer } from 'views/adminSection/tags/modals/TagModal/TagModalContainer';

export const AddTagModalContainer = ({ onModalClose, isOpen }: AddTagModalContainerProps) => {
  const { postTag, postTagError, isPostTagLoading } = usePostTag({ onSuccess: onModalClose });
  const { formatMessage } = useLocale();

  const formMethods = useForm<TagModalFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  return (
    <TagModalContainer
      header="tags.form.add"
      isOpen={isOpen}
      onSubmit={formMethods.handleSubmit(postTag)}
      formMethods={formMethods}
      isLoading={isPostTagLoading}
      onClose={onModalClose}
      error={postTagError}
    />
  );
};
