import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UseModalReturnType } from './useModal.types';

export const useModal: () => UseModalReturnType = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return {
    onClose: () => setIsOpen(false),
    onOpen: () => setIsOpen(true),
    isOpen,
  };
};
