import { Styles } from 'lux/theme';

export const textStyles: Styles = ({ spacing }) => ({
  textAlign: 'center',
  fontSize: spacing(2.5),
  maxWidth: '230px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 2,
});

export const buttonBoxStyles: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
};

export const buttonStyles: Styles = ({ spacing }) => ({
  fontSize: spacing(2.25),
  minWidth: '120px',
  minHeight: '40px',
});
