import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { getTagsQueryKey, useTagDelete } from 'api/actions/tags/tagsActions';
import { useSnackbar } from 'hooks';
import { TagDeleteArgs } from 'api/actions/tags/tagsActions.types';

import { UseDeleteTagArgs } from './useDeleteTag.types';

export const useDeleteTag = ({ onForceDelete, onSuccess }: UseDeleteTagArgs) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate } = useTagDelete({
    onSuccess: () => {
      queryClient.invalidateQueries(getTagsQueryKey);
      showSnackbarSuccess('success.itemDeleted');
      onSuccess && onSuccess();
    },
    onError: (error) => {
      showSnackbarError(error, [409]);
      if (error?.response?.status === 409) {
        onForceDelete && onForceDelete();
      }
    },
  });

  const deleteTag = useCallback(
    ({ id, forceDelete }: TagDeleteArgs) => {
      mutate({ id, forceDelete });
    },
    [mutate],
  );

  return { deleteTag };
};
