import { Styles } from 'lux/theme';

export const technologyName: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const zebraStripe: Styles = {
  backgroundColor: '#f8f8f8',
};
