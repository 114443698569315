import { RichTextPlugin as LexicalRichText } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { Box } from '@mui/material';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';

import * as S from './RichTextPlugin.styles';
import { RichTextPluginProps } from './RichTextPlugin.types';

export const RichTextPlugin = ({ placeholder, minHeight, disabled }: RichTextPluginProps) => {
  return (
    <LexicalRichText
      ErrorBoundary={LexicalErrorBoundary}
      contentEditable={
        <Box sx={S.editorInput(minHeight, disabled)}>
          <ContentEditable className="content-editable" />
        </Box>
      }
      placeholder={<Box sx={S.placeholder}>{placeholder}</Box>}
    />
  );
};
