import { useMemo } from 'react';
import { UseMutationResult, useMutation as useRQMutation, UseMutationOptions, MutationKey } from 'react-query';

import { ClientErrorResponse, ClientResponse, ErrorData } from 'api/types/types';
import { useClient } from 'hooks';
import { useHandleQueryErrors } from 'hooks/useHandleQueryErrors/useHandleQueryErrors';

import { MutationFn } from './useMutation.types';

/**
 * Mutating data via this hook will not require specifying client in each mutation function, as it is required in React-query
 * @see https://react-query.tanstack.com/guides/mutations
 * This hook will automatically use client from ClientContext e.g Axios
 * @see ClientContext.ts
 * */
export const useMutation = <TData, TError extends ErrorData = ErrorData, TParams = unknown, TContext = unknown>(
  mutationKey: MutationKey,
  mutation: MutationFn<TParams, TData, TError>,
  options?: UseMutationOptions<ClientResponse<TData>, ClientErrorResponse<TError>, TParams, TContext>,
): UseMutationResult<ClientResponse<TData>, ClientErrorResponse<TError>, TParams, TContext> => {
  const { mutationFn } = useClient();
  const { handleErrors } = useHandleQueryErrors();

  const _mutationFn = useMemo(() => mutationFn<TParams, TData>(mutation), [mutationFn, mutation]);

  return useRQMutation<ClientResponse<TData>, ClientErrorResponse<TError>, TParams, TContext>(
    mutationKey,
    _mutationFn,
    {
      ...options,
      onError: (error, variables, context) => {
        handleErrors(error, ['error.notFound']);

        if (options?.onError) {
          options.onError(error, variables, context);
        }
      },
    },
  );
};
