import { Styles, componentsLibTheme as theme } from 'lux/theme';

export const datePickerWrapperStyles: Styles = {
  mb: 2.5,
  width: '100%',
};

export const helperText: Styles = {
  px: 1.5,
  boxDecorationBreak: 'clone',
};

export const error: Styles = {
  mt: 0.625,
  color: theme.palette.error.main,
};
