import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { ProfileCertificate } from 'api/actions/profile/certificates/profileCertificatesActions.types';
import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';

import { CertificateModalFormFields } from './CertificateModal.types';

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) =>
  Joi.object({
    [CertificateModalFormFields.Certificate]: Joi.object()
      .required()
      .empty([null])
      .messages(getErrorMessages({ formatMessage })),
    [CertificateModalFormFields.ObtainedAt]: Joi.date()
      .required()
      .empty([null])
      .messages(getErrorMessages({ formatMessage })),
    [CertificateModalFormFields.IsHidden]: Joi.boolean().default(false),
  });

export const defaultValues = {
  [CertificateModalFormFields.Certificate]: null,
  [CertificateModalFormFields.ObtainedAt]: null,
  [CertificateModalFormFields.IsHidden]: false,
};

export const getDefaultValues = (editedProfileCertificate: ProfileCertificate) => {
  const {
    certificate: { name, id },
    obtainedAt,
    isHidden,
  } = editedProfileCertificate;

  return {
    [CertificateModalFormFields.Certificate]: { name, id },
    [CertificateModalFormFields.ObtainedAt]: obtainedAt,
    [CertificateModalFormFields.IsHidden]: isHidden,
  };
};
