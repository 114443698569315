import { FormModalWrapperProps } from 'ui/formModalWrapper/FormModalWrapper.types';

export enum TagModalFormFields {
  Name = 'name',
}

export type TagModalFormValues = {
  [TagModalFormFields.Name]: string;
};

export type TagModalProps = Omit<FormModalWrapperProps<TagModalFormValues>, 'children'>;
