import { Styles } from 'views/generatedProfile/theme/theme';

export const title: Styles = ({ spacing }) => ({
  fontSize: spacing(2.25),
  fontWeight: 600,
  marginBottom: 3,
});

export const wrapper: Styles = ({ spacing }) => ({
  pageBreakInside: 'avoid',
  pb: 5,

  '& ul': {
    pl: 2.25,
    m: 0,
  },

  '& li': {
    fontSize: spacing(1.625),
  },
});

export const certificateDate: Styles = ({ spacing, palette }) => ({
  px: 1,
  mb: 0.5,
  borderRadius: 0.5,
  bgcolor: palette.grey['A100'],
  display: 'inline-block',
  fontSize: spacing(1.25),
});

export const certificateName: Styles = {
  mr: 1,
  wordBreak: 'break-word',
};
