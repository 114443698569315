import { useState, useTransition } from 'react';

import { ProjectContext } from './context/ProjectContext';
import { useProjectsQuery } from './hooks/useProjectsQuery';
import { Projects } from './Projects';

export const ProjectsContainer = () => {
  const { projects, profileProjects, isLoading } = useProjectsQuery();
  const [isProjectNew, setIsProjectNew] = useState(false);
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [isPending, startTransition] = useTransition();

  return (
    <ProjectContext.Provider
      value={{
        isPending,
        startTransition,
        isProjectNew,
        setIsProjectNew,
        isProjectSelected,
        setIsProjectSelected,
      }}
    >
      <Projects
        projectsOptions={projects?.result || []}
        isLoading={isLoading}
        profileProjects={profileProjects?.result || []}
      />
    </ProjectContext.Provider>
  );
};
