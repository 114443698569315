import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { Skill } from 'api/actions/skills/skillsActions.types';

import { ProjectModalContainerProps } from './ProjectModalContainer.types';
import { ProjectRoleProps } from './components/myRole/MyRole.types';
import { WorkedToProps } from './components/workedTo/WorkedTo.types';

export enum ProjectModalFormFields {
  Name = 'name',
  ShortDescription = 'shortDescription',
  Description = 'description',
  Role = 'role',
  AdditionalDescription = 'additionalDescription',
  JoinedAt = 'joinedAt',
  LeftAt = 'leftAt',
  ProjectType = 'projectType',
  IsInvisible = 'isInvisible',
  ProfileProjectTechnologies = 'profileProjectTechnologies',
  ProjectTechnologies = 'projectTechnologies',
}

export enum ProjectType {
  Private = 'private',
  Public = 'public',
}

export type ProjectModalFormValues = {
  [ProjectModalFormFields.Name]: string;
  [ProjectModalFormFields.ShortDescription]: string;
  [ProjectModalFormFields.Description]: string;
  [ProjectModalFormFields.Role]: AutocompleteOption | null;
  [ProjectModalFormFields.AdditionalDescription]: string;
  [ProjectModalFormFields.JoinedAt]: Date | null;
  [ProjectModalFormFields.LeftAt]: Date | null;
  [ProjectModalFormFields.ProjectType]: ProjectType;
  [ProjectModalFormFields.IsInvisible]: boolean;
  [ProjectModalFormFields.ProfileProjectTechnologies]: AutocompleteOption[];
  [ProjectModalFormFields.ProjectTechnologies]: AutocompleteOption[];
  isExisting: boolean;
};

export type ProjectModalProps = {
  projectTechnologies: AutocompleteOption[];
  profileProjectTechnologies: AutocompleteOption[];
  allTechnologies: Skill[];
} & Omit<ProjectModalContainerProps, 'error'> &
  ProjectRoleProps &
  WorkedToProps;
