import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useSkillPost } from 'api/actions/skills/skillsActions';
import { PostSkillBody, SkillsQueryKeys } from 'api/actions/skills/skillsActions.types';
import { useSnackbar } from 'hooks';

export const usePostSkill = (onSuccess: VoidFunction) => {
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, isLoading, error } = useSkillPost({
    onSuccess: () => {
      showSnackbarSuccess('success.itemAdded');
      onSuccess();
      queryClient.invalidateQueries(SkillsQueryKeys.Get);
    },
    onError: (error) => showSnackbarError(error),
  });

  const postSkill = useCallback(
    async (body: PostSkillBody) => {
      await mutateAsync({
        body,
      });
    },
    [mutateAsync],
  );

  return { postSkill, isError, isLoading, error };
};
