import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useIntl } from 'react-intl';

import { useCurrentUser, useSnackbar } from 'hooks';
import { useEducationPatch } from 'api/actions/profile/education/profileEducationActions';
import { EducationModalFormValues } from '../schoolModal/EducationModal.types';

export const usePatchEducation = (educationId: string, onSuccess: VoidFunction) => {
  const { user } = useCurrentUser();
  const intl = useIntl();
  const { showSnackbar, showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const educationQueryKey = `profiles/${user?.id}/educations`;

  const { mutateAsync, isError, isLoading, error } = useEducationPatch({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(educationQueryKey);
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const editProfileEducation = useCallback(
    async (body: EducationModalFormValues) => {
      if (!user?.id) {
        showSnackbar(intl.formatMessage({ id: 'error.tryToRelogin' }), 'error');

        return Promise.reject();
      }
      const year = body.year ? body.year.getFullYear() : null;

      return await mutateAsync({
        profileId: user?.id,
        profileEducationId: educationId,
        body: { ...body, year },
      });
    },
    [mutateAsync, user?.id],
  );

  return { editProfileEducation, isError, isLoading, error };
};
