import { DragAndDropTable } from 'ui/tables/dragAndDropTable/DragAndDropTable';
import { useContentAccess } from 'hooks';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { useLanguagesColumns } from './hooks/useLanguagesColumns';
import { useGetLanguagesQuery } from './hooks/useGetLanguagesQuery';
import * as S from './Languages.styles';
import { usePatchLanguage } from './hooks/usePatchLanguage';
import { filtersOperators, keysMapper } from './Languages.utils';

export const Languages = () => {
  const { columns } = useLanguagesColumns();

  const { data, isLoading, isError } = useGetLanguagesQuery();
  const { reorderLanguage } = usePatchLanguage();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Languages });

  return (
    <DragAndDropTable
      isReadOnly={isReadOnly}
      isAutoDefaultSort
      filtersOperators={filtersOperators}
      keysMapper={keysMapper}
      defaultSort={[{ id: 'displayOrder', desc: false }]}
      onDragAndDropEnd={reorderLanguage}
      droppableId="languages-table"
      data={data?.result}
      total={data?.total}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      sx={{ tableHead: S.tableHead, table: S.table }}
    />
  );
};
