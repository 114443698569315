export const isEmpty = (data: string | unknown[] | Record<string, unknown> | object) => {
  if (Array.isArray(data)) {
    const flatArray = data.flat(Infinity);
    if (flatArray.length) {
      const result = flatArray.filter((el) => {
        return typeof el === 'object' ? !!Object.keys(el).length : el;
      });

      return !result.length;
    }

    return !flatArray.length;
  }

  if (typeof data === 'string') {
    return !data.length;
  }

  if (typeof data === 'object') {
    return !Object.keys(data).length;
  }
};
