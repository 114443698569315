import { useState } from 'react';

import { useGetPositions } from 'api/actions/positions/positionsActions';
import { useGetQueryParams } from 'hooks';

export const usePositions = () => {
  const { queryParams } = useGetQueryParams();

  const [selectedPosition, setSelectedPosition] = useState<string | undefined>(queryParams?.search);

  const { data: positions } = useGetPositions();

  const formattedPositions =
    positions?.result.map((position) => ({
      id: position.id,
      value: position.visibleName,
      children: position.visibleName,
    })) || [];

  return {
    selectedPosition,
    setSelectedPosition,
    positions,
    formattedPositions,
  };
};
