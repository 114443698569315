import { useState } from 'react';
import { Table } from 'lux/components';
import { format } from 'date-fns';
import { Box } from '@mui/material';

import { EditButton, DeleteButton, Typography } from 'ui';
import { EditCertificateModal } from '../modals/editCertificateModal/EditCertificateModal';
import { row } from 'ui/tables/common/Tables.styles';

import { ProfileCertificateProps } from './ProfileCertificate.types';
import * as S from './ProfileCertificate.styles';

export const ProfileCertificate = ({
  profileCertificate,
  certificatesOptions,
  onRemoveButtonClick,
}: ProfileCertificateProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    certificate: { name },
    obtainedAt,
    isHidden,
    expiredAt,
  } = profileCertificate;

  return (
    <>
      <Table.Row sx={row}>
        <Table.Cell>
          {name} {isHidden && <Typography variant="helperText">{'table.hidden'}</Typography>}
        </Table.Cell>
        <Table.Cell>{format(new Date(obtainedAt), 'yyyy-MM')}</Table.Cell>
        <Table.Cell>{expiredAt ? format(new Date(expiredAt), 'yyyy-MM') : '-'}</Table.Cell>

        <Table.Cell align="right">
          <Box sx={S.actionButtons} className="actionsCell">
            <EditButton sx={S.editButtonStyles} onClick={() => setIsEditModalOpen(true)} />
            <DeleteButton onClick={onRemoveButtonClick} />
          </Box>
        </Table.Cell>
      </Table.Row>

      {isEditModalOpen && (
        <EditCertificateModal
          isOpen={isEditModalOpen}
          onModalClose={() => setIsEditModalOpen(false)}
          certificatesOptions={certificatesOptions}
          profileCertificate={profileCertificate}
        />
      )}
    </>
  );
};
