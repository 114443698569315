export enum AppRoute {
  Home = '/',
  Profiles = '/profiles',
  Login = '/login',
  ProfileEditor = '/profile-editor',
  GeneratedProfile = '/generated-profile',
  Positions = '/profiles/positions',
  Seniority = '/profiles/seniorities',
  Projects = '/projects',
  ProjectRoles = '/projects/roles',
  TechnologyCategories = '/skills/technology-categories',
  TechnologyCategoriesOrder = '/skills/technology-categories-order',
  Languages = '/profiles/languages',
  Skills = '/skills',
  Certificates = '/certificates',
  ProfileCertificates = '/certificates/profile',
  Tags = '/certificates/tags',
  SkillsOrderEditor = '/skills/order-editor',
  TechnologiesSurvey = '/survey',
}
