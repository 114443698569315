import { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useMsal } from '@azure/msal-react';

import { ClientContext } from '../clientContext/ClientContext';
import { useAxiosStrategy } from 'hooks/useAxiosStrategy/useAxiosStrategy';
import { ApiClientContextValue } from '../clientContext/ClientContext.types';

import { ClientProviderProps } from './ClientContextController.types';

export const ClientContextController = ({ children }: ClientProviderProps) => {
  const { instance } = useMsal();
  const { queryFn, mutationFn, axios } = useAxiosStrategy(instance);

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          queryFn,
          refetchOnWindowFocus: false,
          refetchOnMount: false,
        },
      },
    });
  }, [queryFn]);

  const ctxValue: Omit<ApiClientContextValue, 'axios'> = useMemo(
    () => ({
      queryFn,
      mutationFn,
    }),
    [queryFn, mutationFn],
  );

  return (
    <ClientContext.Provider value={{ ...ctxValue, axios }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ClientContext.Provider>
  );
};
