import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { ClientErrorResponse, ClientResponse, MutationContext } from 'api/types/types';
import { useMutation, useQuery } from 'hooks';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

import {
  LanguagesGetResponse,
  PatchLanguageMutationArgs,
  DeleteLanguageMutationArgs,
  PostLanguageMutationArgs,
  Language,
  GetLanguagesQueryParams,
} from './languagesActions.types';

export const useLanguagePatch = (
  options: UseMutationOptions<
    ClientResponse<Language>,
    ClientErrorResponse,
    PatchLanguageMutationArgs,
    MutationContext<Language>
  >,
) =>
  useMutation(
    'patchLanguage',
    ({ id, name, displayOrder }) => ({
      endpoint: `/languages/language/${id}`,
      method: 'PATCH',
      params: { name, displayOrder },
    }),
    options,
  );

export const useLanguageDelete = (
  options: UseMutationOptions<ClientResponse, ClientErrorResponse, DeleteLanguageMutationArgs>,
) =>
  useMutation(
    'deleteLanguage',
    ({ languageId }) => ({
      endpoint: `/languages/language/${languageId}`,
      method: 'DELETE',
    }),
    options,
  );

export const languagesQueryKey = 'languages';

export const useGetLanguages = (config: AxiosRequestConfig<GetLanguagesQueryParams>) => {
  const { params } = config;

  const queryKey = [languagesQueryKey, params];

  return useQuery<LanguagesGetResponse>(
    '/languages',
    queryKey,
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );
};

export const useLanguagePost = (
  options: UseMutationOptions<ClientResponse<Language>, ClientErrorResponse, PostLanguageMutationArgs>,
) =>
  useMutation(
    'postLanguage',
    ({ body }) => ({
      endpoint: '/languages/language',
      method: 'POST',
      params: body,
    }),
    options,
  );
