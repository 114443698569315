import * as React from 'react';
import { PropsWithChildren } from 'react';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';
import { MenuList } from 'lux/components';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { matchPath, useLocation } from 'react-router-dom';

import { UiLink } from '../menu/UiLink';
import { Typography } from '../../typography/Typography';

import { UiLinkSubMenuLinkProps, UiLinkSubMenuProps } from './UiLInkSubMenu.types';
import * as styles from './UiLinkSubMenu.styles';

export const UiLinkSubMenu = ({ subMenuItems, children }: PropsWithChildren<UiLinkSubMenuProps>) => {
  const location = useLocation();
  if (subMenuItems.length === 0) {
    return <></>;
  }
  const firstItem = subMenuItems[0];

  let activateFirst = false;

  const { to, linkWithQueryParams, target } = firstItem;
  subMenuItems.forEach((item) => {
    if (matchPath(location.pathname, item.to) !== null) {
      activateFirst = true;
    }
  });

  return (
    <PopupState variant="popover" popupId={firstItem.to}>
      {(popupState) => (
        <>
          <UiLink
            isActive={activateFirst}
            bindHover={bindHover(popupState)}
            to={to}
            target={target}
            linkWithQueryParams={linkWithQueryParams}
          >
            <Typography>{children}</Typography>
          </UiLink>

          {subMenuItems.length > 1 && (
            <HoverMenu sx={styles.submenu} {...bindMenu(popupState)}>
              {subMenuItems.slice(1).map(({ to, target, linkWithQueryParams, label }: UiLinkSubMenuLinkProps) => (
                <UiLink key={label} to={to} target={target} linkWithQueryParams={linkWithQueryParams}>
                  <MenuList.MenuItem onClick={popupState.close}>
                    <Typography>{`navigator.${label}`}</Typography>
                  </MenuList.MenuItem>
                </UiLink>
              ))}
            </HoverMenu>
          )}
        </>
      )}
    </PopupState>
  );
};
