import { Box } from '@mui/material';

import { Tag, Typography } from 'ui';
import { Project } from 'api/actions/resume/resumeActions.types';

import { NotableProjectsProps } from './NotableProjects.types';
import * as S from './NotableProjects.styles';

export const NotableProjects = ({ projects }: NotableProjectsProps) => {
  const formatDate = (date: Date) => new Date(date).toLocaleDateString('en-us', { year: 'numeric', month: 'long' });

  const getTimestamp = (date: Date | null): number => {
    return date ? new Date(date).getTime() : 0;
  };

  const compareDates = (dateA: Date | null, dateB: Date | null): number => {
    const timeA = getTimestamp(dateA);
    const timeB = getTimestamp(dateB);

    return timeA - timeB;
  };

  const sortProjects = (projects: Project[]): Project[] => {
    return projects.sort((a, b) => {
      const leftAtA = a.leftAt;
      const leftAtB = b.leftAt;

      if (leftAtA === null && leftAtB === null) {
        return compareDates(b.joinedAt, a.joinedAt);
      }

      if (leftAtA === null) return -1;
      if (leftAtB === null) return 1;

      const leftAtComparison = compareDates(leftAtB, leftAtA);
      if (leftAtComparison !== 0) {
        return leftAtComparison;
      }

      return compareDates(b.joinedAt, a.joinedAt);
    });
  };

  const sortedProjects = sortProjects(projects);

  return (
    <Box>
      <Typography sx={S.title} variant="h2" color="text.primary">
        {'generatedProfile.notableProjects'}
      </Typography>

      {sortedProjects.map(
        ({ name, joinedAt, leftAt, role, shortDescription, additionalDescription, description, techSkills }) => (
          <Box key={name} sx={S.projectWrapper}>
            {joinedAt && (
              <Typography sx={S.date} variant="body2">
                {`Engagement period: ${formatDate(joinedAt)} - ${leftAt ? formatDate(leftAt) : 'now'}`}
              </Typography>
            )}
            <Typography sx={S.shortDescription} variant="h4">
              {shortDescription}
            </Typography>

            <Box sx={S.techSkillsWrapper}>
              {techSkills.map(({ name }) => (
                <Tag key={name} name={name} />
              ))}
            </Box>

            {role && (
              <Typography sx={S.role} variant="h3">
                {role}
              </Typography>
            )}

            <Typography dangerouslySetInnerHTML={{ __html: description }} sx={S.description} />
            {additionalDescription && (
              <>
                <Typography sx={S.myDuties} variant="h3">
                  {'generatedProfile.notableProjects.myDuties'}
                </Typography>
                <Typography dangerouslySetInnerHTML={{ __html: additionalDescription }} sx={S.additionalDescription} />
              </>
            )}
          </Box>
        ),
      )}
    </Box>
  );
};
