import { useMemo, useState } from 'react';

import { QueryParams } from 'api/types/types';

import { UsePageSizeArgs } from './usePageSize.types';

const DEFAULT_LIMIT = 10;

export const usePageSize = ({ initialQueryParams, pageSizeOptions }: UsePageSizeArgs) => {
  const validPageSize = pageSizeOptions.includes(Number(initialQueryParams[QueryParams.LIMIT]))
    ? Number(initialQueryParams[QueryParams.LIMIT])
    : pageSizeOptions[0];

  const [pageSize, setPageSize] = useState(validPageSize || DEFAULT_LIMIT);

  const pageSizeQueryParams = useMemo(() => ({ limit: String(pageSize) }), [pageSize]);

  return { pageSize, setPageSize, pageSizeQueryParams };
};
