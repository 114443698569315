import AddIcon from '@mui/icons-material/Add';

import { Button } from 'ui/button/Button';

import * as S from './OpenModalButton.styles';
import { OpenModalButtonProps } from './OpenModalButton.types';

export const OpenModalButton = ({ label, onClick, disabled, sx }: OpenModalButtonProps) => (
  <Button
    disabled={disabled}
    sx={sx ? sx : S.button}
    label={label}
    onClick={onClick}
    data-testid="openModalButton"
    startIcon={<AddIcon />}
  />
);
