import { Autocomplete, TextField } from '@mui/material';

import { Category } from 'api/actions/categories/categoriesActions.types';
import { useGlobalFilter, useLocale } from 'hooks';
import { Divider } from 'ui';
import { Table } from 'ui/tables/table/Table';
import { useGetCategories } from 'api/actions/categories/categoriesActions';

import { useCategoriesColumns } from './hooks/useCategoriesColumns';
import { filtersOperators, getCategoriesParams, keysMapper } from './Categories.utils';
import * as S from './Categories.styles';

export const Categories = () => {
  const { globalFilter, setGlobalFilter, queryParamsDecoded } = useGlobalFilter({
    keysMapper,
    filtersOperators,
  });

  const { formatMessage } = useLocale();

  const { columns } = useCategoriesColumns();
  const { data, isLoading, isError } = useGetCategories({ params: getCategoriesParams(queryParamsDecoded) });
  const { data: categoriesOptions } = useGetCategories();

  const options =
    categoriesOptions?.result.map(({ id, name }) => ({
      id: id,
      value: name,
      children: name,
    })) || [];

  return (
    <>
      <Autocomplete
        onChange={(_, option) => setGlobalFilter(option ? option.value : '')}
        options={options}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} label={formatMessage({ id: 'categories.table.searchCategories' })} />
        )}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />

      <Divider />
      <Table<Category>
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        filtersOperators={filtersOperators}
        keysMapper={keysMapper}
        total={data?.total}
        data={data?.result}
        defaultSort={[{ id: 'name', desc: false }]}
        isAutoDefaultSort
        globalFilter={globalFilter}
        sx={{ table: S.table, tableHead: S.tableHead }}
      />
    </>
  );
};
