import { FilterOperator } from 'utils';

export const filtersOperators = {
  name: FilterOperator.include,
};

export const keysMapper = {
  name: 'language.name',
  displayOrder: 'display_order',
};
