import { useMemo, useState } from 'react';
import { Badge, Table } from 'lux/components';
import { Box } from '@mui/material';

import { EditButton, DeleteButton } from 'ui';
import { EditLanguageModalContainer } from '../modals/editLanguageModal/EditLanguageModalContainer';
import { useLocale } from 'hooks';
import { actionButtons } from '../../profileCertificates/profileCertificate/ProfileCertificate.styles';
import { row } from 'ui/tables/common/Tables.styles';

import * as S from './ProfileLanguage.styles';
import { ProfileLanguageProps } from './ProfileLanguage.types';

export const ProfileLanguage = ({
  profileLanguage: {
    id: profileLanguageId,
    proficiency,
    language: { name, id },
  },
  onRemoveButtonClick,
  languageOptions,
}: ProfileLanguageProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { formatMessage } = useLocale();

  const editedProfileLanguage = useMemo(() => ({ id, name, proficiency }), [id, name, proficiency]);

  return (
    <>
      <Table.Row sx={row}>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell align="center">
          <Badge color="gray" badgeContent={formatMessage({ id: `languages.${proficiency}` })} />
        </Table.Cell>

        <Table.Cell align="right">
          <Box sx={actionButtons} className="actionsCell">
            <EditButton sx={S.editButtonStyles} onClick={() => setIsEditModalOpen(true)} />
            <DeleteButton onClick={onRemoveButtonClick} />
          </Box>
        </Table.Cell>
      </Table.Row>
      {isEditModalOpen && (
        <EditLanguageModalContainer
          isOpen={isEditModalOpen}
          onModalClose={() => setIsEditModalOpen(false)}
          languageOptions={languageOptions}
          profileLanguageId={profileLanguageId}
          editedProfileLanguage={editedProfileLanguage}
        />
      )}
    </>
  );
};
