import { Box, FilterOptionsState, IconButton } from '@mui/material';
import { Button } from 'lux/components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, useState } from 'react';

import { Skill, SkillType } from 'api/actions/skills/skillsActions.types';
import { Autocomplete, Loader, Separator } from 'ui';
import { Section } from '../../../../components/section/Section';
import { useLocale } from 'hooks';
import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';

import { ProfileSkillContainer } from './profileSkill/ProfileSkillContainer';
import { ProfileSkillsProps } from './ProfileSkills.types';

const OPTIONS_TO_DISPLAY_AMOUNT = 100;

export const ProfileSkills = ({
  type,
  skillsOptions,
  isLoading,
  profileSkills,
  onAutocompleteChange,
}: ProfileSkillsProps) => {
  const [newOptionsCount, setNewOptionsCount] = useState(0);
  const { formatMessage } = useLocale();

  const titleByType: Record<SkillType, string> = {
    [SkillType.Soft]: 'step.subTitle.softSkills',
    [SkillType.Tech]: 'step.subTitle.techSkills',
    [SkillType.Custom]: 'step.subTitle.specialSkills',
  };

  const descriptionByType: Record<SkillType, string> = {
    [SkillType.Soft]: 'step.description.softSkills',
    [SkillType.Tech]: '',
    [SkillType.Custom]: '',
  };

  const filterOptions = (options: AutocompleteOption[], state: FilterOptionsState<Skill>) => {
    return options.filter((option) => option.name.includes(state.inputValue)).slice(0, OPTIONS_TO_DISPLAY_AMOUNT);
  };

  return (
    <Section title={titleByType[type]} subtitle={descriptionByType[type]}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ pl: 1, pr: 1 }}>
          {profileSkills.map((profileSkill) => (
            <ProfileSkillContainer key={profileSkill.id} profileSkill={profileSkill} skillType={type} />
          ))}
          {Array.from({ length: newOptionsCount }).map((_, index) => (
            <Fragment key={index}>
              <Box sx={{ display: 'flex' }}>
                <Autocomplete
                  onChange={(value) => {
                    onAutocompleteChange(value);
                    setNewOptionsCount(newOptionsCount - 1);
                  }}
                  value={null}
                  options={skillsOptions}
                  excludedIds={profileSkills.map(({ skillId }) => skillId)}
                  label="form.skill"
                  blurOnSelect
                  freeSolo
                  forcePopupIcon
                  dataTestId={titleByType[type].split('.').pop()}
                  withAddNewButton
                  filterOptions={filterOptions}
                />
                <IconButton onClick={() => setNewOptionsCount(newOptionsCount - 1)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Separator />
            </Fragment>
          ))}
          <Button startIcon={<AddIcon />} variant="text" onClick={() => setNewOptionsCount(newOptionsCount + 1)}>
            {formatMessage({ id: 'form.addNewSkill' })}
          </Button>
        </Box>
      )}
    </Section>
  );
};
