import { useEffect } from 'react';

import { TagModalContainerProps } from 'views/adminSection/tags/modals/TagModal/TagModalContainer.types';
import { TagModal } from 'views/adminSection/tags/modals/TagModal/TagModal';
import { useSetFormError } from 'hooks';
import { TagModalFormFields, TagModalFormValues } from 'views/adminSection/tags/modals/TagModal/TagModal.types';

export const TagModalContainer = ({
  onClose,
  isOpen,
  onSubmit,
  header,
  isLoading,
  formMethods,
  error,
}: TagModalContainerProps) => {
  const { reset, setError } = formMethods;
  const { setFormError } = useSetFormError();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  useEffect(() => {
    setFormError<TagModalFormValues>(setError, error, TagModalFormFields, 'name');
  }, [setFormError, setError, error]);

  return (
    <TagModal
      header={header}
      isOpen={isOpen}
      onSubmit={onSubmit}
      formMethods={formMethods}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};
