import { Box, Link } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';

import { Button } from 'ui';
import { Steps, StepsRoutesNames } from 'views/profileEditor/ProfileEditor.types';
import { AppRoute } from 'routing/AppRoute.enum';

import * as S from './ActionButtons.styles';
import { ActionButtonsProps } from './ActionButtons.types';

export const ActionButtons = ({ onPrintResume }: ActionButtonsProps) => {
  const { id } = useParams<{ id: string }>();
  const backHref = `${AppRoute.ProfileEditor}/${StepsRoutesNames[Steps.BasicData]}/${id}`;

  return (
    <Box sx={S.buttonsWrapper}>
      <Button onClick={onPrintResume} sx={S.button} startIcon={<PrintIcon />} label="generatedProfile.editor.print" />

      <Link href={backHref} underline="none" sx={S.button}>
        <Button sx={S.button} variant="outlined" startIcon={<ArrowBack />} label="generatedProfile.editor.back" />
      </Link>
    </Box>
  );
};
