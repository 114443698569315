import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { InputHTMLAttributes } from 'react';

import { Loader, Separator, Typography } from 'ui';
import { useLocale } from 'hooks';

import * as S from './ConfirmUpdate.styles';
import { ConfirmUpdateProps } from './ConfirmUpdate.types';

export const ConfirmUpdate = ({
  isUpadatingProfile,
  isUpdatingSuccess,
  isChecked,
  onUpdateProfile,
}: ConfirmUpdateProps) => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={S.wrapper}>
      {isUpadatingProfile && (
        <Box sx={S.loaderOverlay}>
          <Loader />
        </Box>
      )}

      <Typography variant="h6">{'step.confirmation.title'}</Typography>
      <Separator height={8} />
      <Typography variant="caption" color="text.secondary">
        {'step.confirmation.description'}
      </Typography>
      <Separator height={16} />

      <FormControlLabel
        value={isChecked}
        sx={S.formControl(isUpdatingSuccess || isUpadatingProfile)}
        onChange={onUpdateProfile}
        control={
          <Checkbox
            inputProps={{ 'data-testid': 'confirmCheckbox' } as InputHTMLAttributes<HTMLInputElement>}
            checked={isChecked}
          />
        }
        componentsProps={{
          typography: {
            variant: 'body2',
            sx: S.confirmLabel,
          },
        }}
        label={formatMessage(
          { id: 'step.confirmation.checkLabel' },
          {
            timestamp: new Date(Date.now()).toLocaleDateString(),
          },
        )}
      />
    </Box>
  );
};
