import { Helmet } from 'react-helmet';

import { useContentAccess, useLocale, useModal } from 'hooks';
import { AdminSectionWrapper, OpenModalButton } from 'ui';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { ProjectCreationModalContainer } from './modals/projectCreationModal/ProjectCreationModalContainer';
import { Projects } from './Projects';

export const ProjectsContainer = () => {
  const { formatMessage } = useLocale();
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Projects });

  const {
    isOpen: isCreateProjectModalOpen,
    onOpen: onCreateProjectModalOpen,
    onClose: onCreateProjectModalClose,
  } = useModal();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.projects' })}</title>
      </Helmet>

      <AdminSectionWrapper
        title="navigator.projects"
        action={
          isReadOnly ? null : <OpenModalButton label="profileProject.form.add" onClick={onCreateProjectModalOpen} />
        }
        tabs="projectsTabs"
      >
        <Projects />

        {isCreateProjectModalOpen && (
          <ProjectCreationModalContainer isOpen={isCreateProjectModalOpen} onClose={onCreateProjectModalClose} />
        )}
      </AdminSectionWrapper>
    </>
  );
};
