import { useProfileProjectsGet } from 'api/actions/profile/projects/profileProjectsActions';
import { useProjectsGet } from 'api/actions/projects/projectsActions';
import { useCurrentUser } from 'hooks';

export const useProjectsQuery = () => {
  const { user } = useCurrentUser();

  const { data: projects, isLoading: projectsIsLoading } = useProjectsGet({
    params: {
      ['filter[project.isPrivate][eqOr]']: false,
    },
  });

  const { data: profileProjects, isLoading: profileProjectsIsLoading } = useProfileProjectsGet(user?.id ?? '', {
    params: {
      relations: ['profileProject.project'],
      ['sort[profileProject.leftAt]']: 'DESC',
      ['sort[profileProject.joinedAt]']: 'DESC',
    },
  });

  const isLoading = projectsIsLoading || profileProjectsIsLoading;

  return {
    projects,
    profileProjects,
    isLoading,
  };
};
