import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { postProjectRoleMutation } from 'api/actions/projectRoles/projectRolesActions';
import { ProjectRolesPostArgs } from 'api/actions/projectRoles/projectRolesActions.types';
import { getProjectRolesTableKey } from 'views/adminSection/projectRoles/hooks/useProjectRolesQuery';
import { profileProjectRolesGetKey } from 'views/profileEditor/steps/experience/projects/hooks/useGetProjectRoles';

export const usePostProjectRole = () => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, error, isLoading, isSuccess, reset } = useMutation(
    'post-project-role',
    postProjectRoleMutation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getProjectRolesTableKey);
        queryClient.invalidateQueries(profileProjectRolesGetKey);
        showSnackbarSuccess('success.itemAdded');
      },
      onError: (error) => showSnackbarError(error),
    },
  );

  const addProjectRole = useCallback(
    async (body: ProjectRolesPostArgs, onSuccess?: VoidFunction) => {
      const result = await mutateAsync(body);

      if (result) {
        onSuccess && onSuccess();
      }

      return result;
    },
    [mutateAsync],
  );

  return { addProjectRole, postError: error, isPostLoading: isLoading, isPostSuccess: isSuccess, postReset: reset };
};
