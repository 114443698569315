import { useGetSkills } from 'api/actions/skills/skillsActions';
import { SkillType } from 'api/actions/skills/skillsActions.types';

export const useGetAllTechnologies = () => {
  return useGetSkills({
    params: {
      ['filter[skill.type][eq]']: SkillType.Tech,
      ['sort[skill.name]']: 'ASC',
      relations: ['skill.category'],
    },
  });
};
