import { FormInputText, FormModalWrapper } from 'ui';
import { useLocale } from 'hooks';

import { PositionModalFormFields, PositionModalProps } from './PositionModal.types';

export const PositionModal = ({ formMethods, header, isOpen, onClose, isLoading, onSubmit }: PositionModalProps) => {
  const { formatMessage } = useLocale();

  return (
    <FormModalWrapper
      header={header}
      isOpen={isOpen}
      onSubmit={onSubmit}
      formMethods={formMethods}
      isLoading={isLoading}
      onClose={onClose}
    >
      {formMethods.getValues(PositionModalFormFields.DwhCode) && (
        <FormInputText
          helperText={formatMessage({ id: 'positions.form.dwhCodeHelperText' })}
          name={PositionModalFormFields.DwhCode}
          label="positions.placeholder.dwhCode"
          required={false}
          disabled={true}
        />
      )}
      <FormInputText
        name={PositionModalFormFields.VisibleName}
        label="positions.placeholder.name"
        required
        helperText={formatMessage({ id: 'positions.form.nameHelperText' })}
      />
    </FormModalWrapper>
  );
};
