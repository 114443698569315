import { Link } from 'react-router-dom';
import { Button } from 'lux/components';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { Tooltip } from 'ui';

import { EditButtonLinkProps } from './EditButtonLink.types';
import * as S from './EditButtonLink.styles';

export const EditButtonLink = ({ disabled, tooltipTitle, testId, ...props }: EditButtonLinkProps) => {
  if (disabled) {
    return (
      <Tooltip title={disabled ? '' : tooltipTitle || 'tooltips.edit'}>
        <Button adata-testid={testId || 'edit'} iconOnly disabled aria-label="edit" sx={S.editButtonLink}>
          <EditIcon />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipTitle || 'tooltips.edit'}>
      <Link data-testid={testId || 'edit'} {...props}>
        <Button data-testid="edit" aria-label="edit" iconOnly sx={S.editButtonLink} {...props}>
          <EditIcon />
        </Button>
      </Link>
    </Tooltip>
  );
};
