import { DragDropContext } from 'react-beautiful-dnd';

import { AdminSectionTable, AdminSectionWrapper, Loader, Typography } from 'ui';
import { useContentAccess } from 'hooks';
import { handleDragEnd } from 'ui/tables/common';
import { ContentType } from 'config/userPermisions/userPermisions.types';

import { SeniorityContainer } from './seniority/SeniorityContainer';
import { SenioritiesProps } from './Seniorities.types';
import { usePatchSeniority } from './hooks/usePatchSeniority';

export const Seniorities = ({ seniorities, isLoading, isRefetching }: SenioritiesProps) => {
  const { isReadOnly } = useContentAccess({ allowedContent: ContentType.Seniority });

  const { reorderSeniority, isError: isOrderChangeError, isLoading: isOrderChangeLoading } = usePatchSeniority();

  return (
    <AdminSectionWrapper title="navigator.seniorities" tabs="profileTabs">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DragDropContext
            onDragEnd={async (result) => {
              handleDragEnd(seniorities, result, reorderSeniority);
            }}
          >
            {(isOrderChangeLoading || isRefetching) && <Loader fitToParent />}
            <AdminSectionTable dragAndDropID="senioritiesDND" ariaLabel="Seniorities table">
              {seniorities.map((seniority, index) => (
                <SeniorityContainer
                  disabled={isReadOnly}
                  dragAndDropIndex={index}
                  key={seniority.id}
                  seniority={seniority}
                />
              ))}
            </AdminSectionTable>
          </DragDropContext>
          {isOrderChangeError && <Typography variant="helperText">{'seniorities.validation.order'}</Typography>}
        </>
      )}
    </AdminSectionWrapper>
  );
};
