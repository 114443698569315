import { Box } from '@mui/material';
import { Grid } from 'lux/components';
import { Link } from 'react-router-dom';

import { AppRoute } from 'routing/AppRoute.enum';
import { ReactComponent as PreviewIcon } from 'assets/icons/zip-file-search.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Button, Separator, Typography } from 'ui';
import { useCurrentUser, useUser } from 'hooks';

import { ProfileEditorProps, Steps } from './ProfileEditor.types';
import { Stepper } from './components/stepper/Stepper';
import { BasicDataContainer, Experience, Skills, SummaryContainer } from './steps';
import * as S from './ProfileEditor.styles';

export const ProfileEditor = ({ activeStep, setActiveStep }: ProfileEditorProps) => {
  const { formatMessage } = useLocale();

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const steps = [
    formatMessage({ id: 'step.basicData' }),
    formatMessage({ id: 'step.skills' }),
    formatMessage({ id: 'step.experience' }),
    formatMessage({ id: 'step.confirmation' }),
  ];

  const stepComponent = {
    [Steps.BasicData]: <BasicDataContainer handleNext={handleNextStep} />,
    [Steps.Skills]: <Skills />,
    [Steps.Experience]: <Experience />,
    [Steps.Summary]: <SummaryContainer />,
  }[activeStep];

  const { user } = useCurrentUser();
  const { isAdmin } = useUser();
  const showPreviewCVButton = isAdmin || (activeStep !== 0 && activeStep !== 3);

  return (
    <>
      <Grid item xs={4} sx={S.sidebar}>
        <Box sx={S.sidebarBox}>
          <Typography variant="h4">{'navigator.profileEditor'}</Typography>
          <Separator height={40} />
          <Stepper steps={steps} activeStep={activeStep} onStepClick={setActiveStep} />

          {showPreviewCVButton && (
            <Link target={user?.id} to={`${AppRoute.GeneratedProfile}/${user?.id}`} data-testid={'preview'}>
              <Button sx={S.stickyWhileScrolling} label="form.previewCV" startIcon={<PreviewIcon />} />
            </Link>
          )}
        </Box>
        {activeStep > 0 && (
          <Button dataTestId="backButton" label="form.back" onClick={handlePreviousStep} sx={S.backButtonBottom} />
        )}
      </Grid>
      <Grid item xs={6}>
        <Box sx={S.stepStyles}>
          {stepComponent}
          {activeStep > 0 && activeStep < 3 && (
            <Button dataTestId="nextButton" label="form.next" onClick={handleNextStep} sx={S.nextButtonStyles} />
          )}
        </Box>
      </Grid>
    </>
  );
};
