import { SkillsOrder, SkillsOrderEditorPatchBody } from 'api/actions/skillsOrderEditor/skillsOrderEditorActions.types';
import { ClientErrorResponse } from 'api/types/types';

export enum SkillsOrderEditorFormFields {
  SkillsOrder = 'skillsOrder',
}

export type SkillsOrderEditorFormValues = {
  [SkillsOrderEditorFormFields.SkillsOrder]: string;
};

export type SkillsOrderEditorProps = {
  skillsOrderData: SkillsOrder;
  onSubmit: (body: SkillsOrderEditorPatchBody) => void;
  error: ClientErrorResponse | null;
};
