import { Helmet } from 'react-helmet';

import { SkillType } from 'api/actions/skills/skillsActions.types';
import { useLocale, useUser } from 'hooks';
import { CurrentlyEditedUser } from 'views/profileEditor/components/currentlyEditedUser/CurrentlyEditedUser';

import { ProfileSkillsContainer } from './subSteps/profileSkills/ProfileSkillsContainer';
import { ProfileLanguagesContainer } from './subSteps/profileLanguages/ProfileLanguagesContainer';
import { ProfileCertificatesContainer } from './subSteps/profileCertificates/ProfileCertificatesContainer';

export const Skills = () => {
  const { formatMessage } = useLocale();
  const { isAdmin } = useUser();

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'title.skillsEditor' })}</title>
      </Helmet>
      <CurrentlyEditedUser />

      <ProfileSkillsContainer type={SkillType.Soft} />
      {isAdmin && (
        <>
          <ProfileSkillsContainer type={SkillType.Custom} />
        </>
      )}
      <ProfileLanguagesContainer />
      <ProfileCertificatesContainer />
    </>
  );
};
