import { useContext } from 'react';

import { UserContext } from 'context/user/userContext/UserContext';

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('UserContext must be within UserProvider');
  }

  return context;
};
