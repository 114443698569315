import { Styles } from 'lux/theme';

export const table: Styles = {
  position: 'relative',
};

export const tableHead: Styles = {
  '& tr th:last-child': {
    width: 110,
    textAlign: 'center',
  },
};
