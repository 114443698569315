export type Achievement = {
  createdAt: Date;
  description: string;
  id: string;
  profileId: string;
  updatedAt: Date;
};

export type AchievementsGetResponse = {
  result: Achievement[];
  total: number;
};

export type AchievementsProps = {
  isLoading: boolean;
  achievements: Achievement[];
};

export enum AchievementsFormFields {
  Achievement = 'description',
}

export type AchievementsFormValues = {
  [AchievementsFormFields.Achievement]: string;
};

export type AchievementsEditFormValues = AchievementsFormValues;
