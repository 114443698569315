import { AxiosRequestConfig } from 'axios';
import { UseMutationOptions } from 'react-query';

import { useMutation, useQuery } from 'hooks';
import { ClientErrorResponse, ClientResponse } from 'api/types/types';
import { FIFTEEN_MINUTES, TEN_MINUTES } from 'utils';

import {
  CertificateAddArgs,
  CertificateAddResponse,
  CertificateDeleteArgs,
  CertificateDeleteResponse,
  CertificateEditArgs,
  CertificateEditResponse,
  CertificateGetResponse,
  CertificatesGetResponse,
  ProfilesCertificatesGetResponse,
} from './certificatesActions.types';

export const getCertificatesQueryKey = 'certificates';

export const useCertificateGet = (certificateId: string, config?: AxiosRequestConfig) =>
  useQuery<CertificateGetResponse>(
    `/certificates/certificate/${certificateId}`,
    [getCertificateQueryKey, certificateId],
    {
      refetchOnMount: 'always',
    },
    config,
  );

export const useCertificatesGet = (config?: AxiosRequestConfig) =>
  useQuery<CertificatesGetResponse>(
    '/certificates',
    [getCertificatesQueryKey, config],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );

export const profilesCertificatesQueryKey = 'expired-certificates';

export const useProfilesCertificatesGet = (config?: AxiosRequestConfig) =>
  useQuery<ProfilesCertificatesGetResponse>(
    '/profiles/certificates',
    [profilesCertificatesQueryKey, config?.params],
    { refetchOnMount: false, staleTime: TEN_MINUTES, cacheTime: FIFTEEN_MINUTES, keepPreviousData: true },
    config,
  );

export const useCertificatePost = (
  options: UseMutationOptions<ClientResponse<CertificateAddResponse>, ClientErrorResponse, CertificateAddArgs>,
) =>
  useMutation(
    'postCertificate',
    ({ body }) => ({
      endpoint: '/certificates/certificate',
      method: 'POST',
      params: body,
    }),
    options,
  );

export const useCertificatePatch = (
  options: UseMutationOptions<ClientResponse<CertificateEditResponse>, ClientErrorResponse, CertificateEditArgs>,
) =>
  useMutation(
    'patchCertificate',
    ({ certificateId, body }) => ({
      endpoint: `/certificates/certificate/${certificateId}`,
      method: 'PATCH',
      params: body,
    }),
    options,
  );

const getCertificateQueryKey = 'certificate';

export const useCertificateDelete = (
  options?: UseMutationOptions<ClientResponse<CertificateDeleteResponse>, ClientErrorResponse, CertificateDeleteArgs>,
) =>
  useMutation(
    'deleteCertificate',
    ({ certificateId, forceDelete = false }) => ({
      endpoint: `/certificates/certificate/${certificateId}`,
      method: 'DELETE',
      params: { forceDelete },
    }),
    options,
  );
