import { Box, Button, Divider, Grid } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { format } from 'date-fns';

import { Loader, Separator, Typography } from 'ui';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { useTechnologiesSurveyGet } from 'api/actions/technologiesSurvey/technologiesSurveyActions';

import { usePostTechnologiesSurvey } from './hooks/usePostTechnologiesSurvey';
import { TechnologiesSurveyModalFormValues } from './hooks/usePostTechnologiesSurvey.types';
import { mapTechnologiesSurveyToFormValues } from './TechnologiesSurvey.utils';
import * as S from './TechnologiesSurvey.styles';
import { TechnologiesSurveyProps } from './TechnologiesSurvey.types';
import { SurveyHeader } from './components/survey/surveyHeader/SurveyHeader';
import { SurveyAnswers } from './components/survey/surveyAnswers/SurveyAnswers';

export const TechnologiesSurvey = ({ formatMessage }: TechnologiesSurveyProps) => {
  const { user } = useCurrentUser();
  const { data, isLoading: isLoadingGet } = useTechnologiesSurveyGet(user?.id);

  const { control, handleSubmit } = useForm<TechnologiesSurveyModalFormValues>({
    values: mapTechnologiesSurveyToFormValues(data),
  });

  const { fields } = useFieldArray({ control, name: 'skills' });

  const { addTechnologiesSurvey, isLoading } = usePostTechnologiesSurvey();

  const onSubmit = handleSubmit((formData) => {
    addTechnologiesSurvey(formData);
  });

  if (isLoadingGet) {
    return <Loader />;
  }

  return (
    <>
      <Separator height={60} />

      <Grid container xs={12} lg={10} xl={8} textAlign="center">
        <Grid item xs={12} textAlign="left">
          <Separator height={20} />
          <Typography variant="h6">{'navigator.technologiesSurvey'}</Typography>
          <Separator height={20} />
          <Typography>{'technologiesSurvey.description'}</Typography>
          <Separator height={20} />

          {data?.profile.surveyUpdatedAt && (
            <Box sx={S.lastUpdateDate}>
              {formatMessage({ id: 'technologiesSurvey.lastUpdateDate' })}
              {format(new Date(data.profile.surveyUpdatedAt), 'yyyy-MM-dd')}
            </Box>
          )}

          <Separator height={20} />
        </Grid>

        <Grid container sx={S.formContainer}>
          <Grid container xs={12}>
            <Grid item xs={2} />
            <Grid item xs={4}>
              <Typography sx={S.header} variant="body2">
                {'technologiesSurvey.form.header.familiarity'}
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <Typography sx={S.header} variant="body2">
                {'technologiesSurvey.form.header.preference'}
              </Typography>
            </Grid>
          </Grid>
          <Separator height={40} />
          <SurveyHeader />
          <Divider variant="middle" sx={S.hr} />
          <SurveyAnswers fields={fields} control={control} formatMessage={formatMessage} />
          <Divider variant="middle" sx={S.hr} />
          <Grid item xs={12}>
            <Button variant="contained" onClick={onSubmit} disabled={isLoading}>
              <Typography>{'technologiesSurvey.form.submit'}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
