import { Box } from '@mui/material';

import { Loader } from 'ui/loader/Loader';
import { Typography } from 'ui/typography/Typography';

import * as Styles from './TableStatus.styles';
import { TableStatusProps } from './TableStatus.types';

export const TableStatus = <T extends Record<string, unknown>>({ data, isError, isLoading }: TableStatusProps<T>) => {
  if (data?.length && isLoading) {
    return <Loader fitToParent />;
  }

  if (data?.length && !isError) {
    return null;
  }

  return (
    <Box sx={Styles.wrapper}>
      {isLoading && <Loader sx={Styles.loader} />}
      {isError && <Typography sx={Styles.textPlaceholder}>{'error.errorOccurred'}</Typography>}
      {!data.length && !isLoading && !isError && <Typography sx={Styles.textPlaceholder}>{'table.noData'}</Typography>}
    </Box>
  );
};
