import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { Profile } from '../profiles/profilesActions.types';

import { UpdateBasicDataMutationArgs } from './userActions.types';

export const loggedUserUrl = '/profiles/me';

export const userUrl = (id: string) => `/profiles/profile/${id}`;

export const patchBasicDataMutation: MutationFn<UpdateBasicDataMutationArgs, Profile> = ({ id, ...body }) => ({
  endpoint: `profiles/profile/${id}`,
  method: 'PATCH',
  params: {
    ...body,
  },
});
