import { useCurrentUser, useQuery } from 'hooks';
import { useProfileProjectTechnologiesGet } from 'api/actions/profile/projects/technologies/profileProjectsTechnologiesActions';
import { useProjectGet } from 'api/actions/projects/projectsActions';
import { ProfileProjectGetResponse } from 'api/actions/profile/projects/profileProjectsActions.types';
import { profileProjectGetKey } from 'api/actions/profile/projects/profileProjectsActions';

export const useGetProfileProjectData = (projectId: string, profileProjectId: string) => {
  const { user } = useCurrentUser();

  const { data: profileProject, isLoading: isProfileProjectLoading } = useQuery<ProfileProjectGetResponse>(
    `/profiles/${user?.id}/project/${profileProjectId}`,
    [profileProjectGetKey, `/profiles/${user?.id}/project/${profileProjectId}`],
    undefined,
  );

  const { data: project, isLoading: isProjectLoading, isError } = useProjectGet(projectId);
  const {
    data: profileData,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useProfileProjectTechnologiesGet(user?.id ?? '', profileProjectId, {
    params: {
      relations: ['profileProjectSkill.skill'],
    },
  });

  return {
    profileProjectData: {
      profileProject,
      projectTechnologies: project?.technologies,
      profileProjectTechnologies: profileData?.result,
    },
    isLoading: isProfileLoading || isProfileProjectLoading || isProjectLoading,
    isError: isProfileError || isError,
  };
};
