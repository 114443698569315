import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { patchSeniorityMutation, seniorityQueryKey } from 'api/actions/seniorities/senioritiesActions';
import { PatchSeniorityBody } from 'api/actions/seniorities/senioritiesActions.types';
import { DragAndDropBody } from 'api/types/types';

export const usePatchSeniority = (seniorityId?: string, onSuccess?: VoidFunction) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, isSuccess, isLoading, error } = useMutation('patchSeniority', patchSeniorityMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(seniorityQueryKey);
      onSuccess && onSuccess();

      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => showSnackbarError(error),
  });

  const editSeniority = useCallback(
    async (body: PatchSeniorityBody) => {
      if (!seniorityId) {
        throw new Error('Add seniority ID to usePatchSeniority -> editSeniority function.');
      }
      await mutateAsync(body);
    },
    [mutateAsync, seniorityId],
  );

  const reorderSeniority = useCallback(
    async (body: DragAndDropBody) => {
      await mutateAsync(body);
    },
    [mutateAsync],
  );

  return { editSeniority, reorderSeniority, isError, isSuccess, isLoading, error };
};
