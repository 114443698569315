import { FormattedMessage } from 'react-intl';
import { Tooltip as ICLTooltip } from 'lux/components';
import { forwardRef } from 'react';

import { isTranslationString } from 'utils/isTranslationString/isTranslationString';
import { CustomTooltipProps } from 'ui/tooltip/Tooltip.types';

export const Tooltip = forwardRef<HTMLDivElement, CustomTooltipProps>(
  ({ title, children, values, placement = 'top', arrow = true, ...props }, ref) => {
    const translatedMessage =
      typeof title === 'string' && isTranslationString(title) ? <FormattedMessage id={title} values={values} /> : title;

    return (
      <ICLTooltip {...props} ref={ref} title={translatedMessage} placement={placement} arrow={arrow} disableInteractive>
        {children}
      </ICLTooltip>
    );
  },
);
