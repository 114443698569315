import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useLocale } from 'hooks';
import { PositionModalFormFields, PositionModalFormValues } from '../positionModal/PositionModal.types';
import { getDefaultValues, getFormSchema } from '../positionModal/PositionModal.utils';
import { usePostPosition } from '../../hooks/usePostPosition';
import { FormMode } from 'ui/formModalWrapper/FormModalWrapper.types';
import { PositionModalContainer } from '../positionModal/PositionModalContainer';
import { PostPositionBody } from 'api/actions/positions/positionsActions.types';

import { AddPositionModalContainerProps } from './AddPositionModalContainer.types';

export const AddPositionModalContainer = ({ onModalClose, isModalOpen }: AddPositionModalContainerProps) => {
  const { formatMessage } = useLocale();
  const formMethods = useForm<PositionModalFormValues>({
    defaultValues: getDefaultValues(),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { handleSubmit } = formMethods;

  const { addPosition, error, isLoading } = usePostPosition(onModalClose);
  const submitOnlyVisibleName = (formBody: PostPositionBody) =>
    addPosition({ [PositionModalFormFields.VisibleName]: formBody[PositionModalFormFields.VisibleName] });

  return (
    <PositionModalContainer
      isOpen={isModalOpen}
      isLoading={isLoading}
      formMode={FormMode.add}
      formMethods={formMethods}
      onClose={onModalClose}
      onSubmit={handleSubmit(submitOnlyVisibleName)}
      header="positions.form.addCustom"
      error={error}
    />
  );
};
