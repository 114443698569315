import { Styles } from 'lux/theme';

export const button: Styles = {
  minHeight: 50,
};

export const globalFilter: Styles = {
  width: '100%',
};

export const hr: Styles = {
  mt: 3,
  mb: 3,
};
