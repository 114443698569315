import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { CategoryModalContainer } from '../categoryModal/CategoryModalContainer';
import { useLocale } from 'hooks';
import { CategoryModalFormValues } from '../categoryModal/CategoryModal.types';
import { defaultValues, getFormSchema } from '../categoryModal/CategoryModal.utils';
import { usePostCategory } from '../../hooks/usePostCategory';

import { AddCategoryModalContainerProps } from './AddCategoryModalContainer.types';

export const AddCategoryModalContainer = ({ onClose, isOpen }: AddCategoryModalContainerProps) => {
  const { formatMessage } = useLocale();

  const formMethods = useForm<CategoryModalFormValues>({
    defaultValues,
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  const { addCategory, isLoading, error } = usePostCategory(onClose);

  const { handleSubmit } = formMethods;

  return (
    <CategoryModalContainer
      isOpen={isOpen}
      isLoading={isLoading}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={handleSubmit(addCategory)}
      header="categories.form.add"
      error={error}
    />
  );
};
