import { ClientErrorResponse } from 'api/types/types';
import { isClientError, isServerError } from 'hooks/useQuery/useQuery.utils';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

export const useHandleQueryErrors = () => {
  const { showSnackbar } = useSnackbar();

  const handleErrors = (error: ClientErrorResponse, restrictArr?: string[]) => {
    if (isServerError(error.response?.status)) {
      showSnackbar('error.internalServerError', 'error');
    }

    if (isClientError(error.response?.status)) {
      error.response?.data?.errors?.forEach((err) => {
        if (!restrictArr || (restrictArr && restrictArr.includes(err.code))) {
          showSnackbar(err.code, 'error');
        }
      });
    }
  };

  return { handleErrors };
};
