import Joi from 'joi';
import { IntlShape } from 'react-intl';

import { ProjectModalFormFields } from './ProjectModal.types';

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  const stringFieldSchema = Joi.string()
    .required()
    .allow('')
    .trim()
    .messages({ 'string.empty': formatMessage({ id: 'error.required' }) });

  return Joi.object({
    [ProjectModalFormFields.Name]: stringFieldSchema,
    [ProjectModalFormFields.Description]: stringFieldSchema,
    [ProjectModalFormFields.ShortDescription]: stringFieldSchema,
    [ProjectModalFormFields.IsPrivate]: Joi.boolean().required(),
    [ProjectModalFormFields.Technologies]: Joi.array()
      .items(
        Joi.object({
          id: Joi.string().optional(),
          name: Joi.alternatives().conditional('id', {
            is: Joi.string().required(),
            then: Joi.string().optional(),
            otherwise: Joi.string().required().trim(),
          }),
        }),
      )
      .optional(),
  });
};
