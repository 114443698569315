import { componentsLibTheme as theme, Styles } from 'lux/theme';

export const tableHead: Styles = {
  '& tr th: first-of-type': {
    padding: theme.spacing(2, 2, 0, 1),
  },
  '& tr th: last-of-type': {
    width: '110px',
    textAlign: 'center',
  },
};
