import { Styles } from 'lux/theme';

export const headerWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const technologyAutocomplete: Styles = {
  pointerEvents: 'none',
};
