import { AutocompleteOption, AutocompleteValue } from './Autocomplete.types';

export const formatValue = (autocompleteValue: AutocompleteValue | null) => {
  if (autocompleteValue === null) return null;

  const isValueAString = typeof autocompleteValue === 'string';
  const name = isValueAString ? autocompleteValue : autocompleteValue.name;
  const id = isValueAString ? autocompleteValue : autocompleteValue.id;

  return { name, id };
};

export const normalizeValue = (newValue: string | AutocompleteOption): string => {
  if (!newValue) return '';
  if (typeof newValue === 'string') return newValue;

  return newValue.name;
};
