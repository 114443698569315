import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect } from 'react';

import { LanguageModal } from '../languageModal/LanguageModal';
import { useLocale } from 'hooks';
import { LanguageModalFormValues } from '../languageModal/LanguageModal.types';
import { getDefaultValues, getFormSchema } from '../languageModal/LanguageModal.utils';
import { usePatchProfileLanguage } from '../../hooks/usePatchProfileLanguage';

import { EditLanguageModalContainerProps } from './EditLanguageModalContainer.types';

export const EditLanguageModalContainer = ({
  isOpen,
  onModalClose,
  languageOptions,
  editedProfileLanguage,
  profileLanguageId,
}: EditLanguageModalContainerProps) => {
  const { formatMessage } = useLocale();
  const formMethods = useForm<LanguageModalFormValues>({
    defaultValues: getDefaultValues(editedProfileLanguage),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });
  const { reset } = formMethods;

  useEffect(() => {
    if (isOpen) {
      reset(getDefaultValues(editedProfileLanguage));
    }
  }, [isOpen, reset, editedProfileLanguage]);

  const { isLoading, editProfileLanguage, error } = usePatchProfileLanguage(profileLanguageId, onModalClose);

  return (
    <LanguageModal
      error={error}
      editedLanguage={{ id: editedProfileLanguage.id, name: editedProfileLanguage.name }}
      isOpen={isOpen}
      onModalClose={onModalClose}
      formMethods={formMethods}
      onSubmit={editProfileLanguage}
      languageOptions={languageOptions}
      isLoading={isLoading}
      submitLabel={formatMessage({ id: 'form.save' })}
    />
  );
};
