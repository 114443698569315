import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

import { parseSearchParams } from './useGetQueryParams.utils';

export const useGetQueryParams = () => {
  const [searchParams] = useSearchParams();

  const initialQueryParams = useMemo(() => parseSearchParams(searchParams), []);
  const queryParams = useMemo(() => parseSearchParams(searchParams), [searchParams]);

  return { queryParams, initialQueryParams };
};
