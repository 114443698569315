import { useCurrentUser } from 'hooks';
import { useGetLanguages } from 'api/actions/languages/languagesActions';
import { useProfileLanguagesGet as useProfileLanguagesGetHook } from 'api/actions/profile/languages/profileLanguagesActions';

export const useProfileLanguagesQuery = () => {
  const { user } = useCurrentUser();

  const {
    data: languages,
    isLoading: isLanguagesLoading,
    isError: isLanguagesError,
  } = useGetLanguages({
    params: {
      ['sort[language.name]']: 'ASC',
    },
  });

  const {
    data: profileLanguages,
    isLoading: isProfileLanguagesLoading,
    isError: isProfileLanguagesError,
  } = useProfileLanguagesGetHook(user?.id, {
    params: {
      relations: ['profileLanguage.language'],
    },
  });

  return {
    languages,
    profileLanguages,
    isLoading: isLanguagesLoading || isProfileLanguagesLoading,
    isError: isLanguagesError || isProfileLanguagesError,
  };
};
