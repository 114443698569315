import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { deleteProjectRoleMutation } from 'api/actions/projectRoles/projectRolesActions';
import { ProjectRolesDeleteArgs, ProjectRolesGetResponse } from 'api/actions/projectRoles/projectRolesActions.types';
import { mutationUpdater } from 'utils/mutationUpdater/mutationUpdater';
import { profileProjectGetKey, profileProjectsGetKey } from 'api/actions/profile/projects/profileProjectsActions';
import { profileProjectRolesGetKey } from 'views/profileEditor/steps/experience/projects/hooks/useGetProjectRoles';
import { getProjectRolesTableKey } from 'views/adminSection/projectRoles/hooks/useProjectRolesQuery';

export const deleteProjectRoleKey = 'project-roles-delete';

export const useDeleteProjectRole = () => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, error, isSuccess, reset } = useMutation(deleteProjectRoleKey, deleteProjectRoleMutation, {
    onSuccess: (_, variables) => {
      queryClient.refetchQueries(profileProjectGetKey);
      queryClient.invalidateQueries(profileProjectsGetKey);
      queryClient.invalidateQueries(getProjectRolesTableKey);
      queryClient.invalidateQueries(profileProjectRolesGetKey);
      queryClient.setQueriesData<ProjectRolesGetResponse>(profileProjectRolesGetKey, (projectRoles) =>
        mutationUpdater('DELETE', variables.id, projectRoles),
      );
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error, [409]),
  });

  const deleteProjectRole = useCallback(
    async ({ id, forceDelete }: ProjectRolesDeleteArgs) => {
      await mutateAsync({ id, forceDelete });
    },
    [mutateAsync],
  );

  return { deleteProjectRole, deleteError: error, isDeleteSuccess: isSuccess, deleteReset: reset };
};
