import { useMemo } from 'react';
import { QueryKey, UseQueryResult, useQuery as useRqQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosRequestConfig } from 'axios';

import { useClient } from 'hooks';
import { ClientErrorResponse, ErrorData } from 'api/types/types';
import { useHandleQueryErrors } from 'hooks/useHandleQueryErrors/useHandleQueryErrors';

export const useQuery = <TData, TError extends ErrorData = ErrorData>(
  queryUrl: string,
  queryKey?: QueryKey,
  options?: UseQueryOptions<TData, ClientErrorResponse<TError>>,
  config?: AxiosRequestConfig,
): UseQueryResult<TData, ClientErrorResponse<TError>> => {
  const { queryFn } = useClient();
  const { handleErrors } = useHandleQueryErrors();

  const _queryFn = useMemo(() => queryFn<TData>(queryUrl, config), [queryFn, config, queryUrl]);

  return useRqQuery<TData, ClientErrorResponse<TError>, TData, QueryKey>(queryKey ?? queryUrl, _queryFn, {
    ...options,
    onError: (error) => {
      handleErrors(error);
      if (options?.onError) {
        options.onError(error);
      }
    },
  });
};
