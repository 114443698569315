import { IntlShape } from 'react-intl';
import Joi from 'joi';

import { getErrorMessages } from 'utils/getErrorMessages/getErrorMessages';
import { Language } from 'api/actions/languages/languagesActions.types';

import { LanguageModalFormFields } from './LanguageModal.types';

const NAME_MAX_LENGTH = 100;

export const defaultValues = {
  [LanguageModalFormFields.Name]: '',
};

export const getDefaultValues = ({ name }: Language) => ({
  [LanguageModalFormFields.Name]: name,
});

export const getFormSchema = (formatMessage: IntlShape['formatMessage']) => {
  return Joi.object({
    [LanguageModalFormFields.Name]: Joi.string()
      .max(NAME_MAX_LENGTH)
      .trim()
      .required()
      .messages(getErrorMessages({ formatMessage, maxLength: NAME_MAX_LENGTH })),
  });
};
