import { Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import DefaultAvatar from 'assets/images/default-avatar.png';
import { useCurrentUser, useLocale, useModal, useUser } from 'hooks';
import { BorderBox, DeleteButton, DeleteModal, EditButton, Loader, Typography } from 'ui';
import { EditProfileModalContainer } from 'views/adminSection/profiles/modals/editProfileModal/EditProfileModalContainer';
import { useDeleteProfile } from 'views/profileEditor/hooks/useDeleteProfile';

import * as S from './CurrentlyEditedUser.styles';

export const CurrentlyEditedUser = () => {
  const { isOpen: isEditModalOpen, onClose: onEditModalClose, onOpen: onEditModalOpen } = useModal();
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useModal();
  const { user, refetch } = useCurrentUser();
  const { isAdmin } = useUser();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const { deleteProfile } = useDeleteProfile({
    profileId: user?.id || '',
    onSuccess: () => navigate('/profiles'),
    onError: () => onDeleteModalClose(),
  });

  const handleModalClose = async () => {
    await refetch();
    onEditModalClose();
  };
  const handleDeleteProfile = () => {
    if (user?.id) deleteProfile();
  };

  if (!user) {
    return <Loader />;
  }

  return (
    <>
      <BorderBox>
        <Box sx={S.wrapper}>
          <Avatar sx={{ width: 88, height: 88 }} src={user.pictureUrl ?? DefaultAvatar} alt="User avatar" />
          <Box>
            <Typography sx={S.name} variant="h5">{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</Typography>
            <Typography sx={S.subtitle} variant="subtitle1">
              {`${user.seniority?.visibleName ?? ''} ${user.position?.visibleName ?? ''}`}
            </Typography>
            <Typography sx={S.caption} variant="caption">
              {`${user.email ?? ''} `}
            </Typography>
            <Typography sx={S.caption} variant="caption">
              {formatMessage({ id: 'profile.lastUpdated' })}{' '}
              {`${format(parseISO(user.updatedAt), 'dd-MM-yyyy') ?? ''} `}
            </Typography>
          </Box>
          {isAdmin && (
            <Box sx={S.buttons}>
              <EditButton onClick={onEditModalOpen} />
              <DeleteButton onClick={onDeleteModalOpen} />
            </Box>
          )}
        </Box>
      </BorderBox>
      {isEditModalOpen && <EditProfileModalContainer isOpen={isEditModalOpen} onModalClose={handleModalClose} />}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onModalClose={onDeleteModalClose}
          onItemDelete={handleDeleteProfile}
          shortConfirmMessage={false}
          title={<Typography sx={S.titleTypography}>{'profile.delete'}</Typography>}
        />
      )}
    </>
  );
};
