import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { deleteProfileSkillMutation } from 'api/actions/profile/skills/profileSkillsActions';
import {
  GetSkillsQueryKeyType,
  GetSkillsResponse,
  SkillsQueryKeys,
  SkillType,
} from 'api/actions/skills/skillsActions.types';
import { useCurrentUser, useMutation, useSnackbar } from 'hooks';
import { generateGetProfileSkillsQueryKey } from 'api/actions/profile/skills/profileSkillsActions.utils';

export const useDeleteProfileSkill = (skillId: string, skillsType: SkillType, isSpecial: boolean) => {
  const { user } = useCurrentUser();
  const queryClient = useQueryClient();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const optionsSkillsQueryKey: GetSkillsQueryKeyType = [SkillsQueryKeys.Get, { type: skillsType }];

  const mutation = useMutation('deleteProfileSkill', deleteProfileSkillMutation, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(generateGetProfileSkillsQueryKey(variables.isSpecial, user?.id));
      queryClient.invalidateQueries<GetSkillsResponse>(optionsSkillsQueryKey);
      showSnackbarSuccess('success.itemDeleted');
    },
    onError: (error) => showSnackbarError(error),
  });

  return useCallback(async () => {
    await mutation.mutateAsync({ profileId: user?.id, skillId, isSpecial });
  }, [mutation, skillId, user?.id, isSpecial]);
};
