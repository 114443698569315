import { Box } from '@mui/material';
import { format } from 'date-fns';

import { CertificatesProps } from 'views/generatedProfile/content/certificates/Certificates.types';
import { Typography } from 'ui';
import { dateUTCToLocal } from 'utils/handleTimezone/handleTimezone';

import * as S from './Certificates.styles';

export const Certificates = ({ certificates }: CertificatesProps) => {
  return (
    <Box sx={S.wrapper}>
      <Typography sx={S.title} variant="h2" color="text.primary">
        {'generatedProfile.certificates'}
      </Typography>
      <ul>
        {certificates.map(({ name, obtainedAt }) => (
          <li key={name}>
            <Box sx={S.certificateName} component="span">
              {name}
            </Box>
            <Box sx={S.certificateDate}>{format(dateUTCToLocal(obtainedAt), 'yyyy-LL')}</Box>
          </li>
        ))}
      </ul>
    </Box>
  );
};
