import { IntlFormatters } from 'react-intl';

import {
  adminCertificatesSubMenu,
  adminProfilesSubMenu,
  adminProjectsSubMenu,
  adminSkillsSubMenu,
} from 'ui/topBar/menu/MenuDefinitions.utils';

import { Breadcrumbs } from './breadcrumbs.types';

export const canShowBreadcrumbs = (pathname: string, breadcrumbs: Breadcrumbs) => {
  return (
    Object.keys(breadcrumbs)
      .map((to: string) => {
        return pathname.startsWith(to);
      })
      .filter(Boolean).length > 1
  );
};

export const getBreadcrumbs = (intl: IntlFormatters): Breadcrumbs => {
  const breadcrumbs: Breadcrumbs = {};

  adminProfilesSubMenu.concat(adminSkillsSubMenu, adminProjectsSubMenu, adminCertificatesSubMenu).forEach((item) => {
    breadcrumbs[item.to] = intl.formatMessage({ id: `navigator.${item.label}` });
  });

  return breadcrumbs;
};
