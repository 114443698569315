import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useMutation, useSnackbar } from 'hooks';
import { patchPositionMutation } from 'api/actions/positions/positionsActions';
import { PatchPositionBody } from 'api/actions/positions/positionsActions.types';

import { adminPositionsQueryKey } from './usePositionsQuery';

export const usePatchPosition = (positionId: string, onSuccess: (name: string) => void) => {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, error, isLoading } = useMutation('patchPosition', patchPositionMutation, {
    onSuccess: ({ data: { visibleName } }) => {
      onSuccess(visibleName);
      queryClient.invalidateQueries(adminPositionsQueryKey);
      showSnackbarSuccess('success.itemEdited');
    },
    onError: (error) => showSnackbarError(error),
  });

  const editPosition = useCallback(
    async (body: PatchPositionBody) => {
      await mutateAsync({
        positionId,
        body,
      });
    },
    [mutateAsync, positionId],
  );

  return { editPosition, isError, error, isLoading };
};
