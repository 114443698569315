import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCurrentUser, useSnackbar } from 'hooks';
import { useProfileLanguagePost as useProfileLanguagePostHook } from 'api/actions/profile/languages/profileLanguagesActions';
import {
  PostProfileLanguageBody,
  ProfileLanguagesGetResponse,
} from 'api/actions/profile/languages/profileLanguagesActions.types';
import { mutationUpdater } from 'utils/mutationUpdater/mutationUpdater';

export const usePostProfileLanguage = (onSuccess: VoidFunction) => {
  const { user } = useCurrentUser();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();

  const queryClient = useQueryClient();

  const languagesQueryKey = `profiles/${user?.id}/languages`;

  const { mutateAsync, isError, isLoading, error } = useProfileLanguagePostHook({
    onSuccess: ({ data }) => {
      onSuccess();
      queryClient.setQueryData<ProfileLanguagesGetResponse>(languagesQueryKey, (languages) =>
        mutationUpdater('POST', data, languages),
      );
      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });

  const addProfileLanguage = useCallback(
    async (body: PostProfileLanguageBody) => await mutateAsync({ profileId: user?.id, body }),
    [mutateAsync, user?.id],
  );

  return { addProfileLanguage, isError, isLoading, error };
};
