import { Box } from '@mui/material';

import { Separator, Typography } from '../../../../ui';

import { sectionStyles } from './Section.styles';
import { SectionProps } from './Section.types';

export const Section = ({ title, subtitle, children }: SectionProps) => (
  <Box sx={sectionStyles}>
    {title && <Typography variant="h6">{title}</Typography>}
    {subtitle && (
      <>
        <Separator height={16} />
        <Typography variant="body2">{subtitle}</Typography>
      </>
    )}
    <Separator />
    {children}
  </Box>
);
