import { Box } from '@mui/material';
import { Stepper as ICLStepper, Step } from 'lux/components';

import { StepperProps } from './Stepper.types';
import * as S from './Stepper.styles';

export const Stepper = ({ steps, activeStep, onStepClick }: StepperProps) => (
  <Box sx={S.wrapperStyles}>
    <ICLStepper activeStep={activeStep} nonLinear largeGap>
      {steps.map((step, index) => (
        <Step key={step} label={step} onClick={() => onStepClick(index)} completed={activeStep > index} />
      ))}
    </ICLStepper>
  </Box>
);
