import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { EditTagModalContainerProps } from 'views/adminSection/tags/modals/editTagModal/EditTagModalContainer.types';
import { TagModalContainer } from 'views/adminSection/tags/modals/TagModal/TagModalContainer';
import { usePatchTag } from 'views/adminSection/tags/hooks/usePatchTag';
import { TagModalFormValues } from 'views/adminSection/tags/modals/TagModal/TagModal.types';
import { getDefaultValues, getFormSchema } from 'views/adminSection/tags/modals/TagModal/TagModal.utils';
import { useLocale } from 'hooks';

export const EditTagModalContainer = ({ tagData: { name, id }, onModalClose, isOpen }: EditTagModalContainerProps) => {
  const { patchTag, isPatchTagLoading, patchTagError } = usePatchTag({ tagId: id, onSuccess: onModalClose });

  const { formatMessage } = useLocale();

  const formMethods = useForm<TagModalFormValues>({
    defaultValues: getDefaultValues({ name }),
    resolver: joiResolver(getFormSchema(formatMessage)),
  });

  return (
    <TagModalContainer
      header="tags.form.edit"
      isOpen={isOpen}
      onSubmit={formMethods.handleSubmit(patchTag)}
      formMethods={formMethods}
      isLoading={isPatchTagLoading}
      onClose={onModalClose}
      error={patchTagError}
    />
  );
};
