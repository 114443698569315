import { AxiosError, AxiosResponse } from 'axios';

export type MutationHTTPMethod = 'DELETE' | 'POST' | 'PUT' | 'PATCH' | 'GET';

export type SingleError = {
  code: string;
  message: string;
  path?: string;
};

export type ErrorData = {
  status: number;
  errors: SingleError[];
};

export type ClientResponse<TResponse = unknown> = AxiosResponse<TResponse, unknown>;
export type ClientErrorResponse<TErrorData = ErrorData> = AxiosError<TErrorData, unknown>;

export type Collection<T> = {
  result: T[];
  total: number;
};

export type DragAndDropBody = {
  displayOrder: number;
  id: string;
  sourceIndex: number;
  destinationIndex: number;
  queryParams?: Record<string, string | number>;
};

export type MutationContext<T> = Collection<T>;

export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum QueryParams {
  PAGE = 'page',
  LIMIT = 'limit',
  SORT = 'sort',
  FILTER = 'filter',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  PROFILE_READ = 'PROFILE_READ', // Previous called "sales" profile
  NO_ACCESS = 'NO_ACCESS',
}
