import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';

import { useCurrentUser, useSnackbar } from 'hooks';
import { AutocompleteOption } from 'ui/autocomplete/Autocomplete.types';
import { profileProjectsGetKey, useProfileProjectPost } from 'api/actions/profile/projects/profileProjectsActions';
import { profileProjectRolesGetKey } from 'views/profileEditor/steps/experience/projects/hooks/useGetProjectRoles';
import { ProjectModalFormValues, ProjectType } from '../modals/projectModal/ProjectModal.types';
import { projectsGetQueryKey } from 'api/actions/projects/projectsActions';
import { skillQueryKey } from 'api/actions/skills/skillsActions';
import { useProjectContext } from '../context/ProjectContext';

import { removeDuplicates } from './utils/removeDuplicates';

export const usePostProfileProject = (onSuccess?: VoidFunction) => {
  const { user } = useCurrentUser();
  const queryClient = useQueryClient();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { setIsProjectSelected, setIsProjectNew, startTransition } = useProjectContext();

  const profileProjectsQueryKey = `/profiles/${user?.id}/projects`;

  const { mutateAsync, isError, isLoading, error } = useProfileProjectPost({
    onSuccess: () => {
      onSuccess && onSuccess();
      queryClient.invalidateQueries('project');
      queryClient.invalidateQueries([profileProjectsGetKey, profileProjectsQueryKey]);
      queryClient.invalidateQueries([profileProjectRolesGetKey]);
      queryClient.invalidateQueries([projectsGetQueryKey]);
      queryClient.invalidateQueries([skillQueryKey]);

      showSnackbarSuccess('success.itemAdded');
    },
    onError: (error) => showSnackbarError(error),
  });
  const mapSkillsToBeSubmitted = (options: AutocompleteOption[]) =>
    options.map(({ id, name }) => (id === name ? { name } : { id }));

  const filterRole = ({ id, name }: AutocompleteOption) => (id === name ? { name } : { id });

  const addExistingProfileProject = useCallback(
    async (
      projectId: string,
      {
        role,
        profileProjectTechnologies,
        projectTechnologies,
        joinedAt,
        leftAt,
        projectType,
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        name,
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        description,
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        shortDescription,
        ...values
      }: ProjectModalFormValues,
    ) => {
      if (!role) {
        throw new Error('Project role is null');
      }

      await mutateAsync({
        profileId: user?.id,
        body: {
          projectId,
          ...values,
          role: filterRole(role),
          profileProjectTechnologies: mapSkillsToBeSubmitted(profileProjectTechnologies),
          projectTechnologies: removeDuplicates([
            ...mapSkillsToBeSubmitted(projectTechnologies),
            ...mapSkillsToBeSubmitted(profileProjectTechnologies),
          ]),
          joinedAt: joinedAt ? format(joinedAt, 'yyyy-MM-dd') : null,
          leftAt: leftAt ? format(leftAt, 'yyyy-MM-dd') : null,
          isPrivate: projectType === ProjectType.Private,
        },
      });
    },
    [mutateAsync, user?.id],
  );

  const addNewProject = useCallback(
    async ({
      profileProjectTechnologies,
      projectTechnologies,
      role,
      joinedAt,
      leftAt,
      projectType,
      ...values
    }: ProjectModalFormValues) => {
      if (!role) {
        throw new Error('Project role is null');
      }

      startTransition(() => {
        setIsProjectSelected(false);
        setIsProjectNew(false);
      });

      await mutateAsync({
        profileId: user?.id,
        body: {
          ...values,
          role: filterRole(role),
          profileProjectTechnologies: mapSkillsToBeSubmitted(profileProjectTechnologies),
          projectTechnologies: removeDuplicates([
            ...mapSkillsToBeSubmitted(projectTechnologies),
            ...mapSkillsToBeSubmitted(profileProjectTechnologies),
          ]),
          joinedAt: joinedAt ? format(joinedAt, 'yyyy-MM-dd') : null,
          leftAt: leftAt ? format(leftAt, 'yyyy-MM-dd') : null,
          isPrivate: projectType === ProjectType.Private,
        },
      });
    },
    [mutateAsync, user?.id],
  );

  return { addExistingProfileProject, addNewProject, isError, isLoading, error };
};
