import { FormInputText, FormModalWrapper } from 'ui';

import { CategoryModalFormFields, CategoryModalProps } from './CategoryModal.types';

export const CategoryModal = ({ formMethods, header, isOpen, onClose, isLoading, onSubmit }: CategoryModalProps) => {
  return (
    <FormModalWrapper
      header={header}
      isOpen={isOpen}
      onSubmit={onSubmit}
      formMethods={formMethods}
      isLoading={isLoading}
      onClose={onClose}
    >
      <FormInputText autoFocus name={CategoryModalFormFields.Name} label="categories.placeholder.name" required />
    </FormModalWrapper>
  );
};
