import { FilterOperator } from 'utils';

export const filtersOperators = {
  shortDescription: FilterOperator.includeOr,
  projectName: FilterOperator.includeOr,
};

export const keysMapper = {
  shortDescription: 'project.shortDescription',
  projectName: 'project.name',
};

export const getProjectsParams = (queryParams: Record<string, string | number>) => {
  return {
    page: '1',
    limit: '10',
    ...queryParams,
    ['sort[project.name]']: 'ASC',
    relations: ['project.technologies', 'projectTechnologies.technology'],
  };
};
